import React from 'react';
import { Link } from 'react-router-dom';
import TextInput from '../../../app/common/form/TextInput';
import TextArea from '../../../app/common/form/TextArea';
import { reduxForm, Field } from 'redux-form';

function ViewForm(props) {
  const { auth, history, scope, openModal, id, initialValues, handleSubmit } =
    props;

  const onClickSemDelete = () => {
    openModal('SemDelete', {
      data: {
        auth,
        history,
        item: initialValues,
      },
    });
  };

  const handleClickSubmit = () => {};

  return (
    <>
      <div className='level'>
        <div className='level-left'>
          <div className='level-item'>
            <nav className='breadcrumb is-size-6' aria-label='breadcrumbs'>
              <ul>
                <li className='is-active has-text-weight-bold'>
                  <Link to={'/akademik/sem/' + id}>Detail</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className='level-right'>
          <div className='level-item'>
            <div className='buttons'>
              {scope && scope.subm && scope.subm['sem_edit'] && (
                <Link
                  to={`/akademik/sem/edit/${id}`}
                  className='button is-small is-rounded is-info is-outlined'
                >
                  <i className='is-size-6 mdi mdi-pencil icon' />
                </Link>
              )}
              {scope && scope.subm && scope.subm['sem_delete'] && (
                <button
                  className='button is-small is-rounded is-danger is-outlined'
                  onClick={() => onClickSemDelete()}
                >
                  <i className='is-size-6 mdi mdi-delete icon' />
                </button>
              )}
              <Link
                type='button'
                to={'/akademik/sem'}
                className='button custom-grey is-small is-rounded is-outlined'
              >
                <i className='is-size-6 mdi mdi-arrow-left icon' />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='columns'>
        <div className='column is-third-quarter'>
          <form onSubmit={handleSubmit(handleClickSubmit)} autoComplete='off'>
            <Field
              name='code'
              type='text'
              component={TextInput}
              defaultValue={initialValues?.code ?? ''}
              placeholder='Kode'
              label='Kode'
              readOnly={true}
            />
            <Field
              name='title'
              type='text'
              component={TextInput}
              defaultValue={initialValues?.title ?? ''}
              placeholder='Nama Semester'
              label='Nama Semester'
              readOnly={true}
            />
            <Field
              name='description'
              type='text'
              component={TextArea}
              defaultValue={initialValues?.description ?? ''}
              placeholder='Deskripsi'
              label='Deskripsi'
              readOnly={true}
            />
          </form>
        </div>
      </div>
    </>
  );
}

export default reduxForm({
  form: 'semView',
  enableReinitialize: true,
})(ViewForm);
