import React from 'react';
import { Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { convertSize } from '../../../app/common/helpers/othersHelpers';

export default function IndexListItems(props) {
  const { item, currentPage, itemPerPage, index, loading, scope, onDelete } =
    props;
  if (loading)
    return (
      <tr>
        <td>Loading...</td>
      </tr>
    );

  const page = (currentPage - 1) * itemPerPage;

  let file = item && item.file && JSON.parse(item.file);
  let filename = file && file.filename;
  let attach = filename ? (
    <div
      className='has-text-link has-background-grey-lighter px-2 py-1 mb-1 flex justify-between align-center mt-1'
      style={{ maxWidth: 500 }}
    >
      <span className='custom-text-overflow '>
        {filename} ({convertSize(file.filesize)})
      </span>
    </div>
  ) : (
    ''
  );

  return (
    <tr>
      <td className='has-text-centered'>{page + index + 1}</td>
      <td>{item.title}</td>
      <td>{item.date && format(parseISO(item.date), 'd LLLL yyyy')}</td>
      <td>
        <p style={{ whiteSpace: 'pre-wrap' }}>{item.description}</p>
        <div>{attach}</div>
      </td>
      <td className='has-text-centered'>
        {item.createdAt &&
          format(parseISO(item.createdAt), 'd LLLL yyyy - HH:mm:ss')}
      </td>
      <td>
        <div className='content-wrap flex justify-center'>
          <div>
            {scope && scope.subm && scope.subm['announcement_view'] && (
              <Link
                to={`/portal/pengumuman/${item.id}`}
                className='button is-small is-rounded is-info is-outlined mr-2 mb-1'
              >
                <i className='is-size-6 mdi mdi-eye icon' />
              </Link>
            )}
            {scope && scope.subm && scope.subm['announcement_edit'] && (
              <Link
                to={`/portal/pengumuman/edit/${item.id}`}
                className='button is-small is-rounded is-info is-outlined mr-2 mb-1'
              >
                <i className='is-size-6 mdi mdi-pencil icon' />
              </Link>
            )}
            {scope && scope.subm && scope.subm['announcement_delete'] && (
              <button
                className='button is-small is-rounded is-danger is-outlined mb-1'
                onClick={() => onDelete(item)}
              >
                <i className='is-size-6 mdi mdi-delete icon' />
              </button>
            )}
          </div>
        </div>
      </td>
    </tr>
  );
}
