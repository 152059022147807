import {
  asyncActionFinish,
  asyncActionError,
} from '../../../../app/common/reduxs/async/asyncActions';
// import { kalenderGet } from './reduxAction';
import { ASYNC_ACTION_START } from '../../../../app/common/reduxs/async/asyncConstant';
// import { SITE_ADDRESS } from '../../../../App/common/data/siteConfig';
// import { checkRes, checkErr } from '../../../../App/common/helpers/checkRes';
// import format from 'date-fns/format';
import { checkErr } from '../../../../app/common/helpers/checkRes';
import { fetchFunc } from '../../../../app/common/helpers/fetchHelpers';
import {
  calendarYearsGet,
  calendarsGet,
  calendarEdit,
  calendarGet,
  calendarStatus,
  calendarDelete,
} from './reduxAction';
import { nanoid } from 'nanoid';
import { logsFunc } from '../../../../app/common/helpers/othersHelpers';
import { toastr } from 'react-redux-toastr';

export const getCalendars = (auth, history) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getCalendars' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'get',
        auth,
        history,
        'calendar/',
        formData,
        dispatch
      );
      const items = res.items;
      const total = res.total;
      const search = {
        currentPage: '',
        searchText: '',
        itemPerPage: '',
      };
      dispatch(calendarsGet({ total, items, search }));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const getCalendar = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getCalendar' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'get',
        auth,
        history,
        'calendar/view/',
        formData,
        dispatch
      );
      const calendar = res.calendar;
      dispatch(calendarGet(calendar));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const addCalendar = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'addCalendar' });
    let logs = logsFunc('add', auth.userid, auth.username);
    const formData = new FormData();
    formData.append('id', values.id);
    formData.append('title', values.title.trim());
    formData.append('year', values.year);
    formData.append('events', JSON.stringify(values.events));
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'add',
        auth,
        history,
        'calendar/add/' + values.id,
        formData,
        dispatch
      );
      const calendar = res.calendar;
      dispatch(calendarGet(calendar));
      history.push({
        pathname: '/calendar/edit/' + calendar.id,
        state: { detail: calendar },
      });
      toastr.success('Success', 'Kalender berhasil disimpan.')
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const editCalendar = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'editCalendar' });
    let logs = logsFunc('edit', auth.userid, auth.username, values.logs);
    const formData = new FormData();
    formData.append('id', values.id);
    formData.append('title', values.title);
    formData.append('year', values.year);
    formData.append('events', JSON.stringify(values.events));
    formData.append('logs', logs);
    formData.append('status', values.status);
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'calendar/edit/' + values.id,
        formData,
        dispatch
      );
      const calendar = res.calendar;
      dispatch(calendarEdit(calendar));
      history.push({
        pathname: '/calendar/edit/' + calendar.id,
        state: { detail: calendar },
      });
      toastr.success('Success', 'Kalender berhasil disimpan.')
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const statusCalendar = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'statusCalendar' });
    let logs = logsFunc('edit', auth.userid, auth.username, values.logs);
    const formData = new FormData();
    formData.append('id', values.id);
    formData.append('status', values.status);
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'calendar/status/' + values.id,
        formData,
        dispatch
      );
      const calendar = res.calendar;
      dispatch(calendarStatus(calendar));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const importCalendar = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'importCalendar' });
    try {
      let logs = logsFunc('import', auth.userid, auth.username, null);
      const formData = new FormData();
      formData.append('id', nanoid(32));
      formData.append('title', values.title);
      formData.append('year', values.year);
      formData.append('events', JSON.stringify(values.data));
      formData.append('logs', logs);
      const res = await fetchFunc(
        'add',
        auth,
        history,
        'calendar/import/',
        formData,
        dispatch
      );
      const importResult = res.importResult;
      history.push({
        pathname: '/calendar/edit/' + importResult.id,
        state: { detail: importResult },
      });
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const deleteCalendar = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'deleteCalendar' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'delete',
        auth,
        history,
        'calendar/delete/' + id,
        formData,
        dispatch
      );
      const calendar = res.calendar;
      dispatch(calendarDelete(calendar));
      history.push({
        pathname: '/calendar',
        state: { viewMode: 'listmode' },
      });
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const getCalendarYears = (auth, history) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getCalendarYears' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'gets',
        auth,
        history,
        'year/all',
        formData,
        dispatch
      );
      const years = res.years;
      dispatch(calendarYearsGet(years));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};
