import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_UPDATE,
  PROFILE_GET,
  PHOTO_UPDATE,
} from './authConstant';
import { createReducer } from '../../common/utilities/reducerUtils';

const initialState = {
  isAuth: false,
  token: null,
  userId: null,
  username: null,
  authorities: {},
  expiresIn: null,
  profile: {},
};

const authLogin = (state, payload) => {
  return { ...state, ...payload.auth };
};

const authLogout = (state) => {
  return {
    isAuth: false,
    token: null,
    userId: null,
    username: null,
    authorities: {},
    expiresIn: null,
    profile: {},
  };
};

const authUpdate = (state, payload) => {
  return {
    ...state,
    ...payload.auth,
  };
};

const profileGet = (state, payload) => {
  let newProfile = {...payload.profile};
  return {
    ...state,
    profile: newProfile
  }
}

const photoUpdate = (state, payload) => {
  let newProfile = { ...state.profile, photo: payload.photo };
  return {
    ...state,
    profile: newProfile,
  };
};

export default createReducer(initialState, {
  [AUTH_LOGIN]: authLogin,
  [AUTH_LOGOUT]: authLogout,
  [AUTH_UPDATE]: authUpdate,
  [PROFILE_GET]: profileGet,
  [PHOTO_UPDATE]: photoUpdate,
});
