import React, { useRef, useState, useEffect } from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Calendar from 'react-awesome-calendar';
import PullDownWrapper from '../../../app/common/layout/PullDownWrapper';
import { sortArrayObject } from '../../../app/common/helpers/objectHelpers';
import { format, fromUnixTime, getUnixTime } from 'date-fns';
import { openModal } from '../../../app/modals/redux/modalActions';
import ReactTooltip from 'react-tooltip';
import SimpleSearch from '../../../app/common/layout/SimpleSearch';
import LoadingButton from '../../../app/common/layout/LoadingButton';
import List from './IndexList';
import { getCalendars } from './redux/reduxApi';
import { nanoid } from 'nanoid';

function mapState(state) {
  let scope = {};
  if (state?.auth?.authorities) {
    scope = state?.auth?.authorities;
  }
  let calendar = [];
  if (state?.calendars?.items && state?.calendars?.items[0]) {
    let items = state?.calendars?.items;
    for (let i = 0; i < items.length; i++) {
      const el = items[i];
      if (el.status === true) {
        calendar = [...calendar, el];
        break;
      }
    }
  }
  return {
    auth: state.auth,
    calendars: state.calendars,
    calendar: calendar,
    scope: scope,
    loading: state.async.loading,
  };
}

const actions = {
  getCalendars,
  openModal,
};

function Index(props) {
  let history = useHistory();
  let location = useLocation();
  let dataViewMode = location && location.state;
  const elKalender = useRef();
  const [state, setState] = useState({
    title: 'Kalender',
    itemPerPage: 10,
    currentPage: 1,
    searchText: '',
    calendarTitle: '',
    calendarYear: '',
    events: [],
    filterEvents: {},
    viewMode: dataViewMode ? dataViewMode.viewMode : 'calendarmode',
  });
  const newId = nanoid();
  dataViewMode = undefined;
  const [mode, setMode] = useState('monthlyMode');
  const [month, setMonth] = useState(new Date().getMonth());
  const [dataCalendar, setDataCalendar] = useState([]);
  const { auth, loading, scope, calendars, calendar, openModal, getCalendars } =
    props;

  useEffect(() => {
    getCalendars(auth, history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const monthsArr = [
    { id: '01', month: 'jan' },
    { id: '02', month: 'feb' },
    { id: '03', month: 'mar' },
    { id: '04', month: 'apr' },
    { id: '05', month: 'may' },
    { id: '06', month: 'jun' },
    { id: '07', month: 'jul' },
    { id: '08', month: 'aug' },
    { id: '09', month: 'sep' },
    { id: '10', month: 'oct' },
    { id: '11', month: 'nov' },
    { id: '12', month: 'dec' },
  ];

  const formatTime = (valDate, valTime, allDayEvent, startOrEnd) => {
    let arrDate = valDate.split('/');
    let getYear = arrDate[0];
    let getMonth = arrDate[1];
    let getDay = arrDate[2];
    let getHH = 0;
    let getMM = 0;
    if (valTime) {
      let arrHHmm = valTime && valTime.split(':');
      getHH = parseInt(arrHHmm[0]);
      getMM = arrHHmm[1];
    } else {
      if (allDayEvent === true && startOrEnd === 'start') {
        getHH = '00';
        getMM = '00';
      }
      if (allDayEvent === true && startOrEnd === 'end') {
        getHH = '24';
        getMM = '00';
      }
    }
    let uxTime = getUnixTime(
      new Date(
        parseInt(getYear),
        parseInt(getMonth) - 1,
        parseInt(getDay),
        parseInt(getHH),
        parseInt(getMM)
      )
    );
    let newUnixTime = uxTime + 7 * 60 * 60;
    let newDate = format(fromUnixTime(newUnixTime), 'yyyy-MM-dd');
    let newTime = format(fromUnixTime(newUnixTime), 'HH:mm');
    return { newDate, newTime };
  };

  const formatCal = (value) => {
    let dataCal = [];
    let events = value && value.events && JSON.parse(value.events);
    let calendarTitle = value && value.title;
    let calendarYear = value && value.year;
    setState({
      ...state,
      calendarTitle,
      calendarYear,
      events,
    });
    for (let i = 0; i < events.length; i++) {
      const el = events[i];
      let color;
      if (el.type.toLowerCase() === 'libur') {
        color = '#f24667';
      } else {
        color = '#3f8ed0';
      }
      let setStartTime = el.startTime;
      let setEndTime = el.endTime;
      if (el.allDayEvent === true) {
        setStartTime = '00:00AM';
        setEndTime = '24:00PM';
      }

      let startDateTime = formatTime(
        el.startDate,
        setStartTime,
        el.allDayEvent,
        'start'
      );
      let endDateTime = '';
      if (el.endDate !== '' && el.startDate !== el.endDate) {
        endDateTime = formatTime(el.endDate, setEndTime, el.allDayEvent, 'end');
      }
      let newObj = {
        id: el.id,
        color: color,
        startDate: el.startDate,
        startTime: setStartTime,
        endDate: el.endDate,
        endTime: setEndTime,
        date: startDateTime.newDate,
        from: startDateTime.newDate + 'T' + startDateTime.newTime,
        to:
          endDateTime === ''
            ? startDateTime.newDate
            : endDateTime.newDate + 'T' + endDateTime.newTime,
        title: el.subject,
        type: el.type,
        allDayEvent: el.allDayEvent,
      };
      dataCal = [...dataCal, newObj];
    }
    return dataCal;
  };

  const formatDate = (date) => {
    let arrDate = date.split('/');
    let fDate = format(
      new Date(
        parseInt(arrDate[0]),
        parseInt(arrDate[1]) - 1,
        parseInt(arrDate[2])
      ),
      'dd MMM yyyy'
    );
    return fDate;
  };

  useEffect(() => {
    let dataCal = calendar && calendar[0];
    if (dataCal) {
      let getDataCal = formatCal(dataCal);
      setDataCalendar(getDataCal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendar]);

  useEffect(() => {
    let filterEvents = {};
    dataCalendar &&
      dataCalendar.length > 0 &&
      monthsArr.forEach((item, index) => {
        let idx = [];
        for (const obj of dataCalendar) {
          if (obj.date.includes(`-${item.id}-`)) {
            idx = [...idx, obj];
            // let startDate = obj.from.split('T')[0];
            let endDate = obj.to.split('T')[0];
            let convertEndDate = endDate.split('-');
            if (parseInt(convertEndDate[1]) - 1 !== index) {
              let getMonthEvents =
                filterEvents[parseInt(convertEndDate[1]) - 1] &&
                filterEvents[parseInt(convertEndDate[1]) - 1][0]
                  ? filterEvents[parseInt(convertEndDate[1]) - 1]
                  : [];
              getMonthEvents = [...getMonthEvents, obj];
              filterEvents[parseInt(convertEndDate[1]) - 1] = getMonthEvents;
            }
          }
        }
        let existingMonthEvents =
          filterEvents[index] && filterEvents[index][0]
            ? filterEvents[index]
            : [];
        filterEvents[index] = [...existingMonthEvents, ...idx];
      });

    setState({
      ...state,
      filterEvents,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCalendar]);

  function handleMode(date) {
    setMonth(date.month);
    setMode(date.mode);
  }

  const ListEvents =
    state &&
    state.filterEvents &&
    state.filterEvents[month] &&
    state.filterEvents[month].length > 0 ? (
      sortArrayObject(state.filterEvents[month], 'from', 'asc').map(
        (item, key) => {
          let startDate = item.startDate;
          let getConvertStartDate = formatDate(startDate);
          let endDate = item.endDate ? item.endDate : item.startDate;
          let getConvertEndDate = '';
          if (startDate !== endDate) {
            getConvertEndDate = formatDate(endDate);
          }
          if (key > 4) return <div key={key}></div>;
          return (
            <div key={key}>
              <div className='is-size-7 mb-1'>
                <i
                  className='fas fa-square icon is-size-7 mr-1'
                  style={{ color: item.color }}
                />
                {item.title} <br />
                <span
                  className='is-italic has-text-grey'
                  style={{ marginLeft: 28 }}
                >
                  {getConvertStartDate}
                  {getConvertEndDate === '' ? '' : ` - ${getConvertEndDate}`}
                </span>
              </div>
              {key === 4 && state && state.filterEvents[month].length > 4 && (
                <div className='is-size-7 ml-5'>
                  {' '}
                  {state.filterEvents[month].length - (4 + 1)} more...
                </div>
              )}
            </div>
          );
        }
      )
    ) : (
      <div className='is-size-7 is-italic'>
        <i className='fas fa-square icon is-size-7 has-text-grey-lighter mr-1' />
        No Event
      </div>
    );

  const handleSimpleSearch = (searchText) => {
    // getSems(auth, history, search.itemPerPage, 1, searchText);
    // setState({
    //   ...state,
    //   itemPerPage: search.itemPerPage,
    //   currentPage: 1,
    //   searchText: searchText,
    // });
  };

  const onClickViewMode = () => {
    let viewMode = state.viewMode;
    if (viewMode === 'calendarmode') {
      setState({
        ...state,
        viewMode: 'listmode',
      });
    } else {
      setState({
        ...state,
        viewMode: 'calendarmode',
      });
    }
  };

  const onClickStatus = () => {
    openModal('CalendarStatus', {
      data: {
        auth,
        history,
      },
    });
  };

  const onEditCalendar = (item) => {
    history.push({
      pathname: '/calendar/edit/' + item.id,
      state: { detail: item },
    });
  };

  const onEdit = (event, menu) => {
    let item = event;
    if (!menu) {
      item = dataCalendar.filter((i) => i.id + '' === event + '')[0];
      item.subject = item.title;
    }
    openModal('CalendarViewEvent', {
      data: {
        auth,
        history,
        item,
      },
    });
  };

  return (
    <>
      <PullDownWrapper auth={auth}>
        <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
          <div className='p-1'>
            <div className='columns is-variable is-desktop'>
              <div className='column is-12'>
                <div className='card'>
                  <header className='card-header has-background-white has-text-info'>
                    <div
                      className='custom-card-flex is-size-6'
                      style={{ width: '100%' }}
                    >
                      <p>
                        <i className='mdi mdi-calendar-month is-size-5 icon' />{' '}
                        Kalender
                      </p>
                    </div>
                  </header>
                  <div className='card-content'>
                    {dataCalendar && (
                      <div className='content overflow-auto'>
                        <div className='level' style={{ marginTop: -12 }}>
                          <div className='level-left'></div>
                          <div className='level-right mr-2'>
                            <div
                              className='level-item'
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <div
                                data-for='main'
                                data-tip={
                                  state && state.viewMode === 'calendarmode'
                                    ? 'Event Mode'
                                    : 'Calendar Mode'
                                }
                              >
                                <button
                                  className={`button is-small is-info is-rounded is-outlined mr-2${
                                    state && state.viewMode === 'calendarmode'
                                      ? ' mt-3'
                                      : ''
                                  }`}
                                >
                                  <i
                                    onClick={() => onClickViewMode()}
                                    className={`is-size-6 mdi mdi-${
                                      state && state.viewMode === 'calendarmode'
                                        ? 'view-list'
                                        : 'calendar-multiselect'
                                    } icon`}
                                  />
                                </button>
                                <ReactTooltip
                                  id='main'
                                  place='bottom'
                                  type='dark'
                                  effect='solid'
                                  multiline={true}
                                />
                              </div>
                              {state && state.viewMode === 'listmode' && (
                                <>
                                  <button
                                    onClick={() =>
                                      history.push({
                                        pathname: '/calendar/tambah/' + newId,
                                        state: {
                                          detail: {
                                            id: newId,
                                            title: 'Judul Kalender...',
                                            year: '',
                                            events: [],
                                          },
                                        },
                                      })
                                    }
                                    className='button is-small is-info is-rounded is-outlined mr-2'
                                  >
                                    <i className='is-size-6 mdi mdi-plus icon' />
                                  </button>
                                  <div
                                    data-for='main'
                                    data-tip='Set Active Kalender'
                                  >
                                    <button
                                      onClick={() => onClickStatus()}
                                      disabled={
                                        calendars?.items?.length > 0
                                          ? false
                                          : true
                                      }
                                      className={`button is-small is-info is-rounded is-outlined mr-2${
                                        state &&
                                        state.viewMode === 'calendarmode'
                                          ? ' mt-3'
                                          : ''
                                      }`}
                                    >
                                      <i className='is-size-6 mdi mdi-calendar-multiple-check icon' />
                                    </button>
                                    <ReactTooltip
                                      id='main'
                                      place='bottom'
                                      type='dark'
                                      effect='solid'
                                      multiline={true}
                                    />
                                  </div>
                                  <div
                                    data-for='main'
                                    data-tip='Import Kalender'
                                  >
                                    <Link
                                      to='/calendar/import'
                                      className={`button is-small is-info is-rounded is-outlined mr-2${
                                        state &&
                                        state.viewMode === 'calendarmode'
                                          ? ' mt-3'
                                          : ''
                                      }`}
                                    >
                                      <i className='is-size-6 mdi mdi-file-import icon' />
                                    </Link>
                                    <ReactTooltip
                                      id='main'
                                      place='bottom'
                                      type='dark'
                                      effect='solid'
                                      multiline={true}
                                    />
                                  </div>
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      paddingTop: 14,
                                    }}
                                  >
                                    <SimpleSearch
                                      title='Event'
                                      initialValues={state && state.searchText}
                                      loading={loading}
                                      onFormSubmit={handleSimpleSearch}
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        {state && state.viewMode === 'calendarmode' && (
                          <div
                            className='content table-container mt-3 ml-2 mr-2'
                            style={
                              mode === 'yearlyMode' ? { minWidth: 460 } : {}
                            }
                          >
                            <Calendar
                              onChange={(date) => handleMode(date)}
                              ref={elKalender}
                              events={dataCalendar}
                              onClickEvent={(event) => onEdit(event)}
                            />
                            {mode === 'monthlyMode' && (
                              <>
                                <div className='ml-1 mt-3 mb-2 has-text-weight-bold has-text-info is-size-7'>
                                  Event
                                </div>
                                {ListEvents}
                              </>
                            )}
                          </div>
                        )}

                        {state && state.viewMode !== 'calendarmode' && (
                          <>
                            <div
                              className='table-container ml-4 mr-4'
                              style={{ marginTop: -16, marginBottom: 20 }}
                            >
                              {calendars?.items?.length > 1 && (
                                <>
                                  <h4>
                                    <span
                                      onClick={() =>
                                        onEditCalendar(calendar?.[0])
                                      }
                                      className='is-capitalized hand-pointer menu-top'
                                    >
                                      {state?.calendarTitle}{' '}
                                      <i className='mdi mdi-pencil icon is-size-6' />
                                    </span>
                                  </h4>
                                  <p style={{ marginTop: -14 }}>
                                    <span className='tag'>
                                      {state?.calendarYear}
                                    </span>
                                  </p>
                                </>
                              )}
                              <table
                                id='fadein'
                                className='table is-fullwidth is-hoverable is-narrow'
                              >
                                <thead>
                                  <tr>
                                    <th className='has-text-centered'>No</th>
                                    <th>Judul Event</th>
                                    <th>Tipe</th>
                                    <th>Tanggal</th>
                                    <th className='has-text-centered'>Aksi</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {loading === true ? (
                                    <tr>
                                      <td>
                                        <LoadingButton />
                                      </td>
                                    </tr>
                                  ) : (
                                    <>
                                      <List
                                        auth={auth}
                                        loading={loading}
                                        scope={scope}
                                        items={state && state.events}
                                        title={state.title}
                                        currentPage={state && state.currentPage}
                                        itemPerPage={state && state.itemPerPage}
                                        formatDate={formatDate}
                                        onEdit={onEdit}
                                      />

                                      {calendars?.items?.length > 1 && (
                                        <tr>
                                          <td colSpan={3}>
                                            <div className='control mt-2'>
                                              <div className='tags has-addons'>
                                                <span className='tag'>
                                                  Total
                                                </span>
                                                <span className='tag is-info'>
                                                  {state && state.events.length}
                                                </span>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      )}
                                    </>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </>
                        )}
                      </div>
                    )}
                    {!dataCalendar && (
                      <p className='ml-2 is-italic'>Tidak ada kalender aktif</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PullDownWrapper>
    </>
  );
}

export default connect(mapState, actions)(Index);
