import {
  asyncActionFinish,
  asyncActionError,
} from '../../../../app/common/reduxs/async/asyncActions';
import {
  tingkatsGet,
  tingkatJenjangsGet,
  tingkatAdd,
  tingkatEdit,
  tingkatGet,
  tingkatDelete,
} from './reduxAction';
import { ASYNC_ACTION_START } from '../../../../app/common/reduxs/async/asyncConstant';
import { checkErr } from '../../../../app/common/helpers/checkRes';
import { fetchFunc } from '../../../../app/common/helpers/fetchHelpers';
import { logsFunc } from '../../../../app/common/helpers/othersHelpers';

export const getTingkats = (
  auth,
  history,
  itemPerPage,
  currentPage,
  searchText
) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getTingkats' });
    const formData = new FormData();
    formData.append('itemPerPage', itemPerPage ? itemPerPage : 10);
    formData.append('currentPage', currentPage);
    formData.append('searchText', searchText);
    try {
      const res = await fetchFunc(
        'gets',
        auth,
        history,
        'tingkat/',
        formData,
        dispatch
      );
      const items = res.items;
      const total = res.total;
      const search = {
        currentPage,
        searchText,
        itemPerPage: itemPerPage ? itemPerPage : 10,
      };
      dispatch(tingkatsGet({ total, items, search }));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const getTingkatJenjangs = (
  auth,
  history,
) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getTingkatJenjangs' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'gets',
        auth,
        history,
        'jenjang/all',
        formData,
        dispatch
      );
      const jenjangs = res.jenjangs;
      dispatch(tingkatJenjangsGet({jenjangs}));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const addTingkat = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'addTingkat' });
    let logs = logsFunc('create', auth.userid, auth.username, null);
    const formData = new FormData();
    formData.append('code', values.code);
    formData.append('title', values.title);
    formData.append('jenjangId', values.jenjangId);
    formData.append('description', values.description);
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'add',
        auth,
        history,
        'tingkat/add/',
        formData,
        dispatch
      );
      const tingkat = res.tingkat;
      dispatch(tingkatAdd(tingkat));
      history.push('/akademik/tingkat/' + tingkat.id);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const getTingkat = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getTingkat' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'get',
        auth,
        history,
        'tingkat/detail/' + id,
        formData,
        dispatch
      );
      const tingkat = res.tingkat;
      dispatch(tingkatGet(tingkat));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const editTingkat = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'editTingkat' });
    let logs = logsFunc('edit', auth.userid, auth.username, values.logs);
    const formData = new FormData();
    formData.append('code', values.code);
    formData.append('title', values.title);
    formData.append('jenjangId', values.jenjangId);
    formData.append('description', values.description);
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'tingkat/edit/' + values.id,
        formData,
        dispatch
      );
      const tingkat = res.tingkat;
      dispatch(tingkatEdit(tingkat));
      history.push('/akademik/tingkat/' + values.id);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const statusTingkat = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'statusTingkat' });
    let logs = logsFunc('edit', auth.userid, auth.username, values.logs);
    const formData = new FormData();
    formData.append('status', values.status);
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'tingkat/status/' + values.id,
        formData,
        dispatch
      );
      const tingkat = res.tingkat;
      dispatch(tingkatEdit(tingkat));
      history.push('/akademik/tingkat/' + values.id);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const deleteTingkat = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'deleteTingkat' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'delete',
        auth,
        history,
        'tingkat/delete/' + id,
        formData,
        dispatch
      );
      const tingkat = res.tingkat;
      dispatch(tingkatDelete(tingkat));
      history.push('/akademik/tingkat');
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};
