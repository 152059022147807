import {
  SLIDESHOWS_GET,
  SLIDESHOW_ADD,
  SLIDESHOW_EDIT,
  SLIDESHOW_GET,
  SLIDESHOW_UPLOADFILE,
  SLIDESHOW_DELETE,
} from './reduxConstant';

export const slideshowsGet = (slideshows) => {
  return {
    type: SLIDESHOWS_GET,
    payload: {
      slideshows,
    },
  };
};

export const slideshowAdd = (slideshow) => {
  return {
    type: SLIDESHOW_ADD,
    payload: {
      slideshow,
    },
  };
};

export const slideshowEdit = (slideshow) => {
  return {
    type: SLIDESHOW_EDIT,
    payload: {
      slideshow,
    },
  };
};

export const slideshowGet = (slideshow) => {
  return {
    type: SLIDESHOW_GET,
    payload: {
      slideshow,
    },
  };
};

export const slideshowUploadFile = (uploadFile) => {
  return {
    type: SLIDESHOW_UPLOADFILE,
    payload: {
      uploadFile,
    },
  };
};

export const slideshowDelete = (slideshow) => {
  return {
    type: SLIDESHOW_DELETE,
    payload: {
      slideshow,
    },
  };
};
