import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Switch, Redirect, Route } from 'react-router-dom';
import { reset } from 'redux-form';
import ViewFormPesan from './ViewFormPesan';
// import MultiSelect from '../../../app/common/form/MultiSelect';
import ViewFormTab from './ViewFormTab';
import ViewFormInfo from './ViewFormInfo';

function ViewForm(props) {
  const {
    auth,
    id,
    // history,
    loading,
    pathname,
    initialValues,
    // openModal,
    // scope,
  } = props;

  const updateInfo = () => {
    console.log('update')
  }

  return (
    <>
      <ViewFormTab auth={auth} id={id} pathname={pathname} />
      <Switch>
        <Redirect
          exact
          from={`/portal/portaluser/${id}`}
          to={`/portal/portaluser/${id}/info`}
        />
        <Route
          path={`/portal/portaluser/${id}/info`}
          render={() => (
            <ViewFormInfo
              initialValues={initialValues}
              loading={loading}
              onFormSubmit={updateInfo}
              reset={reset}
            />
          )}
        />
        <Route
          path={`/portal/portaluser/${id}/pesan`}
          render={() => (
            <ViewFormPesan
              initialValues={auth}
              loading={loading}
              reset={reset}
            />
          )}
        />
      </Switch>
    </>
  );
}

export default withRouter(connect(null, null)(ViewForm));
