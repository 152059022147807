import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { getJenjangs } from './redux/reduxApi';
import SimpleSearch from '../../../app/common/layout/SimpleSearch';
import PageNumber from '../../../app/common/layout/PageNumber';
import LoadingButton from '../../../app/common/layout/LoadingButton';
import List from './IndexList';
import { openModal } from '../../../app/modals/redux/modalActions';

function mapState(state) {
  let scope = {};
  if (state.auth && state.auth.isAuth) {
    scope = state.auth.authorities;
  }
  return {
    auth: state.auth,
    scope: scope,
    jenjangs: state.jenjangs,
    loading: state.async.loading,
  };
}

const actions = {
  openModal,
  getJenjangs,
};

function Index(props) {
  let history = useHistory();
  const [state, setState] = useState({
    title: 'Jenjang',
    itemPerPage: 10,
    currentPage: 1,
    searchText: '',
  });
  const { getJenjangs, auth, scope, loading, jenjangs, openModal } = props;
  const items = jenjangs && jenjangs.items;
  const total = jenjangs && jenjangs.total;
  const search = jenjangs && jenjangs.search;

  useEffect(() => {
    let itemPerPage =
      search && search.itemPerPage ? search.itemPerPage : state.itemPerPage;
    let currentPage =
      search && search.currentPage ? search.currentPage : state.currentPage;
    let searchText =
      search && search.searchText ? search.searchText : state.searchText;
    getJenjangs(
      auth,
      history,
      itemPerPage,
      currentPage,
      searchText,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSimpleSearch = (searchText) => {
    getJenjangs(auth, history, search.itemPerPage, 1, searchText);
    setState({
      ...state,
      itemPerPage: search.itemPerPage,
      currentPage: 1,
      searchText: searchText,
    });
  };

  const handleResetSearch = () => {
    setState({
      ...state,
      searchText: '',
    });
    getJenjangs(auth, history, state.itemPerPage, 1, '');
  };

  const handleItemPerPage = (val) => {
    setState({
      ...state,
      itemPerPage: val,
      currentPage: 1,
    });
    getJenjangs(auth, history, val, 1, search.searchText);
  };

  const handlePage = (val) => {
    let newCurrentPage = search.currentPage + val;
    setState({
      ...state,
      currentPage: newCurrentPage,
    });
    getJenjangs(
      auth,
      history,
      state.itemPerPage,
      newCurrentPage,
      search.searchText
    );
  };

  const onDelete = (item) => {
    openModal('JenjangDelete', {
      data: {
        auth,
        history,
        item,
      },
    });
  };

  return (
    <>
      <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
        <div className='p-1'>
          <div className='columns is-variable is-desktop'>
            <div className='column is-12'>
              <div className='card'>
                <header className='card-header has-background-white has-text-info'>
                  <div
                    className='custom-card-flex is-size-6'
                    style={{ width: '100%' }}
                  >
                    <p>
                      <i className='mdi mdi-school is-size-5 icon' />{' '}
                      Akademik<i className='mdi mdi-menu-right is-size-6 icon' />Jenjang
                    </p>
                  </div>
                </header>
                <div className='card-content'>
                  <div className='content'>
                    <div className='level' style={{ marginTop: -20 }}>
                      <div className='level-left'></div>

                      <div className='level-right mr-4'>
                        <div
                          className='level-item'
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          {scope && scope.subm && scope.subm['jenjang_add'] && (
                            <>
                              <Link
                                to='/akademik/jenjang/tambah'
                                className='button is-small is-info is-rounded is-outlined mr-2'
                              >
                                <i className='is-size-6 mdi mdi-plus icon' />
                              </Link>
                            </>
                          )}
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              paddingTop: 14,
                            }}
                          >
                            <SimpleSearch
                              title={state.title}
                              initialValues={search && search.searchText}
                              loading={loading}
                              resetSearchText={state.resetSearchText}
                              onFormSubmit={handleSimpleSearch}
                            />
                          </div>
                          {search && search.searchText && search.searchText.length > 0 && (
                            <button
                              onClick={() => handleResetSearch()}
                              className='button is-small is-info is-rounded is-outlined ml-2'
                            >
                              <i className='fas fa-redo icon' />
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      className='table-container ml-4 mr-4'
                      style={{ marginTop: -16, marginBottom: 20 }}
                    >
                      <table id='fadein' className='table is-fullwidth is-hoverable is-narrow'>
                        <thead>
                          <tr>
                            <th className='has-text-centered'>No</th>
                            <th>Kode</th>
                            <th>Nama Jenjang</th>
                            <th className='has-text-centered'>Status Aktif</th>
                            <th className='has-text-centered'>Dibuat</th>
                            <th className='has-text-centered'>Aksi</th>
                          </tr>
                        </thead>
                        <tbody>
                          {loading === true ? (
                            <tr>
                              <td>
                                <LoadingButton />
                              </td>
                            </tr>
                          ) : (
                            <List
                              auth={auth}
                              loading={loading}
                              scope={scope}
                              items={items}
                              title={state.title}
                              currentPage={search && search.currentPage}
                              itemPerPage={search && search.itemPerPage}
                              onDelete={onDelete}
                            />
                          )}
                        </tbody>
                      </table>
                    </div>
                    <PageNumber
                      title={state.title}
                      total={total}
                      currentPage={search && search.currentPage}
                      itemPerPage={search && search.itemPerPage}
                      handleItemPerPage={handleItemPerPage}
                      handlePage={handlePage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default connect(mapState, actions)(Index);
