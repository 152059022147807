import React from 'react';
import { Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { SITE_ADDRESS } from '../../../app/common/data/siteConfig';

export default function IndexListItems(props) {
  const { item, currentPage, itemPerPage, index, loading, scope, onDelete } =
    props;
  if (loading)
    return (
      <tr>
        <td>Loading...</td>
      </tr>
    );

  const page = (currentPage - 1) * itemPerPage;

  let file = item && item.file && JSON.parse(item.file);
  let link = file && file.filelink;
  let image = link ? (
    <figure className='image'>
      <img alt='' src={SITE_ADDRESS + link} style={{maxWidth: 160, height: 'auto'}} />
    </figure>
  ) : (
    ''
  );
  return (
    <tr>
      <td className='has-text-centered'>{page + index + 1}</td>
      <td>{item.title}</td>
      <td>
        <p style={{ whiteSpace: 'pre-wrap' }}>{item.description}</p>
      </td>
      <td>{image}</td>
      <td className='has-text-centered'>
        {item.createdAt &&
          format(parseISO(item.createdAt), 'd LLLL yyyy - HH:mm:ss')}
      </td>
      <td>
        <div className='content-wrap flex justify-center'>
          <div>
            {scope && scope.subm && scope.subm['slideshow_view'] && (
              <Link
                to={`/portal/slideshow/${item.id}`}
                className='button is-small is-rounded is-info is-outlined mr-2 mb-1'
              >
                <i className='is-size-6 mdi mdi-eye icon' />
              </Link>
            )}
            {scope && scope.subm && scope.subm['slideshow_edit'] && (
              <Link
                to={`/portal/slideshow/edit/${item.id}`}
                className='button is-small is-rounded is-info is-outlined mr-2 mb-1'
              >
                <i className='is-size-6 mdi mdi-pencil icon' />
              </Link>
            )}
            {scope && scope.subm && scope.subm['slideshow_delete'] && (
              <button
                className='button is-small is-rounded is-danger is-outlined mb-1'
                onClick={() => onDelete(item)}
              >
                <i className='is-size-6 mdi mdi-delete icon' />
              </button>
            )}
          </div>
        </div>
      </td>
    </tr>
  );
}
