import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import { SITE_ADDRESS } from '../../../app/common/data/siteConfig';
import styled from 'styled-components';
import { ellipsis } from 'polished';

const DescriptionText = styled.div`
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 20px;
  ${({ showMore }) => showMore && ellipsis(undefined, 16)}
`;

const ShowMoreText = styled.div`
  cursor: pointer;
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 30px;
  text-decoration: underline;
`;

function ViewForm(props) {
  const {
    auth,
    history,
    scope,
    id,
    initialValues,
    attachments,
    openModal,
    downloadFile,
  } = props;

  const [attached, setAttached] = useState([]);
  const [isShowMore, setIsShowMore] = useState(true);
  const toggleReadMore = () => setIsShowMore((show) => !show);
  const attachedDiv = useRef(1);

  useEffect(() => {
    const handleKeyDown = (event) => {
      let data = event.target.src;
      if (data && data.includes('images')) {
        openModal('PopUpImageModal', { data });
      }
    };
    window.addEventListener('click', handleKeyDown);

    return () => {
      window.removeEventListener('click', handleKeyDown);
    };
  }, [openModal]);

  useEffect(() => {
    setAttached(attachments);
    attachedDiv.current++;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickMateriDelete = () => {
    openModal('MateriDelete', {
      data: {
        auth,
        history,
        item: initialValues,
      },
    });
  };

  const onClickAttachment = (item) => {
    function checkImage(url) {
      return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
    }
    let isImage = checkImage(item.filelink);
    if (isImage) {
      openModal('PopUpImageModal', { data: SITE_ADDRESS + item.filelink });
    } else {
      downloadFile(auth, history, initialValues, item);
    }
  };
  
  return (
    <>
      <div className='level'>
        <div className='level-left'>
          <div className='level-item'>
            <nav className='breadcrumb is-size-6' aria-label='breadcrumbs'>
              <ul>
                <li className='is-active has-text-weight-bold'>
                  <Link to={'/pembelajaran/materi/' + id}>Detail</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className='level-right'>
          <div className='level-item'>
            <div className='buttons'>
              {scope && scope.subm && scope.subm['materi_publish'] && (
                <Link
                  to={`/pembelajaran/materi/publish/${id}`}
                  className='button is-small is-rounded is-info is-outlined'
                >
                  <i className='is-size-6 mdi mdi-share icon' />
                </Link>
              )}
              {scope && scope.subm && scope.subm['materi_edit'] && (
                <Link
                  to={`/pembelajaran/materi/edit/${id}`}
                  className='button is-small is-rounded is-info is-outlined'
                >
                  <i className='is-size-6 mdi mdi-pencil icon' />
                </Link>
              )}
              {scope && scope.subm && scope.subm['materi_delete'] && (
                <button
                  className='button is-small is-rounded is-danger is-outlined'
                  onClick={() => handleClickMateriDelete()}
                >
                  <i className='is-size-6 mdi mdi-delete icon' />
                </button>
              )}
              <Link
                type='button'
                to={'/pembelajaran/materi'}
                className='button custom-grey is-small is-rounded is-outlined'
              >
                <i className='is-size-6 mdi mdi-arrow-left icon' />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className='columns'>
        <div id='quilEditor' className='column is-third-quarter'>
          <div className='control mb-4'>
            <h1 className='is-capitalized title is-size-6 mb-5'>
              <Link to={'/pembelajaran/materi/' + id}>
                {initialValues.title}
              </Link>
            </h1>
            <DescriptionText showMore={isShowMore}>
              <div
                dangerouslySetInnerHTML={{
                  __html: initialValues.description,
                }}
              ></div>
            </DescriptionText>
            {initialValues &&
              initialValues.description &&
              initialValues.description.length > 2000 && (
                <ShowMoreText onClick={toggleReadMore}>
                  {isShowMore ? 'Show more...' : 'Show less'}
                </ShowMoreText>
              )}
          </div>
          {attached && attached[0] && (
            <>
              <hr />
              <div className='field' style={{ marginBottom: -5 }}>
                <label className='label'>Attachment(s)</label>
              </div>
              <div
                className='column is-full-tablet is-three-quarters-desktop'
                ref={attachedDiv}
                style={{marginLeft: -10}}
              >
                {attached.map((item, index) => (
                  <div
                    key={index}
                    className='has-text-link has-background-grey-lighter px-2 py-1 mb-1 flex justify-between align-center'
                  >
                    <span
                      className='hand-pointer'
                      onClick={() => onClickAttachment(item)}
                    >
                      {item.filename} (
                      {item.filesize.length < 7
                        ? Math.round(parseInt(item.filesize) / 1000) + ' KB'
                        : Math.round(parseInt(item.filesize) / 1000000) + ' MB'}
                      )
                    </span>
                  </div>
                ))}
              </div>
            </>
          )}
          <hr />
          <div className='field'>
            <label className='label'>Status</label>
            <div className='control'>
              <div className='tags has-addons'>
                <span className='tag'>Publish</span>
                <span
                  className={`tag ${
                    initialValues?.published
                      ? 'is-info'
                      : 'has-background-grey has-text-white'
                  }`}
                >
                  {initialValues?.published ? 'Yes' : 'No'}
                </span>
              </div>
            </div>
          </div>
          <div className='field'>
            <label className='label'>Tags</label>
            <div className='control'>
              <div className='tags is-capitalized'>
                {initialValues &&
                  initialValues.tags &&
                  JSON.parse(initialValues.tags).map((item, index) => (
                    <span key={index} className='tag is-info is-light'>
                      {item}
                    </span>
                  ))}
                {initialValues && !initialValues.tags && (
                  <span className='tag'>None</span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default reduxForm({
  form: 'materiView',
  enableReinitialize: true,
})(ViewForm);
