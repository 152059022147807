import {
  JENJANGS_GET,
  JENJANG_ADD,
  JENJANG_EDIT,
  JENJANG_GET,
  JENJANG_DELETE,
} from './reduxConstant';
import { createReducer } from '../../../../app/common/utilities/reducerUtils';

const initialState = {
  total: 0,
  search: {
    currentPage: '',
    searchText: '',
    itemPerPage: '',
  },
  items: [],
};

const jenjangsGet = (state, payload) => {
  const jenjangs = payload.jenjangs;
  return {
    total: jenjangs.total,
    items: [...jenjangs.items],
    search: { ...jenjangs.search },
  };
};

const jenjangAdd = (state, payload) => {
  return {
    total: parseInt(state.total) + 1,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.jenjang.id + ''),
      payload.jenjang,
    ],
    search: { ...state.search },
  };
};

const jenjangEdit = (state, payload) => {
  return {
    total: parseInt(state.total),
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.jenjang.id + ''),
      payload.jenjang,
    ],
    search: { ...state.search },
  };
};

const jenjangGet = (state, payload) => {
  return {
    total: parseInt(state.total),
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.jenjang.id + ''),
      payload.jenjang,
    ],
    search: { ...state.search },
  };
};

const jenjangDelete = (state, payload) => {
  return {
    total: parseInt(state.total) - 1,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.jenjang.id + ''),
    ],
    search: { ...state.search },
  };
};

export default createReducer(initialState, {
  [JENJANGS_GET]: jenjangsGet,
  [JENJANG_ADD]: jenjangAdd,
  [JENJANG_EDIT]: jenjangEdit,
  [JENJANG_GET]: jenjangGet,
  [JENJANG_DELETE]: jenjangDelete,
});
