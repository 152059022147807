import {
  TUGASS_GET,
  TUGAS_ADD,
  TUGAS_GET,
  STUDENT_ANSWER_GET,
  TUGAS_EDIT,
  TUGAS_UPLOADFILE,
  TUGAS_DELETESTUDENTANSWER,
  TUGAS_DELETE,
  TUGAS_RESET_SEARCH,
} from './reduxConstant';

export const tugassGet = (tugass) => {
  return {
    type: TUGASS_GET,
    payload: {
      tugass,
    },
  };
};

export const tugasAdd = (tugas) => {
  return {
    type: TUGAS_ADD,
    payload: {
      tugas,
    },
  };
};

export const tugasGet = (tugas) => {
  return {
    type: TUGAS_GET,
    payload: {
      tugas,
    },
  };
};

export const studentAnswerGet = (answers) => {
  return {
    type: STUDENT_ANSWER_GET,
    payload: {
      answers,
    },
  };
};

export const tugasEdit = (tugas) => {
  return {
    type: TUGAS_EDIT,
    payload: {
      tugas,
    },
  };
};

export const tugasUploadFile = (uploadFile) => {
  return {
    type: TUGAS_UPLOADFILE,
    payload: {
      uploadFile,
    },
  };
};

export const tugasDeleteStudentAnswer = () => {
  return {
    type: TUGAS_DELETESTUDENTANSWER,
    payload: {},
  };
};

export const tugasDelete = (tugas) => {
  return {
    type: TUGAS_DELETE,
    payload: {
      tugas,
    },
  };
};

export const tugasResetSearch = () => {
  return {
    type: TUGAS_RESET_SEARCH,
    payload: {},
  };
};
