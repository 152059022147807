import { authLogin, authLogout } from './authAction';
import {
  asyncActionFinish,
  asyncActionError,
} from '../../common/reduxs/async/asyncActions';
import { ASYNC_ACTION_START } from '../../common/reduxs/async/asyncConstant';
import { toastr } from 'react-redux-toastr';
import { closeModal } from '../../modals/redux/modalActions';
import { SITE_ADDRESS } from '../../common/data/siteConfig';
import jwt_decode from 'jwt-decode';
import { Menus } from '../../common/data/menus';
import { menusGet } from '../../common/reduxs/menu/menusAction';
import { authUpdating } from '../../common/utilities/checkAuth';

export const loginAuth = (creds) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'loginAuth' });
    try {
      const formData = new FormData();
      formData.append('username', creds.username);
      formData.append('password', creds.password);
      let fetchData = await fetch(SITE_ADDRESS + 'login/', {
        method: 'POST',
        body: formData,
      });
      const response = await fetchData.json();
      if (fetchData.status !== 200) {
        const error = new Error(response.message);
        throw error;
      }
      const user = response.user;
      const roles = response.roles;
      const token = response.token;
      const authUpdated = authUpdating(user.roles, roles);
      const expiresIn = jwt_decode(token).exp;
      const setUser = {
        isAuth: true,
        token: token,
        authorities: authUpdated,
        userId: user.id,
        username: user.username,
        expiresIn: expiresIn * 1000,
        profile: user.profile,
      };
      dispatch(menusGet(Menus));
      dispatch(authLogin(setUser));
      dispatch(asyncActionFinish());
      return 'finish';
    } catch (error) {
      console.log(error);
      toastr.error('Error', error.message);
      dispatch(asyncActionError());
    }
  };
};

export const sessionLogout = (elementName, history) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: elementName });
    try {
      history.push('/');
      dispatch(authLogout());
      dispatch(closeModal());
      dispatch(asyncActionFinish());
    } catch (error) {
      console.log(error);
      toastr.error('Error', error.message);
      dispatch(asyncActionError());
    }
  };
};
