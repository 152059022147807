import {
  YEARS_GET,
  YEAR_ADD,
  YEAR_EDIT,
  YEAR_GET,
  YEAR_DELETE,
} from './reduxConstant';

export const yearsGet = (years) => {
  return {
    type: YEARS_GET,
    payload: {
      years,
    },
  };
};

export const yearAdd = (year) => {
  return {
    type: YEAR_ADD,
    payload: {
      year,
    },
  };
};

export const yearEdit = (year) => {
  return {
    type: YEAR_EDIT,
    payload: {
      year,
    },
  };
};

export const yearGet = (year) => {
  return {
    type: YEAR_GET,
    payload: {
      year,
    },
  };
};

export const yearDelete = (year) => {
  return {
    type: YEAR_DELETE,
    payload: {
      year,
    },
  };
};
