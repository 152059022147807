import {
  ANNOUNCEMENTS_GET,
  ANNOUNCEMENT_ADD,
  ANNOUNCEMENT_EDIT,
  ANNOUNCEMENT_GET,
  ANNOUNCEMENT_DELETE,
  ANNOUNCEMENT_UPLOADFILE,
} from './reduxConstant';
import { createReducer } from '../../../../app/common/utilities/reducerUtils';

const initialState = {
  total: 0,
  search: {
    currentPage: '',
    searchText: '',
    itemPerPage: '',
  },
  items: [],
  uploadFile: {},
};

const announcementsGet = (state, payload) => {
  const announcements = payload.announcements;
  return {
    total: announcements.total,
    items: [...announcements.items],
    search: { ...announcements.search },
  };
};

const announcementAdd = (state, payload) => {
  return {
    total: parseInt(state.total) + 1,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.announcement.id + ''),
      payload.announcement,
    ],
    search: { ...state.search },
  };
};

const announcementEdit = (state, payload) => {
  return {
    total: parseInt(state.total),
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.announcement.id + ''),
      payload.announcement,
    ],
    search: { ...state.search },
  };
};

const announcementGet = (state, payload) => {
  return {
    total: parseInt(state.total),
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.announcement.id + ''),
      payload.announcement,
    ],
    search: { ...state.search },
  };
};

const announcementUploadFile = (state, payload) => {
  let uploadFile = payload.uploadFile;
  return {
    ...state,
    uploadFile: {...uploadFile},
  };
};

const announcementDelete = (state, payload) => {
  return {
    total: parseInt(state.total) - 1,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.announcement.id + ''),
    ],
    search: { ...state.search },
  };
};

export default createReducer(initialState, {
  [ANNOUNCEMENTS_GET]: announcementsGet,
  [ANNOUNCEMENT_ADD]: announcementAdd,
  [ANNOUNCEMENT_EDIT]: announcementEdit,
  [ANNOUNCEMENT_GET]: announcementGet,
  [ANNOUNCEMENT_UPLOADFILE]: announcementUploadFile,
  [ANNOUNCEMENT_DELETE]: announcementDelete,
});
