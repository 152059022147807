import {
  asyncActionFinish,
  asyncActionError,
} from '../../../../app/common/reduxs/async/asyncActions';
import {
  pelajaransGet,
  pelajaranJenjangsGet,
  pelajaranAdd,
  pelajaranEdit,
  pelajaranGet,
  pelajaranSync,
  pelajaranDelete,
} from './reduxAction';
import { ASYNC_ACTION_START } from '../../../../app/common/reduxs/async/asyncConstant';
import { checkErr } from '../../../../app/common/helpers/checkRes';
import { fetchFunc } from '../../../../app/common/helpers/fetchHelpers';
import { logsFunc } from '../../../../app/common/helpers/othersHelpers';
import { nanoid } from 'nanoid';

export const getPelajarans = (
  auth,
  history,
  itemPerPage,
  currentPage,
  searchText
) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getPelajarans' });
    const formData = new FormData();
    formData.append('itemPerPage', itemPerPage ? itemPerPage : 10);
    formData.append('currentPage', currentPage);
    formData.append('searchText', searchText);
    try {
      const res = await fetchFunc(
        'gets',
        auth,
        history,
        'pelajaran/',
        formData,
        dispatch
      );
      const items = res.items;
      const total = res.total;
      const search = {
        currentPage,
        searchText,
        itemPerPage: itemPerPage ? itemPerPage : 10,
      };
      dispatch(pelajaransGet({ total, items, search }));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const getPelajaranJenjangs = (auth, history) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getPelajaranJenjangs' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'gets',
        auth,
        history,
        'jenjang/all',
        formData,
        dispatch
      );
      const jenjangs = res.jenjangs;
      dispatch(pelajaranJenjangsGet(jenjangs));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const addPelajaran = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'addPelajaran' });
    let logs = logsFunc('create', auth.userid, auth.username, null);
    const formData = new FormData();
    formData.append('code', values.code);
    formData.append('title', values.title);
    formData.append('jenjangId', values.jenjangId);
    formData.append('description', values.description);
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'add',
        auth,
        history,
        'pelajaran/add/',
        formData,
        dispatch
      );
      const pelajaran = res.pelajaran;
      dispatch(pelajaranAdd(pelajaran));
      history.push('/akademik/pelajaran/' + pelajaran.id);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const getPelajaran = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getPelajaran' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'get',
        auth,
        history,
        'pelajaran/detail/' + id,
        formData,
        dispatch
      );
      const pelajaran = res.pelajaran;
      dispatch(pelajaranGet(pelajaran));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const editPelajaran = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'editPelajaran' });
    let logs = logsFunc('edit', auth.userid, auth.username, values.logs);
    const formData = new FormData();
    formData.append('code', values.code);
    formData.append('title', values.title);
    formData.append('jenjangId', values.jenjangId);
    formData.append('description', values.description);
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'pelajaran/edit/' + values.id,
        formData,
        dispatch
      );
      const pelajaran = res.pelajaran;
      dispatch(pelajaranEdit(pelajaran));
      history.push('/akademik/pelajaran/' + values.id);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const statusPelajaran = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'statusPelajaran' });
    let logs = logsFunc('edit', auth.userid, auth.username, values.logs);
    const formData = new FormData();
    formData.append('status', values.status);
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'pelajaran/status/' + values.id,
        formData,
        dispatch
      );
      const pelajaran = res.pelajaran;
      dispatch(pelajaranEdit(pelajaran));
      history.push('/akademik/pelajaran/' + values.id);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const syncPelajaran = (auth, history) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'syncPelajaran' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'get',
        auth,
        history,
        'pelajaran/sync/',
        formData,
        dispatch
      );
      if (res.pelajarans) {
        const pelajarans = res.pelajarans;
        const syncPelajarans = res.syncPelajarans;
        dispatch(pelajaranSync({ pelajarans, syncPelajarans }));
      }
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const syncSavePelajaran = (
  auth,
  history,
  pelajaransFilter,
  jenjangs
) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'syncSavePelajaran' });
    let logs = logsFunc('create', auth.userid, auth.username, null);
    const formData = new FormData();
    let newPelajaransFilter = [];
    for (const obj of pelajaransFilter) {
      let jenjang = jenjangs.filter((t) => t.code === obj.jenjang)[0];
      if (obj?.selected) {
        newPelajaransFilter.push({
          ...obj,
          jenjangId: jenjang?.id || jenjangs[0]?.id,
        });
      }
    }
    formData.append('syncData', JSON.stringify(newPelajaransFilter));
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'add',
        auth,
        history,
        'pelajaran/sync-save/',
        formData,
        dispatch
      );
      const items = res.items;
      const total = res.total;
      dispatch(
        pelajaransGet({
          total,
          items,
          search: {
            currentPage: '',
            searchText: '',
            itemPerPage: '',
          },
        })
      );
      history.push('/akademik/pelajaran');
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const importPelajaran = (auth, history, allPel, importData, jenjang) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'importPelajaran' });
    try {
      let logs = logsFunc('create', auth.userid, auth.username, null);
      const formData = new FormData();
      let newImportData = [];
      for (const obj of importData) {
        let code = obj.Kode;
        if (allPel.length > 0) {
          for (const obj2 of allPel) {
            if (obj2.code + '' === obj.Kode + '') {
              code = code + '-' + nanoid(10);
              break;
            }
          }
        }
        let jenjangId = 0;
        for (const obj3 of jenjang) {
          if (obj3.title === obj.Jenjang) {
            jenjangId = obj3.id;
            break;
          }
        }
        let newObj = {
          code: code,
          title: obj.Nama,
          jenjangId: jenjangId,
        };
        newImportData = [...newImportData, newObj];
      }
      formData.append('importData', JSON.stringify(newImportData));
      formData.append('logs', logs);
      const res = await fetchFunc(
        'add',
        auth,
        history,
        'pelajaran/import/',
        formData,
        dispatch
      );
      const importResult = res.importResult;
      dispatch(asyncActionFinish());
      return importResult;
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const deletePelajaran = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'deletePelajaran' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'delete',
        auth,
        history,
        'pelajaran/delete/' + id,
        formData,
        dispatch
      );
      const pelajaran = res.pelajaran;
      dispatch(pelajaranDelete(pelajaran));
      history.push('/akademik/pelajaran');
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};
