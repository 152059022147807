import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import {
  composeValidators,
  combineValidators,
  isRequired,
  hasLengthGreaterThan,
} from 'revalidate';
import TextInput from '../../app/common/form/TextInput';
import { addUser } from './redux/reduxApi';

const mapState = (state, ownProps) => {
  const { id } = ownProps;
  return {
    id: id,
    loading: state.async.loading,
    auth: state.auth,
  };
};

const actions = {
  addUser,
};

const validate = combineValidators({
  username: composeValidators(
    isRequired({ message: 'Username harus diisi' }),
    hasLengthGreaterThan(2)({
      message: 'Username harus memiliki paling sedikit 3 karakter',
    })
  )(),
  password: composeValidators(
    isRequired({ message: 'Password harus diisi' }),
    hasLengthGreaterThan(5)({
      message: 'Password harus memiliki paling sedikit 6 karakter',
    })
  )(),
});

function Add(props) {
  const { auth, history, invalid, loading, pristine, handleSubmit, addUser } =
    props;

  const onFormSubmit = (values) => {
    addUser(auth, history, values);
  };

  return (
    <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
      <div className='p-1'>
        <div className='columns is-variable'>
          <div className='column is-fullwidth'>
            <div className='card'>
              <header className='card-header has-background-white has-text-info'>
                <div
                  className='custom-card-flex is-size-6'
                  style={{ width: '100%' }}
                >
                  <p>
                    <i className='mdi mdi-account-cog is-size-5 icon' /> Setting
                    <i className='mdi mdi-menu-right is-size-6 icon' />
                    User
                  </p>
                </div>
              </header>
              <div className='card-content pl-4 pr-4'>
                <div className='level'>
                  <div className='level-left'>
                    <div className='level-item'>
                      <nav
                        className='breadcrumb is-size-6'
                        aria-label='breadcrumbs'
                      >
                        <ul>
                          <li className='is-active has-text-weight-bold'>
                            <Link to='/setting/user/tambah'>Tambah</Link>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>

                  <div className='level-right'>
                    <div className='level-item'>
                      <div className='buttons'>
                        <button
                          disabled={invalid || loading || pristine}
                          onClick={handleSubmit(onFormSubmit)}
                          className={
                            loading
                              ? 'button is-small is-info is-rounded is-outlined is-loading'
                              : 'button is-small is-info is-rounded is-outlined'
                          }
                        >
                          <i className='is-size-6 mdi mdi-content-save icon' />
                        </button>
                        <button
                          type='button'
                          onClick={history.goBack}
                          className='button custom-grey is-small is-rounded is-outlined'
                        >
                          <i className='is-size-6 mdi mdi-arrow-left icon' />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='columns'>
                  <div className='column is-third-quarter'>
                    <form
                      onSubmit={handleSubmit(onFormSubmit)}
                      autoComplete='off'
                    >
                      <Field
                        name='username'
                        type='text'
                        component={TextInput}
                        placeholder='Username'
                        label='Username'
                      />
                      <Field
                        name='password'
                        type='password'
                        component={TextInput}
                        placeholder='Password'
                        label='Password'
                      />
                      <div
                        className='field is-grouped'
                        style={{ marginTop: 20, marginBottom: 20 }}
                      >
                        <div className='control'>
                          <button
                            type='submit'
                            disabled={invalid || loading || pristine}
                            className={
                              loading
                                ? 'button is-info is-small is-rounded is-outlined is-loading'
                                : 'button is-info is-small is-rounded is-outlined'
                            }
                          >
                            <i className='is-size-6 mdi mdi-content-save icon' />
                          </button>
                        </div>
                        <div className='control'>
                          <button
                            onClick={() => history.goBack()}
                            className='button custom-grey is-small is-rounded is-outlined'
                          >
                            <i className='is-size-6 mdi mdi-arrow-left icon' />
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(
  connect(mapState, actions)(reduxForm({ form: 'addContent', validate })(Add))
);
