import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import { SITE_ADDRESS } from '../../../app/common/data/siteConfig';

function ViewForm(props) {
  const {
    auth,
    history,
    scope,
    id,
    initialValues,
    attachments,
    openModal,
    downloadFile,
  } = props;

  const [attached, setAttached] = useState([]);
  const attachedDiv = useRef(1);

  useEffect(() => {
    setAttached(attachments);
    attachedDiv.current++;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickRppDelete = () => {
    openModal('RppDelete', {
      data: {
        auth,
        history,
        item: initialValues,
      },
    });
  };

  const onClickAttachment = (item) => {
    function checkImage(url) {
      return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
    }
    let isImage = checkImage(item.filelink);
    if (isImage) {
      openModal('PopUpImageModal', { data: SITE_ADDRESS + item.filelink });
    } else {
      downloadFile(auth, history, initialValues, item);
    }
  };

  return (
    <>
      <div className='level'>
        <div className='level-left'>
          <div className='level-item'>
            <nav className='breadcrumb is-size-6' aria-label='breadcrumbs'>
              <ul>
                <li className='is-active has-text-weight-bold'>
                  <Link to={'/rpp/' + id}>Detail</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className='level-right'>
          <div className='level-item'>
            <div className='buttons'>
              {scope && scope.m && scope.m['rpp_publish'] && (
                <Link
                  to={`/rpp/publish/${id}`}
                  className='button is-small is-rounded is-info is-outlined'
                >
                  <i className='is-size-6 mdi mdi-share icon' />
                </Link>
              )}
              {scope && scope.m && scope.m['rpp_edit'] && (
                <Link
                  to={`/rpp/edit/${id}`}
                  className='button is-small is-rounded is-info is-outlined'
                >
                  <i className='is-size-6 mdi mdi-pencil icon' />
                </Link>
              )}
              {scope && scope.m && scope.m['rpp_delete'] && (
                <button
                  className='button is-small is-rounded is-danger is-outlined'
                  onClick={() => handleClickRppDelete()}
                >
                  <i className='is-size-6 mdi mdi-delete icon' />
                </button>
              )}
              <Link
                type='button'
                to={'/rpp'}
                className='button custom-grey is-small is-rounded is-outlined'
              >
                <i className='is-size-6 mdi mdi-arrow-left icon' />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className='columns'>
        <div className='column is-third-quarter'>
          <div className='control mb-4'>
            <h1 className='is-capitalized title is-size-6 mb-5'>
              {initialValues.title}
            </h1>
            <div
              dangerouslySetInnerHTML={{
                __html: initialValues.description,
              }}
            ></div>
          </div>
          {attached && attached[0] && (
            <>
              <hr />
              <div className='field' style={{ marginBottom: -5 }}>
                <label className='label'>Attachment(s)</label>
              </div>
              <div
                className='column is-full-tablet is-three-quarters-desktop'
                ref={attachedDiv}
                style={{ marginLeft: -10 }}
              >
                {attached.map((item, index) => (
                  <div
                    key={index}
                    className='has-text-link has-background-grey-lighter px-2 py-1 mb-1 flex justify-between align-center'
                  >
                    <span
                      className='hand-pointer'
                      onClick={() => onClickAttachment(item)}
                    >
                      {item.filename} (
                      {item.filesize.length < 7
                        ? Math.round(parseInt(item.filesize) / 1000) + ' KB'
                        : Math.round(parseInt(item.filesize) / 1000000) + ' MB'}
                      )
                    </span>
                  </div>
                ))}
              </div>
            </>
          )}
          <hr />
          <div className='field'>
            <label className='label'>Status</label>
          </div>
          <div className='tags has-addons'>
            {initialValues && !initialValues.published && (
              <span className='tag has-background-grey has-text-white'>
                Draft
              </span>
            )}
            {initialValues && initialValues.published && (
              <span className='tag is-info'>Post</span>
            )}
          </div>
          <label className='label'>Tags:</label>
          <div className='tags is-capitalized'>
            {initialValues &&
              initialValues.tags &&
              JSON.parse(initialValues.tags).map((item, index) => (
                <span key={index} className='tag is-success is-light'>
                  {item}
                </span>
              ))}
            {initialValues && !initialValues.tags && <>-</>}
          </div>
        </div>
      </div>
    </>
  );
}

export default reduxForm({
  form: 'rppView',
  enableReinitialize: true,
})(ViewForm);
