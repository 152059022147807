import { createReducer } from '../../utilities/reducerUtils';
import {
  SEARCH_JENJANG_CHANGE,
  SEARCH_TINGKAT_CHANGE,
  SEARCH_KELAS_CHANGE,
  SEARCH_PELAJARAN_CHANGE,
  SEARCH_YEARS_CHANGE,
  SEARCH_SEMS_CHANGE,
  SEARCHFORM_DELETE,
} from './reduxConstant';

const initialState = {
  jenjangs: [],
  tingkats: [],
  kelass: [],
  pelajarans: [],
  years: [],
  sems: [],
};

const searchJenjangChange = (state, payload) => {
  return {
    ...state,
    jenjangs: payload.jenjangs,
  };
};

const searchTingkatChange = (state, payload) => {
  return {
    ...state,
    tingkats: payload.tingkats,
  };
};

const searchKelasChange = (state, payload) => {
  return {
    ...state,
    kelass: payload.kelass,
  };
};

const searchPelajaranChange = (state, payload) => {
  return {
    ...state,
    pelajarans: payload.pelajarans,
  };
};

const searchYearsChange = (state, payload) => {
  return {
    ...state,
    years: payload.years,
  };
};

const searchSemsChange = (state, payload) => {
  return {
    ...state,
    sems: payload.sems,
  };
};

const searchFormDelete = (state, payload) => {
  return {
    jenjangs: [],
    tingkats: [],
    kelass: [],
    pelajarans: [],
    years: [],
    sems: [],
  };
};

export default createReducer(initialState, {
  [SEARCH_JENJANG_CHANGE]: searchJenjangChange,
  [SEARCH_TINGKAT_CHANGE]: searchTingkatChange,
  [SEARCH_KELAS_CHANGE]: searchKelasChange,
  [SEARCH_PELAJARAN_CHANGE]: searchPelajaranChange,
  [SEARCH_YEARS_CHANGE]: searchYearsChange,
  [SEARCH_SEMS_CHANGE]: searchSemsChange,
  [SEARCHFORM_DELETE]: searchFormDelete,
});
