import React from 'react';
import { reduxForm, Field } from 'redux-form';
import {
  composeValidators,
  combineValidators,
  isRequired,
  hasLengthGreaterThan,
  createValidator,
} from 'revalidate';
import TextInput from '../../app/common/form/TextInput';

let newPass;
const handleOnFormChange = (newValues, dispatch, props, previousValues) => {
  const { newPassword: newNewPass } = newValues;
  newPass = newNewPass;
};

const isPassEqual = createValidator(
  (message) => (value) => {
    if (value && value !== newPass) {
      return message;
    }
  },
  'Password tidak sama!'
);

const isAlphaNum = createValidator(
  (message) => (value) => {
    if (value && !value.match(/^[0-9a-z]+$/)) {
      return message;
    }
  },
  'Password karakter harus huruf atau angka'
);

const validate = combineValidators({
  oldPassword: isRequired({ message: 'Password lama harus diisi' }),
  newPassword: composeValidators(
    isRequired({ message: 'Password baru harus diisi' }),
    hasLengthGreaterThan(3)({
      message: 'Password harus memiliki paling sedikit 4 karakter',
    }),
    isAlphaNum
  )(),
  confirmPassword: composeValidators(
    isRequired({ message: 'Konfirmasi password harus diisi' }),
    isPassEqual
  )(),
});

function Account(props) {
  const { pristine, invalid, submitting, handleSubmit, updatePassword } =
    props;
  return (
    <form autoComplete='off' onSubmit={handleSubmit(updatePassword)}>
      <Field
        label='Password Lama'
        name='oldPassword'
        type='password'
        component={TextInput}
        placeholder='Password Lama'
        className='is-expanded'
      />
      <Field
        label='Password Baru'
        name='newPassword'
        type='password'
        component={TextInput}
        placeholder='Password Baru'
        className='is-expanded'
      />
      <Field
        label='Konfirmasi Password'
        name='confirmPassword'
        type='password'
        component={TextInput}
        placeholder='Konfirmasi Password'
        className='is-expanded'
      />
      <div
        className='field is-grouped'
        style={{ marginTop: 20, marginBottom: 20 }}
      >
        <div className='control'>
          <button
            type='submit'
            disabled={submitting || invalid || pristine}
            className={
              submitting
                ? 'button is-info is-small is-rounded is-outlined is-loading'
                : 'button is-info is-small is-rounded is-outlined'
            }
          >
            <i className='is-size-6 mdi mdi-content-save icon' />
          </button>
        </div>
      </div>
    </form>
  );
}

export default reduxForm({
  form: 'profileAccount',
  onChange: handleOnFormChange,
  validate,
})(Account);
