import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getSlideshow } from './redux/reduxApi';
import ViewForm from './ViewForm';
import { openModal } from '../../../app/modals/redux/modalActions';

const mapState = (state, ownProps) => {
  const id = ownProps.match.params.id;
  let scope = {};
  if (state.auth && state.auth.isAuth) {
    scope = state.auth.authorities;
  }
  let slideshow = {};
  if (state.slideshows && state.slideshows.items && state.slideshows.items[0]) {
    slideshow = state.slideshows.items.filter((i) => i.id + '' === id + '')[0];
  }
  return {
    loading: state.async.loading,
    auth: state.auth,
    scope: scope,
    id: id,
    slideshow: slideshow,
  };
};

const actions = {
  getSlideshow,
  openModal,
};

function View(props) {
  const { auth, history, scope, openModal, loading, id, slideshow, getSlideshow } = props;

  useEffect(() => {
    getSlideshow(auth, history, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
      <div className='p-1'>
        <div className='columns is-variable'>
          <div className='column is-fullwidth'>
            <div className='card'>
              <header className='card-header has-background-white has-text-info'>
                <div
                  className='custom-card-flex is-size-6'
                  style={{ width: '100%' }}
                >
                  <p>
                    <i className='mdi mdi-account-group is-size-5 icon' /> Portal
                    <i className='mdi mdi-menu-right is-size-6 icon' />
                    Slideshow
                  </p>
                </div>
              </header>
              <div className='card-content pl-4 pr-4'>
                <ViewForm
                  initialValues={slideshow}
                  loading={loading}
                  auth={auth}
                  history={history}
                  scope={scope}
                  openModal={openModal}
                  id={id}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(connect(mapState, actions)(View));
