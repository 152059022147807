import { createReducer } from '../../utilities/reducerUtils';
import { SESSIONSTATE_CHANGE, SESSIONSTATE_DELETE } from './reduxConstant';

const initialState = [];

const sessionStateChange = (state, payload) => {
  let item = {
    id: payload.sessionState.id,
    data: payload.sessionState.data,
  };
  let initState = [];
  if (state && state[0]) {
    initState = [
      ...state.filter((i) => i.id + '' !== payload.sessionState.id + ''),
      item,
    ];
  } else {
    initState = [item];
  }
  return [...initState];
};

const sessionStateDelete = (state, payload) => {
  return [...state.filter((i) => i.id + '' !== payload.sessionState.id + '')];
};

export default createReducer(initialState, {
  [SESSIONSTATE_CHANGE]: sessionStateChange,
  [SESSIONSTATE_DELETE]: sessionStateDelete,
});
