import React from 'react';
import { Link } from 'react-router-dom';
import TextInput from '../../../app/common/form/TextInput';
import TextArea from '../../../app/common/form/TextArea';
import SelectInput from '../../../app/common/form/SelectInput';
import { reduxForm, Field } from 'redux-form';
import {
  composeValidators,
  combineValidators,
  isRequired,
  hasLengthGreaterThan,
} from 'revalidate';

const validate = combineValidators({
  code: composeValidators(
    isRequired({ message: 'Kode harus diisi' }),
    hasLengthGreaterThan(1)({
      message: 'Kode harus memiliki paling sedikit 2 karakter',
    })
  )(),
  title: composeValidators(
    isRequired({ message: 'Nama Pelajaran harus diisi' }),
    hasLengthGreaterThan(1)({
      message: 'Nama Pelajaran harus memiliki paling sedikit 2 karakter',
    })
  )(),
  jenjangId: isRequired({ message: 'Jenjang harus diisi' }),
});

function AddForm(props) {
  const {
    auth,
    history,
    invalid,
    loading,
    pristine,
    handleSubmit,
    initialValues,
    addPelajaran,
  } = props;

  const handleClickSubmit = (values) => {
    addPelajaran(auth, history, values);
  };

  return (
    <>
      <div className='level'>
        <div className='level-left'>
          <div className='level-item'>
            <nav className='breadcrumb is-size-6' aria-label='breadcrumbs'>
              <ul>
                <li className='is-active has-text-weight-bold'>
                  <Link to={'/akademik/pelajaran/tambah'}>Tambah</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className='level-right'>
          <div className='level-item'>
            <div className='buttons'>
              <button
                disabled={invalid || loading || pristine}
                onClick={handleSubmit(handleClickSubmit)}
                className='button is-small is-rounded is-info is-outlined'
              >
                <i className='is-size-6 mdi mdi-content-save icon' />
              </button>
              <Link
                type='button'
                to={'/akademik/pelajaran'}
                className='button custom-grey is-small is-rounded is-outlined'
              >
                <i className='is-size-6 mdi mdi-arrow-left icon' />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='columns'>
        <div className='column is-third-quarter'>
          <form onSubmit={handleSubmit(handleClickSubmit)} autoComplete='off'>
            <Field
              name='code'
              type='text'
              component={TextInput}
              placeholder='Kode'
              label='Kode'
            />
            <Field
              name='title'
              type='text'
              component={TextInput}
              placeholder='Nama Pelajaran'
              label='Nama Pelajaran'
            />
            <Field
              name='jenjangId'
              type='text'
              component={SelectInput}
              placeholder='Jenjang'
              label='Jenjang'
              options={initialValues?.jenjangSelect}
              fullwidth={true}
            />
            <Field
              name='description'
              type='text'
              component={TextArea}
              placeholder='Deskripsi'
              label='Deskripsi'
            />
            <div
              className='field is-grouped'
              style={{ marginTop: 20, marginBottom: 20 }}
            >
              <div className='control'>
                <button
                  type='submit'
                  disabled={invalid || loading || pristine}
                  className={
                    loading
                      ? 'button is-info is-small is-rounded is-outlined is-loading'
                      : 'button is-info is-small is-rounded is-outlined'
                  }
                >
                  <i className='is-size-6 mdi mdi-content-save icon' />
                </button>
              </div>
              <div className='control'>
                <button
                  onClick={() => history.goBack()}
                  className='button custom-grey is-small is-rounded is-outlined'
                >
                  <i className='is-size-6 mdi mdi-arrow-left icon' />
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default reduxForm({
  form: 'tingkatAdd',
  validate,
  enableReinitialize: true,
})(AddForm);
