import React from 'react'; // useEffect,
import { connect } from 'react-redux';
import {
  Redirect,
  // useHistory,
} from 'react-router-dom';
import NotFound from '../../app/common/layout/NotFound';
import { openModal } from '../../app/modals/redux/modalActions';
// import Gallery from './Gallery';
// import { getSlideshow } from '../students-menu/slideshow/redux/reduxApi';
// import { SITE_ADDRESS } from '../../common/util/siteConfig';
// import { getAgenda } from '../students-menu/agenda/redux/reduxApi';
// import Slide from '../students-menu/dashboard/Slide';
// import PrintPage from './PrintPage';
import FilePDF from '../../pictures/example_pdf.pdf';
import PullToRefresh from 'react-simple-pull-to-refresh';

function mapState(state) {
  return {
    auth: state.auth,
    slideshow: state.slideshow,
    // sagenda: state.sagenda,
  };
}

const actions = {
  // getSlideshow,
  // getAgenda,
  openModal,
};

function Index(props) {
  // const history = useHistory();
  const {
    auth,
    // getSlideshow,
    // slideshow,
    // getAgenda,
    // sagenda,
    openModal,
  } = props;

  // useEffect(() => {
  //   getSlideshow(auth, history);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // useEffect(() => {
  //   getAgenda(auth, history);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const fullURL = window.location.href;
  if (auth.isAuth !== true) return <Redirect to='/login' />;
  if (auth.isAuth === true && auth.registerParents)
    return <Redirect to='/register-2' />;
  if (!fullURL.includes('localhost')) {
    return <NotFound />;
  }

  // const images = [];

  // slideshow.forEach((item, index) => {
  //   let image = {
  //     original: `${SITE_ADDRESS}slideshow/view/${item.date}_${item.image}`,
  //     originalTitle: item.image,
  //   };
  //   images.push(image);
  // });

  // let slideShowItems =
  //   images && images.length > 0 ? <Gallery images={images} /> : <></>;

  const handleRefresh = () => {
    window.location.reload();
  }

  return (
    <>
      <PullToRefresh onRefresh={handleRefresh}>
        <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
          <div className='p-1'>
            <div className='columns is-variable is-desktop'>
              <div className='column is-12'>
                <div id='fadein' className='card'>
                  <header className='card-header has-background-info has-text-white'>
                    <div className='custom-card-flex' style={{ width: '70vh' }}>
                      <p>
                        <i className='is-size-6 mdi mdi-view-dashboard icon' />
                        Dashboard
                      </p>
                    </div>
                  </header>
                  <div className='card-content pl-3 pr-3'>
                    <button
                      onClick={() =>
                        openModal('PopUpPdfModal', { data: FilePDF })
                      }
                      className='button is-info'
                    >
                      Preview PDF on Mobile & Tablet with Modal
                    </button>
                    <br />
                    <br />
                    <div className='mb-2'>
                      <span className='tag is-success'>
                        Preview PDF only in desktop mode
                      </span>
                    </div>
                    <object
                      type='application/pdf'
                      data={FilePDF}
                      style={{ width: '100%', height: '70vh' }}
                    >
                      <param
                        name='data'
                        value={FilePDF}
                        style={{ width: '100%', height: '70vh' }}
                      />
                    </object>
                    {/* <PrintPage /> */}
                    {/* <h1
                    className='mb-1 has-text-weight-bold has-text-info'
                    style={{ marginTop: -20 }}
                  >
                    <i className='is-size-6 mdi mdi-image mr-1' /> Galeri
                  </h1>
                  {slideshow && slideshow.length > 0 && (
                    <>
                      {slideShowItems}
                      <hr style={{ margin: 10 }} />
                    </>
                  )}
                  <h1 className='has-text-weight-bold has-text-link mb-4'>
                    <i className='is-size-6 mdi mdi-bullhorn mr-1' /> Agenda
                  </h1>
                  <Slide agenda={sagenda} /> */}
                  </div>
                </div>
                {/* <div id='fadein' className='card'>
                <header className='card-header has-background-info has-text-white'>
                  <div className='custom-card-flex' style={{ width: '100%' }}>
                    <p>
                      <i className='is-size-6 mdi mdi-information icon' />
                      Pengumuman
                    </p>
                  </div>
                </header>
                <div className='card-content pl-3 pr-3'>
                  <h1>Tidak ada pengumuman</h1>
                </div>
              </div> */}
                {/* <div id='fadein' className='card mt-3'>
                <header className='card-header has-background-info has-text-white'>
                  <div className='custom-card-flex' style={{ width: '100%' }}>
                    <p>
                      <i className='is-size-6 mdi mdi-information icon' />
                      Modal
                    </p>
                  </div>
                </header>
                <div className='card-content pl-3 pr-3'>
                  <button
                    className='button is-small is-rounded is-info'
                    onClick={() => openModal('TestModal')}
                  >
                    Open Modal
                  </button>
                </div>
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </PullToRefresh>
    </>
  );
}

export default connect(mapState, actions)(Index);
