import React, { useState, useEffect } from 'react';
import { Button } from './Button';

export default function SimpleSearch(props) {
  const [value, setValue] = useState('');
  const { loading, title, autoFocus, initialValues, onFormSubmit } = props;

  useEffect(() => {
    setValue(initialValues);
  }, [initialValues]);

  const handleFocus = (e) => {
    e.target.select();
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    onFormSubmit(value);
    e.preventDefault();
  };

  return (
    <form onSubmit={(e) => handleSearchSubmit(e)} autoComplete='off'>
      <div className='field has-addons'>
        <p className='control'>
          <input
            name='searchText'
            value={value}
            onChange={handleChange}
            className='input is-small is-rounded is-info'
            type='text'
            placeholder={`Cari ${title}`}
            onFocus={handleFocus}
            autoFocus={autoFocus}
          />
        </p>
        <p className='control'>
          <Button
            type='submit'
            disabled={loading}
            className='button is-small is-info is-rounded is-outlined'
            loading={loading}
            icon='search'
          />
        </p>
      </div>
    </form>
  );
}
