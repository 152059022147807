import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getJenjang, editJenjang } from './redux/reduxApi';
import EditForm from './EditForm';

const mapState = (state, ownProps) => {
  const id = ownProps.match.params.id;
  let scope = {};
  if (state.auth && state.auth.isAuth) {
    scope = state.auth.authorities;
  }
  let jenjang = {};
  if (state.jenjangs && state.jenjangs.items && state.jenjangs.items[0]) {
    jenjang = state.jenjangs.items.filter((i) => i.id + '' === id + '')[0];
  }
  return {
    loading: state.async.loading,
    auth: state.auth,
    scope: scope,
    id: id,
    jenjang: jenjang,
  };
};

const actions = {
  getJenjang,
  editJenjang,
};

function Edit(props) {
  const { auth, history, loading, id, jenjang, getJenjang, editJenjang } =
    props;

  useEffect(() => {
    getJenjang(auth, history, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
      <div className='p-1'>
        <div className='columns is-variable'>
          <div className='column is-fullwidth'>
            <div className='card'>
              <header className='card-header has-background-white has-text-info'>
                <div
                  className='custom-card-flex is-size-6'
                  style={{ width: '100%' }}
                >
                  <p>
                    <i className='mdi mdi-school is-size-5 icon' /> Akademik
                    <i className='mdi mdi-menu-right is-size-6 icon' />
                    Jenjang
                  </p>
                </div>
              </header>
              <div className='card-content pl-4 pr-4'>
                <EditForm
                  initialValues={jenjang}
                  loading={loading}
                  auth={auth}
                  id={id}
                  history={history}
                  editJenjang={editJenjang}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(connect(mapState, actions)(Edit));
