import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getTingkat, editTingkat } from './redux/reduxApi';
import EditForm from './EditForm';

const mapState = (state, ownProps) => {
  const id = ownProps.match.params.id;
  let scope = {};
  if (state.auth && state.auth.isAuth) {
    scope = state.auth.authorities;
  }
  let tingkats = state?.tingkats;
  let tingkat = {};
  if (tingkats?.items && tingkats.items[0]) {
    tingkat = tingkats?.items?.filter((i) => i.id + '' === id + '')[0];
  }
  let jenjang = [];
  if (tingkats?.jenjangs && tingkats?.jenjangs[0]) {
    jenjang = tingkats.jenjangs;
  }
  return {
    loading: state.async.loading,
    auth: state.auth,
    scope: scope,
    id: id,
    jenjang: jenjang,
    tingkat: tingkat,
  };
};

const actions = {
  getTingkat,
  editTingkat,
};

function Edit(props) {
  const {
    auth,
    history,
    loading,
    id,
    jenjang,
    tingkat,
    getTingkat,
    editTingkat,
  } = props;
  const [initialValues, setInitialValues] = useState({
    code: '',
    title: '',
    jenjangId: '',
    description: '',
    jenjangSelect: [],
  });

  useEffect(() => {
    let jenjangSelect = [];
    jenjang?.forEach((j, i) => {
      jenjangSelect = [
        ...jenjangSelect,
        { key: i, value: j.id, text: j.title },
      ];
    });
    setInitialValues({
      ...tingkat,
      jenjangSelect: jenjangSelect,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tingkat, jenjang]);

  useEffect(() => {
    getTingkat(auth, history, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
      <div className='p-1'>
        <div className='columns is-variable'>
          <div className='column is-fullwidth'>
            <div className='card'>
              <header className='card-header has-background-white has-text-info'>
                <div
                  className='custom-card-flex is-size-6'
                  style={{ width: '100%' }}
                >
                  <p>
                    <i className='mdi mdi-school is-size-5 icon' /> Akademik
                    <i className='mdi mdi-menu-right is-size-6 icon' />
                    Tingkat
                  </p>
                </div>
              </header>
              <div className='card-content pl-4 pr-4'>
                <EditForm
                  initialValues={initialValues}
                  loading={loading}
                  auth={auth}
                  id={id}
                  history={history}
                  editTingkat={editTingkat}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(connect(mapState, actions)(Edit));
