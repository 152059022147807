import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import ImportFileInput from '../../../app/common/layout/ImportFileInput';
import { importWhatsapp } from './redux/reduxApi';
import TextInput from '../../../app/common/form/TextInput';
import TextArea from '../../../app/common/form/TextArea';
import { nanoid } from 'nanoid';
import { format } from 'date-fns';

const mapState = (state, ownProps) => {
  const id = ownProps.match.params.id;
  return {
    auth: state.auth,
    loading: state.async.loading,
    importId: id,
  };
};

const actions = {
  importWhatsapp,
};

class Import extends Component {
  state = {
    inputKey: Date.now(),
    tl: 'Pesan',
    headerCols: ['No', 'Nama Siswa', 'No HP', 'Pesan'],
    numberOfStudents: 0,
    importId: null,
    title: null,
    description: ' ',
    messages: [],
    recipients: [],
  };

  componentDidMount = () => {
    const { importId } = this.props;
    this.setState({
      ...this.state,
      importId: importId,
      title: 'Pesan ' + format(new Date(), 'd MMM yyyy hh:mm:ss'),
    });
  };

  // handleFile = (event, input, fileId) => {
  //   event.preventDefault();
  //   let file = event.target.files[0];
  //   let files = this.state.files;
  //   if (file) {
  //     let fileData = {
  //       file,
  //       filename: file.name,
  //       filesize: file.size,
  //       filetype: file.type,
  //     };
  //     let id = { id: fileId, data: fileData };
  //     this.setState({
  //       ...this.state,
  //       files: { ...files, [fileId]: id },
  //     });
  //   }
  // };

  // renderFileInput = ({ input, type, meta, placeholder, file, id }) => {
  //   const { mimeType } = this.props;
  //   return (
  //     <div className='field'>
  //       <div className='control'>
  //         <div className='file has-name is-fullwidth'>
  //           <label className='file-label'>
  //             <input
  //               name={input.name}
  //               className='file-input'
  //               type={type}
  //               accept={mimeType}
  //               onChange={(e) => this.handleFile(e, input, id)}
  //             />
  //             <span className='file-cta'>
  //               <span className='file-icon'>
  //                 <i className='is-size-6 mdi mdi-upload'></i>
  //               </span>
  //               <span className='file-label'>{placeholder}</span>
  //             </span>
  //             <span className='file-name'>{file?.data?.filename}</span>
  //           </label>
  //         </div>
  //       </div>
  //       {meta && meta.invalid && meta.error && <div>{meta.error}</div>}
  //     </div>
  //   );
  // };

  handleSelectedExcel = (e) => {
    let file = e.target.files[0];

    const reader = new FileReader();
    if (file) {
      reader.readAsArrayBuffer(file);

      reader.onload = (e) => {
        let sheetData = new Uint8Array(reader.result);
        let wb = XLSX.read(sheetData, { type: 'array' });

        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        // Read excel to JSON
        const data = XLSX.utils.sheet_to_json(ws, { raw: false });

        let messages = [];
        let numberOfStudents = 0;
        for (let i = 0; i < data.length; i++) {
          const el = data[i];
          if (el['Nama Siswa'] && el['Nama Siswa'].length > 2) {
            let phone = [];
            let arrPhone = el['No HP'].split(';');
            let siswaPhone = arrPhone[0].split('Siswa:')[1];
            let ayahPhone = arrPhone[1].split('Ayah:')[1];
            let ibuPhone = arrPhone[2].split('Ibu:')[1];
            let waliPhone = arrPhone[3].split('Wali:')[1];
            if (siswaPhone && siswaPhone.trim()) {
              phone.push({ recipient: 'siswa', phone: siswaPhone });
            }
            if (ayahPhone && ayahPhone.trim()) {
              phone.push({ recipient: 'ayah', phone: ayahPhone });
            }
            if (ibuPhone && ibuPhone.trim()) {
              phone.push({ recipient: 'ibu', phone: ibuPhone });
            }
            if (waliPhone && waliPhone.trim()) {
              phone.push({ recipient: 'wali', phone: waliPhone });
            }
            el.id = nanoid();
            el.phone = phone;
            messages.push(el);
            numberOfStudents = numberOfStudents + 1;
          }
        }
        this.setState({
          ...this.state,
          messages: messages,
          numberOfStudents: numberOfStudents,
        });
      };
    }
  };

  onImport = async () => {
    const { auth, history, importWhatsapp } = this.props;
    const { messages, numberOfStudents, importId, title, description, files } =
      this.state;
    let formatedPesan = [];
    let allRecipients = [];
    for (let i = 0; i < messages.length; i++) {
      let item = messages[i];
      let phone = item.phone;
      let recipientsItem = [];
      for (let k = 0; k < phone.length; k++) {
        let itemPhone = phone[k];
        let recipientItem = {
          recipient: itemPhone.recipient,
          phone: itemPhone.phone,
          message: item.Pesan,
          status: '',
        };
        recipientsItem = [...recipientsItem, recipientItem];
        allRecipients = [...allRecipients, {...recipientItem, id: importId, studentPesanId: item.id, studentName: item['Nama Siswa']}];
      }
      let newObj = {
        id: item.id,
        studentName: item['Nama Siswa'],
        phone: phone,
        message: item.Pesan,
      };
      formatedPesan = [...formatedPesan, newObj];
    }
    const values = {
      numberOfStudents: numberOfStudents,
      importId: importId,
      title: title.trim(),
      description: description.trim(),
      messages: formatedPesan,
      recipients: allRecipients,
      files: files,
    };
    importWhatsapp(auth, history, values);
    // openModal('PesanImportSave', {
    //   data: {
    //     auth,
    //     history,
    //     item: values,
    //   },
    // });
  };

  onChangeInput = (e, key) => {
    let val = e.target.value;
    if (val.length === 0) {
      val = ' ';
    }
    if (key === 'title') {
      this.setState({
        ...this.state,
        title: val,
      });
    } else if (key === 'description') {
      this.setState({
        ...this.state,
        description: val,
      });
    }
  };

  onCancel = () => {
    this.setState({
      inputKey: Date.now(),
      tl: 'Pesan',
      messages: [],
      numberOfStudents: 0,
      title: 'Pesan ' + format(new Date(), 'd MMM yyyy hh:mm:ss'),
      description: '',
    });
  };

  onGoBack = () => {
    const { history } = this.props;
    this.onCancel();
    history.push({
      pathname: '/pesan/whatsapp',
      state: { data: 'nodata' },
    });
  };

  render() {
    const { loading, handleSubmit } = this.props;
    const { messages, inputKey, tl, importId } = this.state;

    return (
      <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
        <div className='p-1'>
          <div className='columns is-variable'>
            <div className='column is-fullwidth'>
              <div className='card'>
                <header className='card-header has-background-white has-text-info'>
                  <div
                    className='custom-card-flex is-size-6'
                    style={{ width: '100%' }}
                  >
                    <p>
                      <i className='mdi mdi-message-text-outline is-size-5 icon' />{' '}
                      Pesan<i className='mdi mdi-menu-right is-size-6 icon' />Whatsapp
                    </p>
                  </div>
                </header>
                <div className='card-content pl-4 pr-4'>
                  <form autoComplete='off' style={{ marginTop: 1 }}>
                    <div className='level'>
                      <div className='level-left'>
                        <div className='level-item'>
                          <nav
                            className='breadcrumb is-size-6'
                            aria-label='breadcrumbs'
                          >
                            <ul>
                              <li className='is-active has-text-weight-bold'>
                                <Link to={'/pesan/whatsapp/import/' + importId}>Import</Link>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </div>

                      <div className='level-right'>
                        <div className='level-item'>
                          <div className='buttons'>
                            {!loading && messages && messages[0] && (
                              <>
                                <button
                                  disabled={loading}
                                  onClick={handleSubmit(this.onImport)}
                                  className={
                                    loading
                                      ? 'button is-small is-info is-rounded is-outlined is-loading'
                                      : 'button is-small is-info is-rounded is-outlined'
                                  }
                                  style={{ marginRight: 10 }}
                                >
                                  <i className='is-size-6 mdi mdi-content-save icon' />
                                </button>
                                {/* <div
                                  data-for='main'
                                  data-tip='Simpan dan Kirim'
                                >
                                  <button
                                    disabled={loading}
                                    onClick={handleSubmit(this.onImport)}
                                    className={
                                      loading
                                        ? 'button is-small is-info is-rounded is-outlined is-loading'
                                        : 'button is-small is-info is-rounded is-outlined'
                                    }
                                    style={{ marginRight: 10 }}
                                  >
                                    <i className='is-size-6 mdi mdi-email-send icon' />
                                  </button>
                                  <ReactTooltip
                                    id='main'
                                    place='bottom'
                                    type='dark'
                                    effect='solid'
                                    multiline={true}
                                  />
                                </div> */}
                                <button
                                  disabled={loading}
                                  onClick={this.onCancel}
                                  className='button custom-grey is-small is-rounded is-outlined'
                                >
                                  <i className='is-size-6 mdi mdi-reload icon' />
                                </button>
                              </>
                            )}
                            <button
                              disabled={loading}
                              onClick={this.onGoBack}
                              className='button custom-grey is-small is-rounded is-outlined'
                            >
                              <i className='is-size-6 mdi mdi-arrow-left icon' />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {!loading && messages && !messages[0] && (
                      <div className='columns'>
                        <div className='column is-4 is-offset-4'>
                          <div className='field'>
                            <ImportFileInput
                              handleExcelUpload={this.handleSelectedExcel}
                              inputKey={inputKey}
                              tl={tl}
                              link={'/templates/Import_Pesan_Template.xlsx'}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {!loading && (
                      <div className='columns'>
                        <div className='column is-12'>
                          {messages && messages[0] && (
                            <>
                              <Field
                                name='title'
                                type='text'
                                defaultValue={this.state.title ?? ' '}
                                onChange={(e) => this.onChangeInput(e, 'title')}
                                component={TextInput}
                                placeholder='Judul'
                                label='Judul'
                              />
                              <Field
                                name='description'
                                type='text'
                                defaultValue={this.state.description ?? ' '}
                                onChange={(e) =>
                                  this.onChangeInput(e, 'description')
                                }
                                component={TextArea}
                                placeholder='Deskripsi'
                                label='Deskripsi'
                              />
                              <br />
                            </>
                          )}
                          <div className='table-container overflow-auto'>
                            <table className='table is-hoverable'>
                              {messages && messages[0] && (
                                <thead className='has-background-info'>
                                  <tr>
                                    <th
                                      colSpan={
                                        this.state &&
                                        this.state.headerCols &&
                                        this.state.headerCols.length
                                      }
                                      className='has-text-white'
                                    >
                                      Penerima Pesan
                                    </th>
                                  </tr>
                                </thead>
                              )}
                              <thead>
                                {messages && messages[0] && (
                                  <tr>
                                    {this.state &&
                                      this.state.headerCols.map((i, key) => (
                                        <th key={key}>{i}</th>
                                      ))}
                                  </tr>
                                )}
                              </thead>
                              <tbody>
                                {messages && messages[0] && (
                                  <Fragment>
                                    {messages.map((item, index) => {
                                      return (
                                        <Item
                                          item={item}
                                          index={index}
                                          key={item.id}
                                          headerCols={this.state.headerCols}
                                          // files={this.state.files}
                                          // FileInput={this.renderFileInput}
                                        />
                                      );
                                    })}
                                  </Fragment>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  mapState,
  actions
)(reduxForm({ form: 'WhatsappFormImport' })(Import));

export class Item extends Component {
  render() {
    const {
      item,
      index,
      //  files, FileInput
    } = this.props;
    // let strPhone = item?.['No HP'];
    // let arrPhone = strPhone ? strPhone.split(';').filter((e) => e) : [];
    let phone = item?.phone;
    // let file = files && files[item.id] ? files[item.id] : undefined;
    return (
      <tr>
        <td>{index + 1}</td>
        <td style={{ minWidth: 200 }}>{item?.['Nama Siswa']}</td>
        <td style={{ minWidth: 210 }}>
          {phone &&
            phone.map((i, index) => (
              <span className='is-capitalized' key={i + '_' + index}>
                {i.recipient}: {i.phone}
                {index + 1 < phone.length ? <br /> : ''}
              </span>
            ))}
        </td>
        <td style={{ minWidth: 400 }}>{item?.['Pesan']}</td>
        {/* <td style={{ minWidth: 250 }}>
          <Field
            name='attachment'
            type='file'
            component={FileInput}
            placeholder='File'
            label='File Attachement'
            id={item.id}
            file={file}
          />
        </td> */}
      </tr>
    );
  }
}
