import React from 'react';
import { connect } from 'react-redux';
import { closeModal } from '../../app/modals/redux/modalActions';

const actions = {
  closeModal,
};

const TestModal = ({ closeModal, data }) => {
  return (
    <div className='modal is-active'>
      <div className='modal-background' onClick={closeModal}></div>
      <div id='fadein' className='modal-content' style={{height: '100%'}}>
        <div className='box' style={{display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%', backgroundColor: '#00d1b2', marginLeft: 5, marginRight: 5}}>
          <div style={{flexGrow: 4, backgroundColor: '#00d1b2', width: '100%'}}>
            <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', height: '70%'}}>
              <div className='has-text-white' style={{flexGrow: 1, display: 'flex', width: 100, height: 100, justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                <div><i className='is-size-6 mdi mdi-view-dashboard icon is-size-3 mb-3' /></div>
                <p style={{height: 50, textAlign: 'center'}}>Dashboard</p></div>
              <div className='has-text-white' style={{flexGrow: 1, display: 'flex', width: 100, height: 100, justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                <div><i className='is-size-6 mdi mdi-account icon is-size-3 mb-3' /></div>
                <p style={{height: 50, textAlign: 'center'}}>Profil</p></div>
              <div className='has-text-white' style={{flexGrow: 1, display: 'flex', width: 100, height: 100, justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                <div><i className='is-size-6 mdi mdi-file-tree icon is-size-3 mb-3' /></div>
                <p style={{height: 50, textAlign: 'center'}}>Absensi</p></div>
              <div className='has-text-white' style={{flexGrow: 1, display: 'flex', width: 100, height: 100, justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                <div><i className='is-size-6 mdi mdi-clock icon is-size-3 mb-3' /></div>
                <p style={{height: 50, textAlign: 'center'}}>Jadwal</p></div>
              <div className='has-text-white' style={{flexGrow: 1, display: 'flex', width: 100, height: 100, justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                <div><i className='is-size-6 mdi mdi-receipt icon is-size-3 mb-3' /></div>
                <p style={{height: 50, textAlign: 'center'}}>Rapor Mid</p></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, actions)(TestModal);
