import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { withRouter, Switch, Redirect, Route } from 'react-router-dom';
import Tab from './Tab';
import Info from './Info';
import Account from './Account';
import { toastr } from 'react-redux-toastr';

class Form extends Component {
  _isMounted = false;
  state = {
    initialValues: {},
    toggle: false,
    editState: false,
  };

  componentDidMount = () => {
    this._isMounted = true;
    this.updateInitialValues();
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  setEditState = () => {
    this.setState({
      ...this.state,
      editState: !this.state.editState,
    });
  };

  updateInitialValues = () => {
    const { profile, auth } = this.props;
    const setInitialValues = { ...profile, username: auth.username };
    if (this._isMounted) {
      this.setState({
        initialValues: setInitialValues,
      });
    }
  };

  updateInfo = (values) => {
    if (values === this.state.initialValues) {
      this.setEditState();
      return;
    }
    const { auth, history, editProfile } = this.props;
    try {
      if (values.dob) {
        values.dob =
          typeof values.dob !== 'string'
            ? values.dob.toISOString()
            : values.dob;
      }
      const newValues = {
        ...values,
      };
      this.setEditState();
      editProfile(auth, history, newValues);
    } catch (error) {
      console.log(error);
    }
  };

  updatePassword = (values) => {
    if (values === this.state.initialValues) {
      this.setEditState();
      return;
    }
    const { auth, history, resetPasswordProfile, dispatch } = this.props;
    try {
      const newPass = {
        id: auth.userId,
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      };
      resetPasswordProfile(auth, history, newPass).then((res) => {
        if (!res) {
          return;
        }
        if (res.status === 'success') {
          dispatch(reset('profileAccount'));
        }
        toastr.success(
          'Sukses',
          `Update password ${res.user.username} berhasil`
        );
      });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { pathname, auth, loading, reset } = this.props;
    const { initialValues, editState } = this.state;
    return (
      <>
        <Tab auth={auth} pathname={pathname} />
        <Switch>
          <Redirect exact from={`/profil`} to={`/profil/info`} />
          <Route
            path={`/profil/info`}
            render={() => (
              <Info
                initialValues={initialValues}
                loading={loading}
                onFormSubmit={this.updateInfo}
                reset={reset}
                updateInitialValues={this.updateInitialValues}
                editState={editState}
                setEditState={this.setEditState}
              />
            )}
          />
          {auth?.authorities?.title && auth.authorities?.title[0] !== 'SA' && (
            <Route
              path={`/profil/ubah-password`}
              render={() => (
                <Account
                  initialValues={auth}
                  loading={loading}
                  updatePassword={this.updatePassword}
                  reset={reset}
                  updateInitialValues={this.updateInitialValues}
                />
              )}
            />
          )}
        </Switch>
      </>
    );
  }
}

export default withRouter(connect(null, null)(Form));
