import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  getRpp,
  editRpp,
  uploadAttachment,
  downloadFile,
} from './redux/reduxApi';
import EditForm from './EditForm';
import { openModal } from '../../../app/modals/redux/modalActions';

const mapState = (state, ownProps) => {
  const id = ownProps.match.params.id;
  let scope = {};
  if (state.auth && state.auth.isAuth) {
    scope = state.auth.authorities;
  }
  let rpp = {};
  let uploadStatus;
  if (state.rpps && state.rpps.items && state.rpps.items[0]) {
    rpp = state.rpps.items.filter((i) => i.id + '' === id + '')[0];
    uploadStatus = state.rpps.uploadStatus;
  }
  return {
    id: id,
    loading: state.async.loading,
    auth: state.auth,
    scope: scope,
    rpp: rpp,
    uploadStatus,
  };
};

const actions = {
  getRpp,
  editRpp,
  uploadAttachment,
  downloadFile,
  openModal,
};

function Edit(props) {
  const {
    auth,
    loading,
    history,
    uploadAttachment,
    downloadFile,
    id,
    rpp,
    uploadStatus,
    getRpp,
    editRpp,
    openModal,
  } = props;
  const [state, setState] = useState({});

  useEffect(() => {
    getRpp(auth, history, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { title, description, attachments } = rpp;
    setState({
      title: title,
      description: description,
      attachments: attachments ? attachments : '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rpp]);

  const onFormSubmit = (values) => {
    try {
      const data = { ...values };
      editRpp(auth, history, data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
      <div className='p-1'>
        <div className='columns is-variable'>
          <div className='column is-fullwidth'>
            <div className='card'>
              <header className='card-header has-background-white has-text-info'>
                <div
                  className='custom-card-flex is-size-6'
                  style={{ width: '100%' }}
                >
                  <p>
                    <i className='mdi mdi-notebook-multiple is-size-5 icon' />{' '}
                    RPP
                  </p>
                </div>
              </header>
              <div className='card-content pl-4 pr-4'>
                <EditForm
                  auth={auth}
                  loading={loading}
                  history={history}
                  openModal={openModal}
                  id={id}
                  initialValues={state}
                  uploadStatus={uploadStatus}
                  editRpp={editRpp}
                  uploadAttachment={uploadAttachment}
                  downloadFile={downloadFile}
                  onFormSubmit={onFormSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(connect(mapState, actions)(Edit));
