import React from 'react';
import { Link } from 'react-router-dom';

export default function ViewFormTab(props) {
  const { id, pathname } = props;
  return (
    <div className='tabs'>
      <ul>
        <li
          className={pathname === `/portal/portaluser/${id}/info` ? 'is-active' : ''}
        >
          <Link to={`/portal/portaluser/${id}/info`}>
            <span className='icon is-small'>
              <i
                className='is-size-6 mdi mdi-account-box'
                aria-hidden='true'
              ></i>
            </span>
            Info
          </Link>
        </li>
        <li className={pathname === `/portal/portaluser/${id}/pesan` ? 'is-active' : ''}>
          <Link to={`/portal/portaluser/${id}/pesan`}>
            <span className='icon is-small'>
              <i
                className='is-size-6 mdi mdi-message-text-outline'
                aria-hidden='true'
              ></i>
            </span>
            Pesan
          </Link>
        </li>
      </ul>
    </div>
  );
}
