import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import SideMenu from './Sidemenu/SideMenu';
import LogoPicture from '../../pictures/logo.png';
import { sessionLogout } from '../login/redux/authApi';
import { swipeToggleStatus } from '../common/reduxs/swipe/reduxApi';
import { SCHOOL_NAME, SITE_ADDRESS } from '../common/data/siteConfig';
import UserProfile from '../../pictures/user-default.png';
import { useUnamePass } from '../common/helpers/othersHelpers';

const mapState = (state) => {
  return {
    auth: state.auth,
    menus: state.menus,
    loading: state.async.loading,
    elementName: state.async.elementName,
    swipe: state.swipe,
  };
};

const actions = {
  sessionLogout,
  swipeToggleStatus,
};

export class Navbar extends Component {
  _isMounted = false;
  state = {
    width: 0,
    isTogglerActive: false,
    inside: false,
    outside: false,
    uPass: null,
  };

  componentDidMount = (prevProps, prevState) => {
    let uPass = useUnamePass();
    this.setState({
      ...this.state,
      uPass,
    });
    this._isMounted = true;
    const bodyElement = document.getElementsByTagName('BODY')[0];
    this.setState({
      width: bodyElement.offsetWidth,
    });

    if (window.addEventListener) {
      window.addEventListener('resize', this.onResizeEvent, true);
    } else {
      if (window.attachEvent) {
        window.attachEvent('onresize', this.onResizeEvent);
      }
    }
  };

  componentWillUnmount = () => {
    this._isMounted = false;
    if (window.removeEventListener) {
      window.removeEventListener('resize', this.onResizeEvent, true);
    } else {
      if (window.detachEvent) {
        window.detachEvent('onresize', this.onResizeEvent);
      }
    }
  };

  onResizeEvent = () => {
    const bodyElement = document.getElementsByTagName('BODY')[0];
    const newWidth = bodyElement.offsetWidth;
    if (newWidth !== this.state.width) {
      this.setState({
        width: newWidth,
      });
    }
  };

  handleToggler = () => {
    this.setState({
      isTogglerActive: !this.state.isTogglerActive,
    });
    const burger = document.getElementById('burger');
    if (!this.state.isTogglerActive) {
      burger.classList.add('active');
      burger.classList.remove('not-active');
    } else {
      burger.classList.add('not-active');
      burger.classList.remove('active');
    }
  };

  handleInsideClick = (value) => {
    this.setState({
      inside: value,
    });
  };

  handleTogglerOnOff = (value) => {
    this.setState({
      isTogglerActive: value,
    });
  };

  handleActiveLink = () => {
    const { auth, location } = this.props;
    const regex = new RegExp(`/profile/${auth.userId}`);
    const isActive = regex.test(location.pathname);
    return isActive;
  };

  handleLink = (value) => {
    this.props.history.push(value.split('_')[0]);
  };

  handleSessionLogout = (elementName) => {
    const { sessionLogout, history } = this.props;
    let uPass = this.state && this.state.uPass;
    if (uPass && uPass.length > 0) {
      window.ReactNativeWebView.postMessage('logout');
    }
    sessionLogout(elementName, history);
  };

  render() {
    const {
      auth,
      menus,
      location,
      history,
      loading,
      elementName,
      swipeToggleStatus,
      swipe,
    } = this.props;
    const { isTogglerActive } = this.state;
    let uPass = this.state && this.state.uPass;
    return (
      <>
        {auth.isAuth && (
          <nav id='navbarTop' className='navbar is-fixed-top box-shadow-y'>
            <div className='navbar-brand'>
              {auth.isAuth === true && (
                <div
                  style={!uPass ? {} : { display: 'none' }}
                  ref={this.navbarBurger}
                  id='burger'
                  onClick={this.handleToggler}
                  className='navbar-burger toggler not-active is-hidden-tablet'
                >
                  <span />
                  <span />
                  <span />
                </div>
              )}
              <div
                className='navbar-item is-size-5 has-text-weight-semibold hand-pointer2'
                onClick={() => this.handleLink('/dashboard_')}
                style={
                  auth.isAuth === true && this.state.width < 769 && !uPass
                    ? { marginLeft: 38 }
                    : {}
                }
              >
                <img alt='profile-pic' src={LogoPicture} />
                <span className='ml-1'>{SCHOOL_NAME}</span>
              </div>
              {/* profile picture apps */}
              {auth.isAuth === true && (
                <div
                  className='is-hidden-desktop'
                  style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'flex-end',
                  }}
                >
                  <div
                    onClick={() => this.handleLink('/profil_')}
                    className='navbar-item hand-pointer2'
                    style={{ marginRight: -12 }}
                  >
                    <div className='navbar-item'>
                      <div
                        style={{
                          width: 28,
                          height: 28,
                          backgroundImage: `url(${
                            auth && auth.profile && auth.profile.photo
                              ? SITE_ADDRESS + auth.profile.photo
                              : UserProfile
                          })`,
                          borderRadius: '50%',
                          backgroundSize: 'cover',
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            {auth.isAuth && (
              <div className='navbar-menu'>
                <div className='navbar-end'>
                  <div className='navbar-item has-dropdown is-hoverable'>
                    <div id='navUser' className='navbar-item hand-pointer'>
                      <div
                        style={{
                          width: 24,
                          height: 24,
                          backgroundImage: `url(${
                            auth && auth.profile && auth.profile.photo
                              ? SITE_ADDRESS + auth.profile.photo
                              : UserProfile
                          })`,
                          borderRadius: '50%',
                          backgroundSize: 'cover',
                        }}
                      />
                      <span className='has-text-weight-semibold is-size-7 ml-1'>
                        {auth.username && auth.username.length < 6 ? (
                          <span className='is-uppercase'>
                            {auth.username.toLowerCase()}
                          </span>
                        ) : (
                          <span className='is-capitalized'>
                            {auth.username}
                          </span>
                        )}
                      </span>
                    </div>
                    <div className='navbar-dropdown is-right'>
                      <Link
                        to={`/profil`}
                        className={
                          this.handleActiveLink() === true
                            ? 'navbar-item is-active has-background-white-ter'
                            : 'navbar-item'
                        }
                      >
                        <i className='fas fa-user-circle icon mr-1'></i> Profil
                      </Link>
                      <Link
                        to={`/profil/ubah-password`}
                        className='navbar-item'
                      >
                        <i className='fas fa-key icon mr-1'></i> Ubah Password
                      </Link>
                      <hr className='navbar-divider' />
                      {loading === true && elementName === 'logoutNav' ? (
                        <button
                          disabled
                          className='button is-loading is-text is-small'
                        >
                          <i className='fas fa-sign-out-alt icon mr-1'></i>{' '}
                          Logout
                        </button>
                      ) : (
                        <Fragment>
                          <div
                            onClick={() =>
                              this.handleSessionLogout('logoutNav')
                            }
                            className='navbar-item hand-pointer'
                          >
                            <i className='fas fa-sign-out-alt icon mr-1'></i>{' '}
                            Logout
                          </div>
                        </Fragment>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </nav>
        )}
        {auth.isAuth && (
          <SideMenu
            toggler={isTogglerActive}
            inside={this.state.inside}
            swipeToggleStatus={swipeToggleStatus}
            swipe={swipe}
            handleInsideClick={this.handleInsideClick}
            handleTogglerOnOff={this.handleTogglerOnOff}
            profile={auth.profile}
            auth={auth}
            menus={menus}
            logout={this.handleSessionLogout}
            loading={loading}
            elementName={elementName}
            pathname={location.pathname}
            history={history}
          />
        )}
      </>
    );
  }
}

export default withRouter(connect(mapState, actions)(Navbar));
