import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { getPortalusers } from './redux/reduxApi';
import SimpleSearch from '../../../app/common/layout/SimpleSearch';
import PageNumber from '../../../app/common/layout/PageNumber';
import LoadingButton from '../../../app/common/layout/LoadingButton';
import List from './IndexList';
import ReactTooltip from 'react-tooltip';

function mapState(state) {
  let scope = {};
  if (state?.auth?.authorities) {
    scope = state?.auth?.authorities;
  }
  return {
    auth: state.auth,
    scope: scope,
    loading: state.async.loading,
    portalusers: state.portalusers,
  };
}

const actions = {
  getPortalusers,
};

function Index(props) {
  let history = useHistory();
  const [state, setState] = useState({
    title: 'User',
    itemPerPage: 10,
    currentPage: 1,
    searchText: '',
  });
  const { auth, scope, loading, portalusers, getPortalusers } = props;
  const items = portalusers?.items;
  const total = portalusers?.total;
  const search = portalusers?.search;

  useEffect(() => {
    let itemPerPage = search?.itemPerPage
      ? search.itemPerPage
      : state.itemPerPage;
    let currentPage = search?.currentPage
      ? search.currentPage
      : state.currentPage;
    let searchText = search?.searchText ? search.searchText : state.searchText;
    getPortalusers(auth, history, itemPerPage, currentPage, searchText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSimpleSearch = (searchText) => {
    getPortalusers(auth, history, search.itemPerPage, 1, searchText);
    setState({
      ...state,
      itemPerPage: search.itemPerPage,
      currentPage: 1,
      searchText: searchText,
    });
  };

  const handleResetSearch = () => {
    setState({
      ...state,
      searchText: '',
    });
    getPortalusers(auth, history, state.itemPerPage, 1, '');
  };

  const handleItemPerPage = (val) => {
    setState({
      ...state,
      itemPerPage: val,
      currentPage: 1,
    });
    getPortalusers(auth, history, val, 1, search.searchText);
  };

  const handlePage = (val) => {
    let newCurrentPage = search.currentPage + val;
    setState({
      ...state,
      currentPage: newCurrentPage,
    });
    getPortalusers(
      auth,
      history,
      state.itemPerPage,
      newCurrentPage,
      search.searchText
    );
  };

  return (
    <>
      <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
        <div className='p-1'>
          <div className='columns is-variable is-desktop'>
            <div className='column is-12'>
              <div className='card'>
                <header className='card-header has-background-white has-text-info'>
                  <div
                    className='custom-card-flex is-size-6'
                    style={{ width: '100%' }}
                  >
                    <p>
                      <i className='mdi mdi-account-group is-size-5 icon' />
                      Portal
                      <i className='mdi mdi-menu-right is-size-6 icon' />
                      <Link className='has-text-info' to='/portal/portaluser'>
                        User
                      </Link>
                    </p>
                  </div>
                </header>
                <div className='card-content'>
                  <div className='content'>
                    <div className='level' style={{ marginTop: -20 }}>
                      <div className='level-left'></div>

                      <div className='level-right mr-4'>
                        <div
                          className='level-item'
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          {scope && scope.subm && scope.subm['portaluser_sync'] && (
                            <div data-for='main' data-tip='Sync Portal User'>
                              <Link
                                to='/portal/portaluser/sync'
                                className='button is-small is-info is-rounded is-outlined mr-2'
                              >
                                <i className='is-size-6 mdi mdi-sync icon' />
                              </Link>
                              <ReactTooltip
                                id='main'
                                place='bottom'
                                type='dark'
                                effect='solid'
                                multiline={true}
                              />
                            </div>
                          )}
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              paddingTop: 14,
                            }}
                          >
                            <SimpleSearch
                              title={state.title}
                              initialValues={search && search.searchText}
                              loading={loading}
                              resetSearchText={state.resetSearchText}
                              onFormSubmit={handleSimpleSearch}
                            />
                          </div>
                          {search?.searchText?.length > 0 && (
                            <button
                              onClick={() => handleResetSearch()}
                              className='button is-small is-info is-rounded is-outlined ml-2'
                            >
                              <i className='is-size-6 mdi mdi-reload icon' />
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      className='table-container ml-4 mr-4'
                      style={{ marginTop: -16, marginBottom: 20 }}
                    >
                      <table
                        id='fadein'
                        className='table is-fullwidth is-hoverable is-narrow'
                      >
                        <thead>
                          <tr>
                            <th className='has-text-centered'>No</th>
                            <th>NIS</th>
                            <th>Siswa</th>
                            <th className='has-text-centered'>Kelas</th>
                            <th className='has-text-centered'>Install App</th>
                            <th className='has-text-centered'>Device</th>
                            <th className='has-text-centered'>Dibuat</th>
                            <th className='has-text-centered'>Aksi</th>
                          </tr>
                        </thead>
                        <tbody>
                          {loading ? (
                            <tr>
                              <td>
                                <LoadingButton />
                              </td>
                            </tr>
                          ) : (
                            <List
                              auth={auth}
                              items={items}
                              title={state?.title}
                              currentPage={search?.currentPage}
                              itemPerPage={search?.itemPerPage}
                              scope={scope}
                            />
                          )}
                        </tbody>
                      </table>
                    </div>
                    <PageNumber
                      title={state?.title}
                      total={total}
                      currentPage={search?.currentPage}
                      itemPerPage={search?.itemPerPage}
                      handleItemPerPage={handleItemPerPage}
                      handlePage={handlePage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default connect(mapState, actions)(Index);
