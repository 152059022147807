import React from 'react';
import { reduxForm, Field } from 'redux-form';
import TextInput from '../../app/common/form/TextInput';
import SelectInput from '../../app/common/form/SelectInput';
import TextArea from '../../app/common/form/TextArea';
import DateInput from '../../app/common/form/DateInput';
import {
  composeValidators,
  combineValidators,
  isRequired,
  hasLengthGreaterThan,
  createValidator,
} from 'revalidate';

const gender = [
  { key: 1, text: 'Laki-laki', value: 'male' },
  { key: 2, text: 'Perempuan', value: 'female' },
];

const isValidEmail = createValidator(
  (message) => (value) => {
    if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      return message;
    }
  },
  'Email tidak valid'
);

const validate = combineValidators({
  username: composeValidators(
    isRequired({ message: 'Username harus diisi' }),
    hasLengthGreaterThan(1)({
      message:
        'Username paling sedikit memiliki 2 karakter huruf & unik',
    })
  )(),
  email: composeValidators(isValidEmail)(),
});

function Info(props) {
  const { handleSubmit, onFormSubmit, editState, setEditState } = props;

  const handleClickEdit = () => {
    setEditState();
  };

  return (
    <>
      <div style={{ position: 'absolute', right: 15, top: 48 }}>
        {!editState && (
          <button
            id='profileEdit'
            onClick={() => handleClickEdit()}
            className='button is-info is-small is-rounded is-outlined'
          >
            <i className='is-size-6 mdi mdi-pencil icon' />
          </button>
        )}
        {editState && (
          <button
            onClick={handleSubmit(onFormSubmit)}
            className='button is-info is-small is-rounded is-outlined'
          >
            <i className='is-size-6 mdi mdi-content-save icon' />
          </button>
        )}
      </div>
      <form autoComplete='off' onSubmit={handleSubmit(onFormSubmit)}>
        <div className='field is-horizontal'>
          <div className='field-body'>
            <Field
              label='Username'
              name='username'
              type='text'
              component={TextInput}
              placeholder='Username'
              readOnly={true}
              disabled={editState}
              fullwidth={true}
            />
            <Field
              label='Email'
              name='email'
              type='text'
              readOnly={!editState}
              component={TextInput}
              placeholder='Email'
            />
          </div>
        </div>
        <div className='field is-horizontal'>
          <div className='field-body'>
            <Field
              label='Panggilan'
              name='name'
              type='text'
              readOnly={!editState}
              component={TextInput}
              placeholder='Panggilan'
              className='is-expanded'
            />
            <Field
              label='Nama Lengkap'
              name='fullname'
              type='text'
              readOnly={!editState}
              component={TextInput}
              placeholder='Nama Lengkap'
              className='is-expanded'
            />
          </div>
        </div>
        <div className='field is-horizontal'>
          <div className='field-body'>
            <Field
              label='Tempat Lahir'
              name='pob'
              type='text'
              readOnly={!editState}
              component={TextInput}
              placeholder='Tempat Lahir'
              fullwidth={true}
            />
            {editState && (
              <Field
                label='Tanggal Lahir'
                name='dob'
                type='date'
                component={DateInput}
                placeholder='2000/05/21'
                defaultSelected={null}
                fullwidth={true}
              />
            )}
            {!editState && (
              <Field
                label='Tanggal Lahir'
                name='dob'
                type='date'
                component={DateInput}
                placeholder='Pilih Tanggal'
                showMonthDropdown
                showYearDropdown
                defaultSelected={null}
                fullwidth={true}
                readOnly={true}
              />
            )}
          </div>
        </div>
        <div className='field is-horizontal'>
          <div className='field-body'>
            <Field
              label='Jenis Kelamin'
              placeholder='Jenis Kelamin'
              name='gender'
              type='text'
              readOnly={!editState}
              component={SelectInput}
              fullwidth={true}
              options={gender}
            />
            <Field
              label='Nomer Telepon'
              name='phone'
              type='tel'
              readOnly={!editState}
              component={TextInput}
              placeholder='Nomer Telepon'
            />
          </div>
        </div>
        <Field
          label='Alamat'
          name='address'
          type='text'
          readOnly={!editState}
          component={TextArea}
          placeholder='Alamat'
        />
        <br />
      </form>
    </>
  );
}

export default reduxForm({
  form: 'profileInfo',
  enableReinitialize: true,
  validate,
})(Info);
