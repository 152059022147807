import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { addPesanpribadi, getPesanpribadiPortalusers } from './redux/reduxApi';
import AddForm from './AddForm';
import { format } from 'date-fns';
import { openModal } from '../../../app/modals/redux/modalActions';

const mapState = (state, ownProps) => {
  const id = ownProps.match.params.id;
  return {
    auth: state.auth,
    loading: state.async.loading,
    messageId: id,
    pesanpribadis: state.pesanpribadis,
  };
};

const actions = {
  openModal,
  addPesanpribadi,
  getPesanpribadiPortalusers,
};

function Add(props) {
  const {
    messageId,
    auth,
    history,
    openModal,
    pesanpribadis,
    addPesanpribadi,
    getPesanpribadiPortalusers,
  } = props;

  const [initialValues, setInitialValues] = useState({
    title: 'Pesan ' + format(new Date(), 'd MMM yyyy hh:mm:ss'),
  });

  let portalusersOption = pesanpribadis?.portalusers;

  useEffect(() => {
    setInitialValues({
      title: 'Pesan ' + format(new Date(), 'd MMM yyyy hh:mm:ss'),
    });
    getPesanpribadiPortalusers(auth, history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
      <div className='p-1'>
        <div className='columns is-variable'>
          <div className='column is-fullwidth'>
            <div className='card'>
              <header className='card-header has-background-white has-text-info'>
                <div
                  className='custom-card-flex is-size-6'
                  style={{ width: '100%' }}
                >
                  <p>
                    <i className='mdi mdi-account-group is-size-5 icon' />
                    Portal
                    <i className='mdi mdi-menu-right is-size-6 icon' />
                    Pesan Pribadi
                  </p>
                </div>
              </header>
              <div className='card-content pl-4 pr-4'>
                <AddForm
                  messageId={messageId}
                  auth={auth}
                  history={history}
                  initialValues={initialValues}
                  openModal={openModal}
                  portalusersOption={portalusersOption}
                  addPesanpribadi={addPesanpribadi}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(connect(mapState, actions)(Add));
