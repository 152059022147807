import {
  PELAJARANS_GET,
  PELAJARAN_JENJANGS_GET,
  PELAJARAN_ADD,
  PELAJARAN_EDIT,
  PELAJARAN_GET,
  PELAJARAN_SYNC,
  PELAJARAN_DELETE,
} from './reduxConstant';
import { createReducer } from '../../../../app/common/utilities/reducerUtils';

const initialState = {
  total: 0,
  search: {
    currentPage: '',
    searchText: '',
    itemPerPage: '',
  },
  items: [],
  jenjangs: [],
  pelajaransSync: [],
};

const pelajaransGet = (state, payload) => {
  const pelajarans = payload.pelajarans;
  return {
    ...state,
    total: pelajarans.total,
    items: [...pelajarans.items],
    search: { ...pelajarans.search },
  };
};

const pelajaranJenjangsGet = (state, payload) => {
  const jenjangs = payload.jenjangs;
  return {
    ...state,
    jenjangs: [...jenjangs],
  };
};

const pelajaranAdd = (state, payload) => {
  return {
    ...state,
    total: parseInt(state.total) + 1,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.pelajaran.id + ''),
      payload.pelajaran,
    ],
  };
};

const pelajaranEdit = (state, payload) => {
  return {
    ...state,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.pelajaran.id + ''),
      payload.pelajaran,
    ],
  };
};

const pelajaranGet = (state, payload) => {
  return {
    ...state,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.pelajaran.id + ''),
      payload.pelajaran,
    ],
  };
};

const pelajaranSync = (state, payload) => {
  const pelajarans = payload.pelajarans?.pelajarans || [];
  const syncPelajarans = payload.pelajarans?.syncPelajarans || [];
  return {
    ...state,
    items: [...pelajarans],
    pelajaransSync: [...syncPelajarans],
  };
};

const pelajaranDelete = (state, payload) => {
  return {
    ...state,
    total: parseInt(state.total) - 1,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.pelajaran.id + ''),
    ],
  };
};

export default createReducer(initialState, {
  [PELAJARANS_GET]: pelajaransGet,
  [PELAJARAN_JENJANGS_GET]: pelajaranJenjangsGet,
  [PELAJARAN_ADD]: pelajaranAdd,
  [PELAJARAN_EDIT]: pelajaranEdit,
  [PELAJARAN_GET]: pelajaranGet,
  [PELAJARAN_SYNC]: pelajaranSync,
  [PELAJARAN_DELETE]: pelajaranDelete,
});
