import {
  WHATSAPPS_GET,
  WHATSAPP_ADD,
  WHATSAPP_EDIT,
  WHATSAPP_GET,
  WHATSAPP_UPLOADFILE,
  WHATSAPP_STATUS,
  WHATSAPP_INFO,
  WHATSAPP_DELETE,
  WHATSAPP_IMPORT,
} from './reduxConstant';
import { createReducer } from '../../../../app/common/utilities/reducerUtils';

const initialState = {
  total: 0,
  items: [],
  uploadFiles: [],
  status: {},
  info: {},
};

const whatsappsGet = (state, payload) => {
  const whatsapps = payload.whatsapps;
  return {
    ...state,
    total: whatsapps.total,
    items: [...whatsapps.items],
  };
};

const whatsappAdd = (state, payload) => {
  return {
    ...state,
    total: parseInt(state.total) + 1,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.whatsapp.id + ''),
      payload.whatsapp,
    ],
  };
};

const whatsappEdit = (state, payload) => {
  return {
    ...state,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.whatsapp.id + ''),
      payload.whatsapp,
    ],
  };
};

const whatsappGet = (state, payload) => {
  return {
    ...state,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.whatsapp.id + ''),
      payload.whatsapp,
    ],
  };
};

const whatsappUploadFile = (state, payload) => {
  let uploadFile = payload.uploadFile;
  let uploadFiles = [];
  if (state && state.uploadFiles) {
    uploadFiles = [...state.uploadFiles];
  }
  let filteredUploadFiles = uploadFiles.filter(
    (i) => i.studentPesanId !== uploadFile.studentPesanId
  );
  return {
    ...state,
    uploadFiles: [...filteredUploadFiles, uploadFile],
  };
};

const whatsappStatus = (state, payload) => {
  let status = payload.whatsappStatus;
  return {
    ...state,
    status: status,
  };
};

const whatsappInfo = (state, payload) => {
  let info = payload.whatsappInfo;
  return {
    ...state,
    info: info,
  };
};

const whatsappDelete = (state, payload) => {
  return {
    ...state,
    total: parseInt(state.total) - 1,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.whatsapp.id + ''),
    ],
  };
};

const whatsappImport = (state, payload) => {
  const whatsapp = payload.whatsapp;
  return {
    ...state,
    total: parseInt(state.total) + 1,
    items: [
      ...state.items.filter((i) => i.id + '' !== whatsapp.id + ''),
      whatsapp,
    ],
  };
};

export default createReducer(initialState, {
  [WHATSAPPS_GET]: whatsappsGet,
  [WHATSAPP_ADD]: whatsappAdd,
  [WHATSAPP_EDIT]: whatsappEdit,
  [WHATSAPP_GET]: whatsappGet,
  [WHATSAPP_UPLOADFILE]: whatsappUploadFile,
  [WHATSAPP_STATUS]: whatsappStatus,
  [WHATSAPP_INFO]: whatsappInfo,
  [WHATSAPP_DELETE]: whatsappDelete,
  [WHATSAPP_IMPORT]: whatsappImport,
});
