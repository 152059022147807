import {
  ROLES_GET,
  ROLE_ADD,
  ROLE_EDIT,
  ROLE_DELETE,
} from './reduxConstant';
import { createReducer } from '../../../app/common/utilities/reducerUtils';

const initialState = {
  total: 0,
  search: {
    currentPage: '',
    searchText: '',
    itemPerPage: '',
  },
  items: [],
};

const rolesGet = (state, payload) => {
  const roles = payload.roles
  return { total: roles.total, items: [...roles.items], search: {...roles.search} };
};

const roleAdd = (state, payload) => {
  return {
    total: parseInt(state.total) + 1,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.role.id + ''),
      payload.role,
    ],
    search: {...state.search}
  };
};

const roleEdit = (state, payload) => {
  return {
    total: parseInt(state.total),
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.role.id + ''),
      payload.role,
    ],
    search: {...state.search}
  };
};

const roleDelete = (state, payload) => {
  return {
    total: parseInt(state.total) - 1,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.role.id + ''),
    ],
    search: {...state.search}
  };
};

export default createReducer(initialState, {
  [ROLES_GET]: rolesGet,
  [ROLE_ADD]: roleAdd,
  [ROLE_EDIT]: roleEdit,
  [ROLE_DELETE]: roleDelete,
});
