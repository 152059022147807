import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Fragment } from 'react';

class Submenu extends Component {
  render() {
    const { subm, authSubm, pathname } = this.props;
    let url = subm.alias;
    const regex = new RegExp(url);
    const isActive = regex.test(pathname);
    const accessSubm = authSubm[subm.id + '_view'];
    return (
      <Fragment>
        {accessSubm && (
          <li>
            <Link
              to={subm.subUrl}
              className={
                isActive === true
                  ? `is-size-12 is-active custom-text-overflow disable-select`
                  : 'is-size-12 custom-text-overflow disable-select'
              }
            >
              {subm.submenuTitle}
            </Link>
          </li>
        )}
      </Fragment>
    );
  }
}

export default Submenu;
