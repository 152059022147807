import {
  PORTALUSERS_GET,
  PORTALUSER_GET,
  PORTALUSER_EDIT,
  PORTALUSER_SYNC,
} from './reduxConstant';
import { createReducer } from '../../../../app/common/utilities/reducerUtils';

const initialState = {
  total: 0,
  search: {
    currentPage: '',
    searchText: '',
    itemPerPage: '',
  },
  items: [],
  portalusersSync: [],
  portalusersMerge: [],
};

const portalusersGet = (state, payload) => {
  const portalusers = payload.portalusers;
  return {
    total: portalusers.total,
    items: [...portalusers.items],
    search: { ...portalusers.search },
  };
};

const portaluserGet = (state, payload) => {
  return {
    total: state.total,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.portaluser.id + ''),
      payload.portaluser,
    ],
    search: { ...state.search },
  };
};

const portaluserEdit = (state, payload) => {
  return {
    total: parseInt(state.total),
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.portaluser.id + ''),
      payload.portaluser,
    ],
    search: { ...state.search },
  };
};

const portaluserSync = (state, payload) => {
  let dataPortalusersSync = payload?.portaluser?.portalusersSync;
  let dataPortalusersExisting = payload?.portaluser?.portalusers;
  let portalusersMerge = [];
  for (const el of dataPortalusersSync) {
    let dataExist = dataPortalusersExisting?.find((dt) => {
      return dt.userId + '' === el.USERID + '';
    });
    let id = '';
    let deviceId = '';
    let newHistoryClass = [];
    if (dataExist) {
      id = dataExist.id;
      deviceId = dataExist.deviceId;
      let historyClass =
        dataExist?.description?.historyClass &&
        dataExist?.description?.historyClass[0]
          ? dataExist?.description?.historyClass
          : [];
      newHistoryClass = [
        ...historyClass.filter((h) => h.class !== el.KELAS_AKTIF),
        { class: el.KELAS_AKTIF },
      ];
    } else {
      newHistoryClass = [{ class: el.KELAS_AKTIF }]
    }
    portalusersMerge.push({
      id,
      deviceId,
      userId: el?.USERID,
      username: el?.VUSN || el.NIS,
      nis: el.NIS,
      name: el.NAMA_SISWA,
      activeClass: el.KELAS_AKTIF,
      description: {
        nis: el.NIS,
        noAbsen: el.NO_ABSEN,
        name: el.NAMA_SISWA,
        fatherName: el.NAMA_AYAH,
        fatherPhoneNumber: el.NO_HP_AYAH,
        motherName: el.NAMA_IBU,
        motherPhoneNumber: el.NO_HP_IBU,
        guardianName: el.NAMA_WALI,
        guardianPhoneNumber: el.NO_HP_WALI,
        activeClass: el.KELAS_AKTIF,
        historyClass: newHistoryClass,
      },
      selected: true,
    });
  }
  // const groupByCategory = portalusersMerge.reduce((group, item) => {
  //   const { activeClass } = item;
  //   group[activeClass] = group[activeClass] ?? [];
  //   group[activeClass].push(item);
  //   return group;
  // }, {});
  // console.log(groupByCategory);

  return {
    ...state,
    items: [...payload?.portaluser?.portalusers],
    portalusersSync: [...payload?.portaluser?.portalusersSync],
    portalusersMerge: [...portalusersMerge]
  };
};

export default createReducer(initialState, {
  [PORTALUSERS_GET]: portalusersGet,
  [PORTALUSER_GET]: portaluserGet,
  [PORTALUSER_EDIT]: portaluserEdit,
  [PORTALUSER_SYNC]: portaluserSync,
});
