import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { syncPortaluser, syncSavePortaluser } from './redux/reduxApi';
import LoadingButton from '../../../app/common/layout/LoadingButton';
import { nanoid } from 'nanoid';

function mapState(state) {
  return {
    auth: state.auth,
    loading: state.async.loading,
    portalusers: state.portalusers,
  };
}

const actions = {
  syncPortaluser,
  syncSavePortaluser,
};

function Sync(props) {
  let history = useHistory();
  const [state, setState] = useState({
    title: 'Sync Data User Portal',
    portalusersSync: [],
    portalusersExisting: [],
    portalusersFilter: [],
    portalusersMerge: [],
  });
  const { auth, loading, portalusers, syncPortaluser, syncSavePortaluser } =
    props;

  useEffect(() => {
    syncPortaluser(auth, history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let portalusersSync = portalusers?.portalusersSync;
    let portalusersMerge = portalusers?.portalusersMerge;
    let portalusersExisting = portalusers?.items;

    let sortPortalusersMerge = portalusersMerge?.sort(function (a, b) {
      return (
        a.activeClass.localeCompare(b.activeClass) ||
        a.name.localeCompare(b.name)
      );
    });
    setState({
      portalusersSync: portalusersSync,
      portalusersExisting: portalusersExisting,
      portalusersMerge: sortPortalusersMerge,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portalusers]);

  const onChangeCheckbox = (e) => {
    e.preventDefault();
    let portalusersMerge = [...state?.portalusersMerge];
    for (const obj of portalusersMerge) {
      if (obj.userId === e.target.name) {
        obj.selected = obj.selected === undefined ? true : !obj.selected;
        break;
      }
    }
    setState({
      ...state,
      portalusersMerge: portalusersMerge,
    });
  };

  const onSelectAll = (e) => {
    e.preventDefault();
    let portalusersMerge = [...state?.portalusersMerge];
    let newPortalusersMerge = [];
    for (const obj of portalusersMerge) {
      newPortalusersMerge.push({ ...obj, selected: true });
    }
    setState({
      ...state,
      portalusersMerge: newPortalusersMerge,
    });
  };

  const onUnselectAll = (e) => {
    e.preventDefault();
    let portalusersMerge = [...state?.portalusersMerge];
    let newPortalusersMerge = [];
    for (const obj of portalusersMerge) {
      newPortalusersMerge.push({ ...obj, selected: false });
    }
    setState({
      ...state,
      portalusersMerge: newPortalusersMerge,
    });
  };

  const onSave = (e) => {
    e.preventDefault();
    let portalusersMerge = state?.portalusersMerge;
    let filterPortalUsersMerge = portalusersMerge?.filter(
      (i) => i.selected === true
    );
    syncSavePortaluser(auth, history, filterPortalUsersMerge);
  };

  return (
    <>
      <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
        <div className='p-1'>
          <div className='columns is-variable is-desktop'>
            <div className='column is-12'>
              <div className='card'>
                <header className='card-header has-background-white has-text-info'>
                  <div
                    className='custom-card-flex is-size-6'
                    style={{ width: '100%' }}
                  >
                    <p>
                      <i className='mdi mdi-account-group is-size-5 icon' />{' '}
                      Portal
                      <i className='mdi mdi-menu-right is-size-6 icon' />
                      User
                    </p>
                  </div>
                </header>
                <div className='card-content'>
                  <div className='content'>
                    <div className='level' style={{ marginTop: -20 }}>
                      <div className='level-left'></div>

                      <div className='level-right mt-4 mr-4'>
                        <div
                          className='level-item'
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <button
                            onClick={() => history.goBack()}
                            className='button custom-grey is-small is-rounded is-outlined'
                          >
                            <i className='is-size-6 mdi mdi-arrow-left icon' />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div
                      className='table-container ml-4 mr-4'
                      style={{ marginBottom: 20 }}
                    >
                      <table className='table is-fullwidth is-bordered is-hoverable'>
                        <tbody>
                          {loading ? (
                            <tr>
                              <td colSpan={6}>
                                <LoadingButton />
                              </td>
                            </tr>
                          ) : (
                            <IndexList
                              auth={auth}
                              portalusersMerge={state?.portalusersMerge}
                              totalItem={state?.portalusersMerge?.length}
                              title={state.title}
                              onChangeCheckbox={onChangeCheckbox}
                              onSelectAll={onSelectAll}
                              onUnselectAll={onUnselectAll}
                              onSave={onSave}
                            />
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function IndexList(props) {
  const {
    portalusersMerge,
    totalItem,
    loading,
    onChangeCheckbox,
    onSelectAll,
    onUnselectAll,
    onSave,
  } = props;
  return (
    <>
      <tr>
        <td className='has-text-weight-medium' colSpan='6'>
          <div className='flex justify-between'>
            <div className='flex align-center'>User</div>
            <div className='mt-1'>
              <button
                onClick={(e) => onSelectAll(e)}
                className='button is-info is-small is-rounded is-outlined mr-1 mb-1'
              >
                <i className='is-size-6 mdi mdi-checkbox-marked mr-1' /> All
              </button>
              <button
                onClick={(e) => onUnselectAll(e)}
                className='button is-info is-small is-rounded is-outlined mr-1 mb-1'
              >
                <i className='is-size-6 mdi mdi-checkbox-blank mr-1' /> All
              </button>
              <button
                onClick={(e) => onSave(e)}
                className='button is-info is-small is-rounded is-outlined mr-1 mb-1'
              >
                <i className='is-size-6 mdi mdi-content-save icon' />
              </button>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <th className='has-text-centered'>No</th>
        <th>NIS</th>
        <th>Nama Siswa</th>
        <th>Kelas</th>
        <th>History Kelas</th>
        <th className='has-text-centered'>Aksi</th>
      </tr>
      {portalusersMerge?.length !== 0 &&
        portalusersMerge?.map((item, index) => (
          <IndexListItems
            key={index + '_' + nanoid()}
            index={index}
            item={item}
            totalItem={totalItem}
            loading={loading}
            onChangeCheckbox={onChangeCheckbox}
          />
        ))}
      {portalusersMerge?.length === 0 && (
        <tr>
          <td colSpan='6'>Update User tidak tersedia.</td>
        </tr>
      )}
    </>
  );
}

function IndexListItems(props) {
  const { item, totalItem, index, loading, onChangeCheckbox } = props;
  if (loading)
    return (
      <tr>
        <td colSpan={6}>Loading...</td>
      </tr>
    );

  let fatherName = item?.description?.fatherName;
  let fatherPhoneNumber = item?.description?.fatherPhoneNumber;
  let motherName = item?.description?.motherName;
  let motherPhoneNumber = item?.description?.motherPhoneNumber;
  let guardianName = item?.description?.guardianName;
  let guardianPhoneNumber = item?.description?.guardianPhoneNumber;

  return (
    <tr>
      <td className='has-text-centered'>{index + 1}</td>
      <td>{item?.description?.nis}</td>
      <td>
        <span>{item?.description?.name}</span>
        <div
          className={`dropdown is-hoverable${
            index + 7 > totalItem ? ' is-up' : ''
          }`}
        >
          <div className='dropdown-trigger'>
            <button
              aria-haspopup='true'
              aria-controls={`dropdown-menu${index}`}
            >
              <span className='icon is-small mr-1'>
                <i className='fas fa-angle-down' aria-hidden='true'></i>
              </span>
            </button>
          </div>
          <div
            className='dropdown-menu'
            id={`dropdown-menu${index}`}
            role='menu'
            style={{ width: 250 }}
          >
            <div className='dropdown-content panel is-size-7'>
              <div
                className='panel-heading is-small'
                style={{ marginTop: -10 }}
              >
                Detail
              </div>
              <div className='panel-block is-justify-content-space-between'>
                <span>Nama Ayah</span>
                <span>{fatherName || '-'}</span>
              </div>
              <div className='panel-block is-justify-content-space-between'>
                <span>No HP Ayah</span>
                <span>{fatherPhoneNumber || '-'}</span>
              </div>
              <div className='panel-block is-justify-content-space-between'>
                <span>Nama Ibu</span>
                <span>{motherName || '-'}</span>
              </div>
              <div className='panel-block is-justify-content-space-between'>
                <span>No HP Ibu</span>
                <span>{motherPhoneNumber || '-'}</span>
              </div>
              <div className='panel-block is-justify-content-space-between'>
                <span>Nama Wali</span>
                <span>{guardianName || '-'}</span>
              </div>
              <div className='panel-block is-justify-content-space-between'>
                <span>No HP Wali</span>
                <span>{guardianPhoneNumber || '-'}</span>
              </div>
            </div>
          </div>
        </div>
      </td>
      <td>{item?.description?.activeClass}</td>
      <td>
        {item?.description?.historyClass?.map((cl, index) => (
          <p key={item.userId + index}>
            <span>{cl.class}</span>
            {item?.description?.historyClass?.length - 1 === index ? '' : ', '}
          </p>
        ))}
      </td>
      <td className='has-text-centered'>
        <div className='flex justify-center ml-3'>
          <div className='field' style={{ marginTop: -15 }}>
            <div className='control'>
              <label className='custom-checkbox'>
                {!item?.selected && (
                  <input
                    name={item.userId}
                    type='checkbox'
                    onChange={(e) => onChangeCheckbox(e)}
                  />
                )}
                {item?.selected && (
                  <input
                    name={item.userId}
                    type='checkbox'
                    checked
                    onChange={(e) => onChangeCheckbox(e)}
                  />
                )}
                <span className='checkmark' />
              </label>
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
}

export default connect(mapState, actions)(Sync);
