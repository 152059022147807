import React from 'react';
import ReactQuill from 'react-quill';
// import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function EditorQuill({ input, label, placeholder, modules, meta: { touched, error } }) {
  return (
    <div className='field'>
      <label className='label'>{label}</label>
      <div className='control'>
        <ReactQuill
          {...input}
          modules={modules}
          onChange={(newValue, delta, source) => {
            if (source === 'user') {
              input.onChange(newValue);
            }
          }}
          onBlur={(range, source, quill) => {
            input.onBlur(quill.getHTML());
          }}
          placeholder={placeholder}
        />
      </div>
      {touched && error && <p className='help is-danger'>{error}</p>}
    </div>
  );
}

export default EditorQuill;
