import {
  asyncActionFinish,
  asyncActionError,
} from '../../../app/common/reduxs/async/asyncActions';
import {
  usersGet,
  userAdd,
  userEdit,
  userDelete,
  rolesAll,
} from './reduxAction';
import { ASYNC_ACTION_START } from '../../../app/common/reduxs/async/asyncConstant';
import { checkErr } from '../../../app/common/helpers/checkRes';
import { fetchFunc } from '../../../app/common/helpers/fetchHelpers';
import { logsFunc } from '../../../app/common/helpers/othersHelpers';

export const getUsers = (
  auth,
  history,
  itemPerPage,
  currentPage,
  searchText
) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getUsers' });
    const formData = new FormData();
    const allDataAccess = auth.authorities.subm.user_all;
    formData.append('itemPerPage', itemPerPage ? itemPerPage : 10);
    formData.append('currentPage', currentPage);
    formData.append('searchText', searchText);
    formData.append('allData', allDataAccess);
    try {
      const res = await fetchFunc(
        'gets',
        auth,
        history,
        'users/',
        formData,
        dispatch
      );
      const items = res.items;
      const total = res.total;
      const search = {
        currentPage,
        searchText,
        itemPerPage: itemPerPage ? itemPerPage : 10,
      };
      dispatch(usersGet({ total, items, search }));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const addUser = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'addUser' });
    let logs = logsFunc('create', auth.userid, auth.username, null);
    const formData = new FormData();
    formData.append('uname', values.username);
    formData.append('password', values.password);
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'add',
        auth,
        history,
        'users/add/',
        formData,
        dispatch
      );
      if (res && res.user) {
        const user = res.user;
        dispatch(userAdd(user));
        history.push('/setting/user/' + user.id);
      }
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const getUser = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getUser' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'get',
        auth,
        history,
        'users/detail/' + id,
        formData,
        dispatch
      );
      const user = res.user;
      dispatch(userAdd(user));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const editUser = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'editUser' });
    let roles = values.roles.map((i) => i.value);
    let logs = logsFunc('edit', auth.userid, auth.username, values.logs);
    const formData = new FormData();
    formData.append('uname', values.username);
    formData.append('roles', roles.toString());
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'users/edit/' + values.id,
        formData,
        dispatch
      );
      const user = res.user;
      dispatch(userEdit(user));
      history.push('/setting/user/' + values.id);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const statusUser = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'statusUser' });
    let logs = logsFunc('edit', auth.userid, auth.username, values.logs);
    const formData = new FormData();
    formData.append('status', values.status);
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'users/status/' + values.id,
        formData,
        dispatch
      );
      const user = res.user;
      dispatch(userEdit(user));
      history.push('/setting/user/' + values.id);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const setRole = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'setRole' });
    let logs = logsFunc('edit', auth.userid, auth.username, values.logs);
    const formData = new FormData();
    formData.append('roles', values.roles);
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'users/set-role/' + values.id,
        formData,
        dispatch
      );
      dispatch(asyncActionFinish());
      if (res && res.status === 'success') {
        const status = res.status;
        const message = res.message;
        const user = res.user;
        return { status, message, user };
      }
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const allRoles = (auth, history) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'allRoles' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'gets',
        auth,
        history,
        'users/get-roles',
        formData,
        dispatch
      );
      const roles = res.roles;
      dispatch(rolesAll(roles));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const resetPassword = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'resetPassword' });
    let logs = logsFunc('edit', auth.userid, auth.username, values.logs);
    const formData = new FormData();
    formData.append('newPassword', values.newPassword);
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'users/reset-password/' + values.id,
        formData,
        dispatch
      );
      dispatch(asyncActionFinish());
      if (res && res.status === 'success') {
        const status = res.status;
        const message = res.message;
        const user = res.user;
        return { status, message, user };
      }
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const deleteUser = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'deleteUser' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'delete',
        auth,
        history,
        'users/delete/' + id,
        formData,
        dispatch
      );
      const user = res.user;
      dispatch(userDelete(user));
      history.push('/setting/user');
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};
