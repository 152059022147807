import React, { useState, useEffect } from 'react';
import { closeModal } from '../../../app/modals/redux/modalActions';
import { connect } from 'react-redux';
import { importWhatsapp } from './redux/reduxApi';
// import uploadFileService from '../../../app/common/utilities/uploadFileService';
// import { toastr } from 'react-redux-toastr';
import ReactTooltip from 'react-tooltip';
// import axiosInstance from '../../../app/common/utilities/axios';

function mapState(state) {
  let scope = {};
  if (state.auth && state.auth.isAuth) {
    scope = state.auth.authorities;
  }
  return {
    auth: state.auth,
    scope: scope,
    loading: state.async.loading,
  };
}

const actions = {
  closeModal,
  importWhatsapp,
};

function PesanImportSave(props) {
  const { data, closeModal, importWhatsapp } = props;
  const auth = data.auth;
  const history = data.history;
  const item = data.item;
  const [progress, setProgress] = useState(false);
  const [state, setState] = useState({
    importId: item?.importId || null,
    title: item?.title || null,
    description: item?.description || null,
    messages: item?.messages || [],
    files: item?.files || [],
    progressUpload: 0,
    currentFile: undefined,
  });

  const uploadFile = async (values, importId, setState) => {
    let id = importId;
    let currentFile = values.data.file;
    let studentPesanId = values.id;
    // let url = 'message/upload-file/' + id;
    let formData = new FormData();
    console.log('test', currentFile.name);
    formData.append('userId', auth.userId);
    formData.append('username', auth.username);
    formData.append('id', id);
    formData.append('studentPesanId', studentPesanId);
    formData.append('filename', currentFile.name);
    formData.append('filesize', currentFile.size);
    formData.append('filetype', currentFile.type);
    formData.append('file', currentFile);
    // axiosInstance
    //   .post(url, formData, {
    //     headers: {
    //       'Content-Type': 'multipart/form-data',
    //       Authorization: 'Bearer ' + auth.token,
    //     },
    //     onUploadProgress: (data) => {
    //       setState({
    //         ...state,
    //         currentFile: currentFile.name,
    //         progressUpload: Math.round(data.loaded / data.total),
    //       });
    //       console.log(data.loaded, data.total);
    //     },
    //   })
    //   .then((response) => {
    //     console.log(response);
    //   })
    //   .catch((err) => {
    //     setState({
    //       ...state,
    //       progressUpload: 0,
    //     });
    //     toastr.error('Peringatan', 'Terjadi kesalahan ' + err);
    //   });
  };

  const importWhatsappUploadFile = async () => {
    const files = state && state.files && state.files;
    const importingPesan = importWhatsapp(auth, history, item);
    const pesan = await importingPesan;
    if (pesan) {
      setState({
        ...state,
        progressUpload: 100,
        currentFile: 'Pesan',
      });
    }
    if (pesan && files && Object.keys(files).length > 0) {
      for (const key in files) {
        const file = files[key];
        console.log(file);
        setState({
          ...state,
          progressUpload: 0,
          currentFile: file.data.filename || '',
        });
        const uploadingFile = await uploadFile(file, state.importId, setState);
        if (uploadingFile) {
          setState({
            ...state,
            files: { ...files, [key]: { file, status: 'success' } },
          });
        } else {
          setState({
            ...state,
            files: { ...files, [key]: { file, status: 'error' } },
          });
        }
      }
    }
  };

  useEffect(() => {
    setProgress(true);
    importWhatsappUploadFile();
    setProgress(false);
    // setTimeout(() => {
    //   closeModal();
    //   history.push('/pesan/' + state?.importId);
    // }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const abortProcess = () => {
    closeModal();
  };

  const handleClose = () => {
    if (progress) {
      return;
    }
    closeModal();
  };

  console.log(state?.progressUpload);

  return (
    <div className='modal is-active'>
      <div className='modal-background' onClick={() => handleClose()}></div>
      <div className='modal-card'>
        <header className='modal-card-head'>
          <div className='modal-card-title has-text-info has-text-weight-normal'>
            <i className='is-size-4 mdi mdi-content-save icon' /> Menyimpan
          </div>
        </header>
        <section className='modal-card-body is-size-6'>
          <p className='my-3 mx-1'>Progress : Menyimpan {state?.currentFile}</p>
          <p className='mb-5'>
            <progress
              className='progress is-info'
              value={
                state?.progressUpload === undefined ? 0 : state.progressUpload
              }
              max='100'
            >
              {state?.progressUpload === undefined ? 0 : state.progressUpload}%
            </progress>
          </p>
        </section>
        <footer className='modal-card-foot'>
          <div data-for='main' data-tip='Batalkan Proses Simpan'>
            <button
              className='button is-danger is-small is-rounded is-outlined'
              onClick={abortProcess}
            >
              <i className='is-size-6 mdi mdi-close icon' />
            </button>
            <ReactTooltip id='main' place='bottom' type='dark' effect='solid' />
          </div>
        </footer>
      </div>
    </div>
  );
}

export default connect(mapState, actions)(PesanImportSave);
