import {
  USERS_GET,
  USER_ADD,
  USER_EDIT,
  ROLES_ALL,
  USER_DELETE,
} from './reduxConstant';
import { createReducer } from '../../../app/common/utilities/reducerUtils';

const initialState = {
  total: 0,
  search: {
    currentPage: '',
    searchText: '',
    itemPerPage: '',
  },
  items: [],
  roles: [],
};

const usersGet = (state, payload) => {
  const users = payload.users;
  return {
    total: users.total,
    items: [...users.items],
    search: { ...users.search },
    roles: [...state.roles],
  };
};

const userAdd = (state, payload) => {
  return {
    total: parseInt(state.total) + 1,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.user.id + ''),
      payload.user,
    ],
    search: { ...state.search },
    roles: [...state.roles],
  };
};

const userEdit = (state, payload) => {
  return {
    total: parseInt(state.total),
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.user.id + ''),
      payload.user,
    ],
    search: { ...state.search },
    roles: [...state.roles],
  };
};

const rolesAll = (state, payload) => {
  return {
    total: parseInt(state.total),
    items: [...state.items],
    search: { ...state.search },
    roles: [...payload.roles],
  };
};

const userDelete = (state, payload) => {
  return {
    total: parseInt(state.total) - 1,
    items: [...state.items.filter((i) => i.id + '' !== payload.user.id + '')],
    search: { ...state.search },
    roles: [...state.roles],
  };
};

export default createReducer(initialState, {
  [USERS_GET]: usersGet,
  [USER_ADD]: userAdd,
  [USER_EDIT]: userEdit,
  [ROLES_ALL]: rolesAll,
  [USER_DELETE]: userDelete,
});
