import {
  RPPS_GET,
  RPP_ADD,
  RPP_GET,
  RPP_EDIT,
  RPP_UPLOADFILE,
  RPP_DELETE,
  RPP_RESET_SEARCH,
} from './reduxConstant';
import { createReducer } from '../../../../app/common/utilities/reducerUtils';

const initialState = {
  total: 0,
  search: {
    year: '',
    sem: '',
    jenjang: '',
    tingkat: '',
    kelas: '',
    pelajaran: '',
    published: 'all',
  },
  items: [],
  uploadStatus: 'finish',
};

const rppsGet = (state, payload) => {
  const rpps = payload.rpps;
  return {
    total: rpps.total,
    items: [...rpps.items],
    search: { ...rpps.search },
  };
};

const rppAdd = (state, payload) => {
  return {
    ...state,
    total: parseInt(state.total) + 1,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.rpp.id + ''),
      payload.rpp,
    ],
  };
};

const rppGet = (state, payload) => {
  return {
    ...state,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.rpp.id + ''),
      payload.rpp,
    ],
  };
};

const rppEdit = (state, payload) => {
  return {
    ...state,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.rpp.id + ''),
      payload.rpp,
    ],
  };
};

const rppUploadFile = (state, payload) => {
  let status = payload.status;
  return {
    ...state,
    uploadStatus: status,
  };
};

const rppDelete = (state, payload) => {
  return {
    total: parseInt(state.total) - 1,
    items: [...state.items.filter((i) => i.id + '' !== payload.rpp.id + '')],
    search: { ...state.search },
  };
};

const rppResetSearch = (state, payload) => {
  return {
    ...state,
    search: {
      year: '',
      sem: '',
      jenjang: '',
      tingkat: '',
      kelas: '',
      pelajaran: '',
      published: 'all',
    },
  };
};

export default createReducer(initialState, {
  [RPPS_GET]: rppsGet,
  [RPP_ADD]: rppAdd,
  [RPP_GET]: rppGet,
  [RPP_EDIT]: rppEdit,
  [RPP_UPLOADFILE]: rppUploadFile,
  [RPP_DELETE]: rppDelete,
  [RPP_RESET_SEARCH]: rppResetSearch,
});
