import React, { Component } from 'react';
import { connect } from 'react-redux';
import { resetPassword } from './redux/reduxApi';
import { closeModal } from '../../app/modals/redux/modalActions';
import ResetPasswordForm from './ResetPasswordForm';

const actions = {
  closeModal,
  resetPassword,
};

class ResetPassword extends Component {

  render() {
    const { data, closeModal, resetPassword } = this.props;
    const auth = data.auth;
    const history = data.history;
    const item = data.item;
    if (item.roles === 'SA') {
      closeModal();
    }
    return (
      <div className='modal is-active'>
        <div className='modal-background' onClick={closeModal}></div>
        <div className='modal-card'>
          <header className='modal-card-head'>
            <div className='modal-card-title has-text-info has-text-weight-normal'>
              <i className='is-size-4 mdi mdi-account-lock icon mr-2' />
              Reset Password
            </div>
            <button
              onClick={closeModal}
              className='delete'
              aria-label='close'
            ></button>
          </header>
          <ResetPasswordForm
            auth={auth}
            history={history}
            initialValues={item}
            resetPassword={resetPassword}
            closeModal={closeModal}
          />
        </div>
      </div>
    );
  }
}

export default connect(null, actions)(ResetPassword);
