import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getMateri, publishMateri } from './redux/reduxApi';
import PublishForm from './PublishForm';
import {
  changeSearchJenjang,
  changeSearchTingkat,
  changeSearchKelas,
  changeSearchPelajaran,
  changeSearchYears,
  changeSearchSems,
} from '../../../app/common/reduxs/search/reduxApi';

const mapState = (state, ownProps) => {
  const id = ownProps.match.params.id;
  let scope = {};
  if (state.auth && state.auth.isAuth) {
    scope = state.auth.authorities;
  }
  let materi = {};
  if (state.materis && state.materis.items && state.materis.items[0]) {
    materi = state.materis.items.filter((i) => i.id + '' === id + '')[0];
  }
  return {
    id: id,
    loading: state.async.loading,
    auth: state.auth,
    scope: scope,
    materi: materi,
    searchForm: state.searchForm,
  };
};

const actions = {
  getMateri,
  publishMateri,
  changeSearchJenjang,
  changeSearchTingkat,
  changeSearchKelas,
  changeSearchPelajaran,
  changeSearchYears,
  changeSearchSems,
};

class Publish extends Component {
  componentDidMount = () => {
    const {
      auth,
      history,
      id,
      changeSearchJenjang,
      changeSearchTingkat,
      changeSearchKelas,
      changeSearchPelajaran,
      changeSearchYears,
      changeSearchSems,
    } = this.props;
    getMateri(auth, history, id);
    changeSearchJenjang(auth, history);
    changeSearchTingkat(auth, history);
    changeSearchKelas(auth, history);
    changeSearchPelajaran(auth, history);
    changeSearchYears(auth, history);
    changeSearchSems(auth, history);
  };

  onPublish = () => {};

  render() {
    const { auth, history, id, materi, publishMateri, searchForm } = this.props;
    let initialValues = {
      ...materi,
    };
    const kelas = materi.kelas ? materi.kelas.split(',') : [];
    let arrKelas = [];
    for (let i = 0; i < kelas.length; i++) {
      arrKelas = [...arrKelas, { value: kelas[i], label: kelas[i] }];
    }
    initialValues.kelas = [...arrKelas];
    return (
      <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
        <div className='p-1'>
          <div className='columns is-variable'>
            <div className='column is-fullwidth'>
              <div className='card'>
                <header className='card-header has-background-white has-text-info'>
                  <div
                    className='custom-card-flex is-size-6'
                    style={{ width: '100%' }}
                  >
                    <p>
                      <i className='mdi mdi-bookshelf is-size-5 icon' />{' '}
                      Pembelajaran
                      <i className='mdi mdi-menu-right is-size-6 icon' />
                      Materi
                    </p>
                  </div>
                </header>
                <div className='card-content pl-4 pr-4'>
                  <PublishForm
                    auth={auth}
                    history={history}
                    id={id}
                    initialValues={initialValues}
                    jenjangs={searchForm?.jenjangs}
                    tingkats={searchForm?.tingkats}
                    kelass={searchForm?.kelass}
                    pelajarans={searchForm?.pelajarans}
                    years={searchForm?.years}
                    sems={searchForm?.sems}
                    publishMateri={publishMateri}
                    onFormSubmit={this.onPublish}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapState, actions)(Publish);
