import React from 'react';

const CheckInput = ({
  input: { onChange, ...input },
  width,
  style,
  isChecked,
  type,
  label,
  meta: { touched, error },
}) => {
  return (
    <div className='field' style={style ? style : {}}>
      <div className='control'>
        <label className='custom-checkbox'>
          {label}
          {!isChecked && (
            <input type={type} onChange={onChange} />
          )}
          {isChecked && <input checked type={type} onChange={onChange} />}
          <span className='checkmark' />
        </label>
      </div>
      {touched && error && <p className='help is-danger'>{error}</p>}
    </div>
  );
};

export default CheckInput;
