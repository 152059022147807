import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { getTugas, getStudentAnswer } from './redux/reduxApi';
import ViewForm from './ViewForm';
import { openModal } from '../../../app/modals/redux/modalActions';

const mapState = (state, ownProps) => {
  const id = ownProps.match.params.id;
  let scope = {};
  if (state.auth && state.auth.isAuth) {
    scope = state.auth.authorities;
  }
  let tugas = {};
  let studentAnswer = [];
  if (state.tugass && state.tugass.items && state.tugass.items[0]) {
    tugas = state.tugass.items.filter((i) => i.id + '' === id + '')[0];
  }
  if (
    state.tugass &&
    state.tugass.studentAnswer &&
    state.tugass.studentAnswer[0]
  ) {
    studentAnswer = [...state.tugass.studentAnswer];
  }
  return {
    id: id,
    loading: state.async.loading,
    auth: state.auth,
    scope: scope,
    tugas: tugas,
    studentAnswer: studentAnswer,
  };
};

const actions = {
  openModal,
  getTugas,
  getStudentAnswer,
};

function View(props) {
  const {
    auth,
    history,
    scope,
    openModal,
    id,
    tugas,
    studentAnswer,
    getTugas,
    getStudentAnswer,
  } = props;

  useEffect(() => {
    getTugas(auth, history, id);
    getStudentAnswer(auth, history, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFormSubmit = async (values) => {
    console.log('submit form');
  };

  return (
    <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
      <div className='p-1'>
        <div className='columns is-variable'>
          <div className='column is-fullwidth'>
            <div className='card'>
              <header className='card-header has-background-white has-text-info'>
                <div
                  className='custom-card-flex is-size-6'
                  style={{ width: '100%' }}
                >
                  <p>
                    <i className='mdi mdi-bookshelf is-size-5 icon' />{' '}
                    Pembelajaran
                    <i className='mdi mdi-menu-right is-size-6 icon' />
                    Tugas
                  </p>
                </div>
              </header>
              <div className='card-content pl-4 pr-4'>
                <ViewForm
                  auth={auth}
                  history={history}
                  scope={scope}
                  openModal={openModal}
                  id={id}
                  initialValues={{ ...tugas }}
                  studentAnswer={studentAnswer}
                  onFormSubmit={onFormSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(
  connect(mapState, actions)(reduxForm({ form: 'viewTugas' })(View))
);
