import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  getMateri,
  editMateri,
  uploadAttachment,
  downloadFile,
} from './redux/reduxApi';
import EditForm from './EditForm';
import { openModal } from '../../../app/modals/redux/modalActions';

const mapState = (state, ownProps) => {
  const id = ownProps.match.params.id;
  let scope = {};
  if (state.auth && state.auth.isAuth) {
    scope = state.auth.authorities;
  }
  let materi = {};
  let uploadStatus;
  if (state.materis && state.materis.items && state.materis.items[0]) {
    materi = state.materis.items.filter((i) => i.id + '' === id + '')[0];
    uploadStatus = state.materis.uploadStatus;
  }
  return {
    id: id,
    loading: state.async.loading,
    auth: state.auth,
    scope: scope,
    materi: materi,
    uploadStatus: uploadStatus,
  };
};

const actions = {
  getMateri,
  editMateri,
  uploadAttachment,
  downloadFile,
  openModal,
};

function Edit(props) {
  const {
    auth,
    history,
    scope,
    uploadAttachment,
    downloadFile,
    id,
    materi,
    uploadStatus,
    getMateri,
    editMateri,
    openModal,
  } = props;
  const [state, setState] = useState({});

  useEffect(() => {
    getMateri(auth, history, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { title, description, attachments } = materi;
    setState({
      title: title,
      description: description,
      attachments: attachments ? attachments : '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [materi]);

  const onFormSubmit = (values) => {
    try {
      const data = { ...values };
      editMateri(auth, history, data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
      <div className='p-1'>
        <div className='columns is-variable'>
          <div className='column is-fullwidth'>
            <div className='card'>
              <header className='card-header has-background-white has-text-info'>
                <div
                  className='custom-card-flex is-size-6'
                  style={{ width: '100%' }}
                >
                  <p>
                    <i className='mdi mdi-bookshelf is-size-5 icon' />{' '}
                    Pembelajaran
                    <i className='mdi mdi-menu-right is-size-6 icon' />
                    Materi
                  </p>
                </div>
              </header>
              <div className='card-content pl-4 pr-4'>
                <EditForm
                  auth={auth}
                  history={history}
                  scope={scope}
                  openModal={openModal}
                  id={id}
                  initialValues={state}
                  uploadStatus={uploadStatus}
                  editMateri={editMateri}
                  uploadAttachment={uploadAttachment}
                  downloadFile={downloadFile}
                  onFormSubmit={onFormSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(connect(mapState, actions)(Edit));
