import {
  USERS_GET,
  USER_ADD,
  USER_EDIT,
  USER_DELETE,
  ROLES_ALL,
} from './reduxConstant';

export const usersGet = (users) => {
  return {
    type: USERS_GET,
    payload: {
      users,
    },
  };
};

export const userAdd = (user) => {
  return {
    type: USER_ADD,
    payload: {
      user,
    },
  };
};

export const userEdit = (user) => {
  return {
    type: USER_EDIT,
    payload: {
      user,
    },
  };
};

export const rolesAll = (roles) => {
  return {
    type: ROLES_ALL,
    payload: {
      roles,
    },
  };
};

export const userDelete = (user) => {
  return {
    type: USER_DELETE,
    payload: {
      user,
    },
  };
};
