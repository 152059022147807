import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { addTes, uploadImage } from './redux/reduxApi';
import { nanoid } from 'nanoid';
import {
  changeSessionState,
  deleteSessionState,
} from '../../../app/common/reduxs/session/reduxApi';
import AddForm from './AddForm';

const mapState = (state, ownProps) => {
  const id = ownProps.match.params.id;
  let sesState = {};
  if (state.sessionState && state.sessionState[0]) {
    sesState = state.sessionState.filter((i) => i.id + '' === id + '')[0];
  }
  return {
    loading: state.async.loading,
    auth: state.auth,
    sesState: sesState,
    id: id,
  };
};

const actions = {
  changeSessionState,
  deleteSessionState,
  addTes,
  uploadImage,
};

function Add(props) {
  let keyInit = nanoid();
  const [state, setState] = useState({
    title: '',
    description: '',
    questions: [
      {
        no: 1,
        key: keyInit,
        questionType: 'optionQuestion',
        question: 'Pertanyaan di sini...',
        options: [
          'a. pilihan 1',
          'b. pilihan 2',
          'c. pilihan 3',
          'd. pilihan 4',
        ],
        picture: null,
      },
    ],
    answers: [
      {
        no: 1,
        key: keyInit,
        answer: 'Ex: a',
        explanation: 'Penjelasan jawaban...',
      },
    ],
  });
  const { auth, history, id, sesState, changeSessionState, addTes, uploadImage } = props;

  useEffect(() => {
    if (sesState && sesState.id) {
      setState({
        title: sesState?.data?.title ?? state.title,
        description: sesState?.data?.description ?? state.description,
        questions: sesState?.data?.questions ?? state.questions,
        answers: sesState?.data?.answers ?? state.answers,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
      <div className='p-1'>
        <div className='columns is-variable'>
          <div className='column is-fullwidth'>
            <div className='card'>
                <header className='card-header has-background-white has-text-info'>
                  <div
                    className='custom-card-flex is-size-6'
                    style={{ width: '100%' }}
                  >
                    <p>
                      <i className='mdi mdi-bookshelf is-size-5 icon' />{' '}
                      Pembelajaran<i className='mdi mdi-menu-right is-size-6 icon' />Tes
                    </p>
                  </div>
                </header>
              <AddForm
                auth={auth}
                history={history}
                id={id}
                initialValues={state}
                sesState={sesState}
                changeSessionState={changeSessionState}
                deleteSessionState={deleteSessionState}
                addTes={addTes}
                uploadImage={uploadImage}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(connect(mapState, actions)(Add));
