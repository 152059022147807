import { sessionStateChange, sessionStateDelete } from './reduxAction';

export const changeSessionState = (sessionState) => {
  return async (dispatch) => {
    dispatch(sessionStateChange(sessionState));
  };
};

export const deleteSessionState = (sessionState) => {
  return async (dispatch) => {
    dispatch(sessionStateDelete(sessionState));
  };
};
