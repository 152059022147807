import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getRole, editRole } from './redux/reduxApi';
import { Menus } from '../../app/common/data/menus';
import EditForm from './EditForm';
import { openModal } from '../../app/modals/redux/modalActions';

const mapState = (state, ownProps) => {
  const id = ownProps.match.params.id;
  let scope = {};
  if (state.auth && state.auth.isAuth) {
    scope = state.auth.authorities;
  }
  let role = {};
  if (state.roles && state.roles.items && state.roles.items[0]) {
    role = state.roles.items.filter((i) => i.id + '' === id + '')[0];
  }
  return {
    id: id,
    scope: scope,
    role: role,
    loading: state.async.loading,
    auth: state.auth,
  };
};

const actions = {
  getRole,
  editRole,
  openModal,
};

function Edit(props) {
  const {
    id,
    scope,
    role,
    auth,
    history,
    loading,
    getRole,
    editRole,
    openModal,
  } = props;

  useEffect(() => {
    getRole(auth, history, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const objFn = () => {
    const menus = [...Menus];
    let dataState = {};
    const dataM = [];
    const dataSubm = [];
    menus.forEach((m) => {
      dataM.push(m.id);
      dataState = { ...dataState, ...m.auth };
      if (m.subm.length > 0) {
        m.subm.forEach((subm) => {
          dataState = { ...dataState, ...subm.auth };
          dataSubm.push(subm.id);
        });
      }
    });

    dataState.title = role.title;
    dataState.logs = role.logs;
    dataState.dataM = dataM;
    dataState.dataSubm = dataSubm;
    return dataState;
  };

  return (
    <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
      <div className='p-1'>
        <div className='columns is-variable'>
          <div className='column is-fullwidth'>
            <div className='card'>
              <header className='card-header has-background-white has-text-info'>
                <div
                  className='custom-card-flex is-size-6'
                  style={{ width: '100%' }}
                >
                  <p>
                    <i className='mdi mdi-account-cog is-size-5 icon' /> Setting
                    <i className='mdi mdi-menu-right is-size-6 icon' />
                    Role
                  </p>
                </div>
              </header>
              <div className='card-content pl-4 pr-4'>
                <EditForm
                  id={id}
                  initialValues={role}
                  menus={Menus}
                  dataState={objFn}
                  loading={loading}
                  editRole={editRole}
                  auth={auth}
                  history={history}
                  scope={scope}
                  openModal={openModal}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(connect(mapState, actions)(Edit));
