import {
  JENJANGS_GET,
  JENJANG_ADD,
  JENJANG_EDIT,
  JENJANG_GET,
  JENJANG_DELETE,
} from './reduxConstant';

export const jenjangsGet = (jenjangs) => {
  return {
    type: JENJANGS_GET,
    payload: {
      jenjangs,
    },
  };
};

export const jenjangAdd = (jenjang) => {
  return {
    type: JENJANG_ADD,
    payload: {
      jenjang,
    },
  };
};

export const jenjangEdit = (jenjang) => {
  return {
    type: JENJANG_EDIT,
    payload: {
      jenjang,
    },
  };
};

export const jenjangGet = (jenjang) => {
  return {
    type: JENJANG_GET,
    payload: {
      jenjang,
    },
  };
};

export const jenjangDelete = (jenjang) => {
  return {
    type: JENJANG_DELETE,
    payload: {
      jenjang,
    },
  };
};
