import React from 'react';
import { Link } from 'react-router-dom';
import TextInput from '../../../app/common/form/TextInput';
import DateInput from '../../../app/common/form/DateInput';
import TextArea from '../../../app/common/form/TextArea';
import { reduxForm, Field } from 'redux-form';
import {
  composeValidators,
  combineValidators,
  isRequired,
  hasLengthGreaterThan,
} from 'revalidate';
import { convertSize } from '../../../app/common/helpers/othersHelpers';
import { formatISO } from 'date-fns';

const validate = combineValidators({
  title: composeValidators(
    isRequired({ message: 'Judul pengumuman harus diisi' }),
    hasLengthGreaterThan(0)({
      message: 'Judul pengumuman harus memiliki paling sedikit 1 karakter',
    })
  )(),
});

let onChangeTitle = '';
let onChangeDate = '';
let onChangeDescription = '';
const handleOnFormChange = (newValues, dispatch, props, previousValues) => {
  const {
    title: newTitle,
    date: newDate,
    description: newDescription,
  } = newValues;
  onChangeTitle = newTitle;
  onChangeDate = newDate;
  onChangeDescription = newDescription;
};

function EditForm(props) {
  const {
    auth,
    history,
    id,
    initialValues,
    uploadFile,
    invalid,
    loading,
    pristine,
    handleSubmit,
    editAnnouncement,
    uploadAnnouncement,
  } = props;

  let file =
    initialValues && initialValues.file && JSON.parse(initialValues.file);

  const handleClickSubmit = (value) => {
    let newDate = formatISO(value.date)
    let newValues = {
      ...value,
      date: newDate,
      id,
    };
    editAnnouncement(auth, history, newValues);
  };

  const onSelectFile = (e, announcementId) => {
    e.preventDefault();
    let file = e.target.files[0];
    const values = {
      id: announcementId,
      title: onChangeTitle,
      date: formatISO(onChangeDate),
      description: onChangeDescription,
      filename: file.name,
      filesize: file.size,
      filetype: file.type,
      file: file,
    };
    uploadAnnouncement(auth, history, values);
  };

  const renderFileInput = (props) => {
    const { input, meta, placeholder, file, type, mimeType } = props;
    return (
      <div className='field'>
        <label className='label' htmlFor='file'>
          File
        </label>
        <div className='control'>
          <div className='file is-small is-info has-name is-fullwidth'>
            <label className='file-label'>
              <input
                name={input.name}
                className='file-input'
                type={type}
                accept={mimeType}
                onChange={(e) => onSelectFile(e, id)}
              />
              <span className='file-cta'>
                <span className='file-icon'>
                  <i className='is-size-6 mdi mdi-upload'></i>
                </span>
                <span className='file-label'>{placeholder}</span>
              </span>
              <span className='file-name'>
                {uploadFile?.status === 'start' ? uploadFile?.filename : ''}
              </span>
            </label>
          </div>
          {uploadFile && uploadFile.status === 'start' && (
            <progress className='progress is-small is-info mt-2' max='100'>
              0%
            </progress>
          )}
        </div>
        {meta?.invalid && meta?.error && <div>{meta?.error}</div>}
        {file && (
          <div
            className='has-text-link has-background-grey-lighter px-2 py-1 mb-1 flex justify-between align-center mt-2'
            style={{ maxWidth: 500 }}
          >
            <span className='custom-text-overflow '>
              {file?.filename} ({convertSize(file.filesize)})
            </span>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <div className='level'>
        <div className='level-left'>
          <div className='level-item'>
            <nav className='breadcrumb is-size-6' aria-label='breadcrumbs'>
              <ul>
                <li className='is-active has-text-weight-bold'>
                  <Link to={'/portal/pengumuman/edit/' + id}>Edit</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className='level-right'>
          <div className='level-item'>
            <div className='buttons'>
              <button
                disabled={invalid || loading || pristine}
                onClick={handleSubmit(handleClickSubmit)}
                className='button is-small is-rounded is-info is-outlined'
              >
                <i className='is-size-6 mdi mdi-content-save icon' />
              </button>
              <button
                onClick={() => history.push('/portal/pengumuman/' + id)}
                type='button'
                to={'/portal/pengumuman'}
                className='button custom-grey is-small is-rounded is-outlined'
              >
                <i className='is-size-6 mdi mdi-arrow-left icon' />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='columns'>
        <div className='column is-third-quarter'>
          <form onSubmit={handleSubmit(handleClickSubmit)} autoComplete='off'>
            <Field
              name='title'
              type='text'
              component={TextInput}
              placeholder='Nama pengumuman'
              label='Nama pengumuman'
            />
            <Field
              name='date'
              type='text'
              component={DateInput}
              label='Tanggal'
              placeholder='2000/05/21'
            />
            <Field
              name='description'
              type='text'
              component={TextArea}
              placeholder='Deskripsi'
              label='Deskripsi'
            />
            <Field
              name='attachment'
              type='file'
              component={renderFileInput}
              placeholder='File'
              label='File Attachement'
              file={file ?? ''}
            />
            <div
              className='field is-grouped'
              style={{ marginTop: 20, marginBottom: 20 }}
            >
              <div className='control'>
                <button
                  type='submit'
                  disabled={invalid || loading}
                  className={
                    loading
                      ? 'button is-info is-small is-rounded is-outlined is-loading'
                      : 'button is-info is-small is-rounded is-outlined'
                  }
                >
                  <i className='is-size-6 mdi mdi-content-save icon' />
                </button>
              </div>
              <div className='control'>
                <button
                  onClick={() => history.push('/portal/pengumuman/' + id)}
                  className='button custom-grey is-small is-rounded is-outlined'
                >
                  <i className='is-size-6 mdi mdi-arrow-left icon' />
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default reduxForm({
  form: 'announcementEdit',
  onChange: handleOnFormChange,
  validate,
  enableReinitialize: true,
})(EditForm);
