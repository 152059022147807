import {
  asyncActionFinish,
  asyncActionError,
} from '../../../../app/common/reduxs/async/asyncActions';
import {
  tugassGet,
  tugasAdd,
  tugasGet,
  studentAnswerGet,
  tugasEdit,
  tugasUploadFile,
  tugasDeleteStudentAnswer,
  tugasDelete,
  tugasResetSearch,
} from './reduxAction';
import { ASYNC_ACTION_START } from '../../../../app/common/reduxs/async/asyncConstant';
import { checkErr } from '../../../../app/common/helpers/checkRes';
import { fetchFunc } from '../../../../app/common/helpers/fetchHelpers';
import { logsFunc } from '../../../../app/common/helpers/othersHelpers';
import { SITE_ADDRESS } from '../../../../app/common/data/siteConfig';
import { saveAs } from 'file-saver';

export const getTugass = (
  auth,
  history,
  itemPerPage,
  currentPage,
  searchText,
  searchMoreText,
  isReset
) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getTugass' });
    const formData = new FormData();
    if (isReset === 'reset') {
      dispatch(tugasResetSearch());
    }
    const allDataAccess = auth.authorities.subm.materi_all;
    formData.append('itemPerPage', itemPerPage ? itemPerPage : 10);
    formData.append('currentPage', currentPage);
    formData.append('searchText', searchText);
    formData.append('allData', allDataAccess);
    formData.append('searchMoreText', JSON.stringify(searchMoreText));
    try {
      const res = await fetchFunc(
        'gets',
        auth,
        history,
        'tugas/',
        formData,
        dispatch
      );
      const items = res.items;
      const total = res.total;
      const search = {
        currentPage,
        searchText,
        itemPerPage: itemPerPage ? itemPerPage : 10,
        ...searchMoreText,
      };
      dispatch(tugassGet({ total, items, search }));
      if (isReset === 'reset') {
        history.push('/pembelajaran/tugas');
      }
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const addTugas = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'addTugas' });
    let logs = logsFunc('create', auth.userid, auth.username, null);
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('description', values.description);
    formData.append('questions', JSON.stringify(values.questions));
    formData.append('answers', JSON.stringify(values.answers));
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'tugas/add/' + values.id,
        formData,
        dispatch
      );
      const tugas = res.tugas;
      dispatch(tugasAdd(tugas));
      history.push('/pembelajaran/tugas/edit/' + values.id);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const getTugas = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getTugas' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'get',
        auth,
        history,
        'tugas/detail/' + id,
        formData,
        dispatch
      );
      const tugas = res.tugas;
      dispatch(tugasGet(tugas));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const editTugas = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'editTugas' });
    let logs = logsFunc('edit', auth.userid, auth.username, values.logs);
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('description', values.description);
    formData.append('questions', JSON.stringify(values.questions));
    formData.append('answers', JSON.stringify(values.answers));
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'tugas/edit/' + values.id,
        formData,
        dispatch
      );
      const tugas = res.tugas;
      dispatch(tugasEdit(tugas));
      history.push('/pembelajaran/tugas/' + values.id);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const publishTugas = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'publishTugas' });
    let logs = logsFunc('edit', auth.userid, auth.username, values.logs);
    const formData = new FormData();
    formData.append('published', values.published);
    formData.append('year', values.year);
    formData.append('sem', values.sem);
    formData.append('jenjang', values.jenjang);
    formData.append('tingkat', values.tingkat);
    formData.append('kelas', values.kelas.toString());
    formData.append('pelajaran', values.pelajaran);
    formData.append('tags', JSON.stringify(values.tags));
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'tugas/publish/' + values.id,
        formData,
        dispatch
      );
      const tugas = res.tugas;
      dispatch(tugasEdit(tugas));
      history.push('/pembelajaran/tugas/' + values.id);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const getStudentAnswer = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getStudentAnswer' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'get',
        auth,
        history,
        'tugas/student-answer/' + id,
        formData,
        dispatch
      );
      const studentAnswer = res.studentAnswer;
      dispatch(studentAnswerGet(studentAnswer));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const submitScores = (auth, history, id, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'submitScores' });
    const formData = new FormData();
    formData.append('scores', JSON.stringify(values));
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'tugas/submit-scores/' + id,
        formData,
        dispatch
      );
      const tugas = res.tugas;
      dispatch(tugasEdit(tugas));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const uploadImage = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'uploadImage' });
    dispatch(
      tugasUploadFile({
        key: values.keyItem,
        filename: values.filename,
        filesize: values.filesize,
        filetype: values.filetype,
        status: 'start',
      })
    );
    let logAction = values.logs ? 'edit' : 'create';
    let logs = logsFunc(logAction, auth.userid, auth.username, values.logs);
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('description', values.description);
    formData.append('keyItem', values.keyItem);
    formData.append('questions', JSON.stringify(values.questions));
    formData.append('answers', JSON.stringify(values.answers));
    formData.append('file', values.file);
    formData.append('filename', values.filename);
    formData.append('filesize', values.filesize);
    formData.append('filetype', values.filetype);
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'uploadAttach',
        auth,
        history,
        'tugas/upload-image/' + values.id,
        formData,
        dispatch
      );
      const tugas = res.tugas;
      dispatch(tugasEdit(tugas));
      dispatch(
        tugasUploadFile({
          key: values.keyItem,
          filename: values.filename,
          filesize: values.filesize,
          filetype: values.filetype,
          status: 'finish',
        })
      );
      dispatch(asyncActionFinish());
      history.push('/pembelajaran/tugas/edit/' + values.id);
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const viewImage = (auth, history, values, file) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'viewImage' });
    const formData = new FormData();
    formData.append('userId', auth.userId);
    formData.append('username', auth.username);
    formData.append('link', file.link);
    try {
      const fetchData = await fetch(
        SITE_ADDRESS + 'tugas/download-file/' + values.id,
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const blob = await fetchData.blob();
      saveAs(blob, file.link.split('/')[file.link.split('/').length - 1]);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const downloadFile = (auth, history, values, file) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'downloadFile' });
    const formData = new FormData();
    formData.append('userId', auth.userId);
    formData.append('username', auth.username);
    formData.append('filelink', file.filelink);
    try {
      const fetchData = await fetch(
        SITE_ADDRESS + 'tugas/download-file/' + values.id,
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const blob = await fetchData.blob();
      saveAs(
        blob,
        file.filelink.split('/')[file.filelink.split('/').length - 1]
      );
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const deleteImage = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'deleteImage' });
    let logs = logsFunc('edit', auth.userid, auth.username, values.logs);
    const formData = new FormData();
    formData.append('questionKey', values.key);
    formData.append('link', values.picture.filelink);
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'deleteFile',
        auth,
        history,
        'tugas/delete-image/' + values.id,
        formData,
        dispatch
      );
      const tugas = res.tugas;
      dispatch(tugasEdit(tugas));
      history.push('/pembelajaran/tugas/edit/' + values.id);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const deleteTugasStudentAnswer = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'deleteTugasStudentAnswer' });
    const formData = new FormData();
    try {
      await fetchFunc(
        'delete',
        auth,
        history,
        'tugas/delete-student-answer/' + id,
        formData,
        dispatch
      );
      dispatch(tugasDeleteStudentAnswer());
      history.push('/pembelajaran/tugas/' + id);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const deleteTugas = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'deleteTugas' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'delete',
        auth,
        history,
        'tugas/delete/' + id,
        formData,
        dispatch
      );
      const tugas = res.tugas;
      dispatch(tugasDelete(tugas));
      history.push('/pembelajaran/tugas');
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};
