import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, Redirect } from 'react-router-dom';
import { getDashboard } from './redux/reduxApi';
import { convertCapacity } from '../../app/common/helpers/othersHelpers';

function mapState(state) {
  let scope = {};
  if (state.auth && state.auth.authorities) {
    scope = state.auth.authorities;
  }
  return {
    auth: state.auth,
    scope: scope,
    loading: state.async.loading,
    dashboard: state.dashboard,
  };
}

const actions = { getDashboard };

function Index(props) {
  let history = useHistory();
  const { auth, loading, dashboard, getDashboard } = props;

  useEffect(() => {
    if (dashboard && !dashboard.drive) {
      setTimeout(() => {
        getDashboard(auth, history);
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (auth.isAuth !== true) return <Redirect to='/login' />;

  const driveSize =
    dashboard &&
    dashboard.drive &&
    dashboard.drive.used &&
    convertCapacity(dashboard.drive.used, 7500000000);

  return (
    <>
      <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
        <div className='p-1'>
          <div className='columns is-variable is-desktop'>
            <div className='column is-12'>
              <div className='card'>
              <header className='card-header has-background-white has-text-info'>
                <div
                  className='custom-card-flex is-size-6'
                  style={{ width: '100%' }}
                >
                  <p>
                    <i className='mdi mdi-view-dashboard is-size-5 icon' />{' '}
                    Dashboard
                  </p>
                </div>
              </header>
                <div className='card-content pl-3 mr-3'>
                  <div className='columns flex flex-wrap'>
                    <div className='column'>
                      Selamat datang di sistem CMS Skoolas
                    </div>
                    <div className='column is-3 flex justify-end'>
                      <div className='flex flex-column'>
                        <progress
                          className='progress is-info'
                          value={driveSize && driveSize.usedPercent}
                          max='100'
                        >
                          {driveSize && driveSize.usedPercent + '%'}
                        </progress>
                        Drive terpakai{' '}
                        {`${
                          !loading && driveSize && driveSize.used !== undefined
                            ? driveSize.used
                            : 0
                        } / ${
                          !loading && driveSize && driveSize.total !== undefined
                            ? driveSize.total
                            : 0
                        }`}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default connect(mapState, actions)(Index);



























