import {
  ALL_TES_GET,
  TES_ADD,
  TES_GET,
  TES_EDIT,
  TES_DELETE,
  TES_RESET_SEARCH,
} from './reduxConstant';
import { createReducer } from '../../../../app/common/utilities/reducerUtils';

const initialState = {
  total: 0,
  search: {
    year: '',
    sem: '',
    jenjang: '',
    tingkat: '',
    kelas: '',
    pelajaran: '',
    published: 'all',
  },
  items: [],
};

const allTesGet = (state, payload) => {
  const allTes = payload.allTes;
  return {
    ...state,
    total: allTes.total,
    items: [...allTes.items],
    search: { ...allTes.search },
  };
};

const tesAdd = (state, payload) => {
  return {
    ...state,
    total: parseInt(state.total) + 1,
    items: [...state.items, payload.tes],
  };
};

const tesGet = (state, payload) => {
  return {
    ...state,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.tes.id + ''),
      payload.tes,
    ],
  };
};

const tesEdit = (state, payload) => {
  return {
    ...state,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.tes.id + ''),
      payload.tes,
    ],
  };
};

const tesDelete = (state, payload) => {
  return {
    ...state,
    total: parseInt(state.total) - 1,
    items: [...state.items.filter((i) => i.id + '' !== payload.tes.id + '')],
  };
};

const tesResetSearch = (state, payload) => {
  return {
    ...state,
    search: {
      year: '',
      sem: '',
      jenjang: '',
      tingkat: '',
      kelas: '',
      pelajaran: '',
      published: 'all',
    },
  };
};

export default createReducer(initialState, {
  [ALL_TES_GET]: allTesGet,
  [TES_ADD]: tesAdd,
  [TES_GET]: tesGet,
  [TES_EDIT]: tesEdit,
  [TES_DELETE]: tesDelete,
  [TES_RESET_SEARCH]: tesResetSearch,
});
