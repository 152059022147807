import React from 'react';
import ListItems from './IndexListItems';

export default function IndexList(props) {
  const { loading, scope, items, title, currentPage, itemPerPage } = props;
  return (
    <>
      {!loading && items &&
        items[0] &&
        items.map((item, index) => (
          <ListItems
            key={item.id}
            index={index}
            item={item}
            currentPage={currentPage}
            itemPerPage={itemPerPage}
            loading={loading}
            scope={scope}
          />
        ))}
      {!loading && items && items.length === 0 && (
        <tr>
          <td colSpan='7'>
            Tidak ada <span className='is-lowercase'>{title}</span>
          </td>
        </tr>
      )}
    </>
  );
}
