import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import TextInput from '../../../app/common/form/TextInput';
import TextArea from '../../../app/common/form/TextArea';
import RadioInput from '../../../app/common/form/RadioInput';
import { sortArrayObject } from '../../../app/common/helpers/objectHelpers';
import { stringSimilarity } from 'string-similarity-js';
import { SITE_ADDRESS } from '../../../app/common/data/siteConfig';
import { nanoid } from 'nanoid';

function ViewLatihanReviewForm(props) {
  const { id, initialValues, keyAnswers } = props;
  const [scoreCount, setScoreCount] = useState(0);
  const [state, setState] = useState({
    keyActiveQuestion: '',
    activeQuestion: 1,
    confirmSubmit: false,
    submitStatus: false,
    showResult: false,
    start: true,
  });

  let title = initialValues.title;
  let description = initialValues.description;
  let keyActiveQuestion = state && state.keyActiveQuestion;
  let activeQuestion = state && state.activeQuestion;
  let questions = initialValues && initialValues.questions;
  let answers = initialValues && initialValues.answers;
  let tags = initialValues && initialValues.tags;

  useEffect(() => {
    setState({
      ...state,
      keyActiveQuestion: questions[activeQuestion - 1].key,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  const handleInputAnswer = (e, item) => {
    e.preventDefault();
    let answer = {
      no: item.no,
      key: item.key,
      answer: e.target.value,
    };
    let answers = [...state.answers.filter((i) => i.key !== item.key), answer];
    setState({
      ...state,
      answers: sortArrayObject(answers, 'no', 'asc'),
    });
  };

  const handleCheckRadio = (e, item, opt) => {
    let answer = {
      no: item.no,
      key: item.key,
      answer: opt,
    };
    let answers = [...state.answers.filter((i) => i.key !== item.key), answer];
    setState({
      ...state,
      answers: sortArrayObject(answers, 'no', 'asc'),
    });
  };

  const handleConfirmSubmit = (e) => {
    e.preventDefault();
    let aQ = activeQuestion + 1;
    setState({
      ...state,
      activeQuestion: aQ,
      confirmSubmit: true,
      submitStatus: true,
      showResult: true,
      start: false,
    });
    handleCountScore();
  };

  const handleCountScore = () => {
    let latihanAnswers = keyAnswers;
    let count = 0;
    latihanAnswers &&
      latihanAnswers.forEach((ans, index) => {
        let kA = ans.answer + '';
        let keyAns = kA.trim();
        let sA = answers && answers[index] && answers[index].answer + '';
        let stAns = sA.trim();
        if (
          questions &&
          questions[0] &&
          questions[index]?.questionType === 'optionQuestion'
        ) {
          const string_chop = function (str, size) {
            if (str == null) return [];
            str = String(str);
            size = ~~size;
            return size > 0
              ? str.match(new RegExp('.{1,' + size + '}', 'g'))
              : [str];
          };
          let firstArray = stAns;
          if (stAns.length > keyAns.length) {
            firstArray = string_chop(stAns, keyAns.length)[0];
          }
          if (firstArray === keyAns) {
            count = count + 1;
          }
        }

        if (
          questions &&
          questions[0] &&
          questions[index]?.questionType === 'longQuestion'
        ) {
          let addScore = stringSimilarity(keyAns, stAns);
          count = count + addScore;
        }
        if (
          questions &&
          questions[0] &&
          questions[index]?.questionType === 'shortQuestion'
        ) {
          if (keyAns?.toLowerCase() === stAns?.toLowerCase()) {
            count = count + 1;
          }
        }
      });
    let latihanAnswersLength = latihanAnswers && latihanAnswers.length;
    let score = (count / latihanAnswersLength) * 100;
    setScoreCount(score);
  };

  const handlePrevNextQuestion = (e, val) => {
    e.preventDefault();
    let aQ = activeQuestion + val;
    setState({
      ...state,
      keyActiveQuestion: questions[aQ - 1].key,
      activeQuestion: aQ,
    });
  };

  const convertScore = (scoreCount) => {
    if (scoreCount === null) {
      return 'calculate';
    } else if (scoreCount === 0) {
      return 0;
    } else if (scoreCount > 0) {
      return scoreCount.toFixed(1);
    }
  };

  let explanation =
    keyAnswers &&
    keyAnswers
      .filter((item) => item.key === keyActiveQuestion)[0]
      ?.explanation?.trim();

  const activeKeyAnswer =
    keyAnswers &&
    keyAnswers[0] &&
    keyAnswers.find((jtem) => jtem.key + '' === keyActiveQuestion);

  const displayKeyAnswer = () => {
    const findAnswer =
      answers &&
      answers[0] &&
      answers.find((item) => item.key + '' === keyActiveQuestion);
    const formatedAnswer =
      activeKeyAnswer &&
      activeKeyAnswer.answer &&
      activeKeyAnswer.answer.toLowerCase();
    const isRightAnswer =
      findAnswer &&
      findAnswer.answer &&
      findAnswer.answer.includes(formatedAnswer);
    const displayKeyAns = isRightAnswer ? (
      <span className='has-text-success-dark'>
        <i className='is-size-6 mdi mdi-check-circle icon' />
      </span>
    ) : (
      <span className='has-text-danger-dark'>
        <i className='is-size-6 mdi mdi-close-circle icon' />
      </span>
    );
    return displayKeyAns;
  };

  const displayFieldKeyAnswer = () => {
    const formatedAnswer =
      activeKeyAnswer &&
      activeKeyAnswer.answer &&
      activeKeyAnswer.answer;
    const displayFieldKeyAns = formatedAnswer && (
      <Field
        readOnly
        name={'key_answer_' + nanoid()}
        type='text'
        component={TextInput}
        defaultValue={formatedAnswer || ' '}
        onChange={(e) => handleInputAnswer(e)}
        placeholder='Jawaban'
        label='Kunci Jawaban'
      />
    );
    return displayFieldKeyAns;
  };

  return (
    <>
      <div className='flex justify-between mb-5'>
        <div className='has-text-weight-bold custom-text-overflow'>{title}</div>
        <div className='flex justify-end'>
          <div className='buttons'>
            <Link
              to={'/pembelajaran/latihan/' + id}
              className='button custom-grey is-small is-rounded is-outlined'
            >
              <i className='is-size-6 mdi mdi-arrow-left icon' />
            </Link>
          </div>
        </div>
      </div>
      <div className='flex flex-column mb-4'>
        <article className='message is-info'>
          <div className='message-body is-size-7'>
            <p>Deskripsi:</p>
            <p className='is-capitalized' style={{ whiteSpace: 'pre-line' }}>
              {description}
            </p>
          </div>
        </article>
      </div>
      {state && state.start && !state.submitStatus && !state.confirmSubmit && (
        <div className='flex justify-center mt-5 mb-5'>
          <button
            disabled={
              activeQuestion === 1 || activeQuestion === questions.length + 1
            }
            className='button is-small is-rounded is-info is-outlined mr-1'
            onClick={(e) => handlePrevNextQuestion(e, -1)}
          >
            <i className='is-size-6 mdi mdi-arrow-left icon' />
          </button>
          <div className='ml-1 mr-1'>
            {activeQuestion}/{questions.length + 1}
          </div>
          {activeQuestion < questions.length && (
            <button
              disabled={activeQuestion === questions.length || !state.start}
              className='button is-small is-rounded is-info is-outlined ml-1'
              onClick={(e) => handlePrevNextQuestion(e, 1)}
            >
              <i className='is-size-6 mdi mdi-arrow-right icon' />
            </button>
          )}
          {activeQuestion >= questions.length && (
            <button
              disabled={activeQuestion === questions.length + 1}
              onClick={(e) => handleConfirmSubmit(e)}
              className='button is-small is-rounded is-info is-outlined ml-1'
            >
              <i className='is-size-6 mdi mdi-arrow-right icon' />
            </button>
          )}
        </div>
      )}
      <div className='columns'>
        <div className='column is-third-quarter'>
          <div className='content'>
            {state && !state.submitStatus && !state.confirmSubmit && (
              <>
                <div className='mb-2' style={{ whiteSpace: 'pre-line' }}>
                  {questions && questions[activeQuestion - 1] && (
                    <>
                      <div>
                        {questions[activeQuestion - 1].picture &&
                          questions[activeQuestion - 1].picture.filelink && (
                            <img
                              src={
                                SITE_ADDRESS +
                                questions[activeQuestion - 1].picture.filelink
                              }
                              alt=''
                              style={{ maxHeight: 284 }}
                            />
                          )}
                      </div>
                      <div>{questions[activeQuestion - 1].question}</div>
                    </>
                  )}
                </div>
                {questions &&
                  questions[activeQuestion - 1] &&
                  questions[activeQuestion - 1].questionType !==
                    'optionQuestion' && (
                    <>
                      <div className='field'>
                        <label
                          className='label'
                          htmlFor={'jawaban_' + nanoid()}
                        >
                          Jawaban:{' '}
                          <span className='is-lowercase has-text-weight-normal'>
                            {displayKeyAnswer()}
                          </span>
                        </label>
                      </div>
                      <Field
                        readOnly
                        name={'answers_' + questions[activeQuestion - 1].key}
                        type='text'
                        component={
                          questions &&
                          questions[activeQuestion - 1] &&
                          questions[activeQuestion - 1].questionType ===
                            'shortQuestion'
                            ? TextInput
                            : TextArea
                        }
                        defaultValue={
                          answers &&
                          answers[activeQuestion - 1] &&
                          answers[activeQuestion - 1].answer
                            ? answers[activeQuestion - 1].answer
                            : ''
                        }
                        onChange={(e) =>
                          handleInputAnswer(e, questions[activeQuestion - 1])
                        }
                        placeholder='Jawaban'
                        label='noLabel'
                      />
                      {displayFieldKeyAnswer()}
                    </>
                  )}
                {questions &&
                  questions[activeQuestion - 1] &&
                  questions[activeQuestion - 1].options &&
                  questions[activeQuestion - 1].options[0] &&
                  questions[activeQuestion - 1].options.map((opt, index) => {
                    let key = questions[activeQuestion - 1].key;
                    let sortAnswer = answers.filter(
                      (item) => item.key === key
                    )[0];
                    let keyAnswer =
                      keyAnswers &&
                      keyAnswers.filter((item) => item.key === key)[0];

                    let string = keyAnswer.answer;
                    let splitString = new RegExp('(' + string + ')');
                    let splitResult = opt
                      .split(splitString)
                      .filter((item) => item);

                    return (
                      <div
                        key={index}
                        className='has-background-grey-lighter px-2 py-1 mb-1 flex justify-between align-center'
                      >
                        <Field
                          key={index}
                          disabled={true}
                          name='answer'
                          type='radio'
                          component={RadioInput}
                          value={opt}
                          checked={opt === sortAnswer.answer ? true : false}
                          onChange={(e) =>
                            handleCheckRadio(
                              e,
                              questions[activeQuestion - 1],
                              opt
                            )
                          }
                          placeholder={opt}
                          label={opt}
                          size='is-size-6'
                        />
                        {splitResult[0] + '' === keyAnswer.answer + '' && (
                          <span
                            className='has-text-success-dark'
                            style={{ position: 'absolute', right: 16 }}
                          >
                            <i className='is-size-6 mdi mdi-check-circle icon' />
                          </span>
                        )}
                      </div>
                    );
                  })}
                {explanation &&
                  explanation !== 'Penjelasan jawaban...' &&
                  explanation !== '' && (
                    <Field
                      readOnly
                      name={'explanation_' + nanoid()}
                      type='text'
                      component={TextArea}
                      defaultValue={explanation || ' '}
                      onChange={(e) => handleInputAnswer(e)}
                      placeholder='Penjelasan'
                      label='Penjelasan'
                    />
                  )}
              </>
            )}
            {state &&
              state.confirmSubmit &&
              state.submitStatus &&
              state.showResult && (
                <div className='has-text-centered'>
                  <p>Your Score</p>
                  <p
                    style={{
                      marginTop: -30,
                      marginBottom: 0,
                      fontSize: 80,
                      minHeight: 80,
                    }}
                  >
                    {convertScore(scoreCount) === 'calculate' && (
                      <span className='is-size-5'>Calculating...</span>
                    )}
                    <span>{convertScore(scoreCount)}</span>
                  </p>
                  <p>Selesai dalam {initialValues.time}</p>
                  <div className='flex justify-center'>
                    <Link
                      to={`/pembelajaran/latihan/` + id}
                      className='button is-small is-rounded is-info is-outlined'
                    >
                      Kembali
                    </Link>
                  </div>
                </div>
              )}
            <hr />
            <label className='label'>Tags:</label>
            <div className='tags is-capitalized'>
              {tags &&
                tags.map((item, index) => (
                  <span key={index} className='tag is-success is-light'>
                    {item}
                  </span>
                ))}
              {!tags && <>-</>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default reduxForm({
  form: 'latihanReview',
  enableReinitialize: true,
})(ViewLatihanReviewForm);
