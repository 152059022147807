import {
  KELASS_GET,
  KELAS_JENJANGS_GET,
  KELAS_TINGKATS_GET,
  KELAS_ADD,
  KELAS_EDIT,
  KELAS_GET,
  KELAS_DELETE,
  KELASS_SYNC,
} from './reduxConstant';
import { createReducer } from '../../../../app/common/utilities/reducerUtils';

const initialState = {
  total: 0,
  search: {
    currentPage: '',
    searchText: '',
    itemPerPage: '',
  },
  items: [],
  jenjangs: [],
  tingkats: [],
  kelassSync: [],
};

const kelassGet = (state, payload) => {
  const kelass = payload.kelass;
  return {
    ...state,
    total: kelass.total,
    items: [...kelass.items],
    search: { ...kelass.search },
  };
};

const kelasJenjangsGet = (state, payload) => {
  const jenjangs = payload.jenjangs;
  return {
    ...state,
    jenjangs: [...jenjangs],
  };
};

const kelasTingkatsGet = (state, payload) => {
  const tingkats = payload.tingkats;
  return {
    ...state,
    tingkats: [...tingkats],
  };
};

const kelasAdd = (state, payload) => {
  return {
    ...state,
    total: parseInt(state.total) + 1,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.kelas.id + ''),
      payload.kelas,
    ],
  };
};

const kelasEdit = (state, payload) => {
  return {
    ...state,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.kelas.id + ''),
      payload.kelas,
    ],
  };
};

const kelasGet = (state, payload) => {
  return {
    ...state,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.kelas.id + ''),
      payload.kelas,
    ],
  };
};

const kelassSync = (state, payload) => {
  const kelass = payload.kelass?.kelass || [];
  const syncKelass = payload.kelass?.syncKelass || [];
  return {
    ...state,
    items: [...kelass],
    kelassSync: [...syncKelass],
  };
};

const kelasDelete = (state, payload) => {
  return {
    ...state,
    total: parseInt(state.total) - 1,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.kelas.id + ''),
    ],
  };
};

export default createReducer(initialState, {
  [KELASS_GET]: kelassGet,
  [KELAS_JENJANGS_GET]: kelasJenjangsGet,
  [KELAS_TINGKATS_GET]: kelasTingkatsGet,
  [KELAS_ADD]: kelasAdd,
  [KELAS_EDIT]: kelasEdit,
  [KELAS_GET]: kelasGet,
  [KELASS_SYNC]: kelassSync,
  [KELAS_DELETE]: kelasDelete,
});
