import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { addRpp, uploadAttachment } from './redux/reduxApi';
import AddForm from './AddForm';
import { format } from 'date-fns';

const mapState = (state, ownProps) => {
  const id = ownProps.match.params.id;
  return {
    auth: state.auth,
    loading: state.async.loading,
    id: id,
  };
};

const actions = {
  addRpp,
  uploadAttachment,
};

class Add extends Component {
  state = {
    title: '',
    description: ' ',
    attached: [],
  };

  componentDidMount = () => {
    this.setState({
      title: 'RPP ' + format(new Date(), 'yy.MM.d.hh.mm.ss'),
      description: ' ',
      attached: '',
    });
  };

  render() {
    const {
      auth,
      loading,
      history,
      openModal,
      id,
      addRpp,
      uploadAttachment,
    } = this.props;
    return (
      <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
        <div className='p-1'>
          <div className='columns is-variable'>
            <div className='column is-fullwidth'>
              <div className='card'>
                <header className='card-header has-background-white has-text-info'>
                  <div
                    className='custom-card-flex is-size-6'
                    style={{ width: '100%' }}
                  >
                    <p>
                      <i className='mdi mdi-notebook-multiple is-size-5 icon' />{' '}
                      RPP
                    </p>
                  </div>
                </header>
                <AddForm
                  auth={auth}
                  loading={loading}
                  history={history}
                  openModal={openModal}
                  id={id}
                  initialValues={this.state}
                  addRpp={addRpp}
                  uploadAttachment={uploadAttachment}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(mapState, actions)(Add));
