import {
  ALL_TES_GET,
  TES_ADD,
  TES_GET,
  TES_EDIT,
  TES_DELETE,
  TES_RESET_SEARCH,
} from './reduxConstant';

export const allTesGet = (allTes) => {
  return {
    type: ALL_TES_GET,
    payload: {
      allTes,
    },
  };
};

export const tesAdd = (tes) => {
  return {
    type: TES_ADD,
    payload: {
      tes,
    },
  };
};

export const tesGet = (tes) => {
  return {
    type: TES_GET,
    payload: {
      tes,
    },
  };
};

export const tesEdit = (tes) => {
  return {
    type: TES_EDIT,
    payload: {
      tes,
    },
  };
};

export const tesDelete = (tes) => {
  return {
    type: TES_DELETE,
    payload: {
      tes,
    },
  };
};

export const tesResetSearch = () => {
  return {
    type: TES_RESET_SEARCH,
    payload: {},
  };
};
