import {
  asyncActionFinish,
  asyncActionError,
} from '../../../../app/common/reduxs/async/asyncActions';
import { ASYNC_ACTION_START } from '../../../../app/common/reduxs/async/asyncConstant';
import { checkErr } from '../../../../app/common/helpers/checkRes';
import {
  fetchFunc,
  fetchFuncWa,
} from '../../../../app/common/helpers/fetchHelpers';
import {
  whatsappsGet,
  whatsappEdit,
  whatsappGet,
  whatsappUploadFile,
  whatsappStatus,
  whatsappInfo,
  whatsappDelete,
} from './reduxAction';
import { logsFunc } from '../../../../app/common/helpers/othersHelpers';
import { toastr } from 'react-redux-toastr';

export const getWhatsapps = (
  auth,
  history,
  itemPerPage,
  currentPage,
  searchText
) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getWhatsapps' });
    const formData = new FormData();
    formData.append('itemPerPage', itemPerPage ? itemPerPage : 10);
    formData.append('currentPage', currentPage);
    formData.append('searchText', searchText);
    try {
      const res = await fetchFunc(
        'gets',
        auth,
        history,
        'whatsapp/',
        formData,
        dispatch
      );
      const items = res.items;
      const total = res.total;
      dispatch(whatsappsGet({ total, items }));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const getWhatsapp = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getWhatsapp' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'get',
        auth,
        history,
        'whatsapp/view/' + id,
        formData,
        dispatch
      );
      const whatsapp = res.message;
      dispatch(whatsappGet(whatsapp));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const addWhatsapp = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'addWhatsapp' });
    let logs = logsFunc('add', auth.userid, auth.username);
    const formData = new FormData();
    formData.append('id', values.id);
    formData.append('title', values.title.trim());
    formData.append('year', values.year);
    formData.append('events', JSON.stringify(values.events));
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'add',
        auth,
        history,
        'whatsapp/add/' + values.id,
        formData,
        dispatch
      );
      const whatsapp = res.message;
      dispatch(whatsappGet(whatsapp));
      history.push({
        pathname: '/pesan/whatsapp/edit/' + whatsapp.id,
        state: { detail: whatsapp },
      });
      toastr.success('Success', 'Pesan berhasil disimpan.');
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const uploadFileWhatsapp = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'uploadFileWhatsapp' });
    let logs = logsFunc('add', auth.userid, auth.username);
    let file = values.file;
    dispatch(
      whatsappUploadFile({
        studentPesanId: values.studentPesanId,
        filename: file.name,
        filesize: file.size,
        filetype: file.type,
        status: 'start',
      })
    );
    const formData = new FormData();
    formData.append('id', values.id);
    formData.append('studentPesanId', values.studentPesanId);
    formData.append('filename', file.name);
    formData.append('filesize', file.size);
    formData.append('filetype', file.type);
    formData.append('file', values.file);
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'uploadAttach',
        auth,
        history,
        'whatsapp/upload-file/' + values.id,
        formData,
        dispatch
      );
      if (!res) {
        dispatch(asyncActionFinish());
        return;
      }
      dispatch(whatsappGet(res.message));
      dispatch(
        whatsappUploadFile({
          studentPesanId: values.studentPesanId,
          filename: file.name,
          filesize: file.size,
          filetype: file.type,
          status: 'finish',
        })
      );
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const editWhatsapp = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'editWhatsapp' });
    let logs = logsFunc('edit', auth.userid, auth.username, values.logs);
    const formData = new FormData();
    formData.append('id', values.id);
    formData.append('title', values.title);
    formData.append('year', values.year);
    formData.append('events', JSON.stringify(values.events));
    formData.append('logs', logs);
    formData.append('status', values.status);
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'whatsapp/edit/' + values.id,
        formData,
        dispatch
      );
      const whatsapp = res.message;
      dispatch(whatsappEdit(whatsapp));
      history.push({
        pathname: '/pesan/whatsapp/edit/' + whatsapp.id,
        state: { detail: whatsapp },
      });
      toastr.success('Success', 'Pesan berhasil disimpan.');
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const statusWhatsapp = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'statusWhatsapp' });
    const formData = new FormData();
    try {
      const res = await fetchFuncWa(
        'edit',
        auth,
        history,
        'api/whatsapp/status',
        formData,
        dispatch
      );
      const status = res.whatsappStatus;
      const qrCodeUrl = res.qrCodeUrl;
      const info = res.info;
      console.log(res);
      if (status) {
        dispatch(whatsappStatus({ status, info, qrCodeUrl }));
      }
      dispatch(asyncActionFinish());
    } catch (err) {
      console.log(err);
      // checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const infoWhatsapp = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'infoWhatsapp' });
    const formData = new FormData();
    try {
      const res = await fetchFuncWa(
        'get',
        auth,
        history,
        'api/whatsapp/info',
        formData,
        dispatch
      );
      const info = res.info;
      console.log(res);
      if (info) {
        dispatch(whatsappInfo({ info }));
      }
      dispatch(asyncActionFinish());
    } catch (err) {
      console.log(err);
      // checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const importWhatsapp = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'importWhatsapp' });
    try {
      let logs = logsFunc('import', auth.userid, auth.username, null);
      const id = values.importId;
      const formData = new FormData();
      formData.append('id', id);
      formData.append('title', values.title);
      formData.append('description', values.description);
      formData.append('messages', JSON.stringify(values.messages));
      formData.append('recipients', JSON.stringify(values.recipients));
      formData.append('logs', logs);
      const res = await fetchFunc(
        'import',
        auth,
        history,
        'whatsapp/import/' + id,
        formData,
        dispatch
      );
      if (res && res.status === 'success') {
        history.push('/pesan/whatsapp/' + id);
      }
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const deleteFileWhatsapp = (auth, history, data, file) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'deleteFileWhatsapp' });
    let logs = logsFunc('edit', auth.userid, auth.username, data.logs);
    const formData = new FormData();
    formData.append('studentPesanId', data.studentPesanId);
    formData.append('filelink', file.filelink);
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'deleteFile',
        auth,
        history,
        'whatsapp/delete-file/' + data.id,
        formData,
        dispatch
      );
      const whatsapp = res.message;
      dispatch(whatsappEdit(whatsapp));
      history.push('/pesan/whatsapp/' + data.id);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const deleteWhatsapp = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'deleteWhatsapp' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'delete',
        auth,
        history,
        'whatsapp/delete/' + id,
        formData,
        dispatch
      );
      const whatsapp = res.message;
      dispatch(whatsappDelete(whatsapp));
      history.push('/pesan/whatsapp');
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};
