import React from 'react';

const SelectInput = ({
  input,
  label,
  type,
  placeholder,
  multiple,
  options,
  fullwidth,
  disabled,
  readOnly,
  style,
  defaultValue,
  meta: { touched, error },
}) => {
  return (
    <div className='field'>
      {label && label !== 'noLabel' && (
        <label className='label' htmlFor={input.name}>
          {label}
        </label>
      )}
      <div
        className={
          touched && !!error
            ? 'control is-danger is-expanded'
            : 'control is-expanded'
        }
      >
        <div
          className={`select${fullwidth === true ? ' is-fullwidth' : ''}${
            readOnly ? ' cursor-text' : ''
          }`}
        >
          {defaultValue && (
            <select
              id={input.name}
              defaultValue={defaultValue}
              onChange={input.onChange}
              placeholder={placeholder}
              multiple={multiple}
              style={
                readOnly
                  ? {
                      ...style,
                      cursor: 'text',
                      backgroundColor: 'white',
                      borderColor: '#dbdbdb',
                      pointerEvents: 'none',
                    }
                  : style
              }
            >
              <option value=''>{placeholder}</option>
              {options &&
                options.map((item) => (
                  <option key={item.key} value={item.value} style={style}>
                    {item.text}
                  </option>
                ))}
            </select>
          )}
          {!defaultValue && (
            <select
              id={input.name}
              value={input.value}
              onChange={input.onChange}
              placeholder={placeholder}
              multiple={multiple}
              style={
                readOnly
                  ? {
                      ...style,
                      cursor: 'text',
                      backgroundColor: 'white',
                      borderColor: '#dbdbdb',
                      pointerEvents: 'none',
                    }
                  : style
              }
            >
              <option value=''>{placeholder}</option>
              {options &&
                options.map((item) => (
                  <option key={item.key} value={item.value} style={style}>
                    {item.text}
                  </option>
                ))}
            </select>
          )}
        </div>
        {touched && error && <p className='help is-danger'>{error}</p>}
      </div>
    </div>
  );
};

export default SelectInput;
