import {
  searchJenjangChange,
  searchTingkatChange,
  searchKelasChange,
  searchPelajaranChange,
  searchYearsChange,
  searchSemsChange,
  searchFormDelete,
} from './reduxAction';
import { ASYNC_ACTION_START } from '../async/asyncConstant';
import { asyncActionFinish, asyncActionError } from '../async/asyncActions';
import { fetchFunc } from '../../helpers/fetchHelpers';
import { checkErr } from '../../helpers/checkRes';

export const changeSearchJenjang = (auth, history) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'changeSearchJenjang' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'get',
        auth,
        history,
        'jenjang/all/',
        formData,
        dispatch
      );
      const jenjangs = res.jenjangs;
      dispatch(searchJenjangChange(jenjangs));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const changeSearchTingkat = (auth, history) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'changeSearchTingkat' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'get',
        auth,
        history,
        'tingkat/all/',
        formData,
        dispatch
      );
      const tingkats = res.tingkats;
      dispatch(searchTingkatChange(tingkats));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const changeSearchKelas = (auth, history) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'changeSearchKelas' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'get',
        auth,
        history,
        'kelas/all/',
        formData,
        dispatch
      );
      const kelass = res.kelass;
      dispatch(searchKelasChange(kelass));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const changeSearchPelajaran = (auth, history) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'changeSearchPelajaran' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'get',
        auth,
        history,
        'pelajaran/all/',
        formData,
        dispatch
      );
      const pelajarans = res.pelajarans;
      dispatch(searchPelajaranChange(pelajarans));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const changeSearchYears = (auth, history) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'changeSearchYears' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'get',
        auth,
        history,
        'year/all/',
        formData,
        dispatch
      );
      const years = res.years;
      dispatch(searchYearsChange(years));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const changeSearchSems = (auth, history) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'changeSearchSems' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'get',
        auth,
        history,
        'sem/all/',
        formData,
        dispatch
      );
      const sems = res.sems;
      dispatch(searchSemsChange(sems));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const deleteSearchForm = () => {
  return async (dispatch) => {
    dispatch(searchFormDelete());
  };
};
