import React from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import ViewReviewForm from './ViewReviewForm';
import PullDownWrapper from '../../../app/common/layout/PullDownWrapper';

function mapState(state, ownProps) {
  const id = ownProps.match.params.id;
  let scope = {};
  if (state.auth && state.auth.isAuth) {
    scope = state.auth.authorities;
  }
  return {
    auth: state.auth,
    loading: state.async.loading,
    scope: scope,
    id: id,
  };
}

function ViewReview(props) {
  let history = useHistory();
  let location = useLocation();
  let data = location.state;
  let keyAnswers = data && data.keyAnswers;
  const { auth, id } = props;

  const onFormSubmit = (values) => {};

  // const handleLink = useCallback(
  //   (url) => {
  //     history.push(url);
  //   },
  //   [history]
  // );

  return (
    <>
      <PullDownWrapper auth={auth}>
        <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
          <div className='p-1'>
            <div className='columns is-variable is-desktop'>
              <div className='column is-12'>
                <div className='card'>
                  <header className='card-header has-background-white has-text-info'>
                    <div className='custom-card-flex' style={{ width: '100%' }}>
                      <p>
                        <i className='is-size-5 mdi mdi-bookshelf icon' />{' '}
                        Pembelajaran
                        <i className='mdi mdi-menu-right is-size-6 icon' />
                        Tugas
                        <i className='mdi mdi-menu-right is-size-6 icon' />
                        Review
                      </p>
                    </div>
                  </header>
                  <div className='card-content ml-2 mr-2'>
                    <ViewReviewForm
                      auth={auth}
                      history={history}
                      id={id}
                      initialValues={data}
                      keyAnswers={keyAnswers}
                      onFormSubmit={onFormSubmit}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PullDownWrapper>
    </>
  );
}

export default connect(mapState, null)(ViewReview);
