import {
  CALENDARS_GET,
  CALENDAR_GET,
  CALENDAR_ADD,
  CALENDAR_EDIT,
  CALENDAR_STATUS,
  CALENDAR_DELETE,
  CALENDAR_IMPORT,
  CALENDAR_YEARS_GET
} from './reduxConstant';

export const calendarsGet = (calendars) => {
  return {
    type: CALENDARS_GET,
    payload: {
      calendars,
    },
  };
};

export const calendarAdd = (calendar) => {
  return {
    type: CALENDAR_ADD,
    payload: {
      calendar,
    },
  };
};

export const calendarEdit = (calendar) => {
  return {
    type: CALENDAR_EDIT,
    payload: {
      calendar,
    },
  };
};

export const calendarGet = (calendar) => {
  return {
    type: CALENDAR_GET,
    payload: {
      calendar,
    },
  };
};

export const calendarStatus = (calendar) => {
  return {
    type: CALENDAR_STATUS,
    payload: {
      calendar,
    },
  };
};

export const calendarDelete = (calendar) => {
  return {
    type: CALENDAR_DELETE,
    payload: {
      calendar,
    },
  };
};

export const calendarImport = (calendar) => {
  return {
    type: CALENDAR_IMPORT,
    payload: {
      calendar,
    },
  };
};

export const calendarYearsGet = (years) => {
  return {
    type: CALENDAR_YEARS_GET,
    payload: {
      years,
    },
  };
};
