import {
  asyncActionFinish,
  asyncActionError,
} from '../../../../app/common/reduxs/async/asyncActions';
import {
  yearsGet,
  yearAdd,
  yearEdit,
  yearGet,
  yearDelete,
} from './reduxAction';
import { ASYNC_ACTION_START } from '../../../../app/common/reduxs/async/asyncConstant';
import { checkErr } from '../../../../app/common/helpers/checkRes';
import { fetchFunc } from '../../../../app/common/helpers/fetchHelpers';

export const getYears = (
  auth,
  history,
  itemPerPage,
  currentPage,
  searchText
) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getYears' });
    const formData = new FormData();
    formData.append('itemPerPage', itemPerPage ? itemPerPage : 10);
    formData.append('currentPage', currentPage);
    formData.append('searchText', searchText);
    try {
      const res = await fetchFunc(
        'gets',
        auth,
        history,
        'year/',
        formData,
        dispatch
      );
      const items = res.items;
      const total = res.total;
      const search = {
        currentPage,
        searchText,
        itemPerPage: itemPerPage ? itemPerPage : 10,
      };
      dispatch(yearsGet({ total, items, search }));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const addYear = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'addYear' });
    const formData = new FormData();
    formData.append('code', values.code);
    formData.append('title', values.title);
    formData.append('description', values.description);
    try {
      const res = await fetchFunc(
        'add',
        auth,
        history,
        'year/add/',
        formData,
        dispatch
      );
      const year = res.year;
      dispatch(yearAdd(year));
      history.push('/akademik/year/' + year.id);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const getYear = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getYear' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'get',
        auth,
        history,
        'year/detail/' + id,
        formData,
        dispatch
      );
      const year = res.year;
      dispatch(yearGet(year));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const editYear = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'editYear' });
    const formData = new FormData();
    formData.append('code', values.code);
    formData.append('title', values.title);
    formData.append('description', values.description ? values.description : undefined);
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'year/edit/' + values.id,
        formData,
        dispatch
      );
      const year = res.year;
      dispatch(yearEdit(year));
      history.push('/akademik/year/' + values.id);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const statusYear = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'statusYear' });
    const formData = new FormData();
    formData.append('status', values.status);
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'year/status/' + values.id,
        formData,
        dispatch
      );
      const year = res.year;
      dispatch(yearEdit(year));
      history.push('/akademik/year/' + values.id);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const deleteYear = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'deleteYear' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'delete',
        auth,
        history,
        'year/delete/' + id,
        formData,
        dispatch
      );
      const year = res.year;
      dispatch(yearDelete(year));
      history.push('/akademik/year');
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};
