import React, { useEffect } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { getCalendar, editCalendar, getCalendarYears } from './redux/reduxApi';
import EditForm from './EditForm';
import { openModal } from '../../../app/modals/redux/modalActions';

const mapState = (state, ownProps) => {
  const id = ownProps.match.params.id;
  let scope = {};
  if (state?.auth?.isAuth) {
    scope = state?.auth?.authorities;
  }
  return {
    loading: state.async.loading,
    auth: state.auth,
    scope: scope,
    id: id,
    calendars: state.calendars,
  };
};

const actions = {
  openModal,
  getCalendar,
  editCalendar,
  getCalendarYears,
};

function Edit(props) {
  const {
    auth,
    history,
    loading,
    openModal,
    id,
    calendars,
    editCalendar,
    getCalendarYears,
  } = props;
  let location = useLocation();
  let dataCalendar = location && location.state;

  useEffect(() => {
    getCalendarYears(auth, history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log(calendars);

  return (
    <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
      <div className='p-1'>
        <div className='columns is-variable'>
          <div className='column is-fullwidth'>
            <div className='card'>
              <header className='card-header has-background-white has-text-info'>
                <div
                  className='custom-card-flex is-size-6'
                  style={{ width: '100%' }}
                >
                  <p>
                    <i className='mdi mdi-calendar-month is-size-5 icon' />{' '}
                    Kalender
                    <i className='mdi mdi-menu-right is-size-6 icon' />
                    Edit
                  </p>
                </div>
              </header>
              <div className='card-content pl-4 pr-4'>
                <EditForm
                  initialValues={dataCalendar && dataCalendar.detail}
                  years={calendars?.years}
                  loading={loading}
                  auth={auth}
                  id={id}
                  history={history}
                  openModal={openModal}
                  editCalendar={editCalendar}
                  getCalendarYears={getCalendarYears}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(connect(mapState, actions)(Edit));
