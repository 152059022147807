import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getTugas, editTugas, uploadImage } from './redux/reduxApi';
import {
  changeSessionState,
  deleteSessionState,
} from '../../../app/common/reduxs/session/reduxApi';
import EditForm from './EditForm';
import { openModal } from '../../../app/modals/redux/modalActions';

const mapState = (state, ownProps) => {
  const id = ownProps.match.params.id;
  let sesState = {};
  if (state.sessionState && state.sessionState[0]) {
    sesState = state.sessionState.filter((i) => i.id + '' === id + '')[0];
  }
  let tugas = {};
  let uploadFiles = [];
  if (state.tugass && state.tugass.items && state.tugass.items[0]) {
    tugas = state.tugass.items.filter((i) => i.id + '' === id + '')[0];
    uploadFiles = state.tugass.uploadFiles;
  }
  return {
    auth: state.auth,
    loading: state.async.loading,
    id: id,
    sesState: sesState,
    tugas: tugas,
    uploadFiles: uploadFiles,
  };
};

const actions = {
  changeSessionState,
  deleteSessionState,
  getTugas,
  editTugas,
  uploadImage,
  openModal,
};

function Edit(props) {
  const {
    auth,
    history,
    id,
    sesState,
    tugas,
    uploadFiles,
    getTugas,
    editTugas,
    uploadImage,
    openModal,
  } = props;
  const [state, setState] = useState({});

  useEffect(() => {
    getTugas(auth, history, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let questions = tugas?.questions ? JSON.parse(tugas.questions) : [];
    let answers = tugas?.answers ? JSON.parse(tugas.answers) : [];
    setState({
      title: tugas.title ?? '',
      description: tugas?.description,
      questions: questions,
      answers: answers,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFormSubmit = async (values) => {
    try {
      const data = { ...values };
      editTugas(auth, history, data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
      <div className='p-1'>
        <div className='columns is-variable'>
          <div className='column is-fullwidth'>
            <div className='card'>
              <header className='card-header has-background-white has-text-info'>
                <div
                  className='custom-card-flex is-size-6'
                  style={{ width: '100%' }}
                >
                  <p>
                    <i className='mdi mdi-bookshelf is-size-5 icon' />{' '}
                    Pembelajaran
                    <i className='mdi mdi-menu-right is-size-6 icon' />
                    Tugas
                  </p>
                </div>
              </header>
              <div className='card-content pl-4 pr-4'>
                <EditForm
                  auth={auth}
                  history={history}
                  openModal={openModal}
                  id={id}
                  initialValues={state}
                  uploadFiles={uploadFiles}
                  sesState={sesState}
                  changeSessionState={changeSessionState}
                  deleteSessionState={deleteSessionState}
                  editTugas={editTugas}
                  uploadImage={uploadImage}
                  onFormSubmit={onFormSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(connect(mapState, actions)(Edit));
