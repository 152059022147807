import {
  LATIHANS_GET,
  LATIHAN_ADD,
  LATIHAN_GET,
  LATIHAN_EDIT,
  LATIHAN_UPLOADFILE,
  LATIHAN_DELETE,
  LATIHAN_RESET_SEARCH,
} from './reduxConstant';

export const latihansGet = (latihans) => {
  return {
    type: LATIHANS_GET,
    payload: {
      latihans,
    },
  };
};

export const latihanAdd = (latihan) => {
  return {
    type: LATIHAN_ADD,
    payload: {
      latihan,
    },
  };
};

export const latihanGet = (latihan) => {
  return {
    type: LATIHAN_GET,
    payload: {
      latihan,
    },
  };
};

export const latihanEdit = (latihan) => {
  return {
    type: LATIHAN_EDIT,
    payload: {
      latihan,
    },
  };
};

export const latihanUploadFile = (uploadFile) => {
  return {
    type: LATIHAN_UPLOADFILE,
    payload: {
      uploadFile,
    },
  };
};

export const latihanDelete = (latihan) => {
  return {
    type: LATIHAN_DELETE,
    payload: {
      latihan,
    },
  };
};

export const latihanResetSearch = () => {
  return {
    type: LATIHAN_RESET_SEARCH,
    payload: {},
  };
};
