import React, { useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getPortaluser, photoUploadPortaluser } from './redux/reduxApi';
import ViewForm from './ViewForm.jsx';
import { openModal } from '../../../app/modals/redux/modalActions';
import Photo from './Photo';
import { SITE_ADDRESS } from '../../../app/common/data/siteConfig';
import ReactTooltip from 'react-tooltip';
import defaultPhoto from '../../../pictures/user-default.png'

const mapState = (state, ownProps) => {
  const id = ownProps.match.params.id;
  let scope = {};
  if (state?.auth?.isAuth) {
    scope = state?.auth?.authorities;
  }
  let portaluser = {};
  if (state?.portalusers?.items && state?.portalusers?.items[0]) {
    portaluser = state?.portalusers?.items?.filter((i) => i.id + '' === id)[0];
  }
  return {
    id: id,
    loading: state.async.loading,
    auth: state.auth,
    scope: scope,
    portaluser: portaluser,
  };
};

const actions = {
  getPortaluser,
  photoUploadPortaluser,
  openModal,
};

function View(props) {
  const {
    auth,
    loading,
    history,
    location,
    scope,
    portaluser,
    getPortaluser,
    photoUploadPortaluser,
    id,
    openModal,
  } = props;
  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    getPortaluser(auth, history, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setInitialValues({ ...portaluser });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portaluser]);

  const onFormSubmit = (values) => {};

  const description =
    initialValues?.description && JSON.parse(initialValues?.description);
  const profile = description?.profile;

  return (
    <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
      <div className='p-1'>
        <div className='columns is-variable is-desktop'>
          <div className='column is-12'>
            <div className='card'>
              <header className='card-header has-background-white has-text-info'>
                <div
                  className='custom-card-flex is-size-6'
                  style={{ width: '100%' }}
                >
                  <p>
                    <i className='mdi mdi-account-group is-size-6 icon' />{' '}
                    Portal
                    <i className='mdi mdi-menu-right is-size-6 icon' />
                    <Link className='has-text-info' to='/portal/portaluser'>
                      User
                    </Link>
                  </p>
                </div>
              </header>
              <div className='card-content pl-4 pr-4'>
                <div className='level mb-1'>
                  <div className='level-item'>
                    <div data-for='main' data-tip='Ukuran maks. 200Kb'>
                      <Photo
                        site={SITE_ADDRESS}
                        auth={auth}
                        profile={profile ?? {}}
                        defaultPhoto={defaultPhoto}
                        loading={loading}
                        history={history}
                        photoUpload={photoUploadPortaluser}
                      />
                    </div>
                    <ReactTooltip
                      id='main'
                      place='bottom'
                      type='dark'
                      effect='solid'
                      multiline={true}
                    />
                  </div>
                </div>
                <div className='level mb-3'>
                  <div className='level-item'>
                    <p className='title is-6 is-capitalized'>{description?.name}</p>
                  </div>
                </div>
                <ViewForm
                  auth={auth}
                  history={history}
                  pathname={location?.pathname}
                  id={id}
                  initialValues={initialValues}
                  onFormSubmit={onFormSubmit}
                  openModal={openModal}
                  scope={scope}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(connect(mapState, actions)(View));
