import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  getPesanpribadi,
  getPesanpribadiPortalusers,
  sendPesanpribadi,
  uploadFilePesanpribadi,
  reportPesanpribadi,
} from './redux/reduxApi';
import ViewForm from './ViewForm';
import { openModal } from '../../../app/modals/redux/modalActions';

const mapState = (state, ownProps) => {
  const id = ownProps.match.params.id;
  let pesanpribadis = state.pesanpribadis;
  let reports = pesanpribadis.reports;
  let uploadFiles = pesanpribadis.uploadFiles;
  return {
    loading: state.async.loading,
    auth: state.auth,
    messageId: id,
    pesanpribadis: pesanpribadis,
    reports,
    uploadFiles,
  };
};

const actions = {
  openModal,
  getPesanpribadi,
  getPesanpribadiPortalusers,
  sendPesanpribadi,
  uploadFilePesanpribadi,
  reportPesanpribadi,
};

function View(props) {
  const {
    messageId,
    auth,
    history,
    openModal,
    pesanpribadis,
    reports,
    uploadFiles,
    getPesanpribadi,
    getPesanpribadiPortalusers,
    sendPesanpribadi,
    uploadFilePesanpribadi,
    reportPesanpribadi,
  } = props;

  let portalusersOption = pesanpribadis && pesanpribadis.portalusers;
  let pesanpribadi =
    pesanpribadis && pesanpribadis.items && pesanpribadis.items.length > 0
      ? pesanpribadis.items.find((i) => i.id + '' === messageId)
      : {};
  let files = pesanpribadi?.files && JSON.parse(pesanpribadi.files);
  const getReport = () => {
    reportPesanpribadi(auth, history, messageId);
  };

  useEffect(() => {
    getPesanpribadi(auth, history, messageId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getPesanpribadiPortalusers(auth, history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
      <div className='p-1'>
        <div className='columns is-variable'>
          <div className='column is-fullwidth'>
            <div className='card'>
              <header className='card-header has-background-white has-text-info'>
                <div
                  className='custom-card-flex is-size-6'
                  style={{ width: '100%' }}
                >
                  <p>
                    <i className='mdi mdi-account-group is-size-5 icon' />
                    Portal
                    <i className='mdi mdi-menu-right is-size-6 icon' />
                    Pesan Pribadi
                  </p>
                </div>
              </header>
              <div className='card-content pl-4 pr-4'>
                <ViewForm
                  messageId={messageId}
                  auth={auth}
                  history={history}
                  initialValues={pesanpribadi}
                  files={files}
                  uploadFiles={uploadFiles}
                  openModal={openModal}
                  portalusersOption={portalusersOption}
                  sendPesanpribadi={sendPesanpribadi}
                  uploadFilePesanpribadi={uploadFilePesanpribadi}
                  getReport={getReport}
                  reports={reports}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(connect(mapState, actions)(View));
