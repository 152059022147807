import React from 'react';
import { Link } from 'react-router-dom';
import TextInput from '../../../app/common/form/TextInput';
import DateInput from '../../../app/common/form/DateInput';
import TextArea from '../../../app/common/form/TextArea';
import { reduxForm, Field } from 'redux-form';
import { convertSize } from '../../../app/common/helpers/othersHelpers';

function ViewForm(props) {
  const { auth, history, scope, openModal, id, initialValues, handleSubmit } =
    props;

  let file =
    initialValues && initialValues.file && JSON.parse(initialValues.file);

    const onDeleteFile = (e, studentMessageId, file) => {
      e.preventDefault();
      const values = {
        announcementId: id,
        logs: initialValues?.logs,
      };
      openModal('AnnouncementFileDelete', {
        data: {
          auth,
          history,
          item: values,
          file,
        },
      });
    };

  const renderFileInput = (props) => {
    const { file } = props;
    return (
      <div className='field'>
        {file && (
          <div
            className='has-text-link has-background-grey-lighter px-2 py-1 mb-1 flex justify-between align-center mt-1'
            style={{ maxWidth: 500 }}
          >
            <span className='custom-text-overflow '>
              {file?.filename} ({convertSize(file.filesize)})
            </span>
            <button
              onClick={(e) => onDeleteFile(e, id, file)}
              className='delete'
            ></button>
          </div>
        )}
      </div>
    );
  };

  const onClickDelete = () => {
    openModal('AnnouncementDelete', {
      data: {
        auth,
        history,
        item: initialValues,
      },
    });
  };

  const handleClickSubmit = () => {};

  return (
    <>
      <div className='level'>
        <div className='level-left'>
          <div className='level-item'>
            <nav className='breadcrumb is-size-6' aria-label='breadcrumbs'>
              <ul>
                <li className='is-active has-text-weight-bold'>
                  <Link to={'/portal/pengumuman/' + id}>Detail</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className='level-right'>
          <div className='level-item'>
            <div className='buttons'>
              {scope && scope.subm && scope.subm['announcement_edit'] && (
                <Link
                  to={`/portal/pengumuman/edit/${id}`}
                  className='button is-small is-rounded is-info is-outlined'
                >
                  <i className='is-size-6 mdi mdi-pencil icon' />
                </Link>
              )}
              {scope && scope.subm && scope.subm['announcement_delete'] && (
                <button
                  className='button is-small is-rounded is-danger is-outlined'
                  onClick={() => onClickDelete()}
                >
                  <i className='is-size-6 mdi mdi-delete icon' />
                </button>
              )}
              <Link
                type='button'
                to={'/portal/pengumuman'}
                className='button custom-grey is-small is-rounded is-outlined'
              >
                <i className='is-size-6 mdi mdi-arrow-left icon' />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='columns'>
        <div className='column is-third-quarter'>
          <form onSubmit={handleSubmit(handleClickSubmit)} autoComplete='off'>
            <Field
              name='title'
              type='text'
              component={TextInput}
              defaultValue={initialValues?.title ?? ''}
              placeholder='Judul Pengumuman'
              label='Judul Pengumuman'
              readOnly={true}
            />
            <Field
              name='date'
              type='text'
              component={DateInput}
              label='Tanggal'
              placeholder='2000/05/21'
            />
            <Field
              name='description'
              type='text'
              component={TextArea}
              defaultValue={initialValues?.description ?? ''}
              placeholder='Deskripsi'
              label='Deskripsi'
              readOnly={true}
            />
            <Field
              name='attachment'
              type='file'
              component={renderFileInput}
              placeholder='File'
              label='File Attachement'
              file={file || ''}
            />
          </form>
        </div>
      </div>
    </>
  );
}

export default reduxForm({
  form: 'announcementView',
  enableReinitialize: true,
})(ViewForm);
