import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import ImportFileInput from '../../../app/common/layout/ImportFileInput';
import { importCalendar } from './redux/reduxApi';
import TextInput from '../../../app/common/form/TextInput';
import SelectInput from '../../../app/common/form/SelectInput';
import { getCalendarYears } from './redux/reduxApi';
import { nanoid } from 'nanoid';

const mapState = (state) => ({
  auth: state.auth,
  loading: state.async.loading,
  calendars: state.calendars,
});

const actions = {
  importCalendar,
  getCalendarYears,
};

class Import extends Component {
  state = {
    inputKey: Date.now(),
    tl: 'Calendar',
    importData: [],
    importResult: [],
    numberOfEvents: 0,
    title: ' ',
    year: '',
    headerCols: [
      'No',
      'Title',
      'Type',
      'Start Date',
      'Start Time',
      'End Date',
      'End Time',
      'Description',
    ],
  };

  componentDidMount() {
    const { auth, history, getCalendarYears } = this.props;
    getCalendarYears(auth, history);
  }

  handleExcelUpload = (e) => {
    let file = e.target.files[0];

    const reader = new FileReader();
    if (file) {
      reader.readAsArrayBuffer(file);

      reader.onload = (e) => {
        let sheetData = new Uint8Array(reader.result);
        let wb = XLSX.read(sheetData, { type: 'array' });

        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        // Read excel to JSON
        const data = XLSX.utils.sheet_to_json(ws, { raw: false });

        let importData = [];
        let numberOfEvents = 0;
        for (let i = 0; i < data.length; i++) {
          const el = data[i];
          if (el.Title && el.Title.length > 2) {
            importData.push(el);
            numberOfEvents = numberOfEvents + 1;
          }
        }

        this.setState({
          ...this.state,
          importData: importData,
          numberOfEvents: numberOfEvents,
        });
      };
    }
  };

  getFormatDate = (date) => {
    let arrDate = date.split('/');
    let formatedDate = `${arrDate[2]}/${
      (arrDate[1] + '').length > 1 ? arrDate[1] : '0' + arrDate[1]
    }/${(arrDate[0] + '').length > 1 ? arrDate[0] : '0' + arrDate[0]}`;
    return formatedDate;
  };

  onImport = () => {
    const { auth, history, importCalendar } = this.props;
    const { importData, numberOfEvents, title, year } = this.state;
    let formatedEvent = [];
    for (let i = 0; i < importData.length; i++) {
      const item = importData[i];
      if (!item.Title || !item['Start Date']) {
        return;
      }
      let startDate = this.getFormatDate(item['Start Date']);
      let startTime = item['Start Time']
        ? item['Start Time'].split(' ').join('')
        : '';
      let endDate = item['End Date']
        ? this.getFormatDate(item['End Date'])
        : '';
      let endTime = item['End Time']
        ? item['End Time'].split(' ').join('')
        : '';
      let newObj = {
        id: nanoid(32),
        subject: item.Title,
        startDate: startDate,
        startTime: startTime,
        endDate: endDate,
        endTime: endTime,
        description: item.Description || '',
        type: item.Type,
        allDayEvent: false,
        createdBy: auth.username,
      };
      if (item.type === 'libur') {
        newObj.allDayEvent = true;
      }
      if (!newObj.endDate) {
        newObj.endTime = '';
      }
      if (!newObj.startTime) {
        newObj.allDayEvent = true;
      }
      formatedEvent = [...formatedEvent, newObj];
    }
    const values = {
      data: formatedEvent,
      numberOfEvents: numberOfEvents,
      title: title.trim(),
      year: year,
    };
    importCalendar(auth, history, values);
  };

  onChangeInput = (e, key) => {
    let val = e.target.value;
    if (val.length === 0) {
      val = ' ';
    }
    if (key === 'title') {
      this.setState({
        ...this.state,
        title: val,
      });
    } else if (key === 'year') {
      this.setState({
        ...this.state,
        year: val,
      });
    }
  };

  onCancel = () => {
    this.setState({
      inputKey: Date.now(),
      tl: 'Kalender',
      importData: [],
      importResult: [],
      numberOfEvents: 0,
      title: ' ',
      year: '',
    });
  };

  onGoBack = () => {
    const { history } = this.props;
    this.onCancel();
    history.push({
      pathname: '/calendar',
      state: { viewMode: 'listmode' },
    });
  };

  render() {
    const { loading, progress, calendars, handleSubmit } = this.props;
    const { importData, inputKey, tl } = this.state;

    const years = calendars?.years;
    let yearsSelect = [];
    years &&
      years.forEach((obj) => {
        let newObj = {
          key: obj.id,
          value: obj.code,
          text: obj.title,
        };
        yearsSelect = [...yearsSelect, newObj];
      });

    return (
      <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
        <div className='p-1'>
          <div className='columns is-variable'>
            <div className='column is-fullwidth'>
              <div className='card'>
                <header className='card-header has-background-white has-text-info'>
                  <div
                    className='custom-card-flex is-size-6'
                    style={{ width: '100%' }}
                  >
                    <p>
                      <i className='mdi mdi-calendar-month is-size-5 icon' />{' '}
                      Kalender
                    </p>
                  </div>
                </header>
                <div className='card-content pl-4 pr-4'>
                  <form autoComplete='off' style={{ marginTop: 1 }}>
                    <div className='level'>
                      <div className='level-left'>
                        <div className='level-item'>
                          <nav
                            className='breadcrumb is-size-6'
                            aria-label='breadcrumbs'
                          >
                            <ul>
                              <li className='is-active has-text-weight-bold'>
                                <Link to={'/calendar/import'}>Import</Link>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </div>

                      <div className='level-right'>
                        <div className='level-item'>
                          <div className='buttons'>
                            <>
                              <button
                                disabled={loading}
                                onClick={handleSubmit(this.onImport)}
                                className={
                                  loading
                                    ? 'button is-small is-info is-rounded is-outlined is-loading'
                                    : 'button is-small is-info is-rounded is-outlined'
                                }
                                style={{ marginRight: 10 }}
                              >
                                <i className='is-size-6 mdi mdi-content-save icon' />
                              </button>
                              <button
                                disabled={loading}
                                onClick={this.onCancel}
                                className='button custom-grey is-small is-rounded is-outlined'
                              >
                                <i className='is-size-6 mdi mdi-reload icon' />
                              </button>
                            </>
                            <button
                              disabled={loading}
                              onClick={this.onGoBack}
                              className='button custom-grey is-small is-rounded is-outlined'
                            >
                              <i className='is-size-6 mdi mdi-arrow-left icon' />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {!loading && importData && !importData[0] && (
                      <div className='columns'>
                        <div className='column is-4 is-offset-4'>
                          <div className='field'>
                            <ImportFileInput
                              handleExcelUpload={this.handleExcelUpload}
                              inputKey={inputKey}
                              tl={tl}
                              link={'/templates/Import_Kalender_Template.xlsx'}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {loading && importData && importData[0] && (
                      <div>
                        <h5>Memproses...</h5>
                        <progress
                          className='progress is-small is-info'
                          max='100'
                        >
                          {progress}%
                        </progress>
                      </div>
                    )}
                    {!loading && (
                      <div className='columns'>
                        <div className='column is-12'>
                          {importData && importData[0] && (
                            <>
                              <Field
                                name='title'
                                type='text'
                                defaultValue={this.state.title ?? ' '}
                                onChange={(e) => this.onChangeInput(e, 'title')}
                                component={TextInput}
                                placeholder='Judul Kalender'
                                label='Judul Kalender'
                              />
                              <Field
                                name='year'
                                type='text'
                                component={SelectInput}
                                onChange={(e) => this.onChangeInput(e, 'year')}
                                placeholder='Pilih Tahun Ajaran'
                                label='Tahun Ajaran'
                                options={yearsSelect}
                              />
                              <br />
                            </>
                          )}
                          <div className='table-container'>
                            <table className='table is-fullwidth is-hoverable'>
                              {importData && importData[0] && (
                                <thead className='has-background-info'>
                                  <tr>
                                    <th
                                      colSpan={
                                        this.state &&
                                        this.state.headerCols &&
                                        this.state.headerCols.length
                                      }
                                      className='has-text-white'
                                    >
                                      Event Kalender
                                    </th>
                                  </tr>
                                </thead>
                              )}
                              <thead>
                                {importData && importData[0] && (
                                  <tr>
                                    {this.state &&
                                      this.state.headerCols.map((i, key) => (
                                        <th key={key}>{i}</th>
                                      ))}
                                  </tr>
                                )}
                              </thead>
                              <tbody>
                                {importData && importData[0] && (
                                  <Fragment>
                                    {importData.map((item, index) => {
                                      if (
                                        index + '' ===
                                        this.state.numberOfEvents + ''
                                      ) {
                                        return <tr key={index + 1}></tr>;
                                      }
                                      return (
                                        <Item
                                          item={item}
                                          index={index}
                                          key={index + 1}
                                        />
                                      );
                                    })}
                                  </Fragment>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  mapState,
  actions
)(reduxForm({ form: 'CalendarFormExport' })(Import));

export class Item extends Component {
  render() {
    const { item, index } = this.props;
    let startDate = item?.['Start Date'];
    let arrStartDate = startDate && startDate.split('/');
    let startYear = startDate ? arrStartDate[2] : '';
    let startMonth = startDate ? parseInt(arrStartDate[0]) : '';
    let startDay = startDate ? parseInt(arrStartDate[1]) : '';
    return (
      <tr>
        <td>{item?.No ?? index + 1}</td>
        <td>{item?.Title}</td>
        <td>{item?.Type}</td>
        <td>{`${startDay.length < 2 ? '0' + startDay : startDay}/${
          startMonth.length < 2 ? '0' + startMonth : startMonth
        }/${startYear}`}</td>
        <td>{item?.['Start Time']}</td>
        <td>{item?.['End Date']}</td>
        <td>{item?.['End Time']}</td>
        <td>{item?.Description}</td>
      </tr>
    );
  }
}

export class SuccessItem extends Component {
  render() {
    const { calendar } = this.props;
    return (
      <span
        className='tag is-info is-light'
        style={{
          marginRight: 5,
          marginBottom: 5,
        }}
      >
        {`${calendar.no}`}
      </span>
    );
  }
}
