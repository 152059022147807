import {
  YEARS_GET,
  YEAR_ADD,
  YEAR_EDIT,
  YEAR_GET,
  YEAR_DELETE,
} from './reduxConstant';
import { createReducer } from '../../../../app/common/utilities/reducerUtils';

const initialState = {
  total: 0,
  search: {
    currentPage: '',
    searchText: '',
    itemPerPage: '',
  },
  items: [],
};

const yearsGet = (state, payload) => {
  const years = payload.years;
  return {
    total: years.total,
    items: [...years.items],
    search: { ...years.search },
  };
};

const yearAdd = (state, payload) => {
  return {
    total: parseInt(state.total) + 1,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.year.id + ''),
      payload.year,
    ],
    search: { ...state.search },
  };
};

const yearEdit = (state, payload) => {
  return {
    total: parseInt(state.total),
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.year.id + ''),
      payload.year,
    ],
    search: { ...state.search },
  };
};

const yearGet = (state, payload) => {
  return {
    total: parseInt(state.total),
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.year.id + ''),
      payload.year,
    ],
    search: { ...state.search },
  };
};

const yearDelete = (state, payload) => {
  return {
    total: parseInt(state.total) - 1,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.year.id + ''),
    ],
    search: { ...state.search },
  };
};

export default createReducer(initialState, {
  [YEARS_GET]: yearsGet,
  [YEAR_ADD]: yearAdd,
  [YEAR_EDIT]: yearEdit,
  [YEAR_GET]: yearGet,
  [YEAR_DELETE]: yearDelete,
});
