import {
  SEMS_GET,
  SEM_ADD,
  SEM_EDIT,
  SEM_GET,
  SEM_DELETE,
} from './reduxConstant';

export const semsGet = (sems) => {
  return {
    type: SEMS_GET,
    payload: {
      sems,
    },
  };
};

export const semAdd = (sem) => {
  return {
    type: SEM_ADD,
    payload: {
      sem,
    },
  };
};

export const semEdit = (sem) => {
  return {
    type: SEM_EDIT,
    payload: {
      sem,
    },
  };
};

export const semGet = (sem) => {
  return {
    type: SEM_GET,
    payload: {
      sem,
    },
  };
};

export const semDelete = (sem) => {
  return {
    type: SEM_DELETE,
    payload: {
      sem,
    },
  };
};
