import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { openModal } from '../../../app/modals/redux/modalActions';
import ReactTooltip from 'react-tooltip';
import { statusWhatsapp } from './redux/reduxApi';

const mapState = (state, ownProps) => {
  let whatsappStatus = state && state.whatsapps && state.whatsapps.status;
  return {
    auth: state.auth,
    loading: state.async.loading,
    whatsappStatus: whatsappStatus,
  };
};

const actions = {
  openModal,
  statusWhatsapp,
};

function Setting(props) {
  const { auth, history, loading, whatsappStatus, statusWhatsapp } = props;
  const [state, setState] = useState({
    status: 'initialize',
  });

  useEffect(() => {
    setState({
      ...whatsappStatus,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let getStatus = setInterval(() => {
      statusWhatsapp(auth, history);
    }, 3000);

    //Clean up can be done like this
    return () => {
      clearInterval(getStatus);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log(state);

  const onSave = () => {};

  const onGoBack = () => {
    history.push({
      pathname: '/pesan/whatsapp',
      state: { data: 'nodata' },
    });
  };

  return (
    <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
      <div className='p-1'>
        <div className='columns is-variable'>
          <div className='column is-fullwidth'>
            <div className='card'>
              <header className='card-header has-background-white has-text-info'>
                <div
                  className='custom-card-flex is-size-6'
                  style={{ width: '100%' }}
                >
                  <p>
                    <i className='mdi mdi-message-text-outline is-size-5 icon' />{' '}
                    Pesan
                    <i className='mdi mdi-menu-right is-size-6 icon' />
                    Whatsapp
                  </p>
                </div>
              </header>
              <div className='card-content pl-4 pr-4'>
                <div className='level'>
                  <div className='level-left'>
                    <div className='level-item'>
                      <nav
                        className='breadcrumb is-size-6'
                        aria-label='breadcrumbs'
                      >
                        <ul>
                          <li className='is-active has-text-weight-bold'>
                            <Link to={'/pesan/whatsapp/setting'}>Setting</Link>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>

                  <div className='level-right'>
                    <div className='level-item'>
                      <div className='buttons'>
                        <div data-for='main' data-tip='Restart Server Whatsapp'>
                          <button
                            disabled={loading}
                            onClick={() => onSave()}
                            className={
                              loading
                                ? 'button is-small is-danger is-rounded is-outlined is-loading'
                                : 'button is-small is-danger is-rounded is-outlined'
                            }
                            style={{ marginRight: 10 }}
                          >
                            <i className='is-size-6 mdi mdi-power icon' />
                          </button>
                          <ReactTooltip
                            id='main'
                            place='bottom'
                            type='dark'
                            effect='solid'
                            multiline={true}
                          />
                        </div>
                        <div data-for='main' data-tip='Reset Link Whatsapp'>
                          <button
                            disabled={loading}
                            onClick={() => onSave()}
                            className={
                              loading
                                ? 'button is-small is-danger is-rounded is-outlined is-loading'
                                : 'button is-small is-danger is-rounded is-outlined'
                            }
                            style={{ marginRight: 10 }}
                          >
                            <i className='is-size-6 mdi mdi-reload icon' />
                          </button>
                          <ReactTooltip
                            id='main'
                            place='bottom'
                            type='dark'
                            effect='solid'
                            multiline={true}
                          />
                        </div>
                        <button
                          disabled={loading}
                          onClick={() => onGoBack()}
                          className='button custom-grey is-small is-rounded is-outlined'
                        >
                          <i className='is-size-6 mdi mdi-arrow-left icon' />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='columns is-justify-content-center mt-5 mb-5'>
                  <div className='column is-6'>
                    <div className='card'>
                      {state && state.status === 'QRCode' && (
                        <div className='card-image'>
                          <figure className='image is-square'>
                            <img
                              src={state.qrCodeUrl}
                              alt='Placeholder'
                            />
                          </figure>
                        </div>
                      )}

                      <div className='card-content'>
                        <div className='media has-text-centered'>
                          <div className='media-content'>
                            <p className='title is-4'>{state?.info?.pushname}</p>
                            <p className='subtitle is-6'>{state?.info?.pushname}</p>
                          </div>
                        </div>

                        <div className='content has-text-centered'>
                          <span className='tag is-info'>{state?.status}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(mapState, actions)(Setting);
