import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { getPesanpribadis } from './redux/reduxApi';
import SimpleSearch from '../../../app/common/layout/SimpleSearch';
import PageNumber from '../../../app/common/layout/PageNumber';
import LoadingButton from '../../../app/common/layout/LoadingButton';
import { nanoid } from 'nanoid';
import { format, parseISO } from 'date-fns';
import ReactTooltip from 'react-tooltip';

function mapState(state) {
  let scope = {};
  if (state?.auth?.isAuth) {
    scope = state?.auth?.authorities;
  }
  return {
    auth: state.auth,
    scope: scope,
    pesanpribadis: state.pesanpribadis,
    loading: state.async.loading,
  };
}

const actions = {
  getPesanpribadis,
};

function Index(props) {
  let history = useHistory();
  const [state, setState] = useState({
    title: 'Pesan Pribadi',
    itemPerPage: 10,
    currentPage: 1,
    searchText: '',
    moreButton: false,
  });
  const { auth, scope, loading, pesanpribadis, getPesanpribadis } = props;
  const items = pesanpribadis?.items;
  const total = pesanpribadis?.total;

  useEffect(() => {
    getPesanpribadis(
      auth,
      history,
      state?.itemPerPage,
      state?.currentPage,
      state?.searchText,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let dropdownElement = document.querySelector('#dropdownElement');
    const closeSearchMore = (e) => {
      if (dropdownElement && !dropdownElement.contains(e.target) && state.moreButton) {
        if (dropdownElement.classList.contains('is-active')) {
          dropdownElement.classList.remove('is-active');
          dropdownElement.classList.remove('is-right');
        }
        setState({
          ...state,
          moreButton: !state.moreButton,
        });
      }
    };
    document.addEventListener('mouseup', closeSearchMore);

    return () => {
      document.removeEventListener('mouseup', closeSearchMore);
    };
  }, [state]);

  const List = (props) => {
    const { loading, scope, items, title, currentPage, itemPerPage } = props;
    return (
      <>
        {!loading &&
          items &&
          items.length !== 0 &&
          items.map((item, index) => (
            <ListItems
              key={item.id}
              index={index}
              item={item}
              currentPage={currentPage}
              itemPerPage={itemPerPage}
              loading={loading}
              scope={scope}
            />
          ))}
        {!loading && items && items.length === 0 && (
          <tr>
            <td colSpan='7'>
              Tidak ada <span className='is-lowercase'>{title}</span>
            </td>
          </tr>
        )}
      </>
    );
  };

  const ListItems = (props) => {
    const { item, currentPage, itemPerPage, index, loading, scope } = props;
    if (loading)
      return (
        <tr>
          <td>Loading...</td>
        </tr>
      );

    const page = (currentPage - 1) * itemPerPage;

    const yStatus = <span className='tag is-info'>Yes</span>;

    const nStatus = (
      <span className='tag has-background-grey has-text-white'>No</span>
    );

    return (
      <tr>
        <td className='has-text-centered'>{page + index + 1}</td>
        <td>{item.title}</td>
        <td className='has-text-centered'>
          {item.published ? yStatus : nStatus}
        </td>
        <td className='has-text-centered is-uppercase'>
          {item && item.creator && JSON.parse(item.creator).username}
        </td>
        <td className='has-text-centered'>
          {item.createdAt &&
            format(parseISO(item.createdAt), 'd LLLL yyyy - HH:mm:ss')}
        </td>
        <td>
          <div className='content-wrap flex justify-center'>
            <div>
              {scope && scope.subm && scope.subm['pesanpribadi_view'] && (
                <Link
                  to={`/portal/pesanpribadi/${item.id}`}
                  className='button is-small is-rounded is-info is-outlined mr-2 mb-1'
                >
                  <i className='is-size-6 mdi mdi-eye icon' />
                </Link>
              )}
            </div>
          </div>
        </td>
      </tr>
    );
  };

  const handleSimpleSearch = (searchText) => {
    getPesanpribadis(auth, history, state.itemPerPage, 1, searchText);
  };

  const handleItemPerPage = (val) => {
    setState({
      ...state,
      itemPerPage: val,
      currentPage: 1,
    });
    getPesanpribadis(auth, history, val, 1, state.searchText);
  };

  const handlePage = (val) => {
    let newCurrentPage = state.currentPage + val;
    setState({
      ...state,
      currentPage: newCurrentPage,
    });
    getPesanpribadis(
      auth,
      history,
      state?.itemPerPage,
      newCurrentPage,
      state?.searchText
    );
  };

  // const handleMoreButton = () => {
  //   setState({
  //     ...state,
  //     moreButton: !state.moreButton,
  //   });
  // };

  return (
    <>
      <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
        <div className='p-1'>
          <div className='columns is-variable is-desktop'>
            <div className='column is-12'>
              <div className='card'>
                <header className='card-header has-background-white has-text-info'>
                  <div
                    className='custom-card-flex is-size-6'
                    style={{ width: '100%' }}
                  >
                    <p>
                      <i className='mdi mdi-account-group is-size-5 icon' />
                      Portal<i className='mdi mdi-menu-right is-size-6 icon' />Pesan Pribadi
                    </p>
                  </div>
                </header>
                <div className='card-content'>
                  <div className='content'>
                    <div className='level' style={{ marginTop: -20 }}>
                      <div className='level-left'></div>

                      <div className='level-right mr-4'>
                        <div className='level-item flex justify-center align-center'>
                          {scope && scope.subm && scope.subm['pesanpribadi_add'] && (
                            <>
                              <Link
                                to={`/portal/pesanpribadi/tambah/${nanoid(32)}`}
                                className='button is-small is-info is-rounded is-outlined mr-2'
                              >
                                <i className='is-size-6 mdi mdi-plus icon' />
                              </Link>
                            </>
                          )}
                          {scope && scope.subm && scope.subm['pesanpribadi_import'] && (
                            <div data-for='main' data-tip='Impor Pesan'>
                              <Link
                                to={`/portal/pesanpribadi/impor/${nanoid(32)}`}
                                className='button is-small is-info is-rounded is-outlined mr-2'
                              >
                                <i className='is-size-6 mdi mdi-file-import icon' />
                              </Link>
                              <ReactTooltip
                                id='main'
                                place='bottom'
                                type='dark'
                                effect='solid'
                                multiline={true}
                              />
                            </div>
                          )}
                          <div
                            className='flex align-center justify-center'
                            style={{
                              paddingTop: 14,
                            }}
                          >
                            <SimpleSearch
                              title={state.title}
                              initialValues={state.searchText}
                              loading={loading}
                              onFormSubmit={handleSimpleSearch}
                            />
                          </div>
                          {/* <div
                            id='dropdownElement'
                            className={
                              state.moreButton === true
                                ? 'dropdown is-right is-active'
                                : 'dropdown'
                            }
                          >
                            <div className='dropdown-trigger ml-2'>
                              <button
                                id='buttonDropDownMenu'
                                className='button is-small is-rounded is-outlined is-info'
                                aria-haspopup='true'
                                aria-controls='dropdown-menu'
                                onClick={() => handleMoreButton()}
                              >
                                <i
                                  className={
                                    state.moreButton === true
                                      ? 'is-size-6 mdi mdi-close icon'
                                      : 'is-size-6 mdi mdi-dots-horizontal icon'
                                  }
                                />
                              </button>
                            </div>
                            <div
                              className='dropdown-menu'
                              id='dropdown-menu'
                              role='menu'
                            >
                              <div className='dropdown-content'>
                                <Link
                                  className='dropdown-item'
                                  to={`/portal/pesanpribadi/impor/${generatedMessageId}`}
                                >
                                  <i className='is-size-6 mdi mdi-file-import icon' />
                                  More...
                                </Link>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                    <div
                      className='table-container ml-4 mr-4'
                      style={{ marginTop: -16, marginBottom: 20 }}
                    >
                      <table
                        id='fadein'
                        className='table is-fullwidth is-hoverable is-narrow'
                      >
                        <thead>
                          <tr>
                            <th className='has-text-centered'>No</th>
                            <th>Judul</th>
                            <th className='has-text-centered'>Dikirim</th>
                            <th className='has-text-centered'>Kreator</th>
                            <th className='has-text-centered'>Dibuat</th>
                            <th className='has-text-centered'>Aksi</th>
                          </tr>
                        </thead>
                        <tbody>
                          {loading === true ? (
                            <tr>
                              <td>
                                <LoadingButton />
                              </td>
                            </tr>
                          ) : (
                            <List
                              auth={auth}
                              loading={loading}
                              items={items}
                              title={state.title}
                              currentPage={state.currentPage}
                              itemPerPage={state.itemPerPage}
                              scope={scope}
                            />
                          )}
                        </tbody>
                      </table>
                    </div>
                    <PageNumber
                      title={state.title}
                      total={total}
                      currentPage={state.currentPage}
                      itemPerPage={state.itemPerPage}
                      handleItemPerPage={handleItemPerPage}
                      handlePage={handlePage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default connect(mapState, actions)(Index);
