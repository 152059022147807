import {
  PELAJARANS_GET,
  PELAJARAN_JENJANGS_GET,
  PELAJARAN_ADD,
  PELAJARAN_EDIT,
  PELAJARAN_GET,
  PELAJARAN_SYNC,
  PELAJARAN_DELETE,
} from './reduxConstant';

export const pelajaransGet = (pelajarans) => {
  return {
    type: PELAJARANS_GET,
    payload: {
      pelajarans,
    },
  };
};

export const pelajaranJenjangsGet = (jenjangs) => {
  return {
    type: PELAJARAN_JENJANGS_GET,
    payload: {
      jenjangs,
    },
  };
};

export const pelajaranAdd = (pelajaran) => {
  return {
    type: PELAJARAN_ADD,
    payload: {
      pelajaran,
    },
  };
};

export const pelajaranEdit = (pelajaran) => {
  return {
    type: PELAJARAN_EDIT,
    payload: {
      pelajaran,
    },
  };
};

export const pelajaranGet = (pelajaran) => {
  return {
    type: PELAJARAN_GET,
    payload: {
      pelajaran,
    },
  };
};

export const pelajaranSync = (pelajarans) => {
  return {
    type: PELAJARAN_SYNC,
    payload: {
      pelajarans,
    },
  };
};

export const pelajaranDelete = (pelajaran) => {
  return {
    type: PELAJARAN_DELETE,
    payload: {
      pelajaran,
    },
  };
};
