import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { getWhatsapp, uploadFileWhatsapp } from './redux/reduxApi';
import TextInput from '../../../app/common/form/TextInput';
import TextArea from '../../../app/common/form/TextArea';
import { openModal } from '../../../app/modals/redux/modalActions';
import ReactTooltip from 'react-tooltip';
import { convertSize } from '../../../app/common/helpers/othersHelpers';

const mapState = (state, ownProps) => {
  const id = ownProps.match.params.id;
  let whatsapp = {};
  const whatsapps = state && state.whatsapps;
  if (whatsapps?.items && whatsapps?.items[0]) {
    whatsapp = state.whatsapps.items.filter((i) => i.id + '' === id + '')[0];
  }
  return {
    auth: state.auth,
    loading: state.async.loading,
    whatsappId: id,
    whatsapp: whatsapp,
    uploadFiles: whatsapps.uploadFiles,
  };
};

const actions = {
  getWhatsapp,
  uploadFileWhatsapp,
  openModal,
};

function View(props) {
  const {
    auth,
    history,
    loading,
    whatsappId,
    whatsapp,
    uploadFiles,
    getWhatsapp,
    uploadFileWhatsapp,
    handleSubmit,
    openModal,
  } = props;
  const [state, setState] = useState({
    tl: 'Whatsapp',
    headerCols: ['No', 'Nama Siswa', 'No HP', 'Pesan', 'File'],
    numberOfStudents: 0,
    whatsappId: null,
    title: null,
    description: ' ',
    messages: [],
    recipients: [],
    files: [],
    uploadFiles: [],
    currentStudentPesanId: {},
    currentFile: '',
    statusProgress: false,
    uploadProgress: 0,
    toggleSave: false,
  });

  useEffect(() => {
    getWhatsapp(auth, history, whatsappId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setState({
      ...state,
      whatsappId: whatsappId,
      title: whatsapp?.title,
      description: whatsapp?.description,
      messages: (whatsapp && JSON.parse(whatsapp.messages)) || [],
      recipients: (whatsapp && JSON.parse(whatsapp.recipients)) || [],
      files: (whatsapp && JSON.parse(whatsapp.files)) || [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [whatsapp]);

  useEffect(() => {
    setState({
      ...state,
      uploadFiles: uploadFiles,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadFiles]);

  const onSelectFile = (e, studentPesanId) => {
    e.preventDefault();
    let file = e.target.files[0];
    const values = {
      id: whatsappId,
      studentPesanId: studentPesanId,
      filename: file.name,
      filesize: file.size,
      filetype: file.type,
      file: file,
    };
    uploadFileWhatsapp(auth, history, values);
  };

  const onDeleteFile = (e, studentPesanId, file) => {
    e.preventDefault();
    const values = {
      id: whatsappId,
      studentPesanId: studentPesanId,
      logs: whatsapp.logs,
    };
    openModal('WhatsappFileDelete', {
      data: {
        auth,
        history,
        item: values,
        file,
      },
    });
  };

  const renderFileInput = (props) => {
    const {
      input,
      type,
      meta,
      placeholder,
      file,
      uploadFile,
      studentPesanId,
      mimeType,
    } = props;
    let isUpload = uploadFile && uploadFile.studentPesanId === studentPesanId;
    return (
      <div className='field'>
        <div className='control'>
          <div className='file is-info has-name is-fullwidth'>
            <label className='file-label'>
              <input
                name={input.name}
                className='file-input is-small'
                type={type}
                accept={mimeType}
                onChange={(e) => onSelectFile(e, studentPesanId)}
              />
              <span className='file-cta'>
                <span className='file-icon'>
                  <i className='is-size-6 mdi mdi-upload'></i>
                </span>
                <span className='file-label'>{placeholder}</span>
              </span>
              <span className='file-name'>
                {uploadFile && uploadFile.status === 'start'
                  ? uploadFile.filename
                  : ''}
              </span>
            </label>
          </div>
          {isUpload && uploadFile.status === 'start' && (
            <progress className='progress is-small is-info mt-2' max='100'>
              0%
            </progress>
          )}
        </div>
        {meta && meta.invalid && meta.error && <div>{meta.error}</div>}
        {file && (
          <div
            className='has-text-link has-background-grey-lighter px-2 py-1 mb-1 flex justify-between align-center mt-1'
            style={{ maxWidth: 240 }}
          >
            <span className='custom-text-overflow '>
              {file?.filename} ({convertSize(file.filesize)})
            </span>
            <button
              onClick={(e) => onDeleteFile(e, studentPesanId, file)}
              className='delete'
            ></button>
          </div>
        )}
      </div>
    );
  };

  const Item = (props) => {
    const { item, index, files, uploadFiles, FileInput } = props;
    let phone = item.phone;
    let file = files && files.filter((i) => i.studentPesanId === item.id)[0];
    let uploadFile =
      uploadFiles && uploadFiles.filter((i) => i.studentPesanId === item.id)[0];
    return (
      <tr>
        <td>{index + 1}</td>
        <td style={{ minWidth: 200 }}>{item?.studentName}</td>
        <td>
          {phone &&
            phone.map((i, index) => (
              <span key={i + '_' + index}>
                {i.recipient}: {i.phone}
                {index + 1 < phone.length ? <br /> : ''}
              </span>
            ))}
        </td>
        <td style={{ minWidth: 400 }}>{item?.message}</td>
        <td style={{ minWidth: 250 }}>
          <Field
            name='attachment'
            type='file'
            component={FileInput}
            placeholder='File'
            label='File Attachement'
            studentPesanId={item.id}
            file={file}
            uploadFile={uploadFile}
          />
        </td>
      </tr>
    );
  };

  const onChangeInput = (e, key) => {
    // let val = e.target.value;
    // if (val.length === 0) {
    //   val = ' ';
    // }
    // if (key === 'title') {
    //   setState({
    //     ...state,
    //     title: val,
    //   });
    // } else if (key === 'description') {
    //   setState({
    //     ...state,
    //     description: val,
    //   });
    // }
    // if (
    //   state.title.trim() !== pesan.title ||
    //   state.description.trim() !== pesan.description
    // ) {
    //   setState({
    //     ...state,
    //     toggleSave: true,
    //   });
    // }
  };

  const onSave = () => {};

  const onDelete = (e) => {
    e.preventDefault();
    const values = {
      id: whatsappId,
      title: state.title,
    };
    openModal('WhatsappDelete', {
      data: {
        auth,
        history,
        item: values,
      },
    });
  };

  const onGoBack = () => {
    history.push({
      pathname: '/pesan/whatsapp',
      state: { data: 'nodata' },
    });
  };

  return (
    <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
      <div className='p-1'>
        <div className='columns is-variable'>
          <div className='column is-fullwidth'>
            <div className='card'>
              <header className='card-header has-background-white has-text-info'>
                <div
                  className='custom-card-flex is-size-6'
                  style={{ width: '100%' }}
                >
                  <p>
                    <i className='mdi mdi-message-text-outline is-size-5 icon' />{' '}
                    Pesan
                    <i className='mdi mdi-menu-right is-size-6 icon' />
                    Whatsapp
                  </p>
                </div>
              </header>
              <div className='card-content pl-4 pr-4'>
                <form autoComplete='off' style={{ marginTop: 1 }}>
                  <div className='level'>
                    <div className='level-left'>
                      <div className='level-item'>
                        <nav
                          className='breadcrumb is-size-6'
                          aria-label='breadcrumbs'
                        >
                          <ul>
                            <li className='is-active has-text-weight-bold'>
                              <Link to={'/pesan/whatsapp/' + whatsappId}>
                                Detail
                              </Link>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>

                    <div className='level-right'>
                      <div className='level-item'>
                        <div className='buttons'>
                          {/* {state && state.toggleSave && (
                            <button
                              disabled={loading}
                              onClick={handleSubmit(onSave)}
                              className={
                                loading
                                  ? 'button is-small is-info is-rounded is-outlined is-loading'
                                  : 'button is-small is-info is-rounded is-outlined'
                              }
                              style={{ marginRight: 10 }}
                            >
                              <i className='is-size-6 mdi mdi-content-save icon' />
                            </button>
                          )} */}
                          <div data-for='main' data-tip='Kirim Pesan'>
                            <button
                              disabled={loading}
                              onClick={handleSubmit(onSave)}
                              className={
                                loading
                                  ? 'button is-small is-info is-rounded is-outlined is-loading'
                                  : 'button is-small is-info is-rounded is-outlined'
                              }
                              style={{ marginRight: 10 }}
                            >
                              <i className='is-size-6 mdi mdi-message-arrow-right icon' />
                            </button>
                            <ReactTooltip
                              id='main'
                              place='bottom'
                              type='dark'
                              effect='solid'
                              multiline={true}
                            />
                          </div>
                          <button
                            disabled={loading}
                            onClick={(e) => onDelete(e)}
                            className={
                              loading
                                ? 'button is-small is-danger is-rounded is-outlined is-loading'
                                : 'button is-small is-danger is-rounded is-outlined'
                            }
                            style={{ marginRight: 10 }}
                          >
                            <i className='is-size-6 mdi mdi-delete icon' />
                          </button>
                          <button
                            disabled={loading}
                            onClick={() => onGoBack()}
                            className='button custom-grey is-small is-rounded is-outlined'
                          >
                            <i className='is-size-6 mdi mdi-arrow-left icon' />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='columns'>
                    <div className='column is-12'>
                      <Field
                        name='title'
                        type='text'
                        defaultValue={state.title ?? ' '}
                        onChange={(e) => onChangeInput(e, 'title')}
                        component={TextInput}
                        placeholder='Judul'
                        label='Judul'
                        readOnly={true}
                      />
                      <Field
                        name='description'
                        type='text'
                        defaultValue={state.description ?? ' '}
                        onChange={(e) => onChangeInput(e, 'description')}
                        component={TextArea}
                        placeholder='Deskripsi'
                        label='Deskripsi'
                        readOnly={true}
                      />
                      <br />
                      <div className='table-container overflow-auto'>
                        <table className='table is-hoverable'>
                          <thead className='has-background-info'>
                            <tr>
                              <th colSpan='5' className='has-text-white'>
                                Penerima Pesan
                              </th>
                            </tr>
                          </thead>
                          <thead>
                            <tr>
                              {state &&
                                state.headerCols.map((i, key) => (
                                  <th key={key}>{i}</th>
                                ))}
                            </tr>
                          </thead>
                          <tbody>
                            {state &&
                              state.messages.map((item, index) => {
                                return (
                                  <Item
                                    item={item}
                                    index={index}
                                    key={item.id}
                                    headerCols={state.headerCols}
                                    files={state.files}
                                    uploadFiles={state.uploadFiles}
                                    FileInput={renderFileInput}
                                  />
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(
  mapState,
  actions
)(reduxForm({ form: 'WhatsappFormView' })(View));
