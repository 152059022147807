import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { getTugass } from './redux/reduxApi';
import {
  changeSearchJenjang,
  changeSearchTingkat,
  changeSearchKelas,
  changeSearchPelajaran,
  changeSearchYears,
  changeSearchSems,
} from '../../../app/common/reduxs/search/reduxApi';
import SimpleSearch from '../../../app/common/layout/SimpleSearch';
import PageNumber from '../../../app/common/layout/PageNumber';
import LoadingButton from '../../../app/common/layout/LoadingButton';
import List from './IndexList';
import SearchForm from './SearchForm';
import { nanoid } from 'nanoid';

function mapState(state) {
  let scope = {};
  if (state.auth && state.auth.isAuth) {
    scope = state.auth.authorities;
  }
  return {
    auth: state.auth,
    scope: scope,
    tugass: state.tugass,
    searchForm: state.searchForm,
    loading: state.async.loading,
  };
}

const actions = {
  getTugass,
  changeSearchJenjang,
  changeSearchTingkat,
  changeSearchKelas,
  changeSearchPelajaran,
  changeSearchYears,
  changeSearchSems,
};

function Index(props) {
  let history = useHistory();
  const [state, setState] = useState({
    title: 'Tugas',
    itemPerPage: 10,
    currentPage: 1,
    searchText: '',
    searchMore: {
      year: '',
      sem: '',
      jenjang: '',
      tingkat: '',
      kelas: '',
      pelajaran: '',
      published: 'all',
    },
    moreButton: false,
    jenjangs: [],
    tingkats: [],
    kelass: [],
    pelajarans: [],
    years: [],
    sems: [],
  });
  const {
    getTugass,
    changeSearchJenjang,
    changeSearchTingkat,
    changeSearchKelas,
    changeSearchPelajaran,
    changeSearchYears,
    changeSearchSems,
    auth,
    scope,
    loading,
    tugass,
    searchForm,
  } = props;
  const items = tugass && tugass.items;
  const total = tugass && tugass.total;
  const search = tugass && tugass.search;
  const jenjangs = searchForm.jenjangs;
  const tingkats = searchForm.tingkats;
  const kelass = searchForm.kelass;
  const pelajarans = searchForm.pelajarans;
  const years = searchForm.years;
  const sems = searchForm.sems;

  useEffect(() => {
    let itemPerPage = search?.itemPerPage || state.itemPerPage;
    let currentPage = search?.currentPage || state.currentPage;
    let searchText = search?.searchText || state.searchText;
    let searchMoreText = {
      year: search?.year || state.searchMore.year,
      sem: search?.sem || state.searchMore.sem,
      jenjang: search?.jenjang || state.searchMore.jenjang,
      tingkat: search?.tingkat || state.searchMore.tingkat,
      kelas: search?.kelas || state.searchMore.kelas,
      pelajaran: search?.pelajaran || state.searchMore.pelajaran,
      published: search?.published || state.searchMore.published,
    };
    getTugass(
      auth,
      history,
      itemPerPage,
      currentPage,
      searchText,
      searchMoreText
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    changeSearchJenjang(auth, history);
    changeSearchTingkat(auth, history);
    changeSearchKelas(auth, history);
    changeSearchPelajaran(auth, history);
    changeSearchYears(auth, history);
    changeSearchSems(auth, history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, history]);

  useEffect(() => {
    let dropdownElement = document.querySelector('#dropdownElement');
    const closeSearchMore = (e) => {
      if (!dropdownElement.contains(e.target) && state.moreButton) {
        if (dropdownElement.classList.contains('is-active')) {
          dropdownElement.classList.remove('is-active');
          dropdownElement.classList.remove('is-right');
        }
        setState({
          ...state,
          moreButton: !state.moreButton,
        });
      }
    };
    document.addEventListener('mouseup', closeSearchMore);

    return () => {
      document.removeEventListener('mouseup', closeSearchMore);
    };
  }, [state]);

  const handleSimpleSearch = (searchText) => {
    getTugass(auth, history, search.itemPerPage, 1, searchText);
    setState({
      ...state,
      itemPerPage: search.itemPerPage,
      currentPage: 1,
      searchText: searchText,
      moreButton: false,
    });
  };

  const handleSearchMore = (values) => {
    const searchMoreText = {
      year: values.year || '',
      sem: values.sem || '',
      jenjang: values.jenjang || '',
      tingkat: values.tingkat || '',
      kelas: values.kelas || '',
      pelajaran: values.pelajaran || '',
      published: values.published || 'all',
    };

    getTugass(auth, history, state.itemPerPage, 1, '', searchMoreText);
    setState({
      ...state,
      ...values,
      moreButton: false,
    });
  };

  const handleResetSearch = () => {
    const searchMoreText = {
      year: '',
      sem: '',
      jenjang: '',
      tingkat: '',
      kelas: '',
      pelajaran: '',
      published: 'all',
    };
    setState({
      ...state,
      searchMore: { ...searchMoreText },
    });
    getTugass(
      auth,
      history,
      state.itemPerPage,
      1,
      '',
      searchMoreText,
      'reset'
    );
  };

  const handleItemPerPage = (val) => {
    setState({
      ...state,
      itemPerPage: val,
      currentPage: 1,
    });
    getTugass(auth, history, val, 1, search.searchText);
  };

  const handlePage = (val) => {
    let newCurrentPage = search.currentPage + val;
    setState({
      ...state,
      currentPage: newCurrentPage,
    });
    getTugass(
      auth,
      history,
      state.itemPerPage,
      newCurrentPage,
      search.searchText
    );
  };

  const handleMoreButton = () => {
    setState({
      ...state,
      moreButton: !state.moreButton,
    });
  };

  return (
    <>
      <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
        <div className='p-1'>
          <div className='columns is-variable is-desktop'>
            <div className='column is-12'>
              <div className='card'>
                <header className='card-header has-background-white has-text-info'>
                  <div
                    className='custom-card-flex is-size-6'
                    style={{ width: '100%' }}
                  >
                    <p>
                      <i className='mdi mdi-bookshelf is-size-5 icon' />{' '}
                      Pembelajaran
                      <i className='mdi mdi-menu-right is-size-6 icon' />
                      Tugas
                    </p>
                  </div>
                </header>
                <div className='card-content'>
                  <div className='content'>
                    <div className='level' style={{ marginTop: -20 }}>
                      <div className='level-left'></div>

                      <div className='level-right mr-4'>
                        <div className='level-item flex justify-center align-center'>
                          {scope && scope.subm && scope.subm['tugas_add'] && (
                            <>
                              <Link
                                to={`/pembelajaran/tugas/tambah/${nanoid(32)}`}
                                className='button is-small is-info is-rounded is-outlined mr-2'
                              >
                                <i className='is-size-6 mdi mdi-plus icon' />
                              </Link>
                            </>
                          )}
                          <div
                            className='flex align-center justify-center'
                            style={{
                              paddingTop: 14,
                            }}
                          >
                            <SimpleSearch
                              title={state.title}
                              initialValues={state.searchText}
                              loading={loading}
                              resetSearchText={state.resetSearchText}
                              onFormSubmit={handleSimpleSearch}
                            />
                          </div>
                          {(search.searchText !== '' ||
                            search.published !== 'all' ||
                            search.jenjang !== '' ||
                            search.tingkat !== '' ||
                            search.kelas !== '' ||
                            search.pelajaran !== '' ||
                            search.year !== '' ||
                            search.sem !== '') && (
                            <button
                              onClick={() => handleResetSearch()}
                              className='button is-small is-info is-rounded is-outlined ml-2'
                            >
                              <i className='is-size-6 mdi mdi-reload icon' />
                            </button>
                          )}
                          <div
                            id='dropdownElement'
                            className={
                              state.moreButton === true
                                ? 'dropdown is-right is-active'
                                : 'dropdown'
                            }
                          >
                            <div className='dropdown-trigger ml-2'>
                              <button
                                className='button is-small is-rounded is-outlined is-info'
                                aria-haspopup='true'
                                aria-controls='dropdown-menu'
                                onClick={() => handleMoreButton()}
                              >
                                <i
                                  className={
                                    state.moreButton === true
                                      ? 'is-size-6 mdi mdi-close icon'
                                      : 'is-size-6 mdi mdi-magnify-plus icon'
                                  }
                                />
                              </button>
                            </div>
                            <div
                              className='dropdown-menu'
                              id='dropdown-menu'
                              role='menu'
                            >
                              <div className='dropdown-content search-adv'>
                                <SearchForm
                                  initialValues={state.searchMore}
                                  jenjangs={jenjangs}
                                  tingkats={tingkats}
                                  kelass={kelass}
                                  pelajarans={pelajarans}
                                  years={years}
                                  sems={sems}
                                  onFormSubmit={handleSearchMore}
                                  resetSearch={handleResetSearch}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className='table-container ml-4 mr-4'
                      style={{ marginTop: -16, marginBottom: 20 }}
                    >
                      <table
                        id='fadein'
                        className='table is-fullwidth is-hoverable is-narrow'
                      >
                        <thead>
                          <tr>
                            <th className='has-text-centered'>No</th>
                            <th>Judul</th>
                            <th className='has-text-centered'>Published</th>
                            <th className='has-text-centered'>Kreator</th>
                            <th className='has-text-centered'>Dibuat</th>
                            <th className='has-text-centered'>Aksi</th>
                          </tr>
                        </thead>
                        <tbody>
                          {loading === true ? (
                            <tr>
                              <td>
                                <LoadingButton />
                              </td>
                            </tr>
                          ) : (
                            <List
                              auth={auth}
                              loading={loading}
                              scope={scope}
                              items={items}
                              title={state.title}
                              currentPage={search.currentPage}
                              itemPerPage={search.itemPerPage}
                            />
                          )}
                        </tbody>
                      </table>
                    </div>
                    <PageNumber
                      title={state.title}
                      total={total}
                      currentPage={search.currentPage}
                      itemPerPage={search.itemPerPage}
                      handleItemPerPage={handleItemPerPage}
                      handlePage={handlePage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default connect(mapState, actions)(Index);
