import {
  asyncActionFinish,
  asyncActionError,
} from '../../../app/common/reduxs/async/asyncActions';
import { profileGet, photoUpdate } from '../../../app/login/redux/authAction';
import { ASYNC_ACTION_START } from '../../../app/common/reduxs/async/asyncConstant';
import { fetchFunc } from '../../../app/common/helpers/fetchHelpers';
import { logsFunc } from '../../../app/common/helpers/othersHelpers';
import { checkErr } from '../../../app/common/helpers/checkRes';
import { toastr } from 'react-redux-toastr';
import { cleanObject } from '../../../app/common/helpers/objectHelpers';

export const getProfile = (auth, history) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getProfile' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'get',
        auth,
        history,
        'profile/' + auth.id,
        formData,
        dispatch
      );
      const user = res.user;
      dispatch(profileGet(user.profile));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const editProfile = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'editProfile' });
    let filteredValues = cleanObject(values);
    let logs = logsFunc('edit', auth.userid, auth.username, filteredValues.logs ? filteredValues.logs : null);
    const formData = new FormData();
    if (filteredValues.email) {
      formData.append('email', filteredValues.email);
    }
    if (filteredValues.name) {
      formData.append('name', filteredValues.name);
    }
    if (filteredValues.fullname) {
      formData.append('fullname', filteredValues.fullname);
    }
    if (filteredValues.pob) {
      formData.append('pob', filteredValues.pob);
    }
    if (filteredValues.dob) {
      formData.append('dob', filteredValues.dob);
    }
    if (filteredValues.gender) {
      formData.append('gender', filteredValues.gender);
    }
    if (filteredValues.phone) {
      formData.append('phone', filteredValues.phone);
    }
    if (filteredValues.address) {
      formData.append('address', filteredValues.address);
    }
    if (filteredValues.logs) {
      formData.append('logs', logs);
    }
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'profile/edit/' + values.id,
        formData,
        dispatch
      );
      const profile = res.profile;
      dispatch(profileGet(profile));
      history.push('/profil')
      toastr.success('Sukses', `Update profil berhasil`);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const photoUpload = (auth, history, image, filename) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'photoUpload' });
    const profileId = auth.userId;
    const formData = new FormData();
    formData.append('profileId', profileId);
    formData.append('file', image);
    formData.append('filename', filename);
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'profile/upload-photo/' + auth.userId,
        formData,
        dispatch
      );
      const urlPhoto = res.urlPhoto;
      dispatch(photoUpdate(urlPhoto));
      dispatch(asyncActionFinish());
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};

export const resetPasswordProfile = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'resetPasswordProfile' });
    let logs = logsFunc('edit', auth.userid, auth.username, values.logs);
    const formData = new FormData();
    formData.append('oldPassword', values.oldPassword);
    formData.append('newPassword', values.newPassword);
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'profile/reset-password/' + values.id,
        formData,
        dispatch
      );
      dispatch(asyncActionFinish());
      if (res && res.status === 'success') {
        const status = res.status;
        const message = res.message;
        const user = res.user;
        return { status, message, user };
      }
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};
