import React from 'react';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { Redirect } from 'react-router-dom';
import { useUnamePass } from '../../common/helpers/othersHelpers';

export default function PullDownWrapper(props) {
  const { auth } = props;
  let uPass = useUnamePass();

  if (auth && !auth.isAuth) {
    if (uPass && uPass.length > 0) {
      window.ReactNativeWebView.postMessage('logout');
    }
    return <Redirect to='/login' />;
  }

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <>
      <div id='content-page'>
        <PullToRefresh onRefresh={handleRefresh} pullingContent={false}>
          {props.children}
        </PullToRefresh>
      </div>
    </>
  );
}
