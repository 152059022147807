import {
  PORTALUSERS_GET,
  PORTALUSER_GET,
  PORTALUSER_EDIT,
  PORTALUSER_SYNC,
} from './reduxConstant';

export const portalusersGet = (portalusers) => {
  return {
    type: PORTALUSERS_GET,
    payload: {
      portalusers,
    },
  };
};

export const portaluserGet = (portaluser) => {
  return {
    type: PORTALUSER_GET,
    payload: {
      portaluser,
    },
  };
};

export const portaluserEdit = (portaluser) => {
  return {
    type: PORTALUSER_EDIT,
    payload: {
      portaluser,
    },
  };
};

export const portaluserSync = (portaluser) => {
  return {
    type: PORTALUSER_SYNC,
    payload: {
      portaluser,
    },
  };
};
