import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import TextInput from '../../../app/common/form/TextInput';
import SelectInput from '../../../app/common/form/SelectInput';
import { reduxForm, Field } from 'redux-form';
import { nanoid } from 'nanoid';
import { toastr } from 'react-redux-toastr';
import { dateIsValid, timeIsValid } from '../../../app/common/helpers/othersHelpers';

function EditForm(props) {
  const {
    auth,
    history,
    id,
    // invalid,
    // loading,
    // pristine,
    openModal,
    handleSubmit,
    initialValues,
    years,
    editCalendar,
  } = props;
  const [state, setState] = useState({
    headerCols: [
      'No',
      'Title',
      'Type',
      'Start Date',
      'Start Time',
      'End Date',
      'End Time',
      'Description',
      'Aksi',
    ],
    id: '',
    title: ' ',
    year: '',
    events: [],
    selectType: [],
  });
  let yearsSelect = [];
  years &&
    years.forEach((obj) => {
      let newObj = {
        key: obj.id,
        value: obj.code,
        text: obj.title,
      };
      yearsSelect = [...yearsSelect, newObj];
    });

  useEffect(() => {
    setState({
      ...state,
      id: initialValues?.id,
      title: initialValues?.title,
      year: initialValues?.year,
      events:
        initialValues &&
        initialValues.events &&
        JSON.parse(initialValues.events),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeInput = (e, key) => {
    let val = e.target.value;
    if (val.length === 0) {
      val = ' ';
    }
    if (key === 'title') {
      setState({
        ...state,
        title: val,
      });
    } else if (key === 'year') {
      setState({
        ...state,
        year: val,
      });
    }
  };

  function tableToJson(table) {
    var data = [];
    // first row needs to be headers
    var headers = [];
    for (var h = 0; h < table.rows[0].cells.length; h++) {
      headers[h] = table.rows[0].cells[h].textContent
        .toLowerCase()
        .replace(/ /gi, '');
    }
    // go through cells
    for (var i = 1; i < table.rows.length; i++) {
      var tableRow = table.rows[i];
      var rowData = {};

      for (var j = 0; j < tableRow.cells.length; j++) {
        rowData[headers[j]] = tableRow.cells[j].textContent;
      }
      data.push(rowData);
    }
    return data;
  }

  const onSelectType = (e, id) => {
    let selectType = state.selectType;
    let newSelectType = [];
    if (selectType.length > 0) {
      newSelectType = selectType.filter((i) => i.id !== id);
    }
    setState({
      ...state,
      selectType: [...newSelectType, { id, type: e.target.value }],
    });
  };

  const onClickAddRow = (e) => {
    e.preventDefault();
    let events = state.events;
    let newDate = new Date();
    let getYear = newDate.getFullYear();
    let getMonth =
      (newDate.getMonth() + 1 + '').length < 2
        ? '0' + (newDate.getMonth() + 1)
        : newDate.getMonth() + 1;
    let getDay = newDate.getDate();
    let getHour = newDate.getHours();
    let newEvents = [
      ...events,
      {
        id: nanoid(),
        subject: 'Judul Event...',
        type: 'event',
        startDate: `${getYear}/${getMonth}/${getDay}`,
        startTime: `${getHour}:00`,
        endDate: `${getYear}/${getMonth}/${getDay}`,
        endTime: `${getHour}:30`,
        description: '',
      },
    ];
    setState({
      ...state,
      events: newEvents,
    });
  };

  function getChangeCal() {
    let calendar = {
      ...initialValues,
      id: state.id,
      title: state.title,
      year: state.year,
    };
    let tableEvents = document.getElementById('tableEvents');
    let dataEvents = [];
    if (tableEvents) {
      let data = tableToJson(tableEvents);
      for (let i = 0; i < data.length; i++) {
        let itemData = data[i];
        let no = itemData.no;
        if (no) {
          let id = no.split('_')[1];
          let itemOldType = state.events.filter((k) => k.id === id)[0];
          let type = itemOldType?.type || 'event';
          if (state.selectType.length > 0) {
            let selectType = state.selectType;
            let dataType = selectType.filter((j) => j.id === id);
            if (dataType.length > 0) {
              type = dataType[0].type;
            }
          }
          dataEvents = [
            ...dataEvents,
            {
              id,
              subject: itemData.title,
              type,
              startDate: itemData.startdate,
              startTime: itemData.starttime,
              endDate: itemData.enddate,
              endTime: itemData.endtime,
              description: itemData.description,
            },
          ];
        }
      }
    }
    
    calendar.events = dataEvents;
    // Validate Input
    let lengthTitle =
      calendar.title && calendar.title.trim().length === 0 ? true : false;
    let lengthYear =
      calendar.year && calendar.year.trim().length === 0 ? true : false;
    if (lengthTitle || lengthYear) {
      toastr.error(
        'Warning',
        `Pastikan ${lengthTitle ? 'Judul' : ''}${
          lengthTitle && lengthYear ? ' dan ' : ''
        }${lengthYear ? 'Tahun' : ''} terisi`
      );
      return;
    }
    let message = {
      field: '',
      format: '',
    };
    let isBreak = false;
    for (let l = 0; l < calendar.events.length; l++) {
      const ltem = calendar.events[l];
      // check date 2022/12/23
      if (dateIsValid(ltem.startDate) === false) {
        message = {field: 'tanggal', format: 'YYYY/MM/DD'};
        isBreak = true;
      }
      if (ltem.startTime && timeIsValid(ltem.startTime) === false) {
        message = {field: 'jam', format: 'HH:MM'};
        isBreak = true;
      }
      if (ltem.endDate && dateIsValid(ltem.endDate) === false) {
        message = {field: 'tanggal', format: 'YYYY/MM/DD'};
        isBreak = true;
      }
      if (ltem.endTime && timeIsValid(ltem.endTime) === false) {
        message = {field: 'jam', format: 'HH:MM'};
        isBreak = true;
      }
    }
    if (isBreak) {
      toastr.error(
        'Warning',
        `Format ${message.field} tidak sesuai, pastikan format tanggal: ${message.format}.`
      );
      return;
    }
    return calendar;
  }

  const onDeleteRow = (e, item) => {
    e.preventDefault();
    let getChangeCalendar = getChangeCal();
    if (!getChangeCalendar) {
      return;
    }
    openModal('CalendarDeleteEvent', {
      data: {
        auth,
        history,
        item,
        calendar: getChangeCalendar,
      },
    });
  };

  const handleClickSubmit = (value) => {
    let getChangeCalendar = getChangeCal();
    if (!getChangeCalendar) {
      return;
    }
    editCalendar(auth, history, getChangeCalendar);
  };

  return (
    <>
      <div className='level'>
        <div className='level-left'>
          <div className='level-item'>
            <nav className='breadcrumb is-size-6' aria-label='breadcrumbs'>
              <ul>
                <li className='is-active has-text-weight-bold'>
                  <Link to={'/calendar/edit/' + id}>Edit</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className='level-right'>
          <div className='level-item'>
            <div className='buttons'>
              <button
                onClick={handleSubmit(handleClickSubmit)}
                className='button is-small is-rounded is-info is-outlined'
              >
                <i className='is-size-6 mdi mdi-content-save icon' />
              </button>
              <button
                onClick={() =>
                  history.push({
                    pathname: '/calendar',
                    state: { viewMode: 'listmode' },
                  })
                }
                type='button'
                to={'/calendar'}
                className='button custom-grey is-small is-rounded is-outlined'
              >
                <i className='is-size-6 mdi mdi-arrow-left icon' />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='columns'>
        <div className='column is-third-quarter'>
          <form onSubmit={handleSubmit(handleClickSubmit)} autoComplete='off'>
            <Field
              name='title'
              type='text'
              defaultValue={state.title ?? ' '}
              onChange={(e) => onChangeInput(e, 'title')}
              component={TextInput}
              placeholder='Judul Kalender'
              label='Judul Kalender'
            />
            <Field
              name='year'
              type='text'
              component={SelectInput}
              defaultValue={state.year ?? ' '}
              onChange={(e) => onChangeInput(e, 'year')}
              placeholder='Pilih Tahun Ajaran'
              label='Tahun Ajaran'
              options={yearsSelect}
            />
            <br />
            <div className='table-container'>
              <table
                id='tableEvents'
                className='table is-fullwidth is-hoverable'
              >
                <thead>
                  <tr>
                    {state &&
                      state.headerCols.map((i, key) => <th key={key}>{i}</th>)}
                  </tr>
                </thead>
                <tbody>
                  <>
                    {state &&
                      state.events &&
                      state.events.map((item, index) => {
                        return (
                          <Item
                            item={item}
                            index={index}
                            key={index + 1}
                            onSelectType={onSelectType}
                            onDeleteRow={onDeleteRow}
                          />
                        );
                      })}
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <button
                          onClick={(e) => onClickAddRow(e)}
                          className='button is-rounded is-small is-outlined'
                        >
                          <i className='mdi mdi-plus icon is-size-6' />
                        </button>
                      </td>
                    </tr>
                  </>
                </tbody>
              </table>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default reduxForm({
  form: 'calendarEdit',
  enableReinitialize: true,
})(EditForm);

function Item(props) {
  const { item, index, onSelectType, onDeleteRow } = props;
  return (
    <tr>
      <td>
        {item?.No ?? index + 1}
        <span style={{ display: 'none' }}>_{item.id}</span>
      </td>
      <td
        contentEditable
        suppressContentEditableWarning={true}
        onClick={() => document.execCommand('selectAll', false, null)}
      >
        {item?.subject}
      </td>
      <td contentEditable suppressContentEditableWarning={true}>
        <select
          defaultValue={item.type}
          onChange={(e) => onSelectType(e, item.id)}
          name='type'
          style={{ border: 'none' }}
        >
          <option value=''>Pilih</option>
          <option value='event'>event</option>
          <option value='libur'>libur</option>
        </select>
      </td>
      <td contentEditable suppressContentEditableWarning={true}>
        {item?.startDate}
      </td>
      <td contentEditable suppressContentEditableWarning={true}>
        {item?.startTime}
      </td>
      <td contentEditable suppressContentEditableWarning={true}>
        {item?.endDate}
      </td>
      <td contentEditable suppressContentEditableWarning={true}>
        {item?.endTime}
      </td>
      <td
        contentEditable
        suppressContentEditableWarning={true}
        onClick={() => document.execCommand('selectAll', false, null)}
      >
        {item?.description}
      </td>
      <td>
        <button
          onClick={(e) => onDeleteRow(e, item)}
          className='button is-rounded is-small is-outlined'
        >
          <i className='mdi mdi-delete has-text-danger icon is-size-6' />
        </button>
      </td>
    </tr>
  );
}
