import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import LoadingButton from '../../../app/common/layout/LoadingButton';
import ViewScoreFormList from './ViewScoreFormList';
import { SITE_ADDRESS } from '../../../app/common/data/siteConfig';
import { stringSimilarity } from 'string-similarity-js';
import { sortArrayObject } from '../../../app/common/helpers/objectHelpers';
import ExcelExportHelper from './components/ExcelExportHelper';
import { toastr } from 'react-redux-toastr';

function ViewScoreForm(props) {
  const {
    auth,
    history,
    loading,
    scope,
    openModal,
    id,
    initialValues,
    questions,
    questionsLength,
    isPaperWork,
    keyAnswers,
    studentAnswer,
    scores,
    tags,
    downloadFile,
    submitScores,
  } = props;
  const [state, setState] = useState([]);
  const [isEdit, setIsEdit] = useState(false);

  const handleCountScore = (answers, kAnswers, quests, qLength) => {
    let count = 0;
    kAnswers &&
      kAnswers.forEach((ans, index) => {
        let keyAns = ans.answer.trim() + '';
        let stAns =
          answers && answers[index] && answers[index].answer.trim() + '';
        let stAnsLength = stAns && stAns.length;
        if (
          quests &&
          quests[0] &&
          quests[index]?.questionType === 'optionQuestion'
        ) {
          const string_chop = function (str, size) {
            if (str == null) return [];
            str = String(str);
            size = ~~size;
            return size > 0
              ? str.match(new RegExp('.{1,' + size + '}', 'g'))
              : [str];
          };
          let firstArray = stAns;
          if (stAnsLength > keyAns.length) {
            firstArray = string_chop(stAns, keyAns.length)[0];
          }
          if (firstArray === keyAns) {
            count = count + 1;
          }
        }

        if (quests[index]?.questionType === 'longQuestion' && keyAns && stAns) {
          let addScore = stringSimilarity(keyAns, stAns);
          count = count + convertScore(addScore, 2);
        }
        if (quests[index]?.questionType === 'shortQuestion') {
          if (keyAns === stAns) {
            count = count + 1;
          }
        }
      });
    let score = (count / qLength) * 100;
    let roundScore = Math.round((score + Number.EPSILON) * 100) / 100;
    return roundScore;
  };

  const convertScore = (scoreCount, val) => {
    if (scoreCount === null) {
      return 'calculate';
    } else if (scoreCount === 0) {
      return 0;
    } else if (scoreCount > 0) {
      let rounder = [10, 100, 1000];
      let roundScore =
        Math.round((scoreCount + Number.EPSILON) * rounder[val - 1]) /
        rounder[val - 1];
      return parseFloat(roundScore);
    }
  };

  useEffect(() => {
    let getAutoScore = [];
    if (!isPaperWork) {
      studentAnswer &&
        studentAnswer.forEach((i) => {
          let getAnswers = i.answers && JSON.parse(i.answers);
          let score = handleCountScore(
            getAnswers,
            keyAnswers,
            questions,
            questionsLength
          );
          let fixedScore = convertScore(score, 2);
          let finalScore = fixedScore;
          if (scores && scores[0]) {
            let item = scores.find((score) => score.nis === i.nis);
            finalScore = item.finalScore;
          }
          let obj = {
            studentTugasId: i.id,
            tugasId: id,
            nis: i.nis,
            autoScore: fixedScore,
            finalScore: finalScore,
          };
          getAutoScore = [...getAutoScore, obj];
        });
      setState([...getAutoScore]);
    } else {
      studentAnswer &&
        studentAnswer.forEach((i) => {
          let finalScore;
          if (scores && scores[0]) {
            let item = scores.find((score) => score.nis === i.nis);
            finalScore = item.finalScore;
          }
          let obj = {
            studentTugasId: i.id,
            tugasId: id,
            nis: i.nis,
            autoScore: 0,
            finalScore: finalScore,
          };
          getAutoScore = [...getAutoScore, obj];
        });
      setState([...getAutoScore]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyAnswers, studentAnswer]);

  const handleEdit = (e) => {
    e.preventDefault();
    setIsEdit(!isEdit);
  };

  const handleDelete = () => {
    openModal('TugasDeleteStudentAnswer', {
      data: {
        auth,
        history,
        item: { tugasId: id },
      },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let newScores = [...state];
    submitScores(auth, history, id, newScores);
    setIsEdit(!isEdit);
  };

  const handleClickAttachment = (e, item) => {
    e.preventDefault();
    function checkImage(url) {
      return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
    }
    let isImage = checkImage(item.filelink);
    if (isImage) {
      openModal('PopUpImageModal', { data: SITE_ADDRESS + item.filelink });
    } else {
      downloadFile(auth, history, initialValues, item);
    }
  };

  const handleEnter = (e) => {
    let inputId = e.target.id;
    let nis = inputId.split('_')[0];
    let arrowUp = 'ArrowUp';
    let arrowDown = 'ArrowDown';
    let index;
    let el;
    studentAnswer &&
      studentAnswer.find((i, idx) => {
        index = idx;
        return i.nis === nis;
      });
    if (e.key.toLowerCase() === 'enter' || e.key === arrowDown) {
      let stateLength = studentAnswer && studentAnswer.length;
      if (index + 1 > stateLength - 1) {
        return;
      }
      el = document.getElementById(studentAnswer[index + 1].nis + '_score');
      if (el) {
        el.focus();
        el.select();
      }
    }
    if (e.key === arrowUp) {
      if (index - 1 < 0) {
        return;
      }
      el = document.getElementById(studentAnswer[index - 1].nis + '_score');
      if (el) {
        el.focus();
        el.select();
      }
    }
  };

  const onChangeInput = (e, values) => {
    e.preventDefault();
    let val = e.target.value;
    if (
      val.length > 1 &&
      val.charAt(0) + '' === '0' &&
      val.charAt(1) + '' !== '.'
    ) {
      val = val.charAt(1);
    }
    if (isNaN(val)) {
      return;
    }
    if (val.length < 1) {
      val = 0;
    }
    if (val > 100) {
      return;
    }
    let filterState =
      state && state.filter((i) => i.studentTugasId + '' !== values.id + '');
    let oldState =
      state && state.filter((i) => i.studentTugasId + '' === values.id + '')[0];
    let updateState = { ...oldState, finalScore: val };

    setState(sortArrayObject([...filterState, updateState], 'no', 'asc'));
  };

  const onViewSummaryAnswer = (
    item,
    questions,
    keyAnswers,
    stateScores,
    scores
  ) => {
    openModal('TugasSummaryAnswer', {
      data: {
        auth,
        id,
        item,
        questions,
        keyAnswers,
        stateScores,
        scores,
      },
    });
  };

  const onCopyTable = (e) => {
    e.preventDefault();
    let head = `<tr><td>No</td><td>NIS</td><td>Siswa</td><td>Nilai</td></tr>`;
    let body = '';
    studentAnswer.forEach((item) => {
      let fSc = state.find((i) => i.nis === item.nis).finalScore + '';
      let fScore = fSc.replace('.', ',');
      body =
        body +
        `<tr><td>${item.no}</td><td>${item.nis}</td><td>${item.siswa}</td><td>${fScore}</td></tr>`;
    });
    let table = `<table><tbody>${head + body}</tbody></table>`;
    navigator.clipboard.writeText(table);
    toastr.success('Success', 'Tabel berhasil dicopy.');
  };

  let columnTitle =
    isPaperWork > 1 ? (
      <tr>
        <th className='has-text-centered'>No</th>
        <th className='has-text-centered'>NIS</th>
        <th>Siswa</th>
        <th className='has-text-centered'>File Upload</th>
        <th className='has-text-centered'>Keterangan</th>
        <th className='has-text-centered'>Submitted</th>
        <th className='has-text-centered'>Nilai</th>
      </tr>
    ) : (
      <tr>
        <th className='has-text-centered'>No</th>
        <th className='has-text-centered'>NIS</th>
        <th>Siswa</th>
        <th className='has-text-centered'>Auto Scoring</th>
        <th className='has-text-centered'>Nilai Akhir</th>
        <th className='has-text-centered'>Submitted</th>
        <th className='has-text-centered'>Jawaban</th>
      </tr>
    );

  return (
    <>
      <div className='level'>
        <div className='level-left'>
          <div className='level-item'>
            <nav className='breadcrumb is-size-6' aria-label='breadcrumbs'>
              <ul>
                <li className='is-active has-text-weight-bold'>
                  <Link to={'/pembelajaran/tugas/' + id}>Penilaian</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className='level-right'>
          <div className='level-item'>
            <div className='buttons'>
              {!isEdit && (
                <>
                  <ExcelExportHelper
                    data={{
                      filename: 'Penilaian ' + initialValues.title,
                      details: studentAnswer,
                      scores: state,
                    }}
                  />
                  <button
                    onClick={(e) => onCopyTable(e)}
                    className='button is-small is-rounded is-info is-outlined ml-1'
                  >
                    <i className='is-size-6 mdi mdi-content-copy icon' />
                  </button>
                </>
              )}
              {scope && scope.subm && scope.subm['tugas_score'] && (
                <>
                  {!isEdit && (
                    <button
                      onClick={(e) => handleEdit(e)}
                      className='button is-small is-rounded is-info is-outlined ml-1'
                    >
                      <i className='is-size-6 mdi mdi-pencil icon' />
                    </button>
                  )}
                  {isEdit && (
                    <button
                      onClick={(e) => handleSubmit(e)}
                      className='button is-small is-rounded is-info is-outlined ml-1'
                    >
                      <i className='is-size-6 mdi mdi-content-save icon' />
                    </button>
                  )}
                </>
              )}
              {isEdit && (
                <button
                  onClick={(e) => handleEdit(e)}
                  className='button is-small is-rounded is-info is-outlined ml-1'
                >
                  <i className='is-size-6 mdi mdi-arrow-left icon' />
                </button>
              )}
              {!isEdit && (
                <>
                  <button
                    onClick={() => handleDelete()}
                    className='button is-small is-rounded is-danger is-outlined ml-1'
                  >
                    <i className='is-size-6 mdi mdi-delete icon' />
                  </button>
                  <Link
                    to={`/pembelajaran/tugas/${id}`}
                    className='button custom-grey is-small is-rounded is-outlined'
                  >
                    <i className='is-size-6 mdi mdi-arrow-left icon' />
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className='columns'>
        <div className='column is-third-quarter'>
          <div className='content'>
            <div className='flex flex-column mb-4' style={{ marginTop: -12 }}>
              <article className='message is-info'>
                <div className='message-body is-size-7'>
                  <p>Judul:</p>
                  <p
                    className='is-capitalized'
                    style={{ whiteSpace: 'pre-line' }}
                  >
                    {initialValues.title}
                  </p>
                </div>
              </article>
            </div>
            <div className='overflow-auto'>
              <table
                id='tableCopy'
                className='table is-bordered is-fullwidth is-hoverable is-narrow'
              >
                <thead>{columnTitle}</thead>
                <tbody>
                  {loading === true ? (
                    <tr>
                      <td>
                        <LoadingButton />
                      </td>
                    </tr>
                  ) : (
                    <ViewScoreFormList
                      auth={auth}
                      scope={scope}
                      studentAnswer={studentAnswer}
                      questions={questions}
                      keyAnswers={keyAnswers}
                      stateScores={[...state]}
                      scores={[...scores]}
                      handleClickAttachment={handleClickAttachment}
                      isPaperWork={isPaperWork}
                      onChangeInput={onChangeInput}
                      state={state}
                      onViewSummaryAnswer={onViewSummaryAnswer}
                      handleEnter={handleEnter}
                      isEdit={isEdit}
                    />
                  )}
                </tbody>
              </table>
            </div>
            <hr />
            <label className='label'>Tags:</label>
            <div className='tags is-capitalized'>
              {tags &&
                tags.map((item, index) => (
                  <span key={index} className='tag is-info is-light'>
                    {item}
                  </span>
                ))}
              {!tags && <>-</>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewScoreForm;
