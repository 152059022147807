import React from 'react';
import { connect } from 'react-redux';
import NotAuthorizedModal from './NotAuthorizedModal';
import UserStatus from '../../menus/users/Status';
import ResetPassword from '../../menus/users/ResetPassword';
import UserDelete from '../../menus/users/DeleteItem';
import RoleDelete from '../../menus/roles/DeleteItem';
import RppFileDelete from '../../menus/_features/rpp/DeleteFile';
import RppDelete from '../../menus/_features/rpp/DeleteItem';
import RppPublish from '../../menus/_features/rpp/Publish';
import MateriFileDelete from '../../menus/_features/materi/DeleteFile';
import MateriDelete from '../../menus/_features/materi/DeleteItem';
import MateriPublish from '../../menus/_features/materi/Publish';
import TugasSummaryAnswer from '../../menus/_features/tugas/ViewSummaryAnswer';
import TugasDeleteImage from '../../menus/_features/tugas/DeleteImage';
import TugasDelete from '../../menus/_features/tugas/DeleteItem';
import LatihanDeleteImage from '../../menus/_features/latihan/DeleteImage';
import LatihanDelete from '../../menus/_features/latihan/DeleteItem';
import TesDeleteImage from '../../menus/_features/tes/DeleteImage';
import TesDelete from '../../menus/_features/tes/DeleteItem';
import CalendarViewEvent from '../../menus/_features/calendar/ModalViewEvent';
import CalendarDeleteEvent from '../../menus/_features/calendar/ModalDeleteEvent';
import CalendarDelete from '../../menus/_features/calendar/DeleteItem';
import CalendarStatus from '../../menus/_features/calendar/ModalSetActive';
import PesanImportSave from '../../menus/_features/whatsapp/ModalImportSave';
import PesanFileDelete from '../../menus/_features/whatsapp/DeleteFile';
import PesanDelete from '../../menus/_features/whatsapp/DeleteItem';
import WhatsappFileDelete from '../../menus/_features/whatsapp/DeleteFile';
import WhatsappDelete from '../../menus/_features/whatsapp/DeleteItem';
import JenjangStatus from '../../menus/_features/jenjang/Status';
import JenjangDelete from '../../menus/_features/jenjang/DeleteItem';
import TingkatStatus from '../../menus/_features/tingkat/Status';
import TingkatDelete from '../../menus/_features/tingkat/DeleteItem';
import KelasStatus from '../../menus/_features/kelas/Status';
import KelasDelete from '../../menus/_features/kelas/DeleteItem';
import PelajaranStatus from '../../menus/_features/pelajaran/Status';
import PelajaranDelete from '../../menus/_features/pelajaran/DeleteItem';
import YearStatus from '../../menus/_features/year/Status';
import YearDelete from '../../menus/_features/year/DeleteItem';
import PopUpImageModal from '../common/layout/PopUpImageModal';
import PopUpPdfModal from '../common/layout/PopUpPdfModal';
import TestModal from '../../menus/tests/TestModal';

const modalLookup = {
  NotAuthorizedModal,
  UserStatus,
  ResetPassword,
  UserDelete,
  RoleDelete,
  RppFileDelete,
  RppDelete,
  RppPublish,
  MateriFileDelete,
  MateriDelete,
  MateriPublish,
  TugasSummaryAnswer,
  TugasDeleteImage,
  TugasDelete,
  LatihanDeleteImage,
  LatihanDelete,
  TesDeleteImage,
  TesDelete,
  CalendarViewEvent,
  CalendarDeleteEvent,
  CalendarDelete,
  CalendarStatus,
  PesanImportSave,
  PesanFileDelete,
  PesanDelete,
  WhatsappFileDelete,
  WhatsappDelete,
  JenjangStatus,
  JenjangDelete,
  TingkatStatus,
  TingkatDelete,
  KelasStatus,
  KelasDelete,
  PelajaranStatus,
  PelajaranDelete,
  YearStatus,
  YearDelete,
  PopUpImageModal,
  PopUpPdfModal,
  TestModal,
};

const mapState = (state) => ({
  currentModal: state.modals,
});

const ModalManager = ({ currentModal }) => {
  let renderedModal;

  if (currentModal) {
    const { modalType, modalProps } = currentModal;
    const ModalComponent = modalLookup[modalType];

    renderedModal = <ModalComponent {...modalProps} />;
  }
  return <span>{renderedModal}</span>;
};

export default connect(mapState)(ModalManager);
