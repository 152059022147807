import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';
import TextInput from '../../../app/common/form/TextInput';
import { convertSize } from '../../../app/common/helpers/othersHelpers';
import ReactTooltip from 'react-tooltip';

function ViewForm(props) {
  const {
    messageId,
    auth,
    history,
    invalid,
    loading,
    initialValues,
    files,
    uploadFiles,
    reports,
    handleSubmit,
    openModal,
    sendPesanpribadi,
    uploadFilePesanpribadi,
    getReport,
  } = props;

  const [state, setState] = useState({
    messageId: '',
    title: '',
    messages: [],
    recipients: [],
    disableSend: false,
  });

  useEffect(() => {
    if (initialValues?.published) {
      getReport();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  const recipients =
    initialValues?.recipients && JSON.parse(initialValues?.recipients);

  useEffect(() => {
    setState((state) => ({
      ...state,
      messageId: messageId,
      title: initialValues?.title,
      messages: initialValues?.messages,
      recipients: recipients,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  const onSelectFile = (e, studentMessageId) => {
    e.preventDefault();
    let file = e.target.files[0];
    const values = {
      messageId: messageId,
      studentMessageId: studentMessageId,
      filename: file.name,
      filesize: file.size,
      filetype: file.type,
      file: file,
    };
    uploadFilePesanpribadi(auth, history, values);
  };

  const onDeleteFile = (e, studentMessageId, file) => {
    e.preventDefault();
    const values = {
      messageId: messageId,
      studentMessageId: studentMessageId,
      logs: initialValues?.logs,
    };
    openModal('PesanFileDelete', {
      data: {
        auth,
        history,
        item: values,
        file,
      },
    });
  };

  const renderFileInput = (props) => {
    const {
      input,
      type,
      meta,
      placeholder,
      file,
      uploadFile,
      studentMessageId,
      mimeType,
    } = props;
    let isUpload =
      uploadFile && uploadFile.studentMessageId === studentMessageId;
    return (
      <div className='field'>
        <div className='control'>
          {!file && (
            <div className='file is-small is-info has-name is-fullwidth'>
              <label className='file-label'>
                <input
                  name={input.name}
                  className='file-input'
                  type={type}
                  accept={mimeType}
                  onChange={(e) => onSelectFile(e, studentMessageId)}
                />
                <span className='file-cta'>
                  <span className='file-icon'>
                    <i className='is-size-6 mdi mdi-upload'></i>
                  </span>
                  <span className='file-label'>{placeholder}</span>
                </span>
                <span className='file-name'>
                  {uploadFile?.status === 'start' ? uploadFile?.filename : ''}
                </span>
              </label>
            </div>
          )}
          {isUpload && uploadFile?.status === 'start' && (
            <progress className='progress is-small is-info mt-2' max='100'>
              0%
            </progress>
          )}
        </div>
        {meta?.invalid && meta?.error && <div>{meta?.error}</div>}
        {file && (
          <div
            className='has-text-link has-background-grey-lighter px-2 py-1 mb-1 flex justify-between align-center mt-1'
            style={{ maxWidth: 240 }}
          >
            <span className='custom-text-overflow '>
              {file?.filename} ({convertSize(file.filesize)})
            </span>
            <button
              onClick={(e) => onDeleteFile(e, studentMessageId, file)}
              className='delete'
            ></button>
          </div>
        )}
      </div>
    );
  };

  const Item = (props) => {
    const { item, index, files, uploadFiles, FileInput, reports } = props;
    let description = item?.description && JSON.parse(item?.description);
    let file =
      files &&
      files.length > 0 &&
      files.filter((i) => i.studentMessageId === item.studentMessageId)[0];
    let uploadFile = uploadFiles?.filter(
      (i) => i.studentMessageId === item.studentMessageId
    )[0];
    let report = reports?.find((i) => i.studentMessageId + '' === item.studentMessageId + '');
    return (
      <tr>
        <td>{index + 1}</td>
        <td style={{ minWidth: 200 }}>
          <p>{description?.name}</p>
          <p>
            {description?.nis} / {description?.activeClass}
          </p>
          {(report?.webmsgStatus || report?.appnotifStatus) && (
            <p className='mt-1'>
              Status:
              {report?.webmsgStatus ? (
                <span className='tag is-info mr-1 ml-1'>
                  <i className='is-size-6 mdi mdi-check-circle icon mr-1' />
                  read
                </span>
              ) : (
                ''
              )}
              {report?.appnotifStatus ? (
                <span className='tag is-info mr-1 ml-1 mt-1'>
                  <i className='is-size-6 mdi mdi-check-circle icon mr-1' />
                  app notif
                </span>
              ) : (
                ''
              )}
            </p>
          )}
        </td>
        <td>
          <p style={{ whiteSpace: 'pre-wrap' }}>{item.messages}</p>
        </td>
        <td style={{ minWidth: 250 }}>
          <Field
            name='attachment'
            type='file'
            component={FileInput}
            placeholder='File'
            label='File Attachement'
            studentMessageId={item.studentMessageId}
            file={file}
            uploadFile={uploadFile}
          />
        </td>
      </tr>
    );
  };

  const handleSend = () => {
    const { messageId, recipients } = state;
    setState({
      ...state,
      disableSend: true,
    });
    let values = {
      messageId: messageId,
      recipients: recipients,
    };
    sendPesanpribadi(auth, history, values);
    setTimeout(() => {
      getReport();
    }, 5000);
  };
  const onDelete = () => {
    const { messageId, title } = state;
    openModal('PesanDelete', {
      data: {
        auth,
        history,
        item: { messageId, title },
      },
    });
  };
  const onFormSubmit = () => {
    console.log('submit');
  };

  return (
    <>
      <div className='level'>
        <div className='level-left'>
          <div className='level-item'>
            <nav className='breadcrumb is-size-6' aria-label='breadcrumbs'>
              <ul>
                <li className='is-active has-text-weight-bold'>
                  <Link to={'/portal/pesanpribadi/' + state?.messageId}>
                    Detail
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className='level-right'>
          <div className='level-item'>
            <div className='buttons'>
              <div data-for='main' data-tip='Kirim Pesan'>
                <button
                  disabled={
                    invalid ||
                    loading ||
                    initialValues?.published ||
                    state?.disableSend
                  }
                  onClick={() => handleSend()}
                  className={
                    loading
                      ? 'button is-small is-info is-rounded is-outlined is-loading'
                      : 'button is-small is-info is-rounded is-outlined'
                  }
                >
                  <i className='is-size-6 mdi mdi-message-arrow-right icon' />
                </button>
                <ReactTooltip
                  id='main'
                  place='bottom'
                  type='dark'
                  effect='solid'
                  multiline={true}
                />
              </div>
              <button
                onClick={() => onDelete()}
                className='button is-danger is-small is-rounded is-outlined'
              >
                <i className='is-size-6 mdi mdi-delete icon' />
              </button>
              <button
                type='button'
                onClick={() => history.push('/portal/pesanpribadi')}
                className='button custom-grey is-small is-rounded is-outlined'
              >
                <i className='is-size-6 mdi mdi-arrow-left icon' />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='columns'>
        <div className='column is-third-quarter'>
          <form onSubmit={handleSubmit(onFormSubmit)} autoComplete='off'>
            <Field
              name='title'
              type='text'
              component={TextInput}
              placeholder='Judul'
              label='Judul Pesan'
              readOnly={true}
            />
            <div className='table-container mt-3'>
              <table className='table is-bordered is-striped is-narrow is-hoverable is-fullwidth'>
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Nama Siswa</th>
                    <th>Pesan Pribadi</th>
                    <th>File</th>
                  </tr>
                </thead>
                <tbody>
                  {recipients?.map((item, key) => (
                    <Item
                      item={item}
                      index={key}
                      key={key}
                      files={files}
                      uploadFiles={uploadFiles}
                      FileInput={renderFileInput}
                      reports={reports}
                    />
                  ))}
                </tbody>
              </table>
            </div>
            <div
              className='field is-grouped'
              style={{ marginTop: 20, marginBottom: 20 }}
            >
              <div className='control'>
                <div data-for='main' data-tip='Kirim Pesan'>
                  <button
                    disabled={
                      invalid ||
                      loading ||
                      initialValues?.published ||
                      state?.disableSend
                    }
                    onClick={() => handleSend()}
                    className={
                      loading
                        ? 'button is-info is-small is-rounded is-outlined is-loading'
                        : 'button is-info is-small is-rounded is-outlined'
                    }
                  >
                    <i className='is-size-6 mdi mdi-message-arrow-right icon' />
                  </button>
                  <ReactTooltip
                    id='main'
                    place='bottom'
                    type='dark'
                    effect='solid'
                    multiline={true}
                  />
                </div>
              </div>
              <div className='control'>
                <button
                  onClick={() => history.push('/portal/pesanpribadi')}
                  className='button custom-grey is-small is-rounded is-outlined'
                >
                  <i className='is-size-6 mdi mdi-arrow-left icon' />
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default withRouter(
  reduxForm({
    form: 'viewPesanPribadi',
  })(ViewForm)
);
