import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { addAnnouncement, uploadAnnouncement } from './redux/reduxApi';
import AddForm from './AddForm';
import { format } from 'date-fns';

const mapState = (state, ownProps) => {
  const id = ownProps.match.params.id;
  let uploadFile = state.slideshows.uploadFile;
  return {
    auth: state.auth,
    loading: state.async.loading,
    id: id,
    uploadFile: uploadFile,
  };
};

const actions = {
  addAnnouncement,
  uploadAnnouncement,
};

function Add(props) {
  const {
    auth,
    history,
    loading,
    id,
    uploadFile,
    addAnnouncement,
    uploadAnnouncement,
  } = props;

  const [initialValues, setInitialValues] = useState({
    title: '',
    date: '',
  });

  useEffect(() => {
    setInitialValues({
      title: 'Pengumuman ' + format(new Date(), 'yy.MM.d.hh.mm.ss'),
      date: new Date(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
      <div className='p-1'>
        <div className='columns is-variable'>
          <div className='column is-fullwidth'>
            <div className='card'>
              <header className='card-header has-background-white has-text-info'>
                <div
                  className='custom-card-flex is-size-6'
                  style={{ width: '100%' }}
                >
                  <p>
                    <i className='mdi mdi-account-group is-size-5 icon' />{' '}
                    Portal
                    <i className='mdi mdi-menu-right is-size-6 icon' />
                    Pengumuman
                  </p>
                </div>
              </header>
              <div className='card-content pl-4 pr-4'>
                <AddForm
                  auth={auth}
                  loading={loading}
                  history={history}
                  id={id}
                  uploadFile={uploadFile}
                  initialValues={initialValues}
                  addAnnouncement={addAnnouncement}
                  uploadAnnouncement={uploadAnnouncement}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(connect(mapState, actions)(Add));
