import React, { Component } from 'react';
import { connect } from 'react-redux';
import { deleteTes } from './redux/reduxApi';
import { closeModal } from '../../../app/modals/redux/modalActions';

const actions = {
  closeModal,
  deleteTes,
};

class DeleteItem extends Component {
  onDelete = ({auth, history, item}) => {
    const {deleteTes, closeModal } = this.props;
    deleteTes(auth, history, item.id);
    closeModal();
  };

  render() {
    const { data, closeModal } = this.props;
    const item = data.item;
    return (
      <div className='modal is-active'>
        <div className='modal-background' onClick={closeModal}></div>
        <div className='modal-card'>
          <header className='modal-card-head'>
            <div className='modal-card-title has-text-danger has-text-weight-normal'>
              <i className='is-size-4 mdi mdi-shield-half-full icon' /> Konfirmasi Hapus
            </div>
            <button
              onClick={closeModal}
              className='delete'
              aria-label='close'
            ></button>
          </header>
          <section className='modal-card-body is-size-6'>
            <p className='my-3 mx-1'>
              Apakah Anda yakin ingin menghapus tes{' '}
              <span className='has-text-danger has-text-weight-semibold'>{`${item.title}`}</span>{' '}
              ?
            </p>
          </section>
          <footer className='modal-card-foot'>
            <button
              onClick={() => this.onDelete(data)}
              className='button is-danger is-small is-rounded is-outlined'
            >
              <i className='is-size-6 mdi mdi-delete icon' />
            </button>
            <button
              className='button custom-grey is-small is-rounded is-outlined'
              onClick={closeModal}
            >
              <i className='is-size-6 mdi mdi-arrow-left icon' />
            </button>
          </footer>
        </div>
      </div>
    );
  }
}

export default connect(null, actions)(DeleteItem);
