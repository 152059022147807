import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import ImportFileInput from '../../../app/common/layout/ImportFileInput';
import { importLatihan } from './redux/reduxApi';
import TextInput from '../../../app/common/form/TextInput';
import TextArea from '../../../app/common/form/TextArea';



const mapState = (state) => ({
  auth: state.auth,
  loading: state.async.loading,
});

const actions = {
  importLatihan,
};

class Import extends Component {
  state = {
    inputKey: Date.now(),
    tl: 'Latihan',
    importData: [],
    importResult: [],
    numberOfQuestion: 0,
    title: ' ',
    description: ' ',
  };

  handleExcelUpload = (e) => {
    let file = e.target.files[0];

    const reader = new FileReader();
    if (file) {
      reader.readAsArrayBuffer(file);

      reader.onload = (e) => {
        let sheetData = new Uint8Array(reader.result);
        let wb = XLSX.read(sheetData, { type: 'array' });

        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        // Convert Array
        const data = XLSX.utils.sheet_to_json(ws, { header: 2 });

        const numberOfQuestion =
          data &&
          data[0] &&
          data.reduce((count, key) => {
            if (key.Pertanyaan && key.Pertanyaan.length > 5) {
              count += 1;
            }
            return count;
          }, 0);

        this.setState({
          ...this.state,
          importData: data,
          numberOfQuestion: numberOfQuestion,
        });
      };
    }
  };

  onImport = () => {
    const { auth, history, importLatihan } = this.props;
    const { importData, numberOfQuestion, title, description } = this.state;
    const values = {
      data: importData,
      numberOfQuestion: numberOfQuestion,
      title: title.trim(),
      description: description.trim(),
    };
    importLatihan(auth, history, values);
  };

  onChangeInput = (e, key) => {
    let val = e.target.value;
    if (val.length === 0) {
      val = ' ';
    }
    if (key === 'title') {
      this.setState({
        ...this.state,
        title: val,
      });
    } else if (key === 'description') {
      this.setState({
        ...this.state,
        description: val,
      });
    }
  };

  onCancel = () => {
    this.setState({
      inputKey: Date.now(),
      tl: 'Latihan',
      importData: [],
      importResult: [],
      numberOfQuestion: 0,
      title: ' ',
      description: ' ',
    });
  };

  onGoBack = () => {
    const { history } = this.props;
    this.onCancel();
    history.push('/pembelajaran/latihan');
  };

  render() {
    const { loading, progress } = this.props;
    const { importData, importResult, inputKey, tl } = this.state;
    const successData = importResult;

    return (
      <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
        <div className='p-1'>
          <div className='columns is-variable'>
            <div className='column is-fullwidth'>
              <div className='card'>
                <header className='card-header has-background-white has-text-info'>
                  <div
                    className='custom-card-flex is-size-6'
                    style={{ width: '100%' }}
                  >
                    <p>
                      <i className='mdi mdi-bookshelf is-size-5 icon' />{' '}
                      Pembelajaran
                      <i className='mdi mdi-menu-right is-size-6 icon' />
                      Latihan
                    </p>
                  </div>
                </header>
                <div className='card-content pl-4 pr-4'>
                  <form autoComplete='off' style={{ marginTop: 1 }}>
                    <div className='level'>
                      <div className='level-left'>
                        <div className='level-item'>
                          <nav
                            className='breadcrumb is-size-6'
                            aria-label='breadcrumbs'
                          >
                            <ul>
                              <li className='is-active has-text-weight-bold'>
                                <Link to={'/pembelajaran/latihan/import'}>
                                  Import
                                </Link>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </div>

                      <div className='level-right'>
                        <div className='level-item'>
                          <div className='buttons'>
                            <>
                              <button
                                disabled={loading}
                                onClick={this.props.handleSubmit(this.onImport)}
                                className={
                                  loading
                                    ? 'button is-small is-info is-rounded is-outlined is-loading'
                                    : 'button is-small is-info is-rounded is-outlined'
                                }
                                style={{ marginRight: 10 }}
                              >
                                <i className='is-size-6 mdi mdi-content-save icon' />
                              </button>
                              <button
                                disabled={loading}
                                onClick={this.onCancel}
                                className='button custom-grey is-small is-rounded is-outlined'
                              >
                                <i className='is-size-6 mdi mdi-reload icon' />
                              </button>
                            </>
                            <button
                              disabled={loading}
                              onClick={this.onGoBack}
                              className='button custom-grey is-small is-rounded is-outlined'
                            >
                              <i className='is-size-6 mdi mdi-arrow-left icon' />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {!loading && importData && !importData[0] && (
                      <div className='columns'>
                        <div className='column is-4 is-offset-4'>
                          <div className='field'>
                            <ImportFileInput
                              handleExcelUpload={this.handleExcelUpload}
                              inputKey={inputKey}
                              tl={tl}
                              link={'/templates/Import_Latihan_Template.xlsx'}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {successData && (
                      <div className='columns'>
                        <div className='column is-12'>
                          <div className='table-container'>
                            {successData && successData.length > 0 && (
                              <table className='table is-fullwidth is-hoverable'>
                                <thead className='has-background-info'>
                                  <tr>
                                    <th className='has-text-white'>
                                      Sukses (
                                      {successData && successData.length})
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      {successData &&
                                        successData[0] &&
                                        successData.map((latihan, i) => (
                                          <SuccessItem
                                            latihan={latihan}
                                            key={i}
                                          />
                                        ))}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    {loading && (
                      <div>
                        <h5>Memproses...</h5>
                        <progress
                          className='progress is-small is-info'
                          max='100'
                        >
                          {progress}%
                        </progress>
                      </div>
                    )}
                    {!loading && (
                      <div className='columns'>
                        <div className='column is-12'>
                          {importData && importData[0] && (
                            <>
                              <Field
                                name='title'
                                type='text'
                                defaultValue={this.state.title ?? ' '}
                                onChange={(e) => this.onChangeInput(e, 'title')}
                                component={TextInput}
                                placeholder='Judul Latihan'
                                label='Judul Latihan'
                              />
                              <Field
                                name='description'
                                type='text'
                                defaultValue={this.state.description ?? ' '}
                                onChange={(e) =>
                                  this.onChangeInput(e, 'description')
                                }
                                component={TextArea}
                                label='Deskripsi Latihan'
                                placeholder='Deskripsi Latihan'
                              />
                            </>
                          )}
                          <div className='table-container'>
                            <table className='table is-fullwidth is-hoverable'>
                              {importData && importData[0] && (
                                <thead className='has-background-info'>
                                  <tr>
                                    <th
                                      colSpan={
                                        importData[0] &&
                                        Object.keys(importData[0]).slice(0, -1)
                                          .length
                                      }
                                      className='has-text-white'
                                    >
                                      Pertanyaan
                                    </th>
                                  </tr>
                                </thead>
                              )}
                              <thead>
                                {importData && importData[0] && (
                                  <tr>
                                    {importData[0] &&
                                      Object.keys(importData[0])
                                        .slice(0, -1)
                                        .map((col, index) => {
                                          return <th key={index}>{col}</th>;
                                        })}
                                  </tr>
                                )}
                              </thead>
                              <tbody>
                                {importData && importData[0] && (
                                  <Fragment>
                                    {importData.map((item, index) => {
                                      return (
                                        <Item
                                          item={item}
                                          index={index}
                                          key={index + 1}
                                        />
                                      );
                                    })}
                                  </Fragment>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  mapState,
  actions
)(reduxForm({ form: 'latihanFormExport' })(Import));

export class Item extends Component {
  render() {
    const { item, index } = this.props;
    if (!item.Pertanyaan) {
      return null;
    }
    let pilihan = '';
    if (item?.Tipe === 'pilihan') {
      let newArray = item?.Pilihan.split('\r\n');
      pilihan = newArray.map((pil, index) => (
        <p key={index + '_' + pil}>{pil}</p>
      ));
    }
    return (
      <tr>
        <td>{item?.No ?? index + 1}</td>
        <td>{item?.Tipe}</td>
        <td>{item?.Pertanyaan}</td>
        <td>{pilihan}</td>
        <td>{item?.Jawaban}</td>
        <td>{item?.Penjelasan}</td>
      </tr>
    );
  }
}

export class SuccessItem extends Component {
  render() {
    const { latihan } = this.props;
    return (
      <span
        className='tag is-info is-light'
        style={{
          marginRight: 5,
          marginBottom: 5,
        }}
      >
        {`${latihan.no}`}
      </span>
    );
  }
}
