import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import TextInput from '../../app/common/form/TextInput';
import MultiSelect from '../../app/common/form/MultiSelect';
import { reduxForm, Field } from 'redux-form';

function EditForm(props) {
  const {
    auth,
    id,
    history,
    invalid,
    loading,
    pristine,
    handleSubmit,
    initialValues,
    onFormSubmit,
    openModal,
    scope,
    roles,
  } = props;
  let dataRoles = [];
  const [state, setState] = useState({});
  roles.forEach((i) => {
    dataRoles.push({ value: i.id, label: i.title });
  });

  let currentData =
    initialValues &&
    initialValues.roles &&
    initialValues.roles[0] &&
    initialValues.roles[0].value === 'SA'
      ? true
      : false;

  useEffect(() => {
    if (initialValues && initialValues.username) {
      setState({ ...initialValues });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  const onChangeInput = (e) => {
    e.preventDefault();
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleClikUserDelete = () => {
    openModal('UserDelete', {
      data: {
        auth,
        history,
        item: initialValues,
      },
    });
  };

  return (
    <>
      <div className='level'>
        <div className='level-left'>
          <div className='level-item'>
            <nav className='breadcrumb is-size-6' aria-label='breadcrumbs'>
              <ul>
                <li className='is-active has-text-weight-bold'>
                  <Link to={'/setting/user/edit/' + id}>Edit</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className='level-right'>
          <div className='level-item'>
            <div className='buttons'>
              {!currentData && (
                <>
                  {scope.subm['user_edit'] && (
                    <button
                      disabled={invalid || loading || pristine}
                      onClick={handleSubmit(onFormSubmit)}
                      className={
                        loading
                          ? 'button is-small is-info is-rounded is-outlined is-loading'
                          : 'button is-small is-info is-rounded is-outlined'
                      }
                    >
                      <i className='is-size-6 mdi mdi-content-save icon' />
                    </button>
                  )}
                  {scope.subm['user_delete'] && (
                    <button
                      className='button is-small is-rounded is-danger is-outlined'
                      onClick={() => handleClikUserDelete()}
                    >
                      <i className='is-size-6 mdi mdi-delete icon' />
                    </button>
                  )}
                </>
              )}
              <button
                type='button'
                onClick={() => history.goBack()}
                className='button custom-grey is-small is-rounded is-outlined'
              >
                <i className='is-size-6 mdi mdi-arrow-left icon' />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='columns'>
        <div className='column is-third-quarter'>
          <form onSubmit={handleSubmit(onFormSubmit)} autoComplete='off'>
            <Field
              name='username'
              type='text'
              component={TextInput}
              defaultValue={state && state.username ? state.username : ''}
              onChange={(e) => onChangeInput(e)}
              placeholder='Username'
              label='Username'
              readOnly={currentData}
            />
            <Field
              name='roles'
              component={MultiSelect}
              placeholder='Pilih Role'
              label='Role'
              options={dataRoles}
              isDisabled={currentData}
            />
            {!currentData && (
              <div
                className='field is-grouped'
                style={{ marginTop: 30, marginBottom: 20 }}
              >
                <div className='control'>
                  <button
                    type='submit'
                    disabled={invalid || loading || pristine}
                    className={
                      loading
                        ? 'button is-info is-small is-rounded is-outlined is-loading'
                        : 'button is-info is-small is-rounded is-outlined'
                    }
                  >
                    <i className='is-size-6 mdi mdi-content-save icon' />
                  </button>
                </div>
                <div className='control'>
                  <Link
                    to={'/setting/user/' + initialValues.id}
                    className='button custom-grey is-small is-rounded is-outlined'
                  >
                    <i className='is-size-6 mdi mdi-arrow-left icon' />
                  </Link>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  );
}

export default reduxForm({
  form: 'userEdit',
  enableReinitialize: true,
})(EditForm);
