import {
  KELASS_GET,
  KELAS_JENJANGS_GET,
  KELAS_TINGKATS_GET,
  KELAS_ADD,
  KELAS_EDIT,
  KELAS_GET,
  KELASS_SYNC,
  KELAS_DELETE,
} from './reduxConstant';

export const kelassGet = (kelass) => {
  return {
    type: KELASS_GET,
    payload: {
      kelass,
    },
  };
};

export const kelasJenjangsGet = (jenjangs) => {
  return {
    type: KELAS_JENJANGS_GET,
    payload: {
      jenjangs,
    },
  };
};

export const kelasTingkatsGet = (tingkats) => {
  return {
    type: KELAS_TINGKATS_GET,
    payload: {
      tingkats,
    },
  };
};

export const kelasAdd = (kelas) => {
  return {
    type: KELAS_ADD,
    payload: {
      kelas,
    },
  };
};

export const kelasEdit = (kelas) => {
  return {
    type: KELAS_EDIT,
    payload: {
      kelas,
    },
  };
};

export const kelasGet = (kelas) => {
  return {
    type: KELAS_GET,
    payload: {
      kelas,
    },
  };
};

export const kelassSync = (kelass) => {
  return {
    type: KELASS_SYNC,
    payload: {
      kelass,
    },
  };
};

export const kelasDelete = (kelas) => {
  return {
    type: KELAS_DELETE,
    payload: {
      kelas,
    },
  };
};
