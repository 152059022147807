import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { reset } from 'redux-form';
import {
  getProfile,
  editProfile,
  resetPasswordProfile,
  photoUpload,
} from './redux/reduxApi';
import { SITE_ADDRESS } from '../../app/common/data/siteConfig';
import defaultPhoto from '../../pictures/user-default.png';
import Photo from './Photo';
import ReactTooltip from 'react-tooltip';
import Form from './Form';

function mapState(state) {
  let scope = {};
  if (state.auth && state.auth.isAuth) {
    scope = state.auth.authorities;
  }
  return {
    auth: state.auth,
    profile: state.auth.profile,
    scope: scope,
    loading: state.async.loading,
  };
}

const actions = { getProfile, editProfile, resetPasswordProfile, photoUpload };

function Index(props) {
  let history = useHistory();
  const {
    auth,
    profile,
    loading,
    location,
    getProfile,
    editProfile,
    resetPasswordProfile,
    photoUpload,
  } = props;

  useEffect(() => {
    getProfile(auth, history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
        <div className='p-1'>
          <div className='columns is-variable is-desktop'>
            <div className='column is-12'>
              <div className='card'>
                <header className='card-header has-background-white has-text-info'>
                  <div
                    className='custom-card-flex is-size-6'
                    style={{ width: '100%' }}
                  >
                    <p>
                      <i className='mdi mdi-account-circle is-size-5 icon' />
                      Profil
                    </p>
                  </div>
                </header>
                <div className='card-content pl-4 pr-4'>
                  <div className='level mb-1'>
                    <div className='level-item'>
                      <div data-for='main' data-tip='Ukuran maks. 200Kb'>
                        <Photo
                          site={SITE_ADDRESS}
                          auth={auth}
                          profile={profile}
                          defaultPhoto={defaultPhoto}
                          loading={loading}
                          history={history}
                          photoUpload={photoUpload}
                        />
                      </div>
                      <ReactTooltip
                        id='main'
                        place='bottom'
                        type='dark'
                        effect='solid'
                        multiline={true}
                      />
                    </div>
                  </div>
                  <div className='level mb-3'>
                    <div className='level-item'>
                      <p className='title is-6 is-capitalized'>
                        {auth.username}
                      </p>
                    </div>
                  </div>
                  <div className='columns'>
                    <div className='column'>
                      <Form
                        auth={auth}
                        history={history}
                        profile={profile}
                        loading={loading}
                        pathname={location.pathname}
                        editProfile={editProfile}
                        reset={reset}
                        resetPasswordProfile={resetPasswordProfile}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default connect(mapState, actions)(Index);
