import {
  asyncActionFinish,
  asyncActionError,
} from '../../../app/common/reduxs/async/asyncActions';
import { rolesGet, roleAdd, roleEdit, roleDelete } from './reduxAction';
import { ASYNC_ACTION_START } from '../../../app/common/reduxs/async/asyncConstant';
import { checkErr } from '../../../app/common/helpers/checkRes';
import { fetchFunc } from '../../../app/common/helpers/fetchHelpers';
import { logsFunc } from '../../../app/common/helpers/othersHelpers';

export const getRoles = (
  auth,
  history,
  itemPerPage,
  currentPage,
  searchText
) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getRoles' });
    const formData = new FormData();
    formData.append('itemPerPage', itemPerPage ? itemPerPage : 10);
    formData.append('currentPage', currentPage);
    formData.append('searchText', searchText);
    try {
      const res = await fetchFunc(
        'gets',
        auth,
        history,
        'roles/',
        formData,
        dispatch
      );
      const items = res.items;
      const total = res.total;
      const search = {
        currentPage,
        searchText,
        itemPerPage: itemPerPage ? itemPerPage : 10,
      };
      dispatch(rolesGet({ total, items, search }));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const addRole = (auth, history, title, m, subm) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'addRole' });
    let logs = logsFunc('create', auth.userid, auth.username, null);
    const authorities = {
      m,
      subm,
    };
    const formData = new FormData();
    formData.append('title', title);
    formData.append('authorities', JSON.stringify(authorities));
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'add',
        auth,
        history,
        'roles/add/',
        formData,
        dispatch
      );
      const role = res && res.role ? res.role : undefined;
      if (role) {
        dispatch(roleAdd(role));
        history.push('/setting/role/' + role.id);
      }
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const getRole = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getRole' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'get',
        auth,
        history,
        'roles/detail/' + id,
        formData,
        dispatch
      );
      const role = res.role;
      dispatch(roleAdd(role));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const editRole = (auth, history, roleId, title, m, subm, dataLogs) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'editRole' });
    let logs = logsFunc('edit', auth.userid, auth.username, dataLogs);
    const authorities = {
      m,
      subm,
    };
    const formData = new FormData();
    formData.append('title', title);
    formData.append('authorities', JSON.stringify(authorities));
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'roles/edit/' + roleId,
        formData,
        dispatch
      );
      const role = res.role;
      dispatch(roleEdit(role));
      history.push('/setting/role/' + roleId);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const deleteRole = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'deleteRole' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'delete',
        auth,
        history,
        'roles/delete/' + id,
        formData,
        dispatch
      );
      const role = res.role;
      dispatch(roleDelete(role));
      history.push('/setting/role');
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};
