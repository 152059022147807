import React, { Component } from 'react';

export default class PageNumber extends Component {
  state = {
    value: 10,
  };
  handleSelectItemPerPage = (event) => {
    this.setState({
      value: event.target.value,
    });
    this.props.handleItemPerPage(event.target.value);
  };
  handleClickPage = (number) => {
    this.props.handlePage(number);
  };
  render() {
    const { currentPage, itemPerPage, total, title } = this.props;
    return (
      <div className='level ml-4 mr-4'>
      <div className='level-left'>
        <div className='level-item'>
          <div className='select is-small is-rounded'>
            <select value={itemPerPage} onChange={this.handleSelectItemPerPage}>
              <option>10</option>
              <option>20</option>
              <option>50</option>
              <option>100</option>
            </select>
          </div>
        </div>
      </div>
        <div className='level-right'>
          <div className='level-item'>
            <div className='field has-addons'>
              <div className='control'>
                <button
                  disabled={currentPage === 1}
                  onClick={() => this.handleClickPage(-1)}
                  className='button is-small is-rounded is-info is-outlined'
                >
                  <span className='icon is-small'>
                    <i className='fas fa-chevron-left' />
                  </span>
                </button>
              </div>
              <div className='control'>
                <div className='button is-small is-disabled'>
                  {total > 0 && (
                    <span>
                      {(currentPage - 1) * itemPerPage + 1}-
                      {currentPage === Math.ceil(total / itemPerPage)
                        ? total !== itemPerPage
                          ? (currentPage - 1) * itemPerPage + (total % itemPerPage)
                          : itemPerPage
                        : currentPage * itemPerPage}{' '}
                      dari {total ? total : ''} {title}
                    </span>
                  )}
                  {total < 1 && <span>0 {title}</span>}
                </div>
              </div>
              <div className='control'>
                <button
                  disabled={currentPage === Math.ceil(total / itemPerPage)}
                  onClick={() => this.handleClickPage(1)}
                  className='button is-small is-rounded is-info is-outlined'
                >
                  <span className='icon is-small'>
                    <i className='fas fa-chevron-right' />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
