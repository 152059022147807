import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import ImportFileInput from '../../../app/common/layout/ImportFileInput';
import { getPesanpribadiPortalusers, addPesanpribadi } from './redux/reduxApi';
import TextInput from '../../../app/common/form/TextInput';
import { nanoid } from 'nanoid';
import { format } from 'date-fns';
import { isNumeric } from 'revalidate';

const mapState = (state, ownProps) => {
  const id = ownProps.match.params.id;
  return {
    auth: state.auth,
    loading: state.async.loading,
    messageId: id,
    pesanpribadis: state.pesanpribadis,
  };
};

const actions = {
  addPesanpribadi,
  getPesanpribadiPortalusers,
};

class Import extends Component {
  state = {
    inputKey: Date.now(),
    tl: 'Pesan',
    headerCols: ['No', 'NIS', 'Nama Siswa', 'Variabel', 'Pesan'],
    numberOfStudents: 0,
    messageId: null,
    title: null,
    description: ' ',
    recipients: [],
  };

  componentDidMount = () => {
    const { messageId, auth, history, getPesanpribadiPortalusers } = this.props;
    this.setState({
      ...this.state,
      messageId: messageId,
      title: 'Pesan ' + format(new Date(), 'd MMM yyyy hh:mm:ss'),
    });
    getPesanpribadiPortalusers(auth, history);
  };

  handleSelectedExcel = (e) => {
    const { pesanpribadis, messageId } = this.props;
    let portalusers = pesanpribadis?.portalusers;
    let file = e.target.files[0];

    const reader = new FileReader();
    if (file) {
      reader.readAsArrayBuffer(file);

      reader.onload = (e) => {
        let sheetData = new Uint8Array(reader.result);
        let wb = XLSX.read(sheetData, { type: 'array' });

        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        // Read excel to JSON
        const data = XLSX.utils.sheet_to_json(ws, { raw: false });
        let countVar = 0;
        let templateMsg = data[0] && data[0].Pesan;
        for (let h = 0; h < 10; h++) {
          if (templateMsg.includes(`[${1 + h}]`)) {
            countVar++;
          }
        }
        let recipients = [];
        for (let i = 0; i < data.length; i++) {
          const el = data[i];
          let student = portalusers.find((x) => {
            return x.nis + '' === el.NIS + '';
          });
          let variable = [];
          for (let k = 0; k < countVar; k++) {
            let newVar = el[`Variabel${k + 1}`];
            let valNewVar = isNumeric(newVar)
              ? newVar.split(',').join('.')
              : newVar;
            if (newVar) {
              variable.push(valNewVar);
            } else {
              variable.push('');
            }
          }
          let newMessages = el?.Pesan;
          for (let j = 0; j < countVar; j++) {
            const ej = variable[j];
            newMessages = newMessages.split(`[${j + 1}]`).join(ej + '');
          }
          recipients.push({
            ...student,
            messageId,
            studentMessageId: nanoid(),
            messages: newMessages,
            variable: variable,
          });
        }
        this.setState({
          ...this.state,
          recipients: recipients,
          numberOfStudents: data?.length,
        });
      };
    }
  };

  onImport = async () => {
    const { auth, history, addPesanpribadi } = this.props;
    const { messageId, title, description, numberOfStudents, recipients } =
      this.state;

    const values = {
      messageId,
      title: title.trim(),
      description,
      numberOfStudents,
      recipients,
    };
    addPesanpribadi(auth, history, values);
  };

  onChangeInput = (e, key) => {
    let val = e.target.value;
    if (val.length === 0) {
      val = ' ';
    }
    if (key === 'title') {
      this.setState({
        ...this.state,
        title: val,
      });
    }
  };

  onCancel = () => {
    this.setState({
      inputKey: Date.now(),
      tl: 'Pesan',
      messages: '',
      numberOfStudents: 0,
      title: 'Pesan ' + format(new Date(), 'd MMM yyyy hh:mm:ss'),
      description: '',
    });
  };

  onGoBack = () => {
    const { history } = this.props;
    this.onCancel();
    history.push({
      pathname: '/portal/pesanpribadi',
      state: { data: 'nodata' },
    });
  };

  render() {
    const { loading, handleSubmit } = this.props;
    const { messages, inputKey, tl, messageId, headerCols, recipients } =
      this.state;

    return (
      <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
        <div className='p-1'>
          <div className='columns is-variable'>
            <div className='column is-fullwidth'>
              <div className='card'>
                <header className='card-header has-background-white has-text-info'>
                  <div
                    className='custom-card-flex is-size-6'
                    style={{ width: '100%' }}
                  >
                    <p>
                      <i className='mdi mdi-account-group is-size-5 icon' />{' '}
                      Portal
                      <i className='mdi mdi-menu-right is-size-6 icon' />
                      Pesan Pribadi
                    </p>
                  </div>
                </header>
                <div className='card-content pl-4 pr-4'>
                  <form autoComplete='off' style={{ marginTop: 1 }}>
                    <div className='level'>
                      <div className='level-left'>
                        <div className='level-item'>
                          <nav
                            className='breadcrumb is-size-6'
                            aria-label='breadcrumbs'
                          >
                            <ul>
                              <li className='is-active has-text-weight-bold'>
                                <Link
                                  to={'/portal/pesanpribadi/impor/' + messageId}
                                >
                                  Impor
                                </Link>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </div>

                      <div className='level-right'>
                        <div className='level-item'>
                          <div className='buttons'>
                            {!loading && recipients && recipients[0] && (
                              <>
                                <button
                                  disabled={loading}
                                  onClick={handleSubmit(this.onImport)}
                                  className={
                                    loading
                                      ? 'button is-small is-info is-rounded is-outlined is-loading'
                                      : 'button is-small is-info is-rounded is-outlined'
                                  }
                                  style={{ marginRight: 10 }}
                                >
                                  <i className='is-size-6 mdi mdi-content-save icon' />
                                </button>
                                <button
                                  disabled={loading}
                                  onClick={this.onCancel}
                                  className='button custom-grey is-small is-rounded is-outlined'
                                >
                                  <i className='is-size-6 mdi mdi-reload icon' />
                                </button>
                              </>
                            )}
                            <button
                              disabled={loading}
                              onClick={this.onGoBack}
                              className='button custom-grey is-small is-rounded is-outlined'
                            >
                              <i className='is-size-6 mdi mdi-arrow-left icon' />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {!loading && recipients && !recipients[0] && (
                      <div className='columns'>
                        <div className='column is-4 is-offset-4'>
                          <div className='field'>
                            <ImportFileInput
                              handleExcelUpload={this.handleSelectedExcel}
                              inputKey={inputKey}
                              tl={tl}
                              link={'/templates/Import_Pesan_Template.xlsx'}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {!loading && (
                      <div className='columns'>
                        <div className='column is-12'>
                          {recipients && recipients[0] && (
                            <>
                              <Field
                                name='title'
                                type='text'
                                defaultValue={this.state.title ?? ' '}
                                onChange={(e) => this.onChangeInput(e, 'title')}
                                component={TextInput}
                                placeholder='Judul'
                                label='Judul'
                              />
                              <br />
                            </>
                          )}
                          <div className='table-container overflow-auto'>
                            <table className='table is-hoverable'>
                              {messages && messages[0] && (
                                <thead className='has-background-info'>
                                  <tr>
                                    <th
                                      colSpan={headerCols?.length}
                                      className='has-text-white'
                                    >
                                      Penerima Pesan
                                    </th>
                                  </tr>
                                </thead>
                              )}
                              <thead>
                                {recipients && recipients[0] && (
                                  <tr>
                                    {headerCols?.map((i, key) => (
                                      <th key={key}>{i}</th>
                                    ))}
                                  </tr>
                                )}
                              </thead>
                              <tbody>
                                {recipients && recipients[0] && (
                                  <Fragment>
                                    {recipients.map((item, index) => {
                                      return (
                                        <Item
                                          item={item}
                                          index={index}
                                          key={index}
                                        />
                                      );
                                    })}
                                  </Fragment>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  mapState,
  actions
)(reduxForm({ form: 'pesanpribadiFormImport' })(Import));

export class Item extends Component {
  render() {
    const { item, index } = this.props;
    return (
      <tr>
        <td>{index + 1}</td>
        <td>{item?.nis}</td>
        <td>{item?.name}</td>
        <td>
          {item?.variable?.length > 0 &&
            item?.variable?.map((v, key) => (
              <span key={key} className='tag mr-1'>
                {v + ''}
              </span>
            ))}
        </td>
        <td>
          <p style={{ whiteSpace: 'pre-wrap' }}>{item?.messages}</p>
        </td>
      </tr>
    );
  }
}
