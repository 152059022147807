import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import TextInput from '../../../app/common/form/TextInput';
import TextArea from '../../../app/common/form/TextArea';
import RadioInput from '../../../app/common/form/RadioInput';
import { sortArrayObject } from '../../../app/common/helpers/objectHelpers';
import { SITE_ADDRESS } from '../../../app/common/data/siteConfig';

function ViewForm(props) {
  const { auth, history, scope, openModal, id, initialValues } = props;
  const [state, setState] = useState({
    answers: [],
    keyAnswers: [],
    scoreCorrection: [],
    activeQuestion: 1,
    confirmSubmit: false,
    submitStatus: false,
    showResult: false,
    start: false,
    ss: 0,
    mm: 0,
    hh: 0,
    time: '00:00:00',
  });

  let activeQuestion = state && state.activeQuestion;
  let questions =
    initialValues &&
    initialValues.questions &&
    JSON.parse(initialValues.questions);
  let questionsLength = questions && questions.length;
  let latihanAnswers =
    initialValues && initialValues.answers && JSON.parse(initialValues.answers);
  let tags =
    initialValues && initialValues.tags && JSON.parse(initialValues.tags);

  let answers = state && state.answers;

  let isPublished =
    initialValues && initialValues.published === true ? true : false;

  useEffect(() => {
    let as = [];
    questions &&
      questions.forEach((item) => {
        return (as = [...as, { no: item.no, key: item.key, answer: '' }]);
      });
    setState({
      ...state,
      answers: as,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  useEffect(() => {
    let timer;
    if (state.start) {
      timer = setTimeout(() => {
        let ss = state.ss + 1;
        let mm = state.mm;
        let hh = state.hh;
        if (ss > 59) {
          ss = 0;
          mm = state.mm + 1;
        }
        if (mm > 59) {
          mm = 0;
          hh = state.hh + 1;
        }
        setState({
          ...state,
          ss: ss,
          mm: mm,
          hh: hh,
        });
      }, 1000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [state]);

  const handleInputAnswer = (e, item) => {
    e.preventDefault();
    let answer = {
      no: item.no,
      key: item.key,
      answer: e.target.value,
    };
    let answers = [...state.answers.filter((i) => i.key !== item.key), answer];
    setState({
      ...state,
      answers: sortArrayObject(answers, 'no', 'asc'),
    });
  };

  const handleCheckRadio = (e, item, opt) => {
    let answer = {
      no: item.no,
      key: item.key,
      answer: opt,
    };
    let answers = [...state.answers.filter((i) => i.key !== item.key), answer];
    setState({
      ...state,
      answers: sortArrayObject(answers, 'no', 'asc'),
    });
  };

  const handleStart = () => {
    setState({
      ...state,
      start: !state.start,
    });
  };

  const handleCountTime = () => {
    let time = `${state.hh}:${state.mm < 10 ? '0' : ''}${state.mm}:${
      state.ss < 10 ? '0' : ''
    }${state.ss}`;
    return time;
  };

  const handleReset = (e) => {
    e.preventDefault();
    setState({
      ...state,
      answers: [],
      keyAnswers: [],
      scoreCorrection: [],
      activeQuestion: 1,
      confirmSubmit: false,
      submitStatus: false,
      showResult: false,
      start: false,
      ss: 0,
      mm: 0,
      hh: 0,
      time: '00:00:00',
    });
  };

  const handleSubmitAnswer = (e) => {
    e.preventDefault();
    let emptyAnswer = false;
    state &&
      state.answers &&
      state.answers.forEach((item) => {
        if (item.answer === '') {
          emptyAnswer = true;
        }
      });
    if (emptyAnswer) {
      setState({
        ...state,
        confirmSubmit: true,
        submitStatus: false,
        showResult: false,
      });
    } else {
      let time = handleCountTime();
      setState({
        ...state,
        confirmSubmit: true,
        submitStatus: true,
        showResult: true,
        start: false,
        ss: 0,
        mm: 0,
        hh: 0,
        time: time,
      });

      history.push({
        pathname: '/pembelajaran/latihan/result/' + id,
        state: {
          ...initialValues,
          ...state,
          questions: questions,
          time: time,
          keyAnswers: latihanAnswers,
          tags: tags,
        },
      });
    }
  };

  const handleConfirmSubmit = (e) => {
    e.preventDefault();
    let time = handleCountTime();
    setState({
      ...state,
      confirmSubmit: true,
      submitStatus: true,
      showResult: true,
      start: false,
      ss: 0,
      mm: 0,
      hh: 0,
      time: time,
    });

    history.push({
      pathname: '/pembelajaran/latihan/result/' + id,
      state: {
        ...initialValues,
        ...state,
        questions: questions,
        time: time,
        keyAnswers: latihanAnswers,
        tags: tags,
      },
    });
  };

  const handleCancelSubmit = (e) => {
    e.preventDefault();
    setState({
      ...state,
      confirmSubmit: !state.confirmSubmit,
    });
  };

  const handlePrevNextQuestion = (e, val) => {
    e.preventDefault();
    let aQ = activeQuestion + val;
    setState({
      ...state,
      activeQuestion: aQ,
    });
  };

  const onClickLatihanDelete = (item) => {
    let data = {
      ...item,
      id: id,
    };
    openModal('LatihanDelete', {
      data: {
        auth,
        history,
        item: data,
      },
    });
  };

  return (
    <>
      <div className='level'>
        <div className='level-left'>
          <div className='level-item'>
            <nav className='breadcrumb is-size-6' aria-label='breadcrumbs'>
              <ul>
                <li className='is-active has-text-weight-bold'>
                  <Link to={'/pembelajaran/latihan/' + id}>Preview</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className='level-right'>
          <div className='level-item'>
            <div className='buttons'>
              {scope && scope.subm && scope.subm['latihan_publish'] && (
                <Link
                  to={`/pembelajaran/latihan/publish/${id}`}
                  className='button is-info is-small is-rounded is-outlined'
                >
                  <i className='is-size-6 mdi mdi-share icon' />
                </Link>
              )}
              {scope && scope.subm && scope.subm['latihan_edit'] && (
                <Link
                  to={`/pembelajaran/latihan/edit/${id}`}
                  className='button is-small is-rounded is-info is-outlined'
                >
                  <i className='is-size-6 mdi mdi-pencil icon' />
                </Link>
              )}
              {!isPublished && (
                <>
                  {scope && scope.subm && scope.subm['latihan_delete'] && (
                    <button
                      className='button is-small is-rounded is-danger is-outlined'
                      onClick={() => onClickLatihanDelete(state)}
                    >
                      <i className='is-size-6 mdi mdi-delete icon' />
                    </button>
                  )}
                </>
              )}
              <Link
                to='/pembelajaran/latihan'
                className='button custom-grey is-small is-rounded is-outlined'
              >
                <i className='is-size-6 mdi mdi-arrow-left icon' />
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div id='fadein' className='columns'>
        <div className='column is-third-quarter overflow-auto'>
          <div className='content'>
            <div className='flex justify-between align-center mb-5'>
              <div className='has-text-weight-bold'>
                <Link to={'/pembelajaran/latihan/' + id}>
                  {initialValues.title}
                </Link>
              </div>
              <div className='flex'>
                {questionsLength > 1 &&
                  activeQuestion === questionsLength &&
                  !state?.confirmSubmit && (
                    <div className='flex justify-center mb-3'>
                      <button
                        onClick={(e) => handleSubmitAnswer(e)}
                        className='button is-small is-rounded is-info is-outlined ml-1'
                      >
                        Submit
                      </button>
                    </div>
                  )}
                {state &&
                  state.start &&
                  questionsLength === 1 &&
                  !state?.confirmSubmit && (
                    <div className='flex justify-center mb-3'>
                      <button
                        onClick={(e) => handleSubmitAnswer(e)}
                        className='button is-small is-rounded is-info is-outlined ml-1'
                      >
                        Submit
                      </button>
                    </div>
                  )}
                {state?.start && (
                  <>
                    <button
                      onClick={(e) => handleReset(e)}
                      className='button is-small is-rounded is-info is-outlined ml-1'
                    >
                      Stop
                    </button>
                    <button
                      className='button is-small is-rounded is-info is-outlined ml-1'
                      style={{ width: 100 }}
                    >{`${state.hh < 10 ? '0' : ''}${state.hh}:${
                      state.mm < 10 ? '0' : ''
                    }${state.mm}:${state.ss < 10 ? '0' : ''}${
                      state.ss
                    }`}</button>
                  </>
                )}
                {!state?.start && !state?.showResult && (
                  <button
                    onClick={(e) => handleStart(e)}
                    className='button is-small is-rounded is-info is-outlined ml-1'
                  >
                    Start
                  </button>
                )}
              </div>
            </div>
            {!state?.start && !state?.showResult && (
              <div className='flex flex-column mb-4' style={{ marginTop: -12 }}>
                <article className='message is-info'>
                  <div className='message-body is-size-7'>
                    <p>Deskripsi:</p>
                    <p
                      className='is-capitalized'
                      style={{ whiteSpace: 'pre-line' }}
                    >
                      {initialValues.description}
                    </p>
                  </div>
                </article>
              </div>
            )}

            {state?.start && !state?.submitStatus && !state?.confirmSubmit && (
              <div className='flex justify-center'>
                <button
                  disabled={activeQuestion === 1}
                  className='button is-small is-rounded is-info is-outlined mr-1'
                  onClick={(e) => handlePrevNextQuestion(e, -1)}
                >
                  <i className='fas fa-arrow-left icon' />
                </button>
                <div className='ml-1 mr-1'>
                  {activeQuestion}/{questions.length}
                </div>
                <button
                  disabled={activeQuestion === questions.length || !state.start}
                  className='button is-small is-rounded is-info is-outlined ml-1'
                  onClick={(e) => handlePrevNextQuestion(e, 1)}
                >
                  <i className='fas fa-arrow-right icon' />
                </button>
              </div>
            )}
            {!state?.submitStatus && !state?.confirmSubmit && (
              <>
                <div className='mb-2' style={{ whiteSpace: 'pre-line' }}>
                  {questions && questions[activeQuestion - 1] && (
                    <>
                      <div>
                        {questions[activeQuestion - 1].picture &&
                          questions[activeQuestion - 1].picture.filelink && (
                            <img
                              src={
                                SITE_ADDRESS +
                                questions[activeQuestion - 1].picture.filelink
                              }
                              alt=''
                              style={{ maxHeight: 360 }}
                            />
                          )}
                      </div>
                      <div>{questions[activeQuestion - 1].question}</div>
                    </>
                  )}
                </div>
                {questions &&
                  questions[activeQuestion - 1] &&
                  questions[activeQuestion - 1].questionType !==
                    'optionQuestion' && (
                    <div
                      className={`${
                        !state.start === true ? 'avoid-click' : ''
                      }`}
                    >
                      <Field
                        name={'answers_' + questions[activeQuestion - 1].key}
                        type='text'
                        component={
                          questions &&
                          questions[activeQuestion - 1] &&
                          questions[activeQuestion - 1].questionType ===
                            'shortQuestion'
                            ? TextInput
                            : TextArea
                        }
                        defaultValue={
                          answers &&
                          answers[activeQuestion - 1] &&
                          answers[activeQuestion - 1].answer
                            ? answers[activeQuestion - 1].answer
                            : ' '
                        }
                        onChange={(e) =>
                          handleInputAnswer(e, questions[activeQuestion - 1])
                        }
                        placeholder='Jawaban'
                        label='Jawaban'
                      />
                    </div>
                  )}
                {questions &&
                  questions[activeQuestion - 1] &&
                  questions[activeQuestion - 1].options &&
                  questions[activeQuestion - 1].options[0] &&
                  questions[activeQuestion - 1].options.map((opt, index) => {
                    let ans =
                      answers &&
                      answers &&
                      answers[activeQuestion - 1] &&
                      answers[activeQuestion - 1].answer;
                    return (
                      <div
                        key={index}
                        className={`has-background-grey-lighter px-2 py-1 mb-1 flex justify-between align-center${
                          !state.start === true ? ' avoid-click' : ''
                        }`}
                      >
                        <Field
                          key={index}
                          name='answer'
                          type='radio'
                          component={RadioInput}
                          value={opt}
                          checked={opt === ans ? true : false}
                          onChange={(e) =>
                            handleCheckRadio(
                              e,
                              questions[activeQuestion - 1],
                              opt
                            )
                          }
                          placeholder={opt}
                          label={opt}
                          size='is-size-6'
                        />
                      </div>
                    );
                  })}
              </>
            )}
            {state &&
              state.confirmSubmit &&
              !state.submitStatus &&
              !state.showResult && (
                <div className='has-text-centered'>
                  <p>
                    Belum semua pertanyaan terjawab, <br />
                    apakah tetap ingin submit jawaban?
                  </p>
                  <div
                    className='flex justify-center mt-3'
                    style={{ marginTop: -8 }}
                  >
                    <button
                      onClick={(e) => handleConfirmSubmit(e, 'reset')}
                      className='button is-small is-rounded is-info is-outlined ml-1'
                    >
                      Konfirm
                    </button>
                    <button
                      onClick={(e) => handleCancelSubmit(e)}
                      className='button is-small is-rounded is-info is-outlined ml-1'
                    >
                      Batal
                    </button>
                  </div>
                </div>
              )}
            <hr />
            <div className='field'>
              <label className='label'>Status</label>
              <div className='control'>
                <div className='tags has-addons'>
                  <span className='tag'>Publish</span>
                  <span
                    className={`tag ${
                      initialValues?.published
                        ? 'is-info'
                        : 'has-background-grey has-text-white'
                    }`}
                  >
                    {initialValues?.published ? 'Yes' : 'No'}
                  </span>
                </div>
              </div>
            </div>
            <div className='field'>
              <label className='label'>Tags</label>
              <div className='control'>
                <div className='tags is-capitalized'>
                  {initialValues &&
                    initialValues.tags &&
                    JSON.parse(initialValues.tags).map((item, index) => (
                      <span key={index} className='tag is-info is-light'>
                        {item}
                      </span>
                    ))}
                  {initialValues && !initialValues.tags && (
                    <span className='tag'>None</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default reduxForm({
  form: 'latihanView',
  enableReinitialize: true,
})(ViewForm);
