import React, { useState } from 'react';
import { connect } from 'react-redux';
import { closeModal } from '../../modals/redux/modalActions';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const actions = {
  closeModal,
};

function PopUpPdfModal(props) {
  const { data, closeModal } = props;
  const url = data;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const handlePageNumber = (e, val) => {
    setPageNumber(pageNumber + val);
  };

  function onDocumentLoadSuccess({ numPages }) {
    console.log('load...');
    setNumPages(numPages);
  }

  return (
    <div className='modal is-active'>
      <div className='modal-background' onClick={closeModal}></div>
      <div className='modal-content'>
        <div className='flex justify-center flex-column'>
          <p className='mt-2 mb-2 has-text-white'>
            Page {pageNumber} of {numPages}{' '}
            <button
              disabled={pageNumber === 1}
              onClick={(e) => handlePageNumber(e, -1)}
              className='button is-small is-rounded is-outlined ml-2 mr-2 has-text-white'
              style={{ backgroundColor: 'rgba(255, 0, 0, 0)' }}
            >
              <i className='fas fa-chevron-left icon' />
            </button>
            <button
              disabled={pageNumber === numPages}
              onClick={(e) => handlePageNumber(e, 1)}
              className='button is-small is-rounded is-outlined ml-2 mr-2 has-text-white'
              style={{ backgroundColor: 'rgba(255, 0, 0, 0)' }}
            >
              <i className='fas fa-chevron-right icon' />
            </button>
          </p>
          <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} />
          </Document>
          <p className='mt-2 mb-2 has-text-white'>
            Page {pageNumber} of {numPages}{' '}
            <button
              disabled={pageNumber === 1}
              onClick={(e) => handlePageNumber(e, -1)}
              className='button is-small is-rounded is-outlined ml-2 mr-2 has-text-white'
              style={{ backgroundColor: 'rgba(255, 0, 0, 0)' }}
            >
              <i className='fas fa-chevron-left icon' />
            </button>
            <button
              disabled={pageNumber === numPages}
              onClick={(e) => handlePageNumber(e, 1)}
              className='button is-small is-rounded is-outlined ml-2 mr-2 has-text-white'
              style={{ backgroundColor: 'rgba(255, 0, 0, 0)' }}
            >
              <i className='fas fa-chevron-right icon' />
            </button>
          </p>
        </div>
        {/* <object
          type='application/pdf'
          data={url}
          // style={{ width: '100%', height: '100vh' }}
        >
          <param
            name='data'
            value={url}
            // style={{ width: '100%', height: '100vh' }}
          />
        </object> */}
      </div>
      <button
        className='modal-close is-large'
        aria-label='close'
        onClick={closeModal}
      ></button>
    </div>
  );
}

export default connect(null, actions)(PopUpPdfModal);
