import { SESSIONSTATE_CHANGE, SESSIONSTATE_DELETE } from './reduxConstant';

export const sessionStateChange = (sessionState) => {
  return {
    type: SESSIONSTATE_CHANGE,
    payload: {
      sessionState,
    },
  };
};

export const sessionStateDelete = (sessionState) => {
  return {
    type: SESSIONSTATE_DELETE,
    payload: {
      sessionState,
    },
  };
};
