import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { getTes } from './redux/reduxApi';
import ViewForm from './ViewForm';
import { openModal } from '../../../app/modals/redux/modalActions';

const mapState = (state, ownProps) => {
  const id = ownProps.match.params.id;
  let scope = {};
  if (state.auth && state.auth.isAuth) {
    scope = state.auth.authorities;
  }
  let tes = {};
  if (state.allTes && state.allTes.items && state.allTes.items[0]) {
    tes = state.allTes.items.filter((i) => i.id + '' === id + '')[0];
  }
  return {
    id: id,
    loading: state.async.loading,
    auth: state.auth,
    scope: scope,
    tes: tes,
  };
};

const actions = {
  openModal,
  getTes,
};

function View(props) {
  const { auth, history, scope, openModal, id, tes, getTes } = props;
  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    getTes(auth, history, id);
    const setValues = { ...tes };
    setInitialValues(setValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFormSubmit = async (values) => {
    console.log('submit form');
  };

  return (
    <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
      <div className='p-1'>
        <div className='columns is-variable'>
          <div className='column is-fullwidth'>
            <div className='card'>
                <header className='card-header has-background-white has-text-info'>
                  <div
                    className='custom-card-flex is-size-6'
                    style={{ width: '100%' }}
                  >
                    <p>
                      <i className='mdi mdi-bookshelf is-size-5 icon' />{' '}
                      Pembelajaran<i className='mdi mdi-menu-right is-size-6 icon' />Tes
                    </p>
                  </div>
                </header>
              <div className='card-content pl-4 pr-4'>
                <ViewForm
                  auth={auth}
                  history={history}
                  scope={scope}
                  openModal={openModal}
                  id={id}
                  initialValues={initialValues}
                  onFormSubmit={onFormSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(
  connect(mapState, actions)(reduxForm({ form: 'viewTes' })(View))
);
