import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  getKelasJenjangs,
  getKelasTingkats,
  syncKelas,
  syncSaveKelas,
} from './redux/reduxApi';
import LoadingButton from '../../../app/common/layout/LoadingButton';
import { nanoid } from 'nanoid';

function mapState(state) {
  return {
    auth: state.auth,
    loading: state.async.loading,
    kelass: state.kelass,
  };
}

const actions = {
  getKelasJenjangs,
  getKelasTingkats,
  syncKelas,
  syncSaveKelas,
};

function Sync(props) {
  let history = useHistory();
  const [state, setState] = useState({
    title: 'Sync Data Kelas',
    kelassSync: [],
    kelassExisting: [],
    kelassFilter: [],
    jenjangs: [],
    tingkats: [],
  });
  const {
    getKelasJenjangs,
    getKelasTingkats,
    syncKelas,
    syncSaveKelas,
    auth,
    loading,
    kelass,
  } = props;

  useEffect(() => {
    getKelasJenjangs(auth, history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getKelasTingkats(auth, history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    syncKelas(auth, history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let dataKelassSync = kelass?.kelassSync;
    let kelassSync = [];
    for (let i = 0; i < dataKelassSync.length; i++) {
      const el = dataKelassSync[i];
      kelassSync.push({
        code: el.KODE,
        title: el.KODE,
        jenjang: el.JENJANG,
        tingkat: el.TINGKAT,
        selected: false,
      });
    }

    let kelassExisting = kelass?.items;
    let kelassFilter = [];
    if (kelassExisting?.length > 0) {
      // filter array object with other array object
      kelassFilter = kelassSync.filter((el) => {
        return kelassExisting.every((f) => {
          return f.code !== el.code;
        });
      });
    } else {
      kelassFilter = kelassSync;
    }
    let jenjangs = kelass?.jenjangs;
    let tingkats = kelass?.tingkats;
    setState({
      kelassSync: kelassSync,
      kelassExisting: kelassExisting,
      kelassFilter: kelassFilter,
      jenjangs: jenjangs,
      tingkats: tingkats,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kelass]);

  const onChangeCheckbox = (e) => {
    e.preventDefault();
    let kelassFilter = [...state?.kelassFilter];
    for (const obj of kelassFilter) {
      if (obj.code === e.target.name) {
        obj.selected = obj.selected === undefined ? true : !obj.selected;
        break;
      }
    }
    setState({
      ...state,
      kelassFilter: kelassFilter,
    });
  };

  const onSelectAll = (e) => {
    e.preventDefault();
    let kelassFilter = [...state?.kelassFilter];
    let newKelassFilter = [];
    for (const obj of kelassFilter) {
      newKelassFilter.push({ ...obj, selected: true });
    }
    setState({
      ...state,
      kelassFilter: newKelassFilter,
    });
  };

  const onUnselectAll = (e) => {
    e.preventDefault();
    let kelassFilter = [...state?.kelassFilter];
    let newKelassFilter = [];
    for (const obj of kelassFilter) {
      newKelassFilter.push({ ...obj, selected: false });
    }
    setState({
      ...state,
      kelassFilter: newKelassFilter,
    });
  };

  const onSave = (e) => {
    e.preventDefault();
    syncSaveKelas(
      auth,
      history,
      state?.kelassFilter,
      state?.jenjangs,
      state?.tingkats
    );
  };

  return (
    <>
      <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
        <div className='p-1'>
          <div className='columns is-variable is-desktop'>
            <div className='column is-12'>
              <div className='card'>
                <header className='card-header has-background-white has-text-info'>
                  <div
                    className='custom-card-flex is-size-6'
                    style={{ width: '100%' }}
                  >
                    <p>
                      <i className='mdi mdi-school is-size-5 icon' /> Akademik
                      <i className='mdi mdi-menu-right is-size-6 icon' />
                      Kelas
                    </p>
                  </div>
                </header>
                <div className='card-content'>
                  <div className='content'>
                    <div className='level' style={{ marginTop: -20 }}>
                      <div className='level-left'></div>

                      <div className='level-right mr-4'>
                        <div
                          className='level-item'
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <button
                            onClick={() => history.goBack()}
                            className='button custom-grey is-small is-rounded is-outlined'
                          >
                            <i className='is-size-6 mdi mdi-arrow-left icon' />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div
                      className='table-container ml-4 mr-4'
                      style={{ marginTop: -16, marginBottom: 20 }}
                    >
                      <table className='table is-fullwidth is-bordered is-hoverable'>
                        <thead>
                          <tr>
                            <th className='has-text-centered'>No</th>
                            <th>Kode</th>
                            <th>Nama</th>
                            <th className='has-text-centered'>Tingkat</th>
                            <th className='has-text-centered'>Jenjang</th>
                            <th className='has-text-centered'>Aksi</th>
                          </tr>
                        </thead>
                        <tbody>
                          {loading ? (
                            <tr>
                              <td>
                                <LoadingButton />
                              </td>
                            </tr>
                          ) : (
                            <IndexList
                              auth={auth}
                              items={state?.kelassExisting}
                              kelassFilter={state?.kelassFilter}
                              jenjangs={state?.jenjangs}
                              tingkats={state?.tingkats}
                              title={state.title}
                              onChangeCheckbox={onChangeCheckbox}
                              onSelectAll={onSelectAll}
                              onUnselectAll={onUnselectAll}
                              onSave={onSave}
                            />
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function IndexList(props) {
  const {
    items,
    kelassFilter,
    jenjangs,
    tingkats,
    loading,
    onChangeCheckbox,
    onSelectAll,
    onUnselectAll,
    onSave,
  } = props;
  return (
    <>
      {items?.length !== 0 && (
        <tr>
          <td className='has-text-weight-semibold' colSpan='6'>
            Existing
          </td>
        </tr>
      )}
      {items?.length !== 0 &&
        items?.map((item, index) => (
          <IndexListItems
            key={item.code + '_' + nanoid()}
            index={index}
            item={item}
            jenjangs={jenjangs}
            tingkats={tingkats}
            loading={loading}
            onChangeCheckbox={onChangeCheckbox}
          />
        ))}
      <tr>
        <td className='has-text-weight-medium' colSpan='6'>
          <div className='flex justify-between'>
            <div className='flex align-center has-text-weight-semibold'>
              Update
            </div>
            <div className='mt-1'>
              <button
                onClick={(e) => onSelectAll(e)}
                className='button is-info is-small is-rounded is-outlined mr-1 mb-1'
              >
                <i className='is-size-6 mdi mdi-checkbox-marked mr-1' /> All
              </button>
              <button
                onClick={(e) => onUnselectAll(e)}
                className='button is-info is-small is-rounded is-outlined mr-1 mb-1'
              >
                <i className='is-size-6 mdi mdi-checkbox-blank mr-1' /> All
              </button>
              <button
                onClick={(e) => onSave(e)}
                className='button is-info is-small is-rounded is-outlined mr-1 mb-1'
              >
                <i className='is-size-6 mdi mdi-content-save icon' />
              </button>
            </div>
          </div>
        </td>
      </tr>
      {kelassFilter?.map((item, index) => (
        <IndexListItems
          key={index + '_' + nanoid()}
          index={index}
          item={item}
          jenjangs={jenjangs}
          tingkats={tingkats}
          loading={loading}
          onChangeCheckbox={onChangeCheckbox}
        />
      ))}
      {kelassFilter?.length === 0 && (
        <tr>
          <td colSpan='6'>Update kelas tidak tersedia.</td>
        </tr>
      )}
    </>
  );
}

function IndexListItems(props) {
  const { item, jenjangs, tingkats, index, loading, onChangeCheckbox } = props;
  if (loading)
    return (
      <tr>
        <td>Loading...</td>
      </tr>
    );

  let objJenjang = {};
  jenjangs.forEach((j) => {
    objJenjang[j.id] = j.title;
  });

  let objTingkat = {};
  tingkats.forEach((k) => {
    objTingkat[k.id] = k.title;
  });

  return (
    <>
      {!item?.tingkat && (
        <tr>
          <td className='has-text-centered'>{index + 1}</td>
          <td>{item.code}</td>
          <td>{item.title}</td>
          <td className='has-text-centered'>
            {item.tingkatId && objTingkat[item.tingkatId]}
          </td>
          <td className='has-text-centered'>
            {item?.jenjangId && objJenjang[item.jenjangId]}
          </td>
          <td className='has-text-centered'></td>
        </tr>
      )}
      {item?.tingkat && (
        <tr>
          <td className='has-text-centered'>{index + 1}</td>
          <td>{item.code}</td>
          <td>{item.title}</td>
          <td className='has-text-centered'>{item.tingkat}</td>
          <td className='has-text-centered'>{item.jenjang}</td>
          <td className='has-text-centered'>
            <div className='flex justify-center ml-3'>
              <div className='field' style={{ marginTop: -15 }}>
                <div className='control'>
                  <label className='custom-checkbox'>
                    {!item?.selected && (
                      <input
                        name={item.code}
                        type='checkbox'
                        onChange={(e) => onChangeCheckbox(e)}
                      />
                    )}
                    {item?.selected && (
                      <input
                        name={item.code}
                        type='checkbox'
                        checked
                        onChange={(e) => onChangeCheckbox(e)}
                      />
                    )}
                    <span className='checkmark' />
                  </label>
                </div>
              </div>
            </div>
          </td>
        </tr>
      )}
    </>
  );
}

export default connect(mapState, actions)(Sync);
