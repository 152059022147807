import {
  asyncActionFinish,
  asyncActionError,
} from '../../../../app/common/reduxs/async/asyncActions';
import { slideshowsGet, slideshowAdd, slideshowEdit, slideshowGet, slideshowUploadFile, slideshowDelete } from './reduxAction';
import { ASYNC_ACTION_START } from '../../../../app/common/reduxs/async/asyncConstant';
import { checkErr } from '../../../../app/common/helpers/checkRes';
import { fetchFunc } from '../../../../app/common/helpers/fetchHelpers';
import { toastr } from 'react-redux-toastr';

export const getSlideshows = (
  auth,
  history,
  itemPerPage,
  currentPage,
  searchText
) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getSlideshows' });
    const formData = new FormData();
    formData.append('itemPerPage', itemPerPage ? itemPerPage : 10);
    formData.append('currentPage', currentPage);
    formData.append('searchText', searchText);
    try {
      const res = await fetchFunc(
        'gets',
        auth,
        history,
        'portalsetting/slideshow/',
        formData,
        dispatch
      );
      const items = res.items;
      const total = res.total;
      const search = {
        currentPage,
        searchText,
        itemPerPage: itemPerPage ? itemPerPage : 10,
      };
      dispatch(slideshowsGet({ total, items, search }));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const addSlideshow = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'addSlideshow' });
    const formData = new FormData();
    console.log(values);
    formData.append('title', values.title);
    formData.append('description', values.description);
    try {
      const res = await fetchFunc(
        'add',
        auth,
        history,
        'portalsetting/slideshow/add/' + values.id,
        formData,
        dispatch
      );
      const slideshow = res?.slideshow;
      if (slideshow) {
        dispatch(slideshowAdd(slideshow));
        history.push('/portal/slideshow/' + slideshow.id);
      }
      toastr.success('Success', 'Slideshow berhasil disimpan.');
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const getSlideshow = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getSlideshow' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'get',
        auth,
        history,
        'portalsetting/slideshow/detail/' + id,
        formData,
        dispatch
      );
      const slideshow = res.slideshow;
      dispatch(slideshowGet(slideshow));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const editSlideshow = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'editSlideshow' });
    const formData = new FormData();
    formData.append('code', values.code);
    formData.append('title', values.title);
    formData.append(
      'description',
      values.description ? values.description : undefined
    );
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'portalsetting/slideshow/edit/' + values.id,
        formData,
        dispatch
      );
      const slideshow = res?.slideshow;
      if (slideshow) {
        dispatch(slideshowEdit(slideshow));
        history.push('/portal/slideshow/' + values.id);
      }
      toastr.success('Success', 'Slideshow berhasil disimpan.');
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const uploadSlideshow = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'uploadSlideshow' });
    let file = values.file;
    dispatch(
      slideshowUploadFile({
        id: values.id,
        filename: file.name,
        filesize: file.size,
        filetype: file.type,
        status: 'start',
      })
    );
    const formData = new FormData();
    formData.append('id', values.id);
    formData.append('title', values.title);
    formData.append('description', values.description);
    formData.append('filename', file.name);
    formData.append('filesize', file.size);
    formData.append('filetype', file.type);
    formData.append('file', values.file);
    try {
      const res = await fetchFunc(
        'uploadAttach',
        auth,
        history,
        'portalsetting/slideshow/upload/' + values.id,
        formData,
        dispatch
      );
      if (!res) {
        dispatch(asyncActionFinish());
        return;
      }
      dispatch(slideshowGet(res.slideshow));
      dispatch(
        slideshowUploadFile({
          id: values.id,
          filename: file.name,
          filesize: file.size,
          filetype: file.type,
          status: 'finish',
        })
      );
      history.push('/portal/slideshow/edit/' + values.id)
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const deleteSlideshow = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'deleteSlideshow' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'delete',
        auth,
        history,
        'portalsetting/slideshow/delete/' + id,
        formData,
        dispatch
      );
      const slideshow = res.slideshow;
      dispatch(slideshowDelete(slideshow));
      history.push('/portal/slideshow');
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};
