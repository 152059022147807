import { DASHBOARD_GET } from './reduxConstant';

export const dashboardGet = (dashboard) => {
  return {
    type: DASHBOARD_GET,
    payload: {
      dashboard,
    },
  };
};
