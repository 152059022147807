import React from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { sessionLogout } from '../../login/redux/authApi';

function mapState(state) {
  return {
    auth: state.auth,
    loading: state.async.loading,
  };
}

const actions = {
  sessionLogout,
};

function NotFound(props) {
  const { sessionLogout, auth } =
    props;
  let history = useHistory();

  const handleSessionLogout = (elementName) => {
    sessionLogout(elementName, history);
  };

  return (
    <>
      <section className='hero is-fullheight' style={{ marginTop: -52 }}>
        <div className='hero-body'>
          <div className='container'>
            <div className='columns'>
              <div className='column is-one-quarter-tablet is-one-quarter-desktop is-one-quarter-widescreen is-one-quarter-fullhd'></div>
              <div className='column is-two-quarter-tablet is-two-quarter-desktop is-two-quarter-widescreen is-two-quarter-fullhd is-centered'>
                <div className='box'>
                  <div className='media'>
                    <div className='media-content'>
                      <div className='content'>
                        <div id='fadein2' className='has-text-centered mt-3 mb-3'>
                          <i className='fas fa-exclamation-triangle icon is-size-1 has-text-danger'/>
                        </div>
                        <div className='is-size-6 has-text-centered is-italic mt-1'>
                          Halaman tidak ditemukan!
                        </div>
                        {!auth.isAuth && (
                        <div className='has-text-link is-size-5 has-text-centered is-italic hand-pointer mt-3'>
                          <div
                            className='button is-link is-small is-rounded is-outlined'
                            onClick={() => handleSessionLogout('logoutNav')}
                          >
                            <i className='fas fa-sign-in-alt icon mr-1' /> Login
                          </div>
                          <br />
                        </div>)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='column is-one-quarter-tablet is-one-quarter-desktop is-one-quarter-widescreen is-one-quarter-fullhd'></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default withRouter(connect(mapState, actions)(NotFound));
