import React from 'react';
import { Link } from 'react-router-dom';
import TextInput from '../../../app/common/form/TextInput';
import TextArea from '../../../app/common/form/TextArea';
import { reduxForm, Field } from 'redux-form';
import { convertSize } from '../../../app/common/helpers/othersHelpers';
import { SITE_ADDRESS } from '../../../app/common/data/siteConfig';

function ViewForm(props) {
  const { auth, history, scope, openModal, id, initialValues, handleSubmit } =
    props;

  let file =
    initialValues && initialValues.file && JSON.parse(initialValues.file);

  const renderFileInput = (props) => {
    const { file } = props;
    return (
      <div className='field'>
        {file && (
          <>
            <figure className='image'>
              <img alt='' src={SITE_ADDRESS + file.filelink} style={{maxWidth: 500, height: 'auto'}} />
            </figure>
            <div
              className='has-text-link has-background-grey-lighter px-2 py-1 mb-1 flex justify-between align-center mt-1'
              style={{ maxWidth: 500 }}
            >
              <span className='custom-text-overflow '>
                {file?.filename} ({convertSize(file.filesize)})
              </span>
            </div>
          </>
        )}
      </div>
    );
  };

  const onClickDelete = () => {
    openModal('SlideshowDelete', {
      data: {
        auth,
        history,
        item: initialValues,
      },
    });
  };

  const handleClickSubmit = () => {};

  return (
    <>
      <div className='level'>
        <div className='level-left'>
          <div className='level-item'>
            <nav className='breadcrumb is-size-6' aria-label='breadcrumbs'>
              <ul>
                <li className='is-active has-text-weight-bold'>
                  <Link to={'/portal/slideshow/' + id}>Detail</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className='level-right'>
          <div className='level-item'>
            <div className='buttons'>
              {scope && scope.subm && scope.subm['slideshow_edit'] && (
                <Link
                  to={`/portal/slideshow/edit/${id}`}
                  className='button is-small is-rounded is-info is-outlined'
                >
                  <i className='is-size-6 mdi mdi-pencil icon' />
                </Link>
              )}
              {scope && scope.subm && scope.subm['slideshow_delete'] && (
                <button
                  className='button is-small is-rounded is-danger is-outlined'
                  onClick={() => onClickDelete()}
                >
                  <i className='is-size-6 mdi mdi-delete icon' />
                </button>
              )}
              <Link
                type='button'
                to={'/portal/slideshow'}
                className='button custom-grey is-small is-rounded is-outlined'
              >
                <i className='is-size-6 mdi mdi-arrow-left icon' />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='columns'>
        <div className='column is-third-quarter'>
          <form onSubmit={handleSubmit(handleClickSubmit)} autoComplete='off'>
            <Field
              name='title'
              type='text'
              component={TextInput}
              defaultValue={initialValues?.title ?? ''}
              placeholder='Judul Slideshow'
              label='Judul Slideshow'
              readOnly={true}
            />
            <Field
              name='description'
              type='text'
              component={TextArea}
              defaultValue={initialValues?.description ?? ''}
              placeholder='Deskripsi'
              label='Deskripsi'
              readOnly={true}
            />
            <Field
              name='attachment'
              type='file'
              component={renderFileInput}
              placeholder='File'
              label='File Attachement'
              file={file || ''}
            />
          </form>
        </div>
      </div>
    </>
  );
}

export default reduxForm({
  form: 'slideshowView',
  enableReinitialize: true,
})(ViewForm);
