import {
  MATERIS_GET,
  MATERI_ADD,
  MATERI_GET,
  MATERI_EDIT,
  MATERI_UPLOADFILE,
  MATERI_DELETE,
  MATERI_RESET_SEARCH,
} from './reduxConstant';
import { createReducer } from '../../../../app/common/utilities/reducerUtils';

const initialState = {
  total: 0,
  search: {
    year: '',
    sem: '',
    jenjang: '',
    tingkat: '',
    kelas: '',
    pelajaran: '',
    published: 'all',
  },
  items: [],
  uploadStatus: 'finish',
};

const materisGet = (state, payload) => {
  const materis = payload.materis;
  return {
    total: materis.total,
    items: [...materis.items],
    search: { ...materis.search },
  };
};

const materiAdd = (state, payload) => {
  return {
    ...state,
    total: parseInt(state.total) + 1,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.materi.id + ''),
      payload.materi,
    ],
  };
};

const materiGet = (state, payload) => {
  return {
    ...state,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.materi.id + ''),
      payload.materi,
    ],
  };
};

const materiEdit = (state, payload) => {
  return {
    ...state,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.materi.id + ''),
      payload.materi,
    ],
  };
};

const materiUploadFile = (state, payload) => {
  let status = payload.status;
  return {
    ...state,
    uploadStatus: status,
  };
};

const materiDelete = (state, payload) => {
  return {
    total: parseInt(state.total) - 1,
    items: [...state.items.filter((i) => i.id + '' !== payload.materi.id + '')],
    search: { ...state.search },
  };
};

const materiResetSearch = (state, payload) => {
  return {
    ...state,
    search: {
      year: '',
      sem: '',
      jenjang: '',
      tingkat: '',
      kelas: '',
      pelajaran: '',
      published: 'all',
    },
  };
};

export default createReducer(initialState, {
  [MATERIS_GET]: materisGet,
  [MATERI_ADD]: materiAdd,
  [MATERI_GET]: materiGet,
  [MATERI_EDIT]: materiEdit,
  [MATERI_UPLOADFILE]: materiUploadFile,
  [MATERI_DELETE]: materiDelete,
  [MATERI_RESET_SEARCH]: materiResetSearch,
});
