import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';
import {
  composeValidators,
  combineValidators,
  isRequired,
} from 'revalidate';
import TextInput from '../../../app/common/form/TextInput';
import EditorQuill from '../../../app/common/form/EditorQuill';
import { Quill } from 'react-quill';
import ImageUploader from 'quill-image-uploader';

const validate = combineValidators({
  title: composeValidators(
    isRequired({ message: 'Judul harus diisi' }),
  )(),
  description: composeValidators(
    isRequired({ message: 'Materi harus diisi' }),
  )(),
});

Quill.register('modules/imageUploader', ImageUploader);

class AddForm extends Component {

  modules = {
    toolbar: [
      [{ size: ['small', false, 'large', 'huge'] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
        { script: 'sub' },
        { script: 'super' },
      ],
      [{ color: [] }, { background: [] }],
      [{ font: [] }],
    ],
  };

  onFormSubmit = async (values) => {
    const { auth, history, id, addMateri } = this.props;
    try {
      const data = { ...values, id: id };
      addMateri(auth, history, data);
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { invalid, loading, pristine, handleSubmit } = this.props;
    return (
      <div className='card-content pl-4 pr-4'>
        <div className='level'>
          <div className='level-left'>
            <div className='level-item'>
              <nav className='breadcrumb is-size-6' aria-label='breadcrumbs'>
                <ul>
                  <li className='is-active has-text-weight-bold'>
                    <Link to='/pembelajaran/materi/tambah'>Tambah</Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>

          <div className='level-right'>
            <div className='level-item'>
              <div className='buttons'>
                <button
                  disabled={invalid || loading || pristine}
                  onClick={handleSubmit(this.onFormSubmit)}
                  className={
                    loading
                      ? 'button is-small is-info is-rounded is-outlined is-loading'
                      : 'button is-small is-info is-rounded is-outlined'
                  }
                >
                  <i className='is-size-6 mdi mdi-content-save icon' />
                </button>
                <Link
                  to='/pembelajaran/materi'
                  className='button custom-grey is-small is-rounded is-outlined'
                >
                  <i className='is-size-6 mdi mdi-arrow-left icon' />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className='columns'>
          <div className='column is-third-quarter'>
            <form className='overflow-auto' onSubmit={handleSubmit(this.onFormSubmit)} autoComplete='off'>
              <Field
                name='title'
                type='text'
                component={TextInput}
                placeholder='Judul'
                label='Judul Materi'
              />
              <Field
                name='description'
                type='text'
                component={EditorQuill}
                modules={this.modules}
                label='Deskripsi'
                placeholder='Deskripsi disini...'
              />
              <span className='tag'>Note: Simpan terlebih dahulu untuk menambahkan file media.</span>
              <div
                className='field is-grouped'
                style={{ marginTop: 20, marginBottom: 20 }}
              >
                <div className='control'>
                  <button
                    type='submit'
                    disabled={invalid || loading || pristine}
                    className={
                      loading
                        ? 'button is-info is-small is-rounded is-outlined is-loading'
                        : 'button is-info is-small is-rounded is-outlined'
                    }
                  >
                    <i className='is-size-6 mdi mdi-content-save icon' />
                  </button>
                </div>
                <div className='control'>
                  <Link
                    to='/pembelajaran/materi'
                    className='button custom-grey is-small is-rounded is-outlined'
                  >
                    <i className='is-size-6 mdi mdi-arrow-left icon' />
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(
  reduxForm({
    form: 'addMateri',
    validate,
    enableReinitialize: true,
  })(AddForm)
);
