import React, { useState, useEffect } from 'react';
import { photoUploadPortaluser } from './redux/reduxApi';
import { connect } from 'react-redux';
import DropzoneInput from './DropzoneInput';

const actions = {
  photoUploadPortaluser,
};

function Photo(props) {
  const { profile, site, auth, defaultPhoto, history, photoUploadPortaluser } =
    props;
  const [files, setFiles] = useState([]);
  const [fileToLarge, setFileToLarge] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if(files.length > 0) {
      (async function upload() {
        try {
          let image = files[0];
          let filename = files[0].name;
          await photoUploadPortaluser(auth, history, image, filename);
          handleCancel();
        } catch (error) {
          setError('Oops, Something went wrong');
          console.log(error);
        }
      })();
    }
    return () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    };
  }, [files, auth, history, photoUploadPortaluser]);

  const handleCancel = () => {
    setFiles([]);
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {files.length === 0 && (
        <DropzoneInput
          site={site}
          profile={profile}
          defaultPhoto={defaultPhoto}
          setFiles={setFiles}
          setFileToLarge={setFileToLarge}
        />
      )}
      {files.length > 0 && (
        <>
          <div className='block custom-flex-center'>
            <figure className='image is-128x128 custom-thumbnail'>
              <img
                alt=''
                src={files[0].preview}
                style={{ maxHeight: 126, width: 'auto' }}
              />
            </figure>
          </div>
        </>
      )}
      {fileToLarge ? (<span className='tag is-warning is-italic mt-1'>File terlalu besar. Maks 200Kb</span>) : ''}
      {error && error.length > 0 && <div>{error}</div>}
    </div>
  );
}

export default connect(null, actions)(Photo);
