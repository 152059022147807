import React from 'react';

const TextInput = ({
  name,
  type,
  onChange,
  onKeyDown,
  disabled,
  readOnly,
  defaultValue,
  placeholder,
  label,
  style,
}) => (
  <div className='field'>
    {label && label !== 'noLabel' && (
      <label className='label' htmlFor={name}>
        {label}
      </label>
    )}
    <div className='control'>
      <input
        className='input is-size-6'
        id={name}
        readOnly={readOnly}
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={defaultValue}
        type={type}
        disabled={disabled}
        placeholder={placeholder}
        style={{ ...style }}
      />
    </div>
  </div>
);

export default function ViewSummaryAnswerFormListItems(props) {
  const {
    // auth,
    // scope,
    state,
    question,
    onChangeInput,
    handleEnter,
    isEdit,
    // index,
  } = props;

  let item = state && state.find((i) => i.key + '' === question.key + '');

  let optionQuestion =
    question && question.questionType === 'optionQuestion' ? true : false;
  let longQuestion =
    question && question.questionType === 'longQuestion' ? true : false;
  let options = question && question.options;

  let isChecked =
    item && item.isCorrect ? (
      <span>
        <i className='is-size-6 mdi mdi-check-circle icon has-text-info' />
      </span>
    ) : (
      ''
    );

  let questionStc = optionQuestion ? (
    <>
      <p>{question?.question}</p>
      <p style={{ marginTop: -10 }}>
        {options.map((i, index) => (
          <span key={index} className='tag mr-2 mb-1'>
            {i}
          </span>
        ))}
      </p>
    </>
  ) : (
    <p>{question?.question}</p>
  );

  return (
    <tr>
      <td className='has-text-centered middle'>{item?.no}</td>
      <td className='middle'>{questionStc}</td>
      <td className='middle'>
        {item?.stAns} {isChecked}{' '}
        {longQuestion && !item?.isCorrect && (
          <span className='tag mb-1'>{item?.score}</span>
        )}
        <br />
        <span className='tag'>
          <i className='is-size-6 mdi mdi-key icon' /> : {item?.keyAns}
        </span>
      </td>
      <td className='middle has-text-right'>
        {!isEdit && item?.finalScore}
        {isEdit && (
          <TextInput
            name={item?.no + '_score'}
            style={{ textAlign: 'right', minWidth: 90 }}
            defaultValue={item?.finalScore ?? 0}
            onKeyDown={(e) => handleEnter(e)}
            onChange={(e) => onChangeInput(e, item)}
            placeholder='ex: 80'
            label='noLabel'
          />
        )}
      </td>
    </tr>
  );
}
