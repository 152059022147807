import React from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { getCalendar, addCalendar, getCalendarYears } from './redux/reduxApi';
import AddForm from './AddForm';
import { openModal } from '../../../app/modals/redux/modalActions';

const mapState = (state, ownProps) => {
  const id = ownProps.match.params.id;
  let scope = {};
  if (state.auth && state.auth.isAuth) {
    scope = state.auth.authorities;
  }
  let calendar = {};
  let years = [];
  if (state.calendars && state.calendars.items && state.calendars.items[0]) {
    calendar = state.calendars.items.filter((i) => i.id + '' === id + '')[0];
    years = state.calendars.years;
  }
  return {
    loading: state.async.loading,
    auth: state.auth,
    scope: scope,
    id: id,
    calendar: calendar,
    years: years,
  };
};

const actions = {
  openModal,
  getCalendar,
  addCalendar,
  getCalendarYears,
};

function Add(props) {
  const {
    auth,
    history,
    loading,
    openModal,
    id,
    years,
    addCalendar,
    getCalendarYears,
  } = props;
  let location = useLocation();
  let dataCalendar = location && location.state;

  return (
    <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
      <div className='p-1'>
        <div className='columns is-variable'>
          <div className='column is-fullwidth'>
            <div className='card'>
              <header className='card-header has-background-white has-text-info'>
                <div
                  className='custom-card-flex is-size-6'
                  style={{ width: '100%' }}
                >
                  <p>
                    <i className='mdi mdi-calendar-month is-size-5 icon' />{' '}
                    Kalender
                    <i className='mdi mdi-menu-right is-size-6 icon' />
                    Tambah
                  </p>
                </div>
              </header>
              <div className='card-content pl-4 pr-4'>
                <AddForm
                  initialValues={dataCalendar && dataCalendar.detail}
                  years={years}
                  loading={loading}
                  auth={auth}
                  id={id}
                  history={history}
                  openModal={openModal}
                  addCalendar={addCalendar}
                  getCalendarYears={getCalendarYears}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(connect(mapState, actions)(Add));
