import React from 'react';
import { Link } from 'react-router-dom';

export default function Tab(props) {
  const { auth, pathname } = props;
  return (
    <div className='tabs'>
      <ul>
        <li className={pathname === `/profil/info` ? 'is-active' : ''}>
          <Link to={`/profil/info`}>
            <span className='icon is-small'>
              <i className='is-size-5 mdi mdi-account-box' aria-hidden='true'></i>
            </span>
            Info
          </Link>
        </li>
        {auth &&
          auth.authorities &&
          auth.authorities.title &&
          auth.authorities.title[0] !== 'SA' && (
            <li
              className={
                pathname === `/profil/ubah-password` ? 'is-active' : ''
              }
            >
              <Link to={`/profil/ubah-password`}>
                <span className='icon is-small'>
                  <i className='is-size-5 mdi mdi-account-lock' aria-hidden='true'></i>
                </span>
                Ubah Password
              </Link>
            </li>
          )}
      </ul>
    </div>
  );
}
