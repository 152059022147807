import React from 'react';

export default function IndexListItems(props) {
  const {
    item,
    currentPage,
    itemPerPage,
    index,
    loading,
    scope,
    onEdit,
    formatDate,
  } = props;
  if (loading)
    return (
      <tr>
        <td>Loading...</td>
      </tr>
    );

  const page = (currentPage - 1) * itemPerPage;

  return (
    <tr>
      <td className='has-text-centered'>{page + index + 1}</td>
      <td className={item.type === 'libur' ? 'has-text-danger has-text-weight-semibold' : ''}>
        {item.subject}
      </td>
      <td>{item.type}</td>
      <td>
        {item.startDate && formatDate(item.startDate)}{' '}
        {item.allDayEvent ? '' : item.startTime}{' '}
        {item.endDate ? '- ' + formatDate(item.endDate) : ''}{' '}
        {item.endDate && item.allDayEvent === true ? '' : item.endTime}
      </td>
      <td>
        <div className='content-wrap flex justify-center'>
          <div>
            {scope && scope.m && scope.m['calendar_edit'] && (
              <button
                onClick={() => onEdit(item, 'listEvent')}
                className='button is-small is-rounded is-info is-outlined mr-2 mb-1'
              >
                <i className='is-size-6 mdi mdi-eye icon' />
              </button>
            )}
          </div>
        </div>
      </td>
    </tr>
  );
}
