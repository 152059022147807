import { ROLES_GET, ROLE_ADD, ROLE_EDIT, ROLE_DELETE } from './reduxConstant';

export const rolesGet = (roles) => {
  return {
    type: ROLES_GET,
    payload: {
      roles,
    },
  };
};

export const roleAdd = (role) => {
  return {
    type: ROLE_ADD,
    payload: {
      role,
    },
  };
};

export const roleEdit = (role) => {
  return {
    type: ROLE_EDIT,
    payload: {
      role,
    },
  };
};

export const roleDelete = (role) => {
  return {
    type: ROLE_DELETE,
    payload: {
      role,
    },
  };
};
