import {
  RPPS_GET,
  RPP_ADD,
  RPP_GET,
  RPP_EDIT,
  RPP_UPLOADFILE,
  RPP_DELETE,
  RPP_RESET_SEARCH,
} from './reduxConstant';

export const rppsGet = (rpps) => {
  return {
    type: RPPS_GET,
    payload: {
      rpps,
    },
  };
};

export const rppAdd = (rpp) => {
  return {
    type: RPP_ADD,
    payload: {
      rpp,
    },
  };
};

export const rppGet = (rpp) => {
  return {
    type: RPP_GET,
    payload: {
      rpp,
    },
  };
};

export const rppEdit = (rpp) => {
  return {
    type: RPP_EDIT,
    payload: {
      rpp,
    },
  };
};

export const rppUploadFile = (status) => {
  return {
    type: RPP_UPLOADFILE,
    payload: {
      status,
    },
  };
};

export const rppDelete = (rpp) => {
  return {
    type: RPP_DELETE,
    payload: {
      rpp,
    },
  };
};

export const rppResetSearch = () => {
  return {
    type: RPP_RESET_SEARCH,
    payload: {},
  };
};
