import {
  asyncActionFinish,
  asyncActionError,
} from '../../../app/common/reduxs/async/asyncActions';
import { ASYNC_ACTION_START } from '../../../app/common/reduxs/async/asyncConstant';
import { dashboardGet } from './reduxAction';
import { checkErr } from '../../../app/common/helpers/checkRes';
import { fetchFunc } from '../../../app/common/helpers/fetchHelpers';

export const getDashboard = (auth, history) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getDashboard' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'gets',
        auth,
        history,
        'dashboard/',
        formData,
        dispatch
      );
      const dashboard = res.dashboard;
      dispatch(dashboardGet(dashboard));
      dispatch(asyncActionFinish());
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};
