import React from 'react';
import { closeModal } from '../../../app/modals/redux/modalActions';
import { connect } from 'react-redux';
import { editCalendar } from './redux/reduxApi';

function mapState(state) {
  let scope = {};
  if (state.auth && state.auth.isAuth) {
    scope = state.auth.authorities;
  }
  return {
    auth: state.auth,
    scope: scope,
    loading: state.async.loading,
  };
}

const actions = {
  closeModal,
  editCalendar,
};

function DeleteEvent(props) {
  const { data, closeModal, editCalendar } = props;
  const auth = data.auth;
  const history = data.history;
  const item = data.item;
  const calendar = data.calendar;

  const onDelete = () => {
    let events = calendar.events;
    let newEvents = events.filter((i) => i.id !== item.id);
    let newCalendar = { ...calendar, events: newEvents };
    editCalendar(auth, history, newCalendar);
    closeModal();
  };

  return (
    <div className='modal is-active'>
      <div className='modal-background' onClick={closeModal}></div>
      <div className='modal-card'>
        <header className='modal-card-head'>
          <div className='modal-card-title has-text-danger has-text-weight-normal'>
            <i className='is-size-4 mdi mdi-shield-half-full icon' /> Konfirmasi
            Hapus
          </div>
          <button
            onClick={closeModal}
            className='delete'
            aria-label='close'
          ></button>
        </header>
        <section className='modal-card-body is-size-6'>
          <p className='my-3 mx-1'>
            Apakah Anda yakin ingin menghapus event{' '}
            <span className='has-text-danger has-text-weight-semibold is-capitalized'>{`${item?.subject}`}</span>{' '}
            ?
          </p>
        </section>
        <footer className='modal-card-foot'>
          <button
            onClick={() => onDelete()}
            className='button is-danger is-small is-rounded is-outlined'
          >
            <i className='is-size-6 mdi mdi-delete icon' />
          </button>
          <button
            className='button custom-grey is-small is-rounded is-outlined'
            onClick={closeModal}
          >
            <i className='is-size-6 mdi mdi-arrow-left icon' />
          </button>
        </footer>
      </div>
    </div>
  );
}

export default connect(mapState, actions)(DeleteEvent);
