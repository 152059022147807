import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getPelajaran, editPelajaran } from './redux/reduxApi';
import EditForm from './EditForm';

const mapState = (state, ownProps) => {
  const id = ownProps.match.params.id;
  let scope = {};
  if (state?.auth?.isAuth) {
    scope = state.auth.authorities;
  }
  let pelajarans = state?.pelajarans;
  let pelajaran = {};
  if (pelajarans?.items && pelajarans.items[0]) {
    pelajaran = pelajarans?.items?.filter((i) => i.id + '' === id + '')[0];
  }
  let jenjangs = [];
  if (pelajarans?.jenjangs && pelajarans?.jenjangs[0]) {
    jenjangs = state.pelajarans.jenjangs;
  }
  return {
    loading: state.async.loading,
    auth: state.auth,
    scope: scope,
    id: id,
    pelajaran: pelajaran,
    jenjangs: jenjangs,
  };
};

const actions = {
  getPelajaran,
  editPelajaran,
};

function Edit(props) {
  const {
    auth,
    history,
    loading,
    id,
    jenjangs,
    pelajaran,
    getPelajaran,
    editPelajaran,
  } = props;
  const [initialValues, setInitialValues] = useState({
    code: '',
    title: '',
    jenjangId: '',
    description: '',
    jenjangSelect: [],
  });

  useEffect(() => {
    let jenjangSelect = [];
    jenjangs.forEach((j, i) => {
      jenjangSelect = [
        ...jenjangSelect,
        { key: i, value: j.id, text: j.title },
      ];
    });
    setInitialValues({
      ...pelajaran,
      jenjangSelect: jenjangSelect,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pelajaran, jenjangs]);

  useEffect(() => {
    getPelajaran(auth, history, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
      <div className='p-1'>
        <div className='columns is-variable'>
          <div className='column is-fullwidth'>
            <div className='card'>
              <header className='card-header has-background-white has-text-info'>
                <div
                  className='custom-card-flex is-size-6'
                  style={{ width: '100%' }}
                >
                  <p>
                    <i className='mdi mdi-school is-size-5 icon' /> Akademik
                    <i className='mdi mdi-menu-right is-size-6 icon' />
                    Pelajaran
                  </p>
                </div>
              </header>
              <div className='card-content pl-4 pr-4'>
                <EditForm
                  initialValues={initialValues}
                  loading={loading}
                  auth={auth}
                  id={id}
                  history={history}
                  editPelajaran={editPelajaran}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(connect(mapState, actions)(Edit));
