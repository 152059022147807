import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import SelectInput from '../../../app/common/form/SelectInput';
import MultiSelect from '../../../app/common/form/MultiSelect';
import CheckInput from '../../../app/common/form/CheckInput';
import { reduxForm, Field } from 'redux-form';
import { combineValidators, isRequired } from 'revalidate';
import { toastr } from 'react-redux-toastr';

const validate = combineValidators({
  year: isRequired({ message: 'Tahun ajaran harus diisi' }),
  sem: isRequired({ message: 'Semester harus diisi' }),
  jenjang: isRequired({ message: 'Jenjang harus diisi' }),
  tingkat: isRequired({ message: 'Tingkat harus diisi' }),
  pelajaran: isRequired({ message: 'Pelajaran harus diisi' }),
});

function PublishForm(props) {
  const {
    auth,
    history,
    id,
    initialValues,
    jenjangs,
    tingkats,
    kelass,
    pelajarans,
    years,
    sems,
    publishTugas,
    loading,
    handleSubmit,
    onFormSubmit,
  } = props;
  const [state, setState] = useState({});
  const [checked, setChecked] = useState(false);
  const [filteredTingkat, setFilteredTingkat] = useState([]);
  const [filteredKelas, setFilteredKelas] = useState([]);
  const [filteredPelajaran, setFilteredPelajaran] = useState([]);

  let jenjangSelect = [];
  jenjangs?.forEach((obj) => {
      let newObj = {
        key: obj.id,
        value: obj.code,
        text: obj.title,
      };
      jenjangSelect = [...jenjangSelect, newObj];
    });

  let tingkatSelect = [];
  tingkats?.forEach((obj1) => {
      let newObj1 = {
        key: obj1.id,
        value: obj1.code,
        text: obj1.title,
        jenjangId: obj1.jenjangId,
      };
      tingkatSelect = [...tingkatSelect, newObj1];
    });

  let kelasSelect = [{ label: 'Semua Kelas', value: 'all kelas' }];
  kelass?.forEach((obj2) => {
      let newObj2 = {
        value: obj2.code,
        label: obj2.title,
        tingkatId: obj2.tingkatId,
      };
      kelasSelect = [...kelasSelect, newObj2];
    });

  let pelajaranSelect = [];
  pelajarans?.forEach((obj3) => {
      let jenjangTitle = '';
      for (const objA of jenjangs) {
        if (objA.id === obj3.jenjangId) {
          jenjangTitle = ` - ${objA.title}`;
          break;
        }
      }
      let newObj3 = {
        key: obj3.id,
        value: obj3.code,
        text: `(${obj3.code}) ` + obj3.title + jenjangTitle,
        jenjangId: obj3.jenjangId,
      };
      pelajaranSelect = [...pelajaranSelect, newObj3];
    });

  let yearsSelect = [{ key: 0, text: 'Semua Tahun Ajaran', value: 'all year' }];
  years?.forEach((obj4) => {
      let newObj4 = {
        key: obj4.id,
        value: obj4.code,
        text: obj4.title,
      };
      yearsSelect = [...yearsSelect, newObj4];
    });

  let semsSelect = [];
  sems?.forEach((obj5) => {
      let newObj5 = {
        key: obj5.id,
        value: obj5.code,
        text: obj5.title,
      };
      semsSelect = [...semsSelect, newObj5];
    });

  useEffect(() => {
    setState({
      ...initialValues,
      jenjang: 'SD',
    });
    setChecked(initialValues.published);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // filtering option kelas
    let tingkat = state?.tingkat;
    changeTingkat(tingkat);
    let jenjang = state?.jenjang;
    changeJenjang(jenjang);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const onChangeSelect = (e, name) => {
    e.preventDefault();
    let value = e.target.value;
    if (name === 'tingkat') {
      changeTingkat(value);
    }
    if (name === 'jenjang') {
      changeJenjang(value);
    }
    setState({
      ...state,
      [name]: value,
    });
  };

  const changeTingkat = (value) => {
    if (value) {
      let objVal = {};
      for (const obj of tingkatSelect) {
        if (obj.value === value) {
          objVal = { ...obj };
          break;
        }
      }
      let arrKelas = [];
      for (const obj2 of kelasSelect) {
        if (obj2.tingkatId + '' === objVal.key + '') {
          arrKelas = [...arrKelas, obj2];
        }
      }
      setFilteredKelas([
        { key: 0, value: 'all kelas', label: 'semua kelas' },
        ...arrKelas,
      ]);
    } else {
      setFilteredKelas([
        { value: 'all kelas', label: 'semua kelas' },
        ...kelasSelect,
      ]);
    }
  };

  const changeJenjang = (value) => {
    if (value) {
      let objVal = {};
      for (const obj of jenjangSelect) {
        if (obj.value === value) {
          objVal = { ...obj };
          break;
        }
      }
      let arrPel = [];
      for (const obj2 of pelajaranSelect) {
        if (obj2.jenjangId + '' === objVal.key + '') {
          arrPel = [...arrPel, obj2];
        }
      }
      setFilteredPelajaran([...arrPel]);
      let arrTingkat = [];
      for (const obj2 of tingkatSelect) {
        if (obj2.jenjangId + '' === objVal.key + '') {
          arrTingkat = [...arrTingkat, obj2];
        }
      }
      setFilteredTingkat([...arrTingkat]);
    } else {
      setFilteredTingkat([...tingkatSelect]);
      setFilteredPelajaran([...pelajaranSelect]);
    }
  };

  const onChangeMultiSelect = (kelas) => {
    setState({ ...state, kelas });
  };

  const onCheckedPublished = () => {
    setChecked(!checked);
  };

  const onClickSubmit = (e) => {
    e.preventDefault();
    if (state.year === '') {
      toastr.error('Warning', 'Pastikan tahun terisi');
      return;
    }
    if (state.sem === '') {
      toastr.error('Warning', 'Pastikan semester terisi');
      return;
    }
    if (state.jenjang === '') {
      toastr.error('Warning', 'Pastikan jenjang terisi');
      return;
    }
    if (state.tingkat === '') {
      toastr.error('Warning', 'Pastikan tingkat terisi');
      return;
    }
    if (state.kelas.toString() === '') {
      toastr.error('Warning', 'Pastikan kelas terisi');
      return;
    }
    if (state.pelajaran === '') {
      toastr.error('Warning', 'Pastikan pelajaran terisi');
      return;
    }
    const newValues = { ...state };
    const kelas = state.kelas;
    const allKelas = JSON.stringify(kelas).includes('all kelas')
      ? ['all kelas']
      : kelas.map((i) => i.value);
    let yearTitle = state.year.includes('all year') ? 'all year' : state.year;
    if (yearTitle !== 'all year') {
      for (const obj2 of years) {
        if (obj2.code === state.year) {
          yearTitle = obj2.title;
          break;
        }
      }
    }
    let semTitle = state.sem === 'all sem' ? 'all sem' : 'Sem ' + state.sem;
    let jenjangTitle = '';
    for (const obj3 of jenjangs) {
      if (obj3.code === state.jenjang) {
        jenjangTitle = obj3.title;
        break;
      }
    }
    let pelajaranTitle = '';
    for (const obj4 of pelajarans) {
      if (obj4.code === state.pelajaran) {
        pelajaranTitle = obj4.title;
        break;
      }
    }
    const tags = [
      ...[
        yearTitle,
        semTitle,
        jenjangTitle,
        'Tingkat ' + state.tingkat,
        pelajaranTitle,
      ],
      ...allKelas,
    ];
    newValues.id = id;
    newValues.kelas = allKelas;
    newValues.tags = tags;
    newValues.published = checked;
    publishTugas(auth, history, newValues);
  };

  return (
    <>
      <div className='level'>
        <div className='level-left'>
          <div className='level-item'>
            <nav className='breadcrumb is-size-6' aria-label='breadcrumbs'>
              <ul>
                <li className='is-active has-text-weight-bold'>
                  <Link to={'/pembelajaran/tugas/publish/' + id}>Publish</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className='level-right'>
          <div className='level-item'>
            <div className='buttons'>
              <button
                disabled={loading}
                onClick={(e) => onClickSubmit(e)}
                className={
                  loading
                    ? 'button is-small is-info is-rounded is-outlined is-loading'
                    : 'button is-small is-info is-rounded is-outlined'
                }
              >
                <i className='is-size-6 mdi mdi-content-save icon' />
              </button>
              <Link
                to={'/pembelajaran/tugas/' + id}
                className='button custom-grey is-small is-rounded is-outlined'
              >
                <i className='is-size-6 mdi mdi-arrow-left icon' />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='columns'>
        <div className='column is-third-quarter is-capitalized'>
          <div className='flex flex-column mb-4' style={{ marginTop: -12 }}>
            <article className='message is-info'>
              <div className='message-body is-size-7'>
                <p>Judul:</p>
                <p
                  className='is-capitalized'
                  style={{ whiteSpace: 'pre-line' }}
                >
                  {state.title}
                </p>
              </div>
            </article>
          </div>
          <form onSubmit={handleSubmit(onFormSubmit)} autoComplete='off'>
            <div className='field is-horizontal'>
              <div className='field-body'>
                <Field
                  name='year'
                  type='text'
                  component={SelectInput}
                  onChange={(e) => onChangeSelect(e, 'year')}
                  defaultValue={state.year ?? ''}
                  placeholder='Tahun Ajaran'
                  label='Tahun Ajaran'
                  options={yearsSelect}
                  fullwidth={true}
                />
                <Field
                  name='sem'
                  type='text'
                  component={SelectInput}
                  onChange={(e) => onChangeSelect(e, 'sem')}
                  defaultValue={state.sem ?? ''}
                  placeholder='Semester'
                  label='Semester'
                  options={semsSelect}
                  fullwidth={true}
                />
              </div>
            </div>
            <div className='field is-horizontal'>
              <div className='field-body'>
                <Field
                  name='jenjang'
                  type='text'
                  component={SelectInput}
                  onChange={(e) => onChangeSelect(e, 'jenjang')}
                  defaultValue={state.jenjang ?? ''}
                  placeholder='Jenjang'
                  label='Jenjang'
                  options={jenjangSelect}
                  fullwidth={true}
                />
                <Field
                  name='grade'
                  type='text'
                  component={SelectInput}
                  onChange={(e) => onChangeSelect(e, 'tingkat')}
                  defaultValue={state.tingkat ?? ''}
                  placeholder='Tingkat'
                  label='Tingkat'
                  options={filteredTingkat}
                  fullwidth={true}
                />
              </div>
            </div>
            <Field
              name='kelas'
              component={MultiSelect}
              placeholder='Pilih Kelas'
              defaultValue={state.kelas ?? ''}
              onChange={onChangeMultiSelect}
              label='Kelas'
              options={filteredKelas}
            />
            <Field
              name='pelajaran'
              type='text'
              component={SelectInput}
              onChange={(e) => onChangeSelect(e, 'pelajaran')}
              defaultValue={state.pelajaran ?? ''}
              placeholder='Pelajaran'
              label='Pelajaran'
              options={filteredPelajaran}
              fullwidth={true}
            />
            <div className='field flex align-center'>
              <div className='mb-5'>
                <Field
                  name='published'
                  id='published'
                  type='checkbox'
                  isChecked={checked}
                  onChange={() => onCheckedPublished()}
                  component={CheckInput}
                  label={checked ? 'Publish' : 'Unpublish'}
                  style={{ fontWeight: 'bold' }}
                />
              </div>
            </div>
            <hr />
            <div
              className='field is-grouped'
              style={{ marginTop: 10, marginBottom: 30 }}
            >
              <div className='control'>
                <button
                  disabled={loading}
                  onClick={(e) => onClickSubmit(e)}
                  className={
                    loading
                      ? 'button is-small is-info is-rounded is-outlined is-loading'
                      : 'button is-small is-info is-rounded is-outlined'
                  }
                >
                  <i className='is-size-6 mdi mdi-content-save icon' />
                </button>
              </div>
              <div className='control'>
                <Link
                  to={'/pembelajaran/tugas/' + id}
                  className='button custom-grey is-small is-rounded is-outlined'
                >
                  <i className='is-size-6 mdi mdi-arrow-left icon' />
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default reduxForm({
  form: 'tugasPublish',
  validate,
  enableReinitialize: true,
})(PublishForm);
