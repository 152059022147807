import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getUser } from './redux/reduxApi';
import ViewForm from './ViewForm.jsx';
import { openModal } from '../../app/modals/redux/modalActions';

const mapState = (state, ownProps) => {
  const id = ownProps.match.params.id;
  let scope = {};
  if (state.auth && state.auth.isAuth) {
    scope = state.auth.authorities;
  }
  let user = {};
  let roles = [];
  let arrRole = [];
  let selectedRole = [];
  if (state.users && state.users.items && state.users.items[0]) {
    user = state.users.items.filter((i) => i.id + '' === id + '')[0];
  }
  if (state.users && state.users.roles && state.users.roles[0]) {
    roles = state.users.roles;
  }
  arrRole =
    user.roles === 'SA' ? ['SA'] : user.roles ? user.roles.split(',') : [];
  if (user.roles !== 'SA') {
    for (let i = 0; i < arrRole.length; i++) {
      for (let j = 0; j < roles.length; j++) {
        if (roles[j].id + '' === arrRole[i] + '') {
          selectedRole = [
            ...selectedRole,
            { value: roles[j].id, label: roles[j].title },
          ];
        }
      }
    }
  } else {
    selectedRole = [{ value: 'SA', label: 'Superadmin' }];
  }
  return {
    id: id,
    loading: state.async.loading,
    auth: state.auth,
    scope: scope,
    user: user,
    roles: roles,
    selectedRole: selectedRole,
  };
};

const actions = {
  getUser,
  openModal,
};

function View(props) {
  const {
    auth,
    history,
    scope,
    user,
    roles,
    selectedRole,
    getUser,
    id,
    openModal,
  } = props;
  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    getUser(auth, history, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const setValues = { ...user, roles: selectedRole };
    setInitialValues(setValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFormSubmit = (values) => {};

  return (
    <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
      <div className='p-1'>
        <div className='columns is-variable'>
          <div className='column is-fullwidth'>
            <div className='card'>
              <header className='card-header has-background-white has-text-info'>
                <div
                  className='custom-card-flex is-size-6'
                  style={{ width: '100%' }}
                >
                  <p>
                    <i className='mdi mdi-account-cog is-size-5 icon' /> Setting
                    <i className='mdi mdi-menu-right is-size-6 icon' />
                    User
                  </p>
                </div>
              </header>
              <div className='card-content pl-4 pr-4'>
                <ViewForm
                  auth={auth}
                  history={history}
                  id={id}
                  initialValues={initialValues}
                  onFormSubmit={onFormSubmit}
                  openModal={openModal}
                  scope={scope}
                  roles={roles}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(connect(mapState, actions)(View));
