import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import TextInput from '../../../app/common/form/TextInput';
import SelectInput from '../../../app/common/form/SelectInput';
import TextArea from '../../../app/common/form/TextArea';
import { reduxForm, Field } from 'redux-form';

function ViewForm(props) {
  const { auth, history, scope, openModal, id, initialValues, jenjangs, tingkats, handleSubmit } =
    props;
  const [hover, setHover] = useState(false);

  let jenjangSelect = [];

  jenjangs.forEach((j, i) => {
    jenjangSelect = [...jenjangSelect, { key: i, value: j.id, text: j.title }];
  });

  let tingkatSelect = [];

  tingkats.forEach((j, i) => {
    tingkatSelect = [...tingkatSelect, { key: i, value: j.id, text: j.title }];
  });

  const handleClickStatus = () => {
    openModal('KelasStatus', {
      data: {
        auth,
        history,
        item: initialValues,
      },
    });
  };

  const toggleHover = () => {
    setHover(!hover);
  };

  const onClickKelasDelete = () => {
    openModal('KelasDelete', {
      data: {
        auth,
        history,
        item: initialValues,
      },
    });
  };

  const handleClickSubmit = () => {};

  return (
    <>
      <div className='level'>
        <div className='level-left'>
          <div className='level-item'>
            <nav className='breadcrumb is-size-6' aria-label='breadcrumbs'>
              <ul>
                <li className='is-active has-text-weight-bold'>
                  <Link to={'/akademik/kelas/' + id}>Detail</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className='level-right'>
          <div className='level-item'>
            <div className='buttons'>
              {scope && scope.subm && scope.subm['kelas_status'] && (
                <>
                  {hover && (
                    <button
                      onMouseEnter={toggleHover}
                      onMouseLeave={toggleHover}
                      className={`button is-small is-rounded is-${
                        initialValues?.status ? 'danger' : 'info'
                      } is-outlined`}
                      onClick={() => handleClickStatus()}
                    >
                      <i
                        className={`is-size-6 mdi mdi-${
                          initialValues?.status ? 'close-circle' : 'check-circle'
                        } icon`}
                      />
                    </button>
                  )}
                  {!hover && (
                    <button
                      onMouseEnter={toggleHover}
                      onMouseLeave={toggleHover}
                      className={`button is-small is-rounded is-${
                        initialValues?.status ? 'info' : 'danger'
                      } is-outlined`}
                      onClick={() => handleClickStatus()}
                    >
                      <i
                        className={`is-size-6 mdi mdi-${
                          initialValues?.status ? 'check-circle' : 'close-circle'
                        } icon`}
                      />
                    </button>
                  )}
                </>
              )}
              {scope && scope.subm && scope.subm['kelas_edit'] && (
                <Link
                  to={`/akademik/kelas/edit/${id}`}
                  className='button is-small is-rounded is-info is-outlined'
                >
                  <i className='is-size-6 mdi mdi-pencil icon' />
                </Link>
              )}
              {scope && scope.subm && scope.subm['kelas_delete'] && (
                <button
                  className='button is-small is-rounded is-danger is-outlined'
                  onClick={() => onClickKelasDelete()}
                >
                  <i className='is-size-6 mdi mdi-delete icon' />
                </button>
              )}
              <Link
                type='button'
                to={'/akademik/kelas'}
                className='button custom-grey is-small is-rounded is-outlined'
              >
                <i className='is-size-6 mdi mdi-arrow-left icon' />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='columns'>
        <div className='column is-third-quarter'>
          <form onSubmit={handleSubmit(handleClickSubmit)} autoComplete='off'>
            <Field
              name='code'
              type='text'
              component={TextInput}
              defaultValue={initialValues?.code ?? ''}
              placeholder='Kode'
              label='Kode'
              readOnly={true}
            />
            <Field
              name='title'
              type='text'
              component={TextInput}
              defaultValue={initialValues?.title ?? ''}
              placeholder='Nama Kelas'
              label='Nama Kelas'
              readOnly={true}
            />
            <Field
              name='jenjangId'
              type='text'
              component={SelectInput}
              defaultValue={initialValues?.jenjangId ?? ''}
              placeholder='Jenjang'
              label='Jenjang'
              options={jenjangSelect}
              fullwidth={true}
              readOnly={true}
            />
            <Field
              name='tingkatId'
              type='text'
              component={SelectInput}
              defaultValue={initialValues?.tingkatId ?? ''}
              placeholder='Tingkat'
              label='Tingkat'
              options={tingkatSelect}
              fullwidth={true}
              readOnly={true}
            />
            <Field
              name='description'
              type='text'
              component={TextArea}
              defaultValue={initialValues?.description ?? ''}
              placeholder='Deskripsi'
              label='Deskripsi'
              readOnly={true}
            />
          </form>
          <div className='field mt-3'>
            <label className='label'>Status Aktif</label>
          </div>
          <div className='tags has-addons'>
            <span
              className={
                initialValues?.status
                  ? 'tag'
                  : 'tag has-background-grey has-text-white'
              }
            >
              No
            </span>
            <span className={initialValues?.status ? 'tag is-link' : 'tag'}>
              Yes
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default reduxForm({
  form: 'kelasView',
  enableReinitialize: true,
})(ViewForm);
