import {
  asyncActionFinish,
  asyncActionError,
} from '../../../../app/common/reduxs/async/asyncActions';
import { semsGet, semAdd, semEdit, semGet, semDelete } from './reduxAction';
import { ASYNC_ACTION_START } from '../../../../app/common/reduxs/async/asyncConstant';
import { checkErr } from '../../../../app/common/helpers/checkRes';
import { fetchFunc } from '../../../../app/common/helpers/fetchHelpers';

export const getSems = (
  auth,
  history,
  itemPerPage,
  currentPage,
  searchText
) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getSems' });
    const formData = new FormData();
    formData.append('itemPerPage', itemPerPage ? itemPerPage : 10);
    formData.append('currentPage', currentPage);
    formData.append('searchText', searchText);
    try {
      const res = await fetchFunc(
        'gets',
        auth,
        history,
        'sem/',
        formData,
        dispatch
      );
      const items = res.items;
      const total = res.total;
      const search = {
        currentPage,
        searchText,
        itemPerPage: itemPerPage ? itemPerPage : 10,
      };
      dispatch(semsGet({ total, items, search }));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const addSem = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'addSem' });
    const formData = new FormData();
    formData.append('code', values.code);
    formData.append('title', values.title);
    formData.append('description', values.description);
    try {
      const res = await fetchFunc(
        'add',
        auth,
        history,
        'sem/add/',
        formData,
        dispatch
      );
      const sem = res?.sem;
      if (sem) {
        dispatch(semAdd(sem));
        history.push('/akademik/sem/' + sem.id);
      }
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const getSem = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getSem' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'get',
        auth,
        history,
        'sem/detail/' + id,
        formData,
        dispatch
      );
      const sem = res.sem;
      dispatch(semGet(sem));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const editSem = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'editSem' });
    const formData = new FormData();
    formData.append('code', values.code);
    formData.append('title', values.title);
    formData.append(
      'description',
      values.description ? values.description : undefined
    );
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'sem/edit/' + values.id,
        formData,
        dispatch
      );
      const sem = res?.sem;
      if (sem) {
        dispatch(semEdit(sem));
        history.push('/akademik/sem/' + values.id);
      }
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const deleteSem = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'deleteSem' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'delete',
        auth,
        history,
        'sem/delete/' + id,
        formData,
        dispatch
      );
      const sem = res.sem;
      dispatch(semDelete(sem));
      history.push('/akademik/sem');
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};
