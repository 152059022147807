import React from 'react';
import { format, parseISO } from 'date-fns';

const TextInput = ({
  id,
  name,
  type,
  onChange,
  onKeyDown,
  disabled,
  readOnly,
  defaultValue,
  placeholder,
  label,
  style,
}) => (
  <div className='field'>
    {label && label !== 'noLabel' && (
      <label className='label' htmlFor={name}>
        {label}
      </label>
    )}
    <div className='control'>
      <input
        className='input is-size-6'
        id={name}
        readOnly={readOnly}
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={defaultValue}
        type={type}
        disabled={disabled}
        placeholder={placeholder}
        style={{ ...style }}
      />
    </div>
  </div>
);

export default function ViewScoreFormListItems(props) {
  const {
    // auth,
    // scope,
    item,
    questions,
    keyAnswers,
    stateScores,
    scores,
    handleClickAttachment,
    isPaperWork,
    onChangeInput,
    state,
    onViewSummaryAnswer,
    handleEnter,
    isEdit,
    // index,
  } = props;

  console.log(isPaperWork);

  const attachments = item && item.attachments && JSON.parse(item.attachments);
  const listAttachments =
    attachments &&
    attachments.map((att, idx) => (
      <div
        onClick={(e) => handleClickAttachment(e, att)}
        className='has-text-link hand-pointer'
        key={idx}
      >
        {att.filename}
      </div>
    ));

  const getRemarks = item && item.remarks && JSON.parse(item.remarks);
  const remarks = getRemarks && getRemarks.remarks ? getRemarks.remarks : '';

  const getScore =
    state && state.find((i) => i.studentTugasId + '' === item.id + '');

  let tableList = isPaperWork ? (
    <tr>
      <td className='has-text-centered'>{item.no}</td>
      <td className='has-text-centered'>{item.nis}</td>
      <td>{item.siswa}</td>
      <td>{listAttachments}</td>
      <td>{remarks}</td>
      <td className='has-text-centered'>
        {item.createdAt &&
          format(parseISO(item.createdAt), 'd LLLL yyyy - HH:mm:ss')}
      </td>
      <td className={`middle${isEdit ? '' : ' has-text-right'}`}>
        {!isEdit && getScore?.finalScore}
        {isEdit && (
          <div className='has-text-right'>
            <TextInput
              name={item.nis + '_score'}
              style={{ textAlign: 'right', width: 80 }}
              defaultValue={getScore?.finalScore ?? 0}
              onKeyDown={(e) => handleEnter(e)}
              onChange={(e) => onChangeInput(e, item)}
              placeholder='ex: 80'
              label='noLabel'
            />
          </div>
        )}
      </td>
    </tr>
  ) : (
    <tr>
      <td className='has-text-centered middle'>{item.no}</td>
      <td className='has-text-centered middle'>{item.nis}</td>
      <td className='middle is-capitalized'>{item.siswa.toLowerCase()}</td>
      <td className='has-text-right middle'>{getScore?.autoScore}</td>
      <td className={`middle${isEdit ? '' : ' has-text-right'}`}>
        {!isEdit && getScore?.finalScore}
        {isEdit && (
          <div className='has-text-right'>
            <TextInput
              name={item.nis + '_score'}
              style={{ textAlign: 'right', width: 80 }}
              defaultValue={getScore?.finalScore ?? 0}
              onKeyDown={(e) => handleEnter(e)}
              onChange={(e) => onChangeInput(e, item)}
              placeholder='ex: 80'
              label='noLabel'
            />
          </div>
        )}
      </td>
      <td className='has-text-centered middle'>
        {item.createdAt &&
          format(parseISO(item.createdAt), 'd LLLL yyyy - HH:mm:ss')}
      </td>
      <td className='has-text-centered middle'>
        <button
          onClick={() =>
            onViewSummaryAnswer(
              item,
              questions,
              keyAnswers,
              stateScores,
              scores
            )
          }
          className='button is-small is-rounded is-info is-outlined ml-1'
        >
          <i className='is-size-6 mdi mdi-eye icon' />
        </button>
      </td>
    </tr>
  );

  return tableList;
}
