import { DASHBOARD_GET } from "./reduxConstant";
import { createReducer } from "../../../app/common/utilities/reducerUtils";

const initialState = {};

const dashboardGet = (state, payload) => {
  return { ...payload.dashboard };
};

export default createReducer(initialState, {
  [DASHBOARD_GET]: dashboardGet,
});
