import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { addYear } from './redux/reduxApi';
import AddForm from './AddForm';

const mapState = (state, ownProps) => {
  return {
    loading: state.async.loading,
    auth: state.auth,
  };
};

const actions = {
  addYear,
};

function Add(props) {
  const { auth, history, loading, addYear } = props;

  return (
    <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
      <div className='p-1'>
        <div className='columns is-variable'>
          <div className='column is-fullwidth'>
            <div className='card'>
              <header className='card-header has-background-white has-text-info'>
                <div
                  className='custom-card-flex is-size-6'
                  style={{ width: '100%' }}
                >
                  <p>
                    <i className='mdi mdi-school is-size-5 icon' /> Akademik
                    <i className='mdi mdi-menu-right is-size-6 icon' />
                    Tahun Ajaran
                  </p>
                </div>
              </header>
              <div className='card-content pl-4 pr-4'>
                <AddForm
                  loading={loading}
                  addYear={addYear}
                  auth={auth}
                  history={history}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(connect(mapState, actions)(Add));
