import React from 'react';
import ViewScoreFormListItems from './ViewScoreFormListItems';

export default function ViewScoreFormList(props) {
  const {
    auth,
    scope,
    questions,
    keyAnswers,
    studentAnswer,
    stateScores,
    scores,
    handleClickAttachment,
    isPaperWork,
    onChangeInput,
    state,
    onViewSummaryAnswer,
    handleEnter,
    isEdit,
  } = props;
  return (
    <>
      {studentAnswer &&
        studentAnswer[0] &&
        studentAnswer.map((item, index) => (
          <ViewScoreFormListItems
            key={item.id}
            index={index}
            auth={auth}
            scope={scope}
            item={item}
            questions={questions}
            keyAnswers={keyAnswers}
            stateScores={stateScores}
            scores={scores}
            handleClickAttachment={handleClickAttachment}
            isPaperWork={isPaperWork}
            onChangeInput={onChangeInput}
            state={state}
            onViewSummaryAnswer={onViewSummaryAnswer}
            handleEnter={handleEnter}
            isEdit={isEdit}
          />
        ))}
      {studentAnswer && studentAnswer.length === 0 && (
        <tr>
          <td colSpan='7'>
            Tidak ada <span className='is-lowercase'>data</span>
          </td>
        </tr>
      )}
    </>
  );
}
