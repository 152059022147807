import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAnnouncement, editAnnouncement, uploadAnnouncement } from './redux/reduxApi';
import EditForm from './EditForm';
import { parseISO } from 'date-fns';

const mapState = (state, ownProps) => {
  const id = ownProps.match.params.id;
  let scope = {};
  if (state.auth && state.auth.isAuth) {
    scope = state.auth.authorities;
  }
  let announcement = {};
  if (state.announcements && state.announcements.items && state.announcements.items[0]) {
    announcement = state.announcements.items.filter((i) => i.id + '' === id + '')[0];
  }
  let uploadFile = state.announcements.uploadFile;
  return {
    auth: state.auth,
    loading: state.async.loading,
    scope: scope,
    id: id,
    announcement: announcement,
    uploadFile: uploadFile,
  };
};

const actions = {
  getAnnouncement,
  editAnnouncement,
  uploadAnnouncement,
};

function Edit(props) {
  const { auth, history, loading, id, announcement, uploadFile, getAnnouncement, editAnnouncement, uploadAnnouncement } = props;

  let date = announcement && announcement.date && parseISO(announcement.date);

  useEffect(() => {
    getAnnouncement(auth, history, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
      <div className='p-1'>
        <div className='columns is-variable'>
          <div className='column is-fullwidth'>
            <div className='card'>
              <header className='card-header has-background-white has-text-info'>
                <div
                  className='custom-card-flex is-size-6'
                  style={{ width: '100%' }}
                >
                  <p>
                    <i className='mdi mdi-account-group is-size-5 icon' /> Portal
                    <i className='mdi mdi-menu-right is-size-6 icon' />
                    Pengumuman
                  </p>
                </div>
              </header>
              <div className='card-content pl-4 pr-4'>
                <EditForm
                  initialValues={{
                    ...announcement,
                    date: date ?? null,
                  }}
                  loading={loading}
                  auth={auth}
                  id={id}
                  uploadFile={uploadFile}
                  history={history}
                  editAnnouncement={editAnnouncement}
                  uploadAnnouncement={uploadAnnouncement}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(connect(mapState, actions)(Edit));
