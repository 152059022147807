import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';
import TextInput from '../../../app/common/form/TextInput';
import EditorQuill from '../../../app/common/form/EditorQuill';
import { composeValidators, combineValidators, isRequired } from 'revalidate';
import { SITE_ADDRESS } from '../../../app/common/data/siteConfig';
import { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageUploader from 'quill-image-uploader';
import { nanoid } from 'nanoid';
import { convertSize } from '../../../app/common/helpers/othersHelpers';

const validate = combineValidators({
  title: composeValidators(isRequired({ message: 'Judul harus diisi' }))(),
  description: composeValidators(
    isRequired({ message: 'Materi harus diisi' })
  )(),
});

let onChangeTitle;
let onChangeDescription;
const handleOnFormChange = (newValues, dispatch, props, previousValues) => {
  const { title: newTitle, description: newDescription } = newValues;
  onChangeTitle = newTitle;
  onChangeDescription = newDescription;
};

Quill.register('modules/imageUploader', ImageUploader);

class EditForm extends Component {
  constructor(props) {
    super(props);
    this.attachmentsDiv = React.createRef(1);
  }

  modules = {
    toolbar: [
      [{ size: ['small', false, 'large', 'huge'] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
        { script: 'sub' },
        { script: 'super' },
      ],
      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      ['link', 'image', 'video'],
    ],
    imageUploader: {
      upload: (file) => {
        return new Promise((resolve, reject) => {
          console.log('test');
          const formData = new FormData();
          formData.append('userId', this.props.auth.userId);
          formData.append('username', this.props.auth.username);
          formData.append('title', onChangeTitle);
          formData.append('description', onChangeDescription);
          formData.append('file', file);
          formData.append('filename', file.name);
          fetch(SITE_ADDRESS + 'materi/upload-image/' + this.props.id, {
            method: 'POST',
            body: formData,
            headers: {
              Authorization: 'Bearer ' + this.props.auth.token,
            },
          })
            .then((response) => response.json())
            .then((result) => {
              resolve(SITE_ADDRESS + result.url);
            })
            .catch((error) => {
              reject('Upload failed');
              console.error('Error:', error);
            });
        });
      },
    },
  };

  onClickAttachment = (item) => {
    const { auth, history, initialValues, openModal, downloadFile } =
      this.props;
    function checkImage(url) {
      return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
    }
    let isImage = checkImage(item.filelink);
    if (isImage) {
      openModal('PopUpImageModal', { data: SITE_ADDRESS + item.filelink });
    } else {
      downloadFile(auth, history, initialValues, item);
    }
  };

  handleClickFileDelete = (e, file) => {
    e.preventDefault();
    const { auth, history, id, initialValues, openModal } = this.props;
    openModal('MateriFileDelete', {
      data: {
        auth,
        history,
        materi: { ...initialValues, id },
        file,
      },
    });
  };

  handleSendFile = async (fileItem) => {
    const { auth, history, uploadAttachment, id } = this.props;
    let title = onChangeTitle;
    let description = onChangeDescription;
    let data = {
      id,
      title,
      description,
      attachments: fileItem.attachments,
      file: fileItem.file,
      key: fileItem.key,
    };
    await uploadAttachment(auth, history, data);
    this.attachmentsDiv.current++;
  };

  handleUploadAttachment = (e, input) => {
    e.preventDefault();
    const { auth, id, initialValues } = this.props;
    let files = e.target.files;
    let fileList = [];
    let newAttachments = initialValues?.attachments
      ? JSON.parse(initialValues.attachments)
      : [];
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      let key = nanoid();
      let attachment = {
        key: key,
        filename: file.name,
        filesize: file.size,
        filetype: file.type,
        filelink:
          `files/materi/${auth.userId}/${id}/${Date.now()}_` +
          file.name.split(',').join('').split(' ').join('-'),
      };
      newAttachments.push(attachment);
      fileList.push({
        file: file,
        key: key,
        attachments: newAttachments,
      });
    }
    fileList.forEach((fileItem) => {
      try {
        this.handleSendFile(fileItem);
      } catch (err) {
        console.log('Error:', err);
      }
    });
  };

  onFormSubmit = async (values) => {
    const { auth, history, id, editMateri } = this.props;
    try {
      let title = values.title ? values.title.trim() : '';
      let description = values.description ? values.description.trim() : '';
      const data = {
        ...values,
        id: id,
        title: title,
        description: description,
      };
      editMateri(auth, history, data);
    } catch (error) {
      console.log(error);
    }
  };

  renderFileInput = ({ input, type, meta, label, placeholder, fileNames }) => {
    return (
      <div className='field'>
        <div className='label'>{label}</div>
        <div className='control'>
          <div className='file has-name is-fullwidth'>
            <label className='file-label'>
              <input
                multiple
                name={input.name}
                accept="image/*,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.ppt,.pptx,.xls,.xlsx,.zip" 
                className='file-input'
                type={type}
                onChange={(event) => this.handleUploadAttachment(event, input)}
              />
              <span className='file-cta'>
                <span className='file-icon'>
                  <i className='is-size-6 mdi mdi-upload'></i>
                </span>
                <span className='file-label'>{placeholder}</span>
              </span>
              <span className='file-name'>{fileNames}</span>
            </label>
          </div>
        </div>
        {meta && meta.invalid && meta.error && <div>{meta.error}</div>}
      </div>
    );
  };

  render() {
    const {
      history,
      id,
      initialValues,
      uploadStatus,
      invalid,
      loading,
      handleSubmit,
    } = this.props;
    let attachments = initialValues && initialValues.attachments && JSON.parse(initialValues.attachments);
    return (
      <>
        <div className='level'>
          <div className='level-left'>
            <div className='level-item'>
              <nav className='breadcrumb is-size-6' aria-label='breadcrumbs'>
                <ul>
                  <li className='is-active has-text-weight-bold'>
                    <Link to={'/pembelajaran/materi/' + id}>Edit</Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>

          <div className='level-right'>
            <div className='level-item'>
              <div className='buttons'>
                <button
                  disabled={invalid || loading}
                  onClick={handleSubmit((e, values) =>
                    this.onFormSubmit(e, values)
                  )}
                  className={
                    loading
                      ? 'button is-small is-info is-rounded is-outlined is-loading'
                      : 'button is-small is-info is-rounded is-outlined'
                  }
                >
                  <i className='is-size-6 mdi mdi-content-save icon' />
                </button>
                <Link
                  to={'/pembelajaran/materi/' + id}
                  className='button custom-grey is-small is-rounded is-outlined'
                >
                  <i className='is-size-6 mdi mdi-arrow-left icon' />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className='columns'>
          <div id='quilEditor' className='column is-third-quarter'>
            <form onSubmit={handleSubmit(this.onFormSubmit)} autoComplete='off'>
              <Field
                name='title'
                type='text'
                component={TextInput}
                placeholder='Judul'
                label='Judul Materi'
              />
              <Field
                name='description'
                type='text'
                component={EditorQuill}
                modules={this.modules}
                label='Deskripsi'
                placeholder='Deskripsi disini...'
              />
              <Field
                name='attachment'
                type='file'
                component={this.renderFileInput}
                placeholder='File'
                label='File Attachement'
              />
              <div
                className='column is-full-tablet is-three-quarters-desktop'
                ref={this.attachmentsDiv}
                style={{ marginTop: -10, marginLeft: -10 }}
              >
                {uploadStatus === 'start' && (
                  <progress
                    className='progress is-small is-info'
                    max='100'
                    style={{ marginBottom: 8 }}
                  >
                    0%
                  </progress>
                )}
                {attachments &&
                  attachments.map((item, index) => (
                    <div
                      key={index}
                      className='has-text-link has-background-grey-lighter px-2 py-1 mb-1 flex justify-between align-center'
                    >
                      <span
                        className='hand-pointer'
                        onClick={() => this.onClickAttachment(item)}
                      >
                        {item.filename} (
                        {item?.filesize && convertSize(item.filesize)})
                      </span>
                      <button
                        onClick={(e) => this.handleClickFileDelete(e, item)}
                        className='delete'
                      ></button>
                    </div>
                  ))}
              </div>
              <div
                className='field is-grouped'
                style={{ marginTop: 20, marginBottom: 20 }}
              >
                <div className='control'>
                  <button
                    type='submit'
                    disabled={invalid || loading}
                    className={
                      loading
                        ? 'button is-info is-small is-rounded is-outlined is-loading'
                        : 'button is-info is-small is-rounded is-outlined'
                    }
                  >
                    <i className='is-size-6 mdi mdi-content-save icon' />
                  </button>
                </div>
                <div className='control'>
                  <button
                    onClick={() => history.goBack()}
                    className='button custom-grey is-small is-rounded is-outlined'
                  >
                    <i className='is-size-6 mdi mdi-arrow-left icon' />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default reduxForm({
  form: 'materiEdit',
  onChange: handleOnFormChange,
  validate,
  enableReinitialize: true,
})(EditForm);
