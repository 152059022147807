import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';
import {
  composeValidators,
  combineValidators,
  isRequired,
  hasLengthGreaterThan,
} from 'revalidate';
import TextInput from '../../../app/common/form/TextInput';
import TextArea from '../../../app/common/form/TextArea';
import SelectInput from '../../../app/common/form/SelectInput';
import { nanoid } from 'nanoid';
import { sortArrayObject } from '../../../app/common/helpers/objectHelpers';
import { ALPHA_INDEX } from '../../../app/common/helpers/othersHelpers';
import { toastr } from 'react-redux-toastr';
import { ltrim } from '../../../app/common/helpers/othersHelpers';

const validate = combineValidators({
  title: composeValidators(
    isRequired({ message: 'Judul harus diisi' }),
    hasLengthGreaterThan(2)({
      message: 'Judul harus memiliki paling sedikit 3 karakter',
    })
  )(),
});

let onChangeTitle;
let onChangeDescription;
const handleOnFormChange = (newValues, dispatch, props, previousValues) => {
  const { title: newTitle, description: newDescription } = newValues;
  onChangeTitle = newTitle;
  onChangeDescription = newDescription;
};

function AddForm(props) {
  const {
    auth,
    history,
    id,
    initialValues,
    uploadFiles,
    changeSessionState,
    deleteSessionState,
    addTugas,
    uploadImage,
    invalid,
    loading,
    pristine,
    handleSubmit,
  } = props;
  const [state, setState] = useState({
    questions: [...initialValues.questions],
    answers: [...initialValues.answers],
  });

  let questions = state && state.questions;
  let questionsLength = questions && questions.length;
  let answers = state && state.answers;

  let questionSelect =
    questionsLength > 1
      ? [
          { key: 1, text: 'Pertanyaan Pilihan', value: 'optionQuestion' },
          { key: 2, text: 'Pertanyaan Singkat', value: 'shortQuestion' },
          { key: 3, text: 'Pertanyaan uraian', value: 'longQuestion' },
        ]
      : [
          { key: 1, text: 'Pertanyaan Pilihan', value: 'optionQuestion' },
          { key: 2, text: 'Pertanyaan Singkat', value: 'shortQuestion' },
          { key: 3, text: 'Pertanyaan uraian', value: 'longQuestion' },
          { key: 4, text: 'Tugas File Upload', value: 'paperWork' },
        ];

  useEffect(() => {
    let timer = setTimeout(() => {
      changeSessionState({
        id,
        data: {
          title: onChangeTitle,
          description: onChangeDescription,
          questions: state.questions,
          answers: state.answers,
        },
      });
    }, 10000);

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickFocus = (e) => {
    let val = e.target.value;
    if (
      val === 'Pertanyaan di sini...' ||
      val === 'Ex: a' ||
      val === 'Jawaban di sini...' ||
      val === 'Penjelasan jawaban...' ||
      val === 'Pertanyaan, penjelasan detail tugas di sini...'
    ) {
      e.target.select();
    }
  };

  const onChangeQuestionType = (e, item) => {
    e.preventDefault();
    let questionType = e.target.value;
    let question;
    let answer;
    if (questionType !== 'optionQuestion' && questionType !== 'paperWork') {
      question = {
        ...item,
        questionType: questionType,
        options: [],
        picture: null,
      };
      let oldAnswer = state.answers.filter(
        (i) => i.key + '' === item.key + ''
      )[0];
      answer = { ...oldAnswer, answer: 'Jawaban di sini...' };
    } else if (questionType === 'optionQuestion') {
      question = {
        ...item,
        questionType: questionType,
        options: [
          'a. pilihan 1',
          'b. pilihan 2',
          'c. pilihan 3',
          'd. pilihan 4',
        ],
        picture: null,
      };
      let oldAnswer = state.answers.filter(
        (i) => i.key + '' === item.key + ''
      )[0];
      answer = { ...oldAnswer, answer: 'Ex: a' };
    } else if (questionType === 'paperWork') {
      question = {
        ...item,
        question: 'Pertanyaan, penjelasan detail tugas di sini...',
        questionType: questionType,
        options: [],
        picture: null,
      };
      let oldAnswer = state.answers.filter(
        (i) => i.key + '' === item.key + ''
      )[0];
      answer = { ...oldAnswer, answer: '' };
    }
    let questions = [
      ...state.questions.filter((i) => i.key + '' !== item.key + ''),
      question,
    ];
    let answers = [
      ...state.answers.filter((i) => i.key + '' !== item.key + ''),
      answer,
    ];
    setState({
      ...state,
      questions: sortArrayObject(questions, 'no', 'asc'),
      answers: sortArrayObject(answers, 'no', 'asc'),
    });
  };

  const onChangeQuestion = (e, item) => {
    let value = e.target.value ? e.target.value : ' ';
    if (value && value.length > 1) {
      value = ltrim(value);
    }
    let question = { ...item, question: value };
    let questions = [
      ...state.questions.filter((i) => i.key !== item.key),
      question,
    ];
    setState({
      ...state,
      questions: sortArrayObject(questions, 'no', 'asc'),
    });
  };

  const onChangeOption = (e, item, options, index) => {
    let newOptions = [];
    options.forEach((item, idx) => {
      if (idx + '' === index + '') {
        let value = e.target.value ? e.target.value : ' ';
        if (value && value.length > 1) {
          value = ltrim(value);
        }
        newOptions = [...newOptions, value];
      } else {
        newOptions = [...newOptions, item];
      }
    });
    let newQuestion = {
      ...item,
      options: newOptions,
    };
    let newQuestions = [
      ...state.questions.filter((i) => i.key + '' !== item.key + ''),
      newQuestion,
    ];
    setState({
      ...state,
      questions: sortArrayObject(newQuestions, 'no', 'asc'),
    });
  };

  const onAddOption = (e, item) => {
    e.preventDefault();
    let options = item.options;
    let optionLength = options.length;
    let newOption = `${ALPHA_INDEX[optionLength]}. pilihan ${optionLength + 1}`;
    options = [...options, newOption];
    let question = { ...item, options };
    let questions = [
      ...state.questions.filter((i) => i.key !== item.key),
      question,
    ];
    setState({
      ...state,
      questions: sortArrayObject(questions, 'no', 'asc'),
    });
  };

  const onRemoveOption = (e, item) => {
    e.preventDefault();
    let options = item.options;
    if (options.length < 3) {
      toastr.error('Warning', 'Minimal 2 pilihan!');
      return;
    }
    options.pop();
    let question = { ...item, options };
    let questions = [
      ...state.questions.filter((i) => i.key !== item.key),
      question,
    ];
    setState({
      ...state,
      questions: sortArrayObject(questions, 'no', 'asc'),
    });
  };

  const onChangeAnswer = (e, item) => {
    let answer = [...state.answers.filter((i) => i.key === item.key)][0];

    let value = e.target.value ? e.target.value : ' ';
    if (value && value.length > 1) {
      value = ltrim(value);
    }

    let newAnswer = { ...answer, answer: value };
    let answers = [
      ...state.answers.filter((i) => i.key !== item.key),
      newAnswer,
    ];
    setState({
      ...state,
      answers: sortArrayObject(answers, 'no', 'asc'),
    });
  };

  const onChangeExplanation = (e, item) => {
    let explanation = [...state.answers.filter((i) => i.key === item.key)][0];

    let value = e.target.value ? e.target.value : ' ';
    if (value && value.length > 1) {
      value = ltrim(value);
    }
    let newExplanation = {
      ...explanation,
      explanation: value,
    };
    let answers = [
      ...state.answers.filter((i) => i.key !== item.key),
      newExplanation,
    ];
    setState({
      ...state,
      answers: sortArrayObject(answers, 'no', 'asc'),
    });
  };

  const onAddQuestion = (e, item) => {
    e.preventDefault();
    let noInit = state.questions.length + 1;
    let keyInit = nanoid();
    let question = {
      ...item,
      no: noInit,
      key: keyInit,
      questionType: 'optionQuestion',
      question: 'Pertanyaan di sini...',
      options: ['a. pilihan 1', 'b. pilihan 2', 'c. pilihan 3', 'd. pilihan 4'],
      picture: null,
    };
    let qAll = [...state.questions, question];
    let answer = {
      no: noInit,
      key: keyInit,
      answer: 'Ex: a',
      explanation: 'Penjelasan jawaban...',
    };
    let aAll = [...state.answers, answer];
    setState({
      ...state,
      questions: qAll,
      answers: aAll,
    });
    changeSessionState({ id, data: state });
  };

  const onCopyQuestion = (e, item) => {
    e.preventDefault();
    let keyInit = nanoid();
    let noInit = state.questions.length + 1;
    let question = {
      ...item,
      no: noInit,
      key: keyInit,
      picture: null,
    };
    let qAll = [...state.questions, question];
    let answer = {
      no: noInit,
      key: keyInit,
      answer: state.answers.filter((i) => i.key === item.key)[0].answer,
      explanation: state.answers.filter((i) => i.key === item.key)[0]
        .explanation,
    };
    let aAll = [...state.answers, answer];
    setState({
      ...state,
      questions: qAll,
      answers: aAll,
    });
  };

  const onDeleteQuestion = (e, item) => {
    e.preventDefault();
    let qAll = [
      ...state.questions.filter((i) => {
        return i.key !== item.key;
      }),
    ];
    qAll.forEach((q, i) => {
      q.no = i + 1;
    });
    let aAll = [
      ...state.answers.filter((i) => {
        return i.key !== item.key;
      }),
    ];
    aAll.forEach((q, i) => {
      q.no = i + 1;
    });
    setState({
      ...state,
      questions: qAll,
      answers: aAll,
    });
  };

  const onUploadImage = (e, key) => {
    e.preventDefault();
    let file = e.target.files[0];
    if (!file) {
      return;
    }
    let title = onChangeTitle;
    let description = onChangeDescription;
    let keyItem = key;
    let questions = state.questions;
    let answers = state.answers;
    let filename = file.name;
    let filesize = file.size;
    let filetype = file.type;
    let data = {
      id: id,
      title,
      description,
      keyItem,
      questions,
      answers,
      file,
      filename,
      filesize,
      filetype,
    };
    uploadImage(auth, history, data);
    deleteSessionState({ id, data: state });
  };

  const onFormSubmit = () => {
    try {
      const data = {
        id: id,
        title: onChangeTitle,
        description: onChangeDescription,
        questions: state.questions,
        answers: state.answers,
      };
      addTugas(auth, history, data);
      deleteSessionState({ id, data: state });
    } catch (error) {
      console.log(error);
    }
  };

  const questionTypePaperWork = () => {
    let qTPW = false;
    for (const obj of questions) {
      if (obj.questionType === 'paperWork') {
        qTPW = true;
        break;
      }
    }
    return qTPW;
  };

  const renderFileInput = ({
    input,
    type,
    meta,
    label,
    placeholder,
    fileNames,
    keyItem,
  }) => {
    return (
      <div className='field'>
        <div className='control'>
          <div className='file has-name'>
            <label className='file-label'>
              <input
                name={input.name}
                className='file-input'
                type={type}
                accept='image/*'
                onChange={(e) => onUploadImage(e, keyItem)}
              />
              <span className='file-cta'>
                <span className='file-icon'>
                  <i className='is-size-6 mdi mdi-image'></i>
                </span>
                <span className='file-label'>Upload Gambar</span>
              </span>
              {fileNames && <span className='file-name'>{fileNames}</span>}
            </label>
          </div>
        </div>
        {meta && meta.invalid && meta.error && <div>{meta.error}</div>}
      </div>
    );
  };

  return (
    <div className='card-content pl-4 pr-4'>
      <div className='level'>
        <div className='level-left'>
          <div className='level-item'>
            <nav className='breadcrumb is-size-6' aria-label='breadcrumbs'>
              <ul>
                <li className='is-active has-text-weight-bold'>
                  <Link to='/pembelajaran/tugas/tambah'>Tambah</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className='level-right'>
          <div className='level-item'>
            <div className='buttons'>
              <button
                disabled={invalid || loading || pristine}
                onClick={handleSubmit(onFormSubmit)}
                className={
                  loading
                    ? 'button is-small is-info is-rounded is-outlined is-loading'
                    : 'button is-small is-info is-rounded is-outlined'
                }
              >
                <i className='is-size-6 mdi mdi-content-save icon' />
              </button>
              <Link
                to='/pembelajaran/tugas'
                className='button custom-grey is-small is-rounded is-outlined'
              >
                <i className='is-size-6 mdi mdi-arrow-left icon' />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='columns'>
        <div className='column is-third-quarter'>
          <form onSubmit={handleSubmit(onFormSubmit)} autoComplete='off'>
            <Field
              name='title'
              type='text'
              component={TextInput}
              placeholder='Judul'
              label='Judul Tugas'
            />
            <Field
              name='description'
              type='text'
              component={TextArea}
              label='Deskripsi'
              placeholder='Deskripsi Tugas'
            />

            <div className='field'>
              <label className='label'>Pertanyaan</label>
            </div>
            {questions &&
              questions.map((item, index) => (
                <div className='box' key={item.key}>
                  <div
                    style={{
                      position: 'relative',
                      left: 1,
                      top: -6,
                    }}
                  >
                    {`${item.no}/${state.questions.length}`}
                  </div>
                  <Field
                    name={'questionType_' + item.key}
                    type='text'
                    onChange={(e) => onChangeQuestionType(e, item)}
                    component={SelectInput}
                    defaultValue={item.questionType ?? ' '}
                    placeholder='Pilih Jenis Pertanyaan'
                    label='noLabel'
                    options={questionSelect}
                    fullwidth={true}
                  />
                  <Field
                    name={'question_' + item.key}
                    type='text'
                    defaultValue={item.question ?? ' '}
                    onChange={(e) => onChangeQuestion(e, item)}
                    onFocus={onClickFocus}
                    component={TextArea}
                    rows={2}
                    placeholder='Pertanyaan disini...'
                    label='noLabel'
                  />
                  <Field
                    name={'image_' + item.key}
                    keyItem={item.key}
                    type='file'
                    component={renderFileInput}
                    placeholder='Gambar'
                    label='Gambar Pertanyaan'
                  />
                  {uploadFiles &&
                    uploadFiles[0] &&
                    uploadFiles?.find((i) => i.key === item.key)?.status ===
                      'start' && (
                      <progress
                        className='progress is-small is-info mt-2'
                        max='100'
                      >
                        0%
                      </progress>
                    )}
                  {item.questionType === 'optionQuestion' &&
                    item.options &&
                    item.options[0] &&
                    item.options.map((opt, idx) => (
                      <Field
                        key={idx}
                        name={'options_' + item.key + '_' + idx}
                        type='text'
                        defaultValue={opt ?? ' '}
                        onChange={(e) =>
                          onChangeOption(e, item, item.options, idx)
                        }
                        component={TextInput}
                        placeholder={`${ALPHA_INDEX[idx]}. pilihan ${idx + 1}`}
                        label='noLabel'
                      />
                    ))}
                  {item.questionType === 'optionQuestion' && (
                    <>
                      <div className='mb-4'>
                        <button
                          onClick={(e) => onAddOption(e, item)}
                          className='button is-small is-rounded is-outlined mr-1 has-text-info'
                        >
                          <i className='is-size-6 mdi mdi-plus icon' />
                        </button>
                        <button
                          onClick={(e) => onRemoveOption(e, item)}
                          className='button is-small is-rounded is-outlined mr-1 has-text-danger'
                        >
                          <i className='is-size-6 mdi mdi-minus icon' />
                        </button>
                      </div>
                    </>
                  )}
                  {item.questionType === 'paperWork' && (
                    <div className='has-text-weight-bold is-size-7 is-italic'>
                      Note: Siswa akan bisa mengupload file tugas seperti
                      (document word, excel, ppt, pdf, gambar).
                    </div>
                  )}
                  {item.questionType !== 'paperWork' && (
                    <>
                      <Field
                        name={'answer_' + item.key}
                        type='text'
                        defaultValue={
                          answers && answers[index] && answers[index].answer
                            ? answers[index].answer
                            : ' '
                        }
                        onChange={(e) => onChangeAnswer(e, item)}
                        onFocus={onClickFocus}
                        component={
                          item.questionType === 'longQuestion'
                            ? TextArea
                            : TextInput
                        }
                        placeholder={
                          item.questionType === 'optionQuestion'
                            ? 'ex: a'
                            : 'Jawaban'
                        }
                        label='Jawaban'
                      />
                      <Field
                        name={'explanation_' + item.key}
                        type='text'
                        defaultValue={
                          answers &&
                          answers[index] &&
                          answers[index].explanation
                            ? answers[index].explanation
                            : ' '
                        }
                        onChange={(e) => onChangeExplanation(e, item)}
                        onFocus={onClickFocus}
                        component={TextArea}
                        placeholder='Penjelasan disini...'
                        label='Penjelasan'
                      />
                    </>
                  )}
                  {!questionTypePaperWork() && (
                    <>
                      <hr style={{ marginTop: 40 }} />
                      <div className='flex justify-end'>
                        <div className='control'>
                          <button
                            onMouseDown={(e) => e.preventDefault()}
                            onClick={(e) => onAddQuestion(e, item)}
                            className='button is-small is-rounded is-outlined mr-1'
                          >
                            <i className='is-size-6 mdi mdi-plus icon' />
                          </button>
                          <button
                            onMouseDown={(e) => e.preventDefault()}
                            onClick={(e) => onCopyQuestion(e, item)}
                            className='button is-small is-rounded is-outlined mr-1'
                          >
                            <i className='is-size-6 mdi mdi-content-copy icon' />
                          </button>
                          <button
                            onMouseDown={(e) => e.preventDefault()}
                            onClick={(e) => onDeleteQuestion(e, item)}
                            className='button is-small is-rounded is-outlined'
                          >
                            <i className='is-size-6 mdi mdi-delete icon' />
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              ))}
            <div
              className='field is-grouped'
              style={{ marginTop: 20, marginBottom: 20 }}
            >
              <div className='control'>
                <button
                  type='submit'
                  disabled={invalid || loading || pristine}
                  className={
                    loading
                      ? 'button is-info is-small is-rounded is-outlined is-loading'
                      : 'button is-info is-small is-rounded is-outlined'
                  }
                >
                  <i className='is-size-6 mdi mdi-content-save icon' />
                </button>
              </div>
              <div className='control'>
                <Link
                  to='/pembelajaran/tugas'
                  className='button custom-grey is-small is-rounded is-outlined'
                >
                  <i className='is-size-6 mdi mdi-arrow-left icon' />
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default withRouter(
  reduxForm({
    form: 'addTugas',
    onChange: handleOnFormChange,
    validate,
    enableReinitialize: true,
  })(AddForm)
);
