import {
  MATERIS_GET,
  MATERI_ADD,
  MATERI_GET,
  MATERI_EDIT,
  MATERI_UPLOADFILE,
  MATERI_DELETE,
  MATERI_RESET_SEARCH,
} from './reduxConstant';

export const materisGet = (materis) => {
  return {
    type: MATERIS_GET,
    payload: {
      materis,
    },
  };
};

export const materiAdd = (materi) => {
  return {
    type: MATERI_ADD,
    payload: {
      materi,
    },
  };
};

export const materiGet = (materi) => {
  return {
    type: MATERI_GET,
    payload: {
      materi,
    },
  };
};

export const materiEdit = (materi) => {
  return {
    type: MATERI_EDIT,
    payload: {
      materi,
    },
  };
};

export const materiUploadFile = (status) => {
  return {
    type: MATERI_UPLOADFILE,
    payload: {
      status,
    },
  };
};

export const materiDelete = (materi) => {
  return {
    type: MATERI_DELETE,
    payload: {
      materi,
    },
  };
};

export const materiResetSearch = () => {
  return {
    type: MATERI_RESET_SEARCH,
    payload: {},
  };
};
