import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getMateri, downloadFile } from './redux/reduxApi';
import ViewForm from './ViewForm.jsx';
import { openModal } from '../../../app/modals/redux/modalActions';

const mapState = (state, ownProps) => {
  const id = ownProps.match.params.id;
  let scope = {};
  if (state.auth && state.auth.isAuth) {
    scope = state.auth.authorities;
  }
  let materi = {};
  let attachments = [];
  if (state.materis && state.materis.items && state.materis.items[0]) {
    materi = state.materis.items.filter((i) => i.id + '' === id + '')[0];
    if (materi && materi.id) {
      attachments = materi.attachments ? JSON.parse(materi.attachments) : [];
    }
  }
  return {
    auth: state.auth,
    loading: state.async.loading,
    scope: scope,
    id: id,
    materi: materi,
    attachments: attachments,
  };
};

const actions = {
  getMateri,
  downloadFile,
  openModal,
};

function View(props) {
  const {
    auth,
    history,
    scope,
    materi,
    attachments,
    getMateri,
    downloadFile,
    id,
    openModal,
  } = props;
  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    const setValues = { ...materi };
    getMateri(auth, history, id);
    setInitialValues(setValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFormSubmit = (values) => {};

  return (
    <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
      <div className='p-1'>
        <div className='columns is-variable'>
          <div className='column is-fullwidth'>
            <div className='card'>
              <header className='card-header has-background-white has-text-info'>
                <div
                  className='custom-card-flex is-size-6'
                  style={{ width: '100%' }}
                >
                  <p>
                    <i className='mdi mdi-bookshelf is-size-5 icon' />{' '}
                    Pembelajaran
                    <i className='mdi mdi-menu-right is-size-6 icon' />
                    Materi
                  </p>
                </div>
              </header>
              <div className='card-content pl-4 pr-4'>
                <ViewForm
                  auth={auth}
                  history={history}
                  scope={scope}
                  id={id}
                  initialValues={initialValues}
                  attachments={attachments}
                  onFormSubmit={onFormSubmit}
                  openModal={openModal}
                  downloadFile={downloadFile}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(connect(mapState, actions)(View));
