import {
  SEMS_GET,
  SEM_ADD,
  SEM_EDIT,
  SEM_GET,
  SEM_DELETE,
} from './reduxConstant';
import { createReducer } from '../../../../app/common/utilities/reducerUtils';

const initialState = {
  total: 0,
  search: {
    currentPage: '',
    searchText: '',
    itemPerPage: '',
  },
  items: [],
};

const semsGet = (state, payload) => {
  const sems = payload.sems;
  return {
    total: sems.total,
    items: [...sems.items],
    search: { ...sems.search },
  };
};

const semAdd = (state, payload) => {
  return {
    total: parseInt(state.total) + 1,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.sem.id + ''),
      payload.sem,
    ],
    search: { ...state.search },
  };
};

const semEdit = (state, payload) => {
  return {
    total: parseInt(state.total),
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.sem.id + ''),
      payload.sem,
    ],
    search: { ...state.search },
  };
};

const semGet = (state, payload) => {
  return {
    total: parseInt(state.total),
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.sem.id + ''),
      payload.sem,
    ],
    search: { ...state.search },
  };
};

const semDelete = (state, payload) => {
  return {
    total: parseInt(state.total) - 1,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.sem.id + ''),
    ],
    search: { ...state.search },
  };
};

export default createReducer(initialState, {
  [SEMS_GET]: semsGet,
  [SEM_ADD]: semAdd,
  [SEM_EDIT]: semEdit,
  [SEM_GET]: semGet,
  [SEM_DELETE]: semDelete,
});
