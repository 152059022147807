import {
  SLIDESHOWS_GET,
  SLIDESHOW_ADD,
  SLIDESHOW_EDIT,
  SLIDESHOW_GET,
  SLIDESHOW_DELETE,
  SLIDESHOW_UPLOADFILE,
} from './reduxConstant';
import { createReducer } from '../../../../app/common/utilities/reducerUtils';

const initialState = {
  total: 0,
  search: {
    currentPage: '',
    searchText: '',
    itemPerPage: '',
  },
  items: [],
  uploadFile: {},
};

const slideshowsGet = (state, payload) => {
  const slideshows = payload.slideshows;
  return {
    total: slideshows.total,
    items: [...slideshows.items],
    search: { ...slideshows.search },
  };
};

const slideshowAdd = (state, payload) => {
  return {
    total: parseInt(state.total) + 1,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.slideshow.id + ''),
      payload.slideshow,
    ],
    search: { ...state.search },
  };
};

const slideshowEdit = (state, payload) => {
  return {
    total: parseInt(state.total),
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.slideshow.id + ''),
      payload.slideshow,
    ],
    search: { ...state.search },
  };
};

const slideshowGet = (state, payload) => {
  return {
    total: parseInt(state.total),
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.slideshow.id + ''),
      payload.slideshow,
    ],
    search: { ...state.search },
  };
};

const slideshowUploadFile = (state, payload) => {
  let uploadFile = payload.uploadFile;
  return {
    ...state,
    uploadFile: {...uploadFile},
  };
};

const slideshowDelete = (state, payload) => {
  return {
    total: parseInt(state.total) - 1,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.slideshow.id + ''),
    ],
    search: { ...state.search },
  };
};

export default createReducer(initialState, {
  [SLIDESHOWS_GET]: slideshowsGet,
  [SLIDESHOW_ADD]: slideshowAdd,
  [SLIDESHOW_EDIT]: slideshowEdit,
  [SLIDESHOW_GET]: slideshowGet,
  [SLIDESHOW_UPLOADFILE]: slideshowUploadFile,
  [SLIDESHOW_DELETE]: slideshowDelete,
});
