import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import TextInput from '../../app/common/form/TextInput';
import { reduxForm, Field } from 'redux-form';
import MultiSelect from '../../app/common/form/MultiSelect';

function ViewForm(props) {
  const {
    auth,
    id,
    history,
    handleSubmit,
    initialValues,
    onFormSubmit,
    openModal,
    scope,
    roles,
  } = props;
  const [hover, setHover] = useState(false);
  let dataRoles = [];
  roles.forEach((i) => {
    dataRoles.push({ value: i.id, label: i.title });
  });

  const handleClikUserDelete = () => {
    openModal('UserDelete', {
      data: {
        auth,
        history,
        item: initialValues,
      },
    });
  };

  const handleClickStatus = () => {
    openModal('UserStatus', {
      data: {
        auth,
        history,
        item: initialValues,
      },
    });
  };

  const toggleHover = () => {
    setHover(!hover);
  };

  let currentData =
    initialValues?.roles &&
    initialValues?.roles[0] &&
    initialValues?.roles[0].value === 'SA'
      ? true
      : false;

  return (
    <>
      <div className='level'>
        <div className='level-left'>
          <div className='level-item'>
            <nav className='breadcrumb is-size-6' aria-label='breadcrumbs'>
              <ul>
                <li className='is-active has-text-weight-bold'>
                  <Link to={'/setting/user/' + id}>Detail</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className='level-right'>
          <div className='level-item'>
            <div className='buttons'>
              {!currentData && (
                <>
                  {scope.subm['user_status'] && (
                    <>
                      {hover && (
                        <button
                          onMouseEnter={toggleHover}
                          onMouseLeave={toggleHover}
                          className={`button is-small is-rounded is-${
                            initialValues?.status ? 'danger' : 'info'
                          } is-outlined`}
                          onClick={() => handleClickStatus()}
                        >
                          <i
                            className={`is-size-6 mdi mdi-${
                              initialValues?.status ? 'account-remove' : 'account-check'
                            } icon`}
                          />
                        </button>
                      )}
                      {!hover && (
                        <button
                          onMouseEnter={toggleHover}
                          onMouseLeave={toggleHover}
                          className={`button is-small is-rounded is-${
                            initialValues?.status ? 'info' : 'danger'
                          } is-outlined`}
                          onClick={() => handleClickStatus()}
                        >
                          <i
                            className={`is-size-6 mdi mdi-${
                              initialValues && initialValues?.status ? 'account-check' : 'account-remove'
                            } icon`}
                          />
                        </button>
                      )}
                    </>
                  )}
                  {scope.subm['user_edit'] && (
                    <Link
                      to={`/setting/user/edit/${id}`}
                      className='button is-small is-rounded is-info is-outlined'
                    >
                      <i className='is-size-6 mdi mdi-pencil icon' />
                    </Link>
                  )}
                  {scope.subm['user_delete'] && (
                    <button
                      className='button is-small is-rounded is-danger is-outlined'
                      onClick={() => handleClikUserDelete()}
                    >
                      <i className='is-size-6 mdi mdi-delete icon' />
                    </button>
                  )}
                </>
              )}
              <Link
                type='button'
                to={'/setting/user'}
                className='button custom-grey is-small is-rounded is-outlined'
              >
                <i className='is-size-6 mdi mdi-arrow-left icon' />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='columns'>
        <div className='column is-third-quarter'>
          <form onSubmit={handleSubmit(onFormSubmit)} autoComplete='off'>
            <Field
              name='username'
              type='text'
              component={TextInput}
              defaultValue={initialValues?.username ?? ''}
              placeholder='Username'
              label='Username'
              readOnly={true}
            />
            <Field
              name='roles'
              component={MultiSelect}
              placeholder={
                initialValues?.roles && initialValues?.roles[0]
                  ? 'Pilih Role'
                  : 'Tidak ada role'
              }
              label='Role'
              options={dataRoles}
              isDisabled={true}
            />
          </form>
          <div className='field mt-3'>
            <label className='label'>Status Aktif</label>
          </div>
          <div className='tags has-addons'>
            <span
              className={
                initialValues?.status
                  ? 'tag'
                  : 'tag has-background-grey has-text-white'
              }
            >
              No
            </span>
            <span className={initialValues?.status ? 'tag is-link' : 'tag'}>
              Yes
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default reduxForm({
  form: 'userView',
  enableReinitialize: true,
})(ViewForm);
