import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getKelas, editKelas } from './redux/reduxApi';
import EditForm from './EditForm';

const mapState = (state, ownProps) => {
  const id = ownProps.match.params.id;
  let scope = {};
  if (state.auth && state.auth.isAuth) {
    scope = state.auth.authorities;
  }
  let kelass = {};
  if (state?.kelass?.items && state.kelass.items[0]) {
    kelass = state.kelass.items.filter((i) => i.id + '' === id + '')[0];
  }
  let jenjangs = [];
  if (state?.kelass?.jenjangs && state?.kelass?.jenjangs[0]) {
    jenjangs = state.kelass.jenjangs;
  }
  let tingkats = [];
  if (state?.kelass?.tingkats && state?.kelass?.tingkats[0]) {
    tingkats = state.kelass.tingkats;
  }
  return {
    loading: state.async.loading,
    auth: state.auth,
    scope: scope,
    id: id,
    jenjangs: jenjangs,
    tingkats: tingkats,
    kelass: kelass,
  };
};

const actions = {
  getKelas,
  editKelas,
};

function Edit(props) {
  const { auth, history, loading, id, jenjangs, tingkats, kelass, getKelas, editKelas } = props;
  const [initialValues, setInitialValues] = useState({
    code: '',
    title: '',
    jenjangId: '',
    tingkatId: '',
    description: '',
    jenjangSelect: [],
    tingkatSelect: [],
  });

  useEffect(() => {
    let jenjangSelect = [];
    jenjangs?.forEach((j, i) => {
      jenjangSelect = [
        ...jenjangSelect,
        { key: i, value: j.id, text: j.title },
      ];
    });
    let tingkatSelect = [];
    tingkats.forEach((j, i) => {
      tingkatSelect = [
        ...tingkatSelect,
        { key: i, value: j.id, text: j.title },
      ];
    });
    setInitialValues({
      initialValues,
      ...kelass,
      jenjangSelect: jenjangSelect,
      tingkatSelect: tingkatSelect,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kelass]);

  useEffect(() => {
    getKelas(auth, history, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
      <div className='p-1'>
        <div className='columns is-variable'>
          <div className='column is-fullwidth'>
            <div className='card'>
              <header className='card-header has-background-white has-text-info'>
                <div
                  className='custom-card-flex is-size-6'
                  style={{ width: '100%' }}
                >
                  <p>
                    <i className='mdi mdi-school is-size-5 icon' /> Akademik
                    <i className='mdi mdi-menu-right is-size-6 icon' />
                    Kelas
                  </p>
                </div>
              </header>
              <div className='card-content pl-4 pr-4'>
                <EditForm
                  initialValues={initialValues}
                  loading={loading}
                  auth={auth}
                  id={id}
                  history={history}
                  editKelas={editKelas}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(connect(mapState, actions)(Edit));
