import {
  SEARCH_JENJANG_CHANGE,
  SEARCH_TINGKAT_CHANGE,
  SEARCH_KELAS_CHANGE,
  SEARCH_PELAJARAN_CHANGE,
  SEARCH_YEARS_CHANGE,
  SEARCH_SEMS_CHANGE,
  SEARCHFORM_DELETE,
} from './reduxConstant';

export const searchJenjangChange = (jenjangs) => {
  return {
    type: SEARCH_JENJANG_CHANGE,
    payload: {
      jenjangs,
    },
  };
};

export const searchTingkatChange = (tingkats) => {
  return {
    type: SEARCH_TINGKAT_CHANGE,
    payload: {
      tingkats,
    },
  };
};

export const searchKelasChange = (kelass) => {
  return {
    type: SEARCH_KELAS_CHANGE,
    payload: {
      kelass,
    },
  };
};

export const searchPelajaranChange = (pelajarans) => {
  return {
    type: SEARCH_PELAJARAN_CHANGE,
    payload: {
      pelajarans,
    },
  };
};

export const searchYearsChange = (years) => {
  return {
    type: SEARCH_YEARS_CHANGE,
    payload: {
      years,
    },
  };
};

export const searchSemsChange = (sems) => {
  return {
    type: SEARCH_SEMS_CHANGE,
    payload: {
      sems,
    },
  };
};

export const searchFormDelete = () => {
  return {
    type: SEARCHFORM_DELETE,
    payload: {},
  };
};
