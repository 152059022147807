import React, { useEffect } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  getTugas,
  getStudentAnswer,
  downloadFile,
  submitScores,
} from './redux/reduxApi';
import ViewScoreForm from './ViewScoreForm';
import { openModal } from '../../../app/modals/redux/modalActions';
import { sortArrayObject } from '../../../app/common/helpers/objectHelpers';

const mapState = (state, ownProps) => {
  const id = ownProps.match.params.id;
  let scope = {};
  if (state.auth && state.auth.isAuth) {
    scope = state.auth.authorities;
  }
  let tugas = {};
  let studentAnswer = [];
  if (state.tugass && state.tugass.items && state.tugass.items[0]) {
    tugas = state.tugass.items.filter((i) => i.id + '' === id + '')[0];
  }
  if (
    state.tugass &&
    state.tugass.studentAnswer &&
    state.tugass.studentAnswer[0]
  ) {
    studentAnswer = [...state.tugass.studentAnswer];
  }
  return {
    id: id,
    loading: state.async.loading,
    auth: state.auth,
    scope: scope,
    tugas: tugas,
    studentAnswer: studentAnswer,
  };
};

const actions = {
  openModal,
  getTugas,
  getStudentAnswer,
  downloadFile,
  submitScores,
};

function ViewScore(props) {
  const {
    auth,
    history,
    loading,
    scope,
    openModal,
    id,
    tugas,
    studentAnswer,
    getTugas,
    getStudentAnswer,
    downloadFile,
    submitScores,
  } = props;
  let location = useLocation();
  let data = location.state;
  let isPaperWork = data && data.isPaperWork;

  useEffect(() => {
    getTugas(auth, history, id);
    getStudentAnswer(auth, history, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFormSubmit = async (values) => {
    console.log('submit form');
  };

  let questions = tugas && tugas.questions && JSON.parse(tugas.questions);
  let questionsLength = questions && questions.length;
  const keyAnswers = tugas && tugas.answers && JSON.parse(tugas.answers);

  let tags = tugas && tugas.tags && JSON.parse(tugas.tags);
  let scores =
    tugas && tugas.scores && JSON.parse(tugas.scores)
      ? JSON.parse(tugas.scores)
      : [];

  return (
    <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
      <div className='p-1'>
        <div className='columns is-variable'>
          <div className='column is-fullwidth'>
            <div className='card'>
              <header className='card-header has-background-white has-text-info'>
                <div
                  className='custom-card-flex is-size-6'
                  style={{ width: '100%' }}
                >
                  <p>
                    <i className='mdi mdi-bookshelf is-size-5 icon' />{' '}
                    Pembelajaran
                    <i className='mdi mdi-menu-right is-size-6 icon' />
                    Tugas
                  </p>
                </div>
              </header>
              <div className='card-content pl-4 pr-4'>
                <ViewScoreForm
                  auth={auth}
                  history={history}
                  loading={loading}
                  scope={scope}
                  openModal={openModal}
                  id={id}
                  initialValues={tugas}
                  questions={questions}
                  questionsLength={questionsLength}
                  isPaperWork={isPaperWork}
                  keyAnswers={keyAnswers}
                  studentAnswer={sortArrayObject(studentAnswer, 'siswa', 'asc')}
                  scores={scores}
                  tags={tags}
                  downloadFile={downloadFile}
                  submitScores={submitScores}
                  onFormSubmit={onFormSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(connect(mapState, actions)(ViewScore));
