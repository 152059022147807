import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { importPelajaran } from './redux/reduxApi';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import ImportFileInput from '../../../app/common/layout/ImportFileInput';

const mapState = (state) => ({
  auth: state.auth,
  loading: state.async.loading,
  pelajarans: state.pelajarans,
});

const actions = {
  importPelajaran,
};

class Import extends Component {
  state = {
    inputKey: Date.now(),
    tl: 'Pelajaran',
    pelajarans: [],
    importData: [],
    jenjangs: [],
    importResult: [],
  };

  componentDidMount = () => {
    const { pelajarans } = this.props;
    const allPel = pelajarans.items || [];
    const jenjangs = pelajarans.jenjangs || [];
    this.setState({
      ...this.state,
      pelajarans: allPel,
      jenjangs: jenjangs,
    });
  };

  handleExcelUpload = (e) => {
    let file = e.target.files[0];

    const reader = new FileReader();
    if (file) {
      reader.readAsArrayBuffer(file);

      reader.onload = (e) => {
        let sheetData = new Uint8Array(reader.result);
        let wb = XLSX.read(sheetData, { type: 'array' });

        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        // Convert Array
        const data = XLSX.utils.sheet_to_json(ws, { header: 2 });

        this.setState({ ...this.state, importData: data });
      };
    }
  };

  onImport = async () => {
    const { importPelajaran, auth, history } = this.props;
    const { pelajarans, importData, jenjangs } = this.state;
    const importResult = await importPelajaran(
      auth,
      history,
      pelajarans,
      importData,
      jenjangs
    );
    this.setState({
      ...this.state,
      importResult,
    });
  };

  onCancel = () => {
    this.setState({
      data: [],
      inputKey: Date.now(),
      importData: [],
      importResult: [],
    });
  };

  onGoBack = () => {
    const { history } = this.props;
    this.onCancel();
    history.push('/akademik/pelajaran');
  };

  render() {
    const { loading, progress } = this.props;
    const { importData, importResult, inputKey, tl } = this.state;
    const successData = importResult;
    return (
      <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
        <div className='p-1'>
          <div className='columns is-variable'>
            <div className='column is-fullwidth'>
              <div className='card'>
                <header className='card-header has-background-white has-text-info'>
                  <div
                    className='custom-card-flex is-size-6'
                    style={{ width: '100%' }}
                  >
                    <p>
                      <i className='mdi mdi-school is-size-5 icon' /> Akademik
                      <i className='mdi mdi-menu-right is-size-6 icon' />
                      Pelajaran
                    </p>
                  </div>
                </header>
                <div className='card-content pl-4 pr-4'>
                  <form autoComplete='off' style={{ marginTop: 1 }}>
                    <div className='level'>
                      <div className='level-left'>
                        <div className='level-item'>
                          <nav
                            className='breadcrumb is-size-6'
                            aria-label='breadcrumbs'
                          >
                            <ul>
                              <li className='is-active has-text-weight-bold'>
                                <Link to={'/akademik/pelajaran/import'}>
                                  Import
                                </Link>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </div>

                      <div className='level-right'>
                        <div className='level-item'>
                          <div className='buttons'>
                            <>
                              <button
                                disabled={loading}
                                onClick={this.props.handleSubmit(this.onImport)}
                                className={`button is-small is-info is-rounded is-outlined ${
                                  loading ? 'is-loading' : ''
                                }`}
                                style={{ marginRight: 10 }}
                              >
                                <i className='is-size-6 mdi mdi-content-save icon' />
                              </button>
                              <button
                                disabled={loading}
                                onClick={this.onCancel}
                                className='button custom-grey is-small is-rounded is-outlined'
                              >
                                <i className='is-size-6 mdi mdi-reload icon' />
                              </button>
                            </>
                            <button
                              disabled={loading}
                              onClick={this.onGoBack}
                              className='button custom-grey is-small is-rounded is-outlined'
                            >
                              <i className='is-size-6 mdi mdi-arrow-left icon' />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {!loading && importData && !importData[0] && (
                      <div className='columns'>
                        <div className='column is-4 is-offset-4'>
                          <div className='field'>
                            <ImportFileInput
                              handleExcelUpload={this.handleExcelUpload}
                              inputKey={inputKey}
                              tl={tl}
                              link={'/templates/Import_Pelajaran_Template.xlsx'}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {successData && (
                      <div className='columns'>
                        <div className='column is-12'>
                          <div className='table-container'>
                            {successData && successData.length > 0 && (
                              <table className='table is-fullwidth is-hoverable'>
                                <thead className='has-background-info'>
                                  <tr>
                                    <th className='has-text-white'>
                                      Sukses (
                                      {successData && successData.length})
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      {successData &&
                                        successData[0] &&
                                        successData.map((pel, i) => (
                                          <SuccessItem
                                            pelajaran={pel}
                                            key={i}
                                          />
                                        ))}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    {loading && (
                      <div>
                        <h5>Memproses...</h5>
                        <progress
                          className='progress is-small is-info'
                          max='100'
                        >
                          {progress}%
                        </progress>
                      </div>
                    )}
                    {!loading && (
                      <div className='columns'>
                        <div className='column is-12'>
                          <div className='table-container'>
                            <table className='table is-fullwidth is-hoverable'>
                              {importData && importData[0] && (
                                <thead className='has-background-info'>
                                  <tr>
                                    <th colSpan='4' className='has-text-white'>
                                      Preview ({importData && importData.length}{' '}
                                      {tl})
                                    </th>
                                  </tr>
                                </thead>
                              )}
                              <thead>
                                {importData && importData[0] && (
                                  <tr>
                                    {importData[0] &&
                                      Object.keys(importData[0]).map(
                                        (col, index) => (
                                          <th key={index}>{col}</th>
                                        )
                                      )}
                                  </tr>
                                )}
                              </thead>
                              <tbody>
                                {importData && importData[0] && (
                                  <Fragment>
                                    {importData.map((item, index) => (
                                      <Item
                                        item={item}
                                        index={index}
                                        key={index + 1}
                                      />
                                    ))}
                                  </Fragment>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  mapState,
  actions
)(reduxForm({ form: 'pelajaranFormExport' })(Import));

export class Item extends Component {
  render() {
    const { item, index } = this.props;
    return (
      <tr>
        <td>{index + 1}</td>
        <td>{item.Kode}</td>
        <td>{item.Nama}</td>
        <td>{item.Jenjang}</td>
      </tr>
    );
  }
}

export class SuccessItem extends Component {
  render() {
    const { pelajaran } = this.props;
    return (
      <span
        className='tag is-info is-light'
        style={{
          marginRight: 5,
          marginBottom: 5,
        }}
      >
        {`${pelajaran.code}: ${pelajaran.title}`}
      </span>
    );
  }
}
