import React from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ViewSummaryAnswerForm from './ViewSummaryAnswerForm';
import { closeModal } from '../../../app/modals/redux/modalActions';
import { submitScores } from './redux/reduxApi';

const mapState = (state, ownProps) => {
  let scope = {};
  if (state.auth && state.auth.isAuth) {
    scope = state.auth.authorities;
  }
  return {
    loading: state.async.loading,
    scope: scope,
  };
};

const actions = {
  closeModal,
  submitScores,
};

function ViewSummaryAnswer(props) {
  const { loading, scope, data, submitScores, closeModal } = props;
  const history = useHistory();
  const auth = data && data.auth;
  const id = data && data.id;
  const studentDetail = data && data.item;
  const answers =
    data && data.item && data.item.answers && JSON.parse(data.item.answers);
  const questions = data && data.questions;
  const keyAnswers = data && data.keyAnswers;
  const stateScores = data && data.stateScores;
  const scores = data && data.scores;
  let selectedScore =
    scores &&
    scores.find((i) => i.studentTugasId + '' === studentDetail.id + '');
  let finalScore;
  let detailScore;
  if (!selectedScore) {
    let selectedStateScore = stateScores.find(
      (i) => i.studentTugasId + '' === studentDetail.id + ''
    );
    finalScore = selectedStateScore.finalScore;
    detailScore = selectedStateScore.detailScore;
  } else {
    finalScore = selectedScore.finalScore;
    detailScore = selectedScore.detailScore;
  }

  const onFormSubmit = async (values) => {
    console.log('submit form');
  };

  return (
    <div className='modal is-active'>
      <div className='modal-background' onClick={closeModal}></div>
      <div className='modal-card'>
        <header className='modal-card-head has-background-info'>
          <div className='modal-card-title has-text-white has-text-weight-normal'>
            <i className='is-size-4 mdi mdi-playlist-check icon' /> Summary Jawaban
          </div>
          <button
            onClick={closeModal}
            className='delete'
            aria-label='close'
          ></button>
        </header>
        <section className='modal-card-body is-size-10'>
          <ViewSummaryAnswerForm
            auth={auth}
            history={history}
            scope={scope}
            loading={loading}
            closeModal={closeModal}
            id={id}
            studentDetail={studentDetail}
            initialValues={{}}
            questions={questions}
            keyAnswers={keyAnswers}
            answers={answers}
            scores={scores}
            finalScore={finalScore}
            detailScore={detailScore}
            submitScores={submitScores}
            onFormSubmit={onFormSubmit}
          />
        </section>
        <footer className='modal-card-foot'>
          <button
            className='button custom-grey is-small is-rounded is-outlined'
            onClick={closeModal}
          >
            <i className='is-size-6 mdi mdi-arrow-left icon' />
          </button>
        </footer>
      </div>
    </div>
  );
}

export default withRouter(connect(mapState, actions)(ViewSummaryAnswer));
