import {
  LATIHANS_GET,
  LATIHAN_ADD,
  LATIHAN_GET,
  LATIHAN_EDIT,
  LATIHAN_UPLOADFILE,
  LATIHAN_DELETE,
  LATIHAN_RESET_SEARCH,
} from './reduxConstant';
import { createReducer } from '../../../../app/common/utilities/reducerUtils';

const initialState = {
  total: 0,
  search: {
    year: '',
    sem: '',
    jenjang: '',
    tingkat: '',
    kelas: '',
    pelajaran: '',
    published: 'all',
  },
  items: [],
  uploadFiles: [],
};

const latihansGet = (state, payload) => {
  const latihans = payload.latihans;
  return {
    ...state,
    total: latihans.total,
    items: [...latihans.items],
    search: { ...latihans.search },
  };
};

const latihanAdd = (state, payload) => {
  return {
    ...state,
    total: parseInt(state.total) + 1,
    items: [...state.items, payload.latihan],
  };
};

const latihanGet = (state, payload) => {
  return {
    ...state,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.latihan.id + ''),
      payload.latihan,
    ],
  };
};

const latihanEdit = (state, payload) => {
  return {
    ...state,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.latihan.id + ''),
      payload.latihan,
    ],
  };
};

const latihanUploadFile = (state, payload) => {
  let uploadFile = payload.uploadFile;
  let uploadFiles = (state.uploadFiles && state.uploadFiles) ? state.uploadFiles : [];
  let filteredUploadFiles = uploadFiles.filter(
    (i) => i.key !== uploadFile.key
  );
  let newUploadFiles = [...filteredUploadFiles, uploadFile];
  return {
    ...state,
    uploadFiles: newUploadFiles,
  };
};

const latihanDelete = (state, payload) => {
  return {
    ...state,
    total: parseInt(state.total) - 1,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.latihan.id + ''),
    ],
  };
};

const latihanResetSearch = (state, payload) => {
  return {
    ...state,
    search: {
      year: '',
      sem: '',
      jenjang: '',
      tingkat: '',
      kelas: '',
      pelajaran: '',
      published: 'all',
    },
  };
};

export default createReducer(initialState, {
  [LATIHANS_GET]: latihansGet,
  [LATIHAN_ADD]: latihanAdd,
  [LATIHAN_GET]: latihanGet,
  [LATIHAN_EDIT]: latihanEdit,
  [LATIHAN_UPLOADFILE]: latihanUploadFile,
  [LATIHAN_DELETE]: latihanDelete,
  [LATIHAN_RESET_SEARCH]: latihanResetSearch,
});
