import React from 'react';
import * as XLSX from 'xlsx';
import * as XlsxPopulate from 'xlsx-populate/browser/xlsx-populate';

const ExcelExportHelper = ({ data }) => {
  const createDownLoadData = () => {
    handleExport().then((url) => {
      const downloadAnchorNode = document.createElement('a');
      downloadAnchorNode.setAttribute('href', url);
      downloadAnchorNode.setAttribute(
        'download',
        data.filename.split(' ').join('_') + '.xlsx'
      );
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    });
  };

  const workbook2blob = (workbook) => {
    const wopts = {
      bookType: 'xlsx',
      bookSST: false,
      type: 'binary',
    };

    const wbout = XLSX.write(workbook, wopts);

    // The application/octet-stream MIME type is used for unknown binary files.
    // It preserves the file contents, but requires the receiver to determine file type,
    // for example, from the filename extension.
    const blob = new Blob([s2ab(wbout)], {
      type: 'application/octet-stream',
    });

    return blob;
  };

  const s2ab = (s) => {
    // The ArrayBuffer() constructor is used to create ArrayBuffer objects.
    // create an ArrayBuffer with a size in bytes
    const buf = new ArrayBuffer(s.length);

    //create a 8 bit integer array
    const view = new Uint8Array(buf);
    
    //charCodeAt The charCodeAt() method returns an integer between 0 and 65535 representing the UTF-16 code
    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i);
    }

    return buf;
  };

  const handleExport = () => {
    const title = [{ A: data.filename }, {}];

    let table1 = [
      {
        A: 'No',
        B: 'NIS',
        C: 'Siswa',
        D: 'Nilai',
      },
    ];

    data.details.forEach((row) => {
      table1.push({
        A: row.no,
        B: row.nis,
        C: row.siswa,
        D: data.scores
          .find((i) => i.nis + '' === row.nis + '')
          .finalScore.toLocaleString(),
      });
    });

    table1 = [''].concat(table1);

    const finalData = [...title, ...table1];

    //create a new workbook
    const wb = XLSX.utils.book_new();

    const sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });

    XLSX.utils.book_append_sheet(wb, sheet, 'Nilai_Tugas');

    // binary large object
    // Since blobs can store binary data, they can be used to store images or other multimedia files.

    const workbookBlob = workbook2blob(wb);

    const totalRecords = data.details.length;

    const dataInfo = {
      titleCell: 'A2',
      titleRange: 'A1:D2',
      theader: `A4:D4`,
      tbodyRange: `A5:D${4 + totalRecords}`,
      siswa: `C5:C${5 + totalRecords}`,
      nilai: `D5:D${5 + totalRecords}`,
    };

    return addStyle(workbookBlob, dataInfo);
  };

  const addStyle = (workbookBlob, dataInfo) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
      workbook.sheets().forEach((sheet) => {
        sheet.usedRange().style({
          fontFamily: 'Arial',
        });

        sheet.column('A').width(8);
        sheet.column('B').width(16);
        sheet.column('C').width(38);
        sheet.column('D').width(10);

        sheet.range(dataInfo.titleRange).merged(true).style({
          bold: true,
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
        });

        sheet.range(dataInfo.theader).style({
          fill: '90C7EF',
          bold: true,
          horizontalAlignment: 'center',
          border: {
            right: {
              style: 'thin',
              color: '000000',
            },
            left: {
              style: 'thin',
              color: '000000',
            },
            top: {
              style: 'thin',
              color: '000000',
            },
            bottom: {
              style: 'thin',
              color: '000000',
            },
          },
        });

        if (dataInfo.tbodyRange) {
          sheet.range(dataInfo.tbodyRange).style({
            horizontalAlignment: 'center',
            border: {
              right: {
                style: 'thin',
                color: '000000',
              },
              left: {
                style: 'thin',
                color: '000000',
              },
              top: {
                style: 'thin',
                color: '000000',
              },
              bottom: {
                style: 'thin',
                color: '000000',
              },
            },
          });
        }

        if (dataInfo.siswa) {
          sheet.range(dataInfo.siswa).style({
            horizontalAlignment: 'left',
          });
        }

        if (dataInfo.nilai) {
          sheet.range(dataInfo.nilai).style({
            horizontalAlignment: 'right',
          });
        }
      });

      return workbook
        .outputAsync()
        .then((workbookBlob) => URL.createObjectURL(workbookBlob));
    });
  };

  return (
    <button
      onClick={() => {
        createDownLoadData();
      }}
      className='button is-small is-rounded is-primary is-outlined ml-1'
    >
      <i className='fas fa-file-excel icon' />
    </button>
  );
};

export default ExcelExportHelper;
