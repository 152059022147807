import React from 'react';
import { Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';

export default function IndexListItems(props) {
  const { item, currentPage, itemPerPage, index, loading, scope, onDelete } =
    props;
  if (loading)
    return (
      <tr>
        <td>Loading...</td>
      </tr>
    );

  const page = (currentPage - 1) * itemPerPage;

  const yesStatus = <span className='tag is-info'>Yes</span>;

  const noStatus = (
    <span className='tag has-background-grey has-text-white'>No</span>
  );

  return (
    <tr>
      <td className='has-text-centered'>{page + index + 1}</td>
      <td>{item.code}</td>
      <td>{item.title}</td>
      <td className='has-text-centered'>{item.status ? yesStatus : noStatus}</td>
      <td className='has-text-centered'>
        {item.createdAt &&
          format(parseISO(item.createdAt), 'd LLLL yyyy - HH:mm:ss')}
      </td>
      <td>
        <div className='content-wrap flex justify-center'>
          <div>
            {scope && scope.subm && scope.subm['jenjang_view'] && (
              <Link
                to={`/akademik/jenjang/${item.id}`}
                className='button is-small is-rounded is-info is-outlined mr-2 mb-1'
              >
                <i className='is-size-6 mdi mdi-eye icon' />
              </Link>
            )}
            {scope && scope.subm && scope.subm['jenjang_edit'] && (
              <Link
                to={`/akademik/jenjang/edit/${item.id}`}
                className='button is-small is-rounded is-info is-outlined mr-2 mb-1'
              >
                <i className='is-size-6 mdi mdi-pencil icon' />
              </Link>
            )}
            {scope && scope.subm && scope.subm['jenjang_delete'] && (
              <button
                className='button is-small is-rounded is-danger is-outlined mb-1'
                onClick={() => onDelete(item)}
              >
                <i className='is-size-6 mdi mdi-delete icon' />
              </button>
            )}
          </div>
        </div>
      </td>
    </tr>
  );
}
