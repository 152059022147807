import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getTes, publishTes } from './redux/reduxApi';
import PublishForm from './PublishForm';
import { openModal } from '../../../app/modals/redux/modalActions';
import {
  changeSearchJenjang,
  changeSearchTingkat,
  changeSearchKelas,
  changeSearchPelajaran,
  changeSearchYears,
  changeSearchSems,
} from '../../../app/common/reduxs/search/reduxApi';

const mapState = (state, ownProps) => {
  const id = ownProps.match.params.id;
  let scope = {};
  if (state.auth && state.auth.isAuth) {
    scope = state.auth.authorities;
  }
  let tes = {};
  if (state.allTes && state.allTes.items && state.allTes.items[0]) {
    tes = state.allTes.items.filter((i) => i.id + '' === id + '')[0];
  }
  return {
    id: id,
    loading: state.async.loading,
    auth: state.auth,
    scope: scope,
    tes: tes,
    searchForm: state.searchForm,
  };
};

const actions = {
  getTes,
  publishTes,
  openModal,
  changeSearchJenjang,
  changeSearchTingkat,
  changeSearchKelas,
  changeSearchPelajaran,
  changeSearchYears,
  changeSearchSems,
};

class Publish extends Component {
  componentDidMount = () => {
    const {
      auth,
      history,
      id,
      changeSearchJenjang,
      changeSearchTingkat,
      changeSearchKelas,
      changeSearchPelajaran,
      changeSearchYears,
      changeSearchSems,
    } = this.props;
    getTes(auth, history, id);
    changeSearchJenjang(auth, history);
    changeSearchTingkat(auth, history);
    changeSearchKelas(auth, history);
    changeSearchPelajaran(auth, history);
    changeSearchYears(auth, history);
    changeSearchSems(auth, history);
  };

  onPublish = () => {};
  render() {
    const {
      auth,
      history,
      scope,
      openModal,
      id,
      tes,
      publishTes,
      searchForm,
    } = this.props;
    let initialValues = {
      ...tes,
    };
    const kelas = tes.kelas ? tes.kelas.split(',') : [];
    let arrKelas = [];
    for (let i = 0; i < kelas.length; i++) {
      arrKelas = [...arrKelas, { value: kelas[i], label: kelas[i] }];
    }
    initialValues.kelas = [...arrKelas];
    return (
      <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
        <div className='p-1'>
          <div className='columns is-variable'>
            <div className='column is-fullwidth'>
              <div className='card'>
                <header className='card-header has-background-white has-text-info'>
                  <div
                    className='custom-card-flex is-size-6'
                    style={{ width: '100%' }}
                  >
                    <p>
                      <i className='mdi mdi-bookshelf is-size-5 icon' />{' '}
                      Pembelajaran<i className='mdi mdi-menu-right is-size-6 icon' />Tes
                    </p>
                  </div>
                </header>
                <div className='card-content pl-4 pr-4'>
                  <PublishForm
                    auth={auth}
                    history={history}
                    id={id}
                    scope={scope}
                    openModal={openModal}
                    initialValues={initialValues}
                    jenjang={searchForm?.jenjang}
                    tingkat={searchForm?.tingkat}
                    kelas={searchForm?.kelas}
                    pelajaran={searchForm?.pelajaran}
                    years={searchForm?.years}
                    sems={searchForm?.sems}
                    publishTes={publishTes}
                    onFormSubmit={this.onPublish}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapState, actions)(Publish);
