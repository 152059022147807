import React from 'react';
import { Link } from 'react-router-dom';
import TextInput from '../../../app/common/form/TextInput';
import TextArea from '../../../app/common/form/TextArea';
import { reduxForm, Field } from 'redux-form';
import {
  composeValidators,
  combineValidators,
  isRequired,
  hasLengthGreaterThan,
} from 'revalidate';

const validate = combineValidators({
  code: composeValidators(
    isRequired({ message: 'Kode harus diisi' }),
    hasLengthGreaterThan(1)({
      message: 'Kode harus memiliki paling sedikit 2 karakter',
    })
  )(),
  title: composeValidators(
    isRequired({ message: 'Nama tahun ajaran harus diisi' }),
    hasLengthGreaterThan(3)({
      message: 'Nama tahun ajaran harus memiliki paling sedikit 4 karakter',
    })
  )(),
});

function EditForm(props) {
  const {
    auth,
    history,
    id,
    invalid,
    loading,
    pristine,
    handleSubmit,
    editYear,
  } = props;

  const handleClickSubmit = (value) => {
    editYear(auth, history, value);
  };

  return (
    <>
      <div className='level'>
        <div className='level-left'>
          <div className='level-item'>
            <nav className='breadcrumb is-size-6' aria-label='breadcrumbs'>
              <ul>
                <li className='is-active has-text-weight-bold'>
                  <Link to={'/akademik/year/edit/' + id}>Edit</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className='level-right'>
          <div className='level-item'>
            <div className='buttons'>
              <button
                disabled={invalid || loading || pristine}
                onClick={handleSubmit(handleClickSubmit)}
                className='button is-small is-rounded is-info is-outlined'
              >
                <i className='is-size-6 mdi mdi-content-save icon' />
              </button>
              <button
                onClick={() => history.goBack()}
                type='button'
                to={'/akademik/year'}
                className='button custom-grey is-small is-rounded is-outlined'
              >
                <i className='is-size-6 mdi mdi-arrow-left icon' />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='columns'>
        <div className='column is-third-quarter'>
          <form onSubmit={handleSubmit(handleClickSubmit)} autoComplete='off'>
            <Field
              name='code'
              type='text'
              component={TextInput}
              placeholder='Kode'
              label='Kode'
            />
            <Field
              name='title'
              type='text'
              component={TextInput}
              placeholder='Tahun Ajaran'
              label='Tahun Ajaran'
            />
            <Field
              name='description'
              type='text'
              component={TextArea}
              placeholder='Deskripsi'
              label='Deskripsi'
            />
            <div
              className='field is-grouped'
              style={{ marginTop: 20, marginBottom: 20 }}
            >
              <div className='control'>
                <button
                  type='submit'
                  disabled={invalid || loading}
                  className={
                    loading
                      ? 'button is-info is-small is-rounded is-outlined is-loading'
                      : 'button is-info is-small is-rounded is-outlined'
                  }
                >
                  <i className='is-size-6 mdi mdi-save icon' />
                </button>
              </div>
              <div className='control'>
                <button
                  onClick={() => history.goBack()}
                  className='button custom-grey is-small is-rounded is-outlined'
                >
                  <i className='is-size-6 mdi mdi-arrow-left icon' />
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default reduxForm({
  form: 'yearEdit',
  validate,
  enableReinitialize: true,
})(EditForm);
