import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getTes, editTes, uploadImage } from './redux/reduxApi';
import {
  changeSessionState,
  deleteSessionState,
} from '../../../app/common/reduxs/session/reduxApi';
import EditForm from './EditForm';
import { openModal } from '../../../app/modals/redux/modalActions';

const mapState = (state, ownProps) => {
  const id = ownProps.match.params.id;
  let sesState = {};
  if (state.sessionState && state.sessionState[0]) {
    sesState = state.sessionState.filter((i) => i.id + '' === id + '')[0];
  }
  let tes = {};
  if (state.allTes && state.allTes.items && state.allTes.items[0]) {
    tes = state.allTes.items.filter((i) => i.id + '' === id + '')[0];
  }
  return {
    id: id,
    loading: state.async.loading,
    auth: state.auth,
    sesState: sesState,
    tes: tes,
  };
};

const actions = {
  changeSessionState,
  deleteSessionState,
  getTes,
  editTes,
  uploadImage,
  openModal,
};

function Edit(props) {
  const {
    auth,
    history,
    id,
    sesState,
    tes,
    getTes,
    editTes,
    uploadImage,
    openModal,
  } = props;
  const [state, setState] = useState({});

  useEffect(() => {
    getTes(auth, history, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let questions = tes?.questions ? JSON.parse(tes.questions) : [];
    let answers = tes?.answers ? JSON.parse(tes.answers) : [];
    setState({
      title: tes.title ?? '',
      description: tes?.description,
      questions: questions,
      answers: answers,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFormSubmit = (values) => {
    try {
      const data = { ...values };
      editTes(auth, history, data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
      <div className='p-1'>
        <div className='columns is-variable'>
          <div className='column is-fullwidth'>
            <div className='card'>
                <header className='card-header has-background-white has-text-info'>
                  <div
                    className='custom-card-flex is-size-6'
                    style={{ width: '100%' }}
                  >
                    <p>
                      <i className='mdi mdi-bookshelf is-size-5 icon' />{' '}
                      Pembelajaran<i className='mdi mdi-menu-right is-size-6 icon' />Tes
                    </p>
                  </div>
                </header>
              <div className='card-content pl-4 pr-4'>
                <EditForm
                  auth={auth}
                  history={history}
                  openModal={openModal}
                  id={id}
                  initialValues={state}
                  sesState={sesState}
                  changeSessionState={changeSessionState}
                  deleteSessionState={deleteSessionState}
                  editTes={editTes}
                  uploadImage={uploadImage}
                  onFormSubmit={onFormSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(
  connect(mapState, actions)(Edit))
;
