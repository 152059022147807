import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getRpp, downloadFile } from './redux/reduxApi';
import ViewForm from './ViewForm.jsx';
import { openModal } from '../../../app/modals/redux/modalActions';

const mapState = (state, ownProps) => {
  const id = ownProps.match.params.id;
  let scope = {};
  if (state.auth && state.auth.isAuth) {
    scope = state.auth.authorities;
  }
  let rpp = {};
  let attachments = [];
  if (state.rpps && state.rpps.items && state.rpps.items[0]) {
    rpp = state.rpps.items.filter((i) => i.id + '' === id + '')[0];
    if (rpp && rpp.id) {
      attachments = rpp.attachments ? JSON.parse(rpp.attachments) : [];
    }
  }
  return {
    auth: state.auth,
    loading: state.async.loading,
    scope: scope,
    id: id,
    rpp: rpp,
    attachments: attachments,
  };
};

const actions = {
  getRpp,
  downloadFile,
  openModal,
};

function View(props) {
  const {
    auth,
    history,
    scope,
    rpp,
    attachments,
    getRpp,
    downloadFile,
    id,
    openModal,
  } = props;
  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    const setValues = { ...rpp };
    getRpp(auth, history, id);
    setInitialValues(setValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFormSubmit = (values) => {};

  return (
    <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
      <div className='p-1'>
        <div className='columns is-variable'>
          <div className='column is-fullwidth'>
            <div className='card'>
                <header className='card-header has-background-white has-text-info'>
                  <div
                    className='custom-card-flex is-size-6'
                    style={{ width: '100%' }}
                  >
                    <p>
                      <i className='mdi mdi-notebook-multiple is-size-5 icon' />{' '}
                      RPP
                    </p>
                  </div>
                </header>
              <div className='card-content pl-4 pr-4'>
                <ViewForm
                  auth={auth}
                  history={history}
                  scope={scope}
                  id={id}
                  initialValues={initialValues}
                  attachments={attachments}
                  onFormSubmit={onFormSubmit}
                  openModal={openModal}
                  downloadFile={downloadFile}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(connect(mapState, actions)(View));
