import React from 'react';
import { Link } from 'react-router-dom';
import TextInput from '../../../app/common/form/TextInput';
import TextArea from '../../../app/common/form/TextArea';
import { reduxForm, Field } from 'redux-form';
import {
  composeValidators,
  combineValidators,
  isRequired,
  hasLengthGreaterThan,
} from 'revalidate';

const validate = combineValidators({
  code: composeValidators(
    isRequired({ message: 'Kode harus diisi' }),
    hasLengthGreaterThan(0)({
      message: 'Kode harus memiliki paling sedikit 1 karakter',
    })
  )(),
  title: composeValidators(
    isRequired({ message: 'Nama semester harus diisi' }),
    hasLengthGreaterThan(0)({
      message: 'Nama semester harus memiliki paling sedikit 1 karakter',
    })
  )(),
});

function AddForm(props) {
  const {
    auth,
    history,
    invalid,
    loading,
    pristine,
    handleSubmit,
    addSem,
  } = props;

  const handleClickSubmit = (value) => {
    addSem(auth, history, value);
  };

  return (
    <>
      <div className='level'>
        <div className='level-left'>
          <div className='level-item'>
            <nav className='breadcrumb is-size-6' aria-label='breadcrumbs'>
              <ul>
                <li className='is-active has-text-weight-bold'>
                  <Link to={'/akademik/jenjnag/tambah'}>Tambah</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className='level-right'>
          <div className='level-item'>
            <div className='buttons'>
              <button
                disabled={invalid || loading || pristine}
                onClick={handleSubmit(handleClickSubmit)}
                className='button is-small is-rounded is-info is-outlined'
              >
                <i className='is-size-6 mdi mdi-content-save icon' />
              </button>
              <Link
                type='button'
                to={'/akademik/sem'}
                className='button custom-grey is-small is-rounded is-outlined'
              >
                <i className='is-size-6 mdi mdi-arrow-left icon' />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='columns'>
        <div className='column is-third-quarter'>
          <form onSubmit={handleSubmit(handleClickSubmit)} autoComplete='off'>
            <Field
              name='code'
              type='text'
              component={TextInput}
              placeholder='Kode'
              label='Kode'
            />
            <Field
              name='title'
              type='text'
              component={TextInput}
              placeholder='Nama Semester'
              label='Nama Semester'
            />
            <Field
              name='description'
              type='text'
              component={TextArea}
              placeholder='Deskripsi'
              label='Deskripsi'
            />
          </form>
        </div>
      </div>
    </>
  );
}

export default reduxForm({
  form: 'semAdd',
  validate,
  enableReinitialize: true,
})(AddForm);
