import {
  TINGKATS_GET,
  TINGKAT_JENJANGS_GET,
  TINGKAT_ADD,
  TINGKAT_EDIT,
  TINGKAT_GET,
  TINGKAT_DELETE,
} from './reduxConstant';
import { createReducer } from '../../../../app/common/utilities/reducerUtils';

const initialState = {
  total: 0,
  search: {
    currentPage: '',
    searchText: '',
    itemPerPage: '',
  },
  items: [],
  jenjangs: [],
};

const tingkatsGet = (state, payload) => {
  const tingkats = payload.tingkats;
  return {
    ...state,
    total: tingkats.total,
    items: [...tingkats.items],
    search: { ...tingkats.search },
  };
};

const tingkatJenjangsGet = (state, payload) => {
  const jenjangs = payload.jenjangs;
  return {
    ...state,
    jenjangs: [...jenjangs.jenjangs],
  };
};

const tingkatAdd = (state, payload) => {
  return {
    ...state,
    total: parseInt(state.total) + 1,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.tingkat.id + ''),
      payload.tingkat,
    ],
  };
};

const tingkatEdit = (state, payload) => {
  return {
    ...state,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.tingkat.id + ''),
      payload.tingkat,
    ],
  };
};

const tingkatGet = (state, payload) => {
  return {
    ...state,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.tingkat.id + ''),
      payload.tingkat,
    ],
  };
};

const tingkatDelete = (state, payload) => {
  return {
    ...state,
    total: parseInt(state.total) - 1,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.tingkat.id + ''),
    ],
  };
};

export default createReducer(initialState, {
  [TINGKATS_GET]: tingkatsGet,
  [TINGKAT_JENJANGS_GET]: tingkatJenjangsGet,
  [TINGKAT_ADD]: tingkatAdd,
  [TINGKAT_EDIT]: tingkatEdit,
  [TINGKAT_GET]: tingkatGet,
  [TINGKAT_DELETE]: tingkatDelete,
});
