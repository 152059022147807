import React, { useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

const DropzoneInput = ({
  profile,
  site,
  defaultPhoto,
  setFiles,
  setFileToLarge,
}) => {
  const maxSize = 200000;
  const onDrop = useCallback(
    (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    [setFiles]
  );
  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      onDrop,
      multiple: false,
      accept: 'image/*',
      minSize: 1000,
      maxSize: maxSize,
    });

  useEffect(() => {
    const isFileTooLarge =
    fileRejections[0] && fileRejections[0].file.size > maxSize
    if (isFileTooLarge) {
      setFileToLarge(true);
    } else {
      setFileToLarge(false);
    }
  }, [fileRejections, setFileToLarge]);

  return (
    <div
      {...getRootProps()}
      className={'dropzone ' + (isDragActive && 'dropzone--isActive')}
    >
      <input {...getInputProps()} />
      <figure id='profile-pic' className='image is-128x128'>
        <div>
          <div
            style={{
              width: 128,
              height: 128,
              backgroundImage: `url(${
                profile && profile.photo ? site + profile.photo : defaultPhoto
              })`,
              borderRadius: '50%',
              backgroundSize: 'cover',
            }}
          />
          {profile && !profile.photo && (
            <i
              className='is-size-6 mdi mdi-plus icon'
              style={{
                fontSize: 50,
                position: 'relative',
                bottom: 75,
                color: 'rgb(0 0 0 / 5%)',
              }}
            />
          )}
        </div>
      </figure>
    </div>
  );
};

export default DropzoneInput;
