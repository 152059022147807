import {
  asyncActionFinish,
  asyncActionError,
} from '../../../../app/common/reduxs/async/asyncActions';
import {
  portalusersGet,
  portaluserGet,
  portaluserEdit,
  portaluserSync,
} from './reduxAction';
import { ASYNC_ACTION_START } from '../../../../app/common/reduxs/async/asyncConstant';
import { checkErr } from '../../../../app/common/helpers/checkRes';
import { fetchFunc } from '../../../../app/common/helpers/fetchHelpers';
import { logsFunc } from '../../../../app/common/helpers/othersHelpers';

export const getPortalusers = (
  auth,
  history,
  itemPerPage,
  currentPage,
  searchText
) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getPortalusers' });
    const formData = new FormData();
    formData.append('itemPerPage', itemPerPage ? itemPerPage : 10);
    formData.append('currentPage', currentPage);
    formData.append('searchText', searchText);
    try {
      const res = await fetchFunc(
        'gets',
        auth,
        history,
        'portaluser/',
        formData,
        dispatch
      );
      const items = res.items;
      const total = res.total;
      const search = {
        currentPage,
        searchText,
        itemPerPage: itemPerPage ? itemPerPage : 10,
      };
      dispatch(portalusersGet({ total, items, search }));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const getPortaluser = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getPortaluser' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'get',
        auth,
        history,
        'portaluser/detail/' + id,
        formData,
        dispatch
      );
      const portaluser = res.portaluser;
      dispatch(portaluserGet(portaluser));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const editPortaluser = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'editPortaluser' });
    let logs = logsFunc('edit', auth.userid, auth.username, values.logs);
    const formData = new FormData();
    formData.append('uname', values.username);
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'portaluser/edit/' + values.id,
        formData,
        dispatch
      );
      const user = res.user;
      dispatch(portaluserEdit(user));
      history.push('/portaluser/edit/' + values.id);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const syncPortaluser = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'syncPortaluser' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'portaluser/sync',
        formData,
        dispatch
      );
      const portusers = res.portalusers;
      const portuserSync = res.portalusersSync;
      dispatch(
        portaluserSync({
          portalusers: portusers,
          portalusersSync: portuserSync,
        })
      );
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const syncSavePortaluser = (
  auth,
  history,
  portalusersMerge,
) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'syncSavePortaluser' });
    let logs = logsFunc('create', auth.userid, auth.username, null);
    const formData = new FormData();
    formData.append('syncData', JSON.stringify(portalusersMerge));
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'add',
        auth,
        history,
        'portaluser/sync-save/',
        formData,
        dispatch
      );
      const items = res.items;
      const total = res.total;
      dispatch(
        portalusersGet({
          total,
          items,
          search: {
            currentPage: '',
            searchText: '',
            itemPerPage: '',
          },
        })
      );
      history.push('/portal/portaluser');
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};



export const photoUploadPortaluser = (auth, history, image, filename) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'photoUploadPortaluser' });
    // const profileId = auth.userId;
    // const formData = new FormData();
    // formData.append('profileId', profileId);
    // formData.append('file', image);
    // formData.append('filename', filename);
    try {
      // const res = await fetchFunc(
      //   'edit',
      //   auth,
      //   history,
      //   'profile/upload-photo/' + auth.userId,
      //   formData,
      //   dispatch
      // );
      // const urlPhoto = res.urlPhoto;
      // dispatch(photoUpdate(urlPhoto));
      dispatch(asyncActionFinish());
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};
