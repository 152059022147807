import React from 'react';
import { Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';

export default function IndexListItems(props) {
  const { item, currentPage, itemPerPage, index, loading, scope } =
    props;
  if (loading)
    return (
      <tr>
        <td>Loading...</td>
      </tr>
    );

  const page = (currentPage - 1) * itemPerPage;

  const yStatus = <span className='tag is-info'>Yes</span>;

  const nStatus = (
    <span className='tag has-background-grey has-text-white'>No</span>
  );

  return (
    <tr>
      <td className='has-text-centered'>{page + index + 1}</td>
      <td>
        <div>{item.title}</div>{' '}
        <div className='tags is-capitalized'>
          {item &&
            item.tags &&
            JSON.parse(item.tags).map((item, index) => (
              <span key={index} className='tag is-info is-light'>
                {item}
              </span>
            ))}
        </div>
      </td>
      <td className='has-text-centered'>
        {item.published ? yStatus : nStatus}
      </td>
      <td className='has-text-centered is-capitalized'>
        {item && item.creator && JSON.parse(item.creator).username}
      </td>
      <td className='has-text-centered'>
        {item.createdAt &&
          format(parseISO(item.createdAt), 'd LLLL yyyy - HH:mm:ss')}
      </td>
      <td>
        <div className='content-wrap flex justify-center'>
          <div>
            {scope && scope.subm && scope.subm['tugas_view'] && (
              <Link
                to={`/pembelajaran/tugas/${item.id}`}
                className='button is-small is-rounded is-info is-outlined mr-2 mb-1'
              >
                <i className='is-size-6 mdi mdi-eye icon' />
              </Link>
            )}
          </div>
        </div>
      </td>
    </tr>
  );
}
