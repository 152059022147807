import {
  TINGKATS_GET,
  TINGKAT_JENJANGS_GET,
  TINGKAT_ADD,
  TINGKAT_EDIT,
  TINGKAT_GET,
  TINGKAT_DELETE,
} from './reduxConstant';

export const tingkatsGet = (tingkats) => {
  return {
    type: TINGKATS_GET,
    payload: {
      tingkats,
    },
  };
};

export const tingkatJenjangsGet = (jenjangs) => {
  return {
    type: TINGKAT_JENJANGS_GET,
    payload: {
      jenjangs,
    },
  };
};

export const tingkatAdd = (tingkat) => {
  return {
    type: TINGKAT_ADD,
    payload: {
      tingkat,
    },
  };
};

export const tingkatEdit = (tingkat) => {
  return {
    type: TINGKAT_EDIT,
    payload: {
      tingkat,
    },
  };
};

export const tingkatGet = (tingkat) => {
  return {
    type: TINGKAT_GET,
    payload: {
      tingkat,
    },
  };
};

export const tingkatDelete = (tingkat) => {
  return {
    type: TINGKAT_DELETE,
    payload: {
      tingkat,
    },
  };
};
