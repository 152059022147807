import React, { useState, useEffect } from 'react';
import SelectInputNoLabel from '../../../app/common/form/SelectInputNoLabel';
import RadioInput from '../../../app/common/form/RadioInput';
import { reduxForm, Field } from 'redux-form';

function SearchForm(props) {
  const {
    loading,
    jenjangs,
    tingkats,
    kelass,
    pelajarans,
    years,
    sems,
    handleSubmit,
    onFormSubmit,
    resetSearch,
  } = props;
  const [filteredKelas, setFilteredKelas] = useState([]);
  const [filteredPelajaran, setFilteredPelajaran] = useState([]);

  let jenjangSelect = [];
  jenjangs &&
    jenjangs.forEach((obj) => {
      let newObj = {
        key: obj.id,
        value: obj.code,
        text: obj.title,
      };
      jenjangSelect = [...jenjangSelect, newObj];
    });

  let tingkatSelect = [];
  tingkats &&
    tingkats.forEach((obj1) => {
      let newObj1 = {
        key: obj1.id,
        value: obj1.code,
        text: obj1.title,
      };
      tingkatSelect = [...tingkatSelect, newObj1];
    });

  let kelasSelect = [];
  kelass &&
    kelass.forEach((obj2) => {
      let newObj2 = {
        key: obj2.id,
        value: obj2.code,
        text: obj2.title,
      };
      kelasSelect = [...kelasSelect, newObj2];
    });

  let yearsSelect = [{ key: 0, text: 'Semua Tahun Ajaran', value: 'all year' }];
  years &&
    years.forEach((obj4) => {
      let newObj4 = {
        key: obj4.id,
        value: obj4.code,
        text: obj4.title,
      };
      yearsSelect = [...yearsSelect, newObj4];
    });

  let semsSelect = [];
  sems &&
    sems.forEach((obj5) => {
      let newObj5 = {
        key: obj5.id,
        value: obj5.code,
        text: obj5.title,
      };
      semsSelect = [...semsSelect, newObj5];
    });

  useEffect(() => {
    defFilteredKelas(kelass);
    defFilteredPelajaran(pelajarans, jenjangs);
  }, [kelass, pelajarans, jenjangs]);

  const onChangeJenjang = (e, name) => {
    let value = e.target.value;
    if (value) {
      let jenjangId;
      for (const obj of jenjangSelect) {
        if (obj.value === e.target.value) {
          jenjangId = obj.key;
        }
      }
      let newPelajaranSelect = [];
      for (const obj1 of pelajarans) {
        if (obj1.jenjangId === jenjangId) {
          let jenjangTitle = '';
          for (const objA of jenjangs) {
            if (objA.id === obj1.jenjangId) {
              jenjangTitle = ` - ${objA.title}`;
              break;
            }
          }
          let newObj1 = {
            key: obj1.id,
            value: obj1.code,
            text: `(${obj1.code}) ` + obj1.title + jenjangTitle,
            jenjangId: obj1.jenjangId,
          };
          newPelajaranSelect = [...newPelajaranSelect, newObj1];
        }
      }
      setFilteredPelajaran([...newPelajaranSelect]);
      let newKelasSelect = [];
      for (const obj1 of kelass) {
        if (obj1.jenjangId === jenjangId) {
          let newObj1 = {
            key: obj1.id,
            value: obj1.code,
            text: obj1.title,
            jenjangId: obj1.jenjangId,
          };
          newKelasSelect = [...newKelasSelect, newObj1];
        }
      }
      setFilteredKelas([...newKelasSelect]);
    } else {
      defFilteredPelajaran(pelajarans, jenjangs);
    }
  };

  const defFilteredKelas = (kelas) => {
    let kelasSelect = [];
    kelas.forEach((obj3) => {
      let newObj3 = {
        key: obj3.id,
        value: obj3.code,
        text: obj3.title,
        jenjangId: obj3.jenjangId,
      };
      kelasSelect = [...kelasSelect, newObj3];
    });
    setFilteredKelas([...kelasSelect]);
  };

  const defFilteredPelajaran = (pelajarans, jenjangs) => {
    let pelajaranSelect = [];
    pelajarans.forEach((obj3) => {
      let jenjangTitle = '';
      for (const objA of jenjangs) {
        if (objA.id === obj3.jenjangId) {
          jenjangTitle = ` - ${objA.title}`;
          break;
        }
      }
      let newObj3 = {
        key: obj3.id,
        value: obj3.code,
        text: `(${obj3.code}) ` + obj3.title + jenjangTitle,
        jenjangId: obj3.jenjangId,
      };
      pelajaranSelect = [...pelajaranSelect, newObj3];
    });
    setFilteredPelajaran([...pelajaranSelect]);
  };

  return (
    <>
      <section className='modal-card-body is-size-6'>
        <div className='columns'>
          <div className='column is-third-quarter is-capitalized'>
            <form onSubmit={handleSubmit(onFormSubmit)} autoComplete='off'>
              <div className='field is-horizontal'>
                <div className='field-body'>
                  <Field
                    name='year'
                    type='text'
                    component={SelectInputNoLabel}
                    placeholder='Tahun Ajaran'
                    label='Tahun Ajaran'
                    options={yearsSelect}
                    fullwidth={true}
                  />
                  <Field
                    name='sem'
                    type='text'
                    component={SelectInputNoLabel}
                    placeholder='Semester'
                    label='Semester'
                    options={semsSelect}
                    fullwidth={true}
                  />
                </div>
              </div>
              <div className='field is-horizontal'>
                <div className='field-body'>
                  <Field
                    name='jenjang'
                    type='text'
                    component={SelectInputNoLabel}
                    onChange={(e) => onChangeJenjang(e)}
                    placeholder='Jenjang'
                    label='Jenjang'
                    options={jenjangSelect}
                    fullwidth={true}
                  />
                  <Field
                    name='tingkat'
                    type='text'
                    component={SelectInputNoLabel}
                    placeholder='Tingkat'
                    label='Tingkat'
                    options={tingkatSelect}
                    fullwidth={true}
                  />
                </div>
              </div>
              <div className='field is-horizontal'>
                <div className='field-body'>
                  <Field
                    name='kelas'
                    component={SelectInputNoLabel}
                    placeholder='Pilih Kelas'
                    label='Kelas'
                    options={filteredKelas}
                    fullwidth={true}
                  />
                  <Field
                    name='pelajaran'
                    type='text'
                    component={SelectInputNoLabel}
                    placeholder='Pelajaran'
                    label='Pelajaran'
                    options={filteredPelajaran}
                    fullwidth={true}
                  />
                </div>
              </div>
              <div className='flex is-size-7 align-center'>
                <div className='ml-3 mr-2'>Publish : </div>
                <div className='ml-2 mr-2'>
                  <Field
                    name='published'
                    type='radio'
                    component={RadioInput}
                    value=''
                    placeholder='All'
                    label='All'
                    size='is-size-7'
                  />
                </div>
                <div className='ml-2 mr-2'>
                  <Field
                    name='published'
                    type='radio'
                    component={RadioInput}
                    value='true'
                    placeholder='Yes'
                    label='Yes'
                    size='is-size-7'
                  />
                </div>
                <div className='ml-2'>
                  <Field
                    name='published'
                    type='radio'
                    component={RadioInput}
                    value='false'
                    placeholder='No'
                    label='No'
                    size='is-size-7'
                  />
                </div>
              </div>
              <div className='field is-grouped mt-4'>
                <div className='control'>
                  <button
                    onClick={handleSubmit(onFormSubmit)}
                    className={
                      loading
                        ? 'button is-small is-info is-rounded is-outlined is-loading'
                        : 'button is-small is-info is-rounded is-outlined'
                    }
                  >
                    <i className='is-size-6 mdi mdi-magnify mr-1' /> Search
                  </button>
                  <button
                    onClick={resetSearch}
                    className='button is-small is-info is-rounded is-outlined ml-2'
                  >
                    <i className='is-size-6 mdi mdi-reload mr-1' /> Reset
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}

export default reduxForm({
  form: 'searchForm',
  enableReinitialize: true,
})(SearchForm);
