import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { addRole } from './redux/reduxApi';
import { Menus } from '../../app/common/data/menus';
import AddForm from './AddForm';

const mapState = (state, ownProps) => {
  return {
    loading: state.async.loading,
    auth: state.auth,
  };
};

const actions = {
  addRole,
};

function Add(props) {
  const { auth, history, loading, addRole } = props;

  const objFn = () => {
    const menus = [...Menus];
    let dataState = {};
    const dataM = [];
    const dataSubm = [];
    menus.forEach((m) => {
      dataM.push(m.id);
      dataState = { ...dataState, ...m.auth };
      if (m.subm.length > 0) {
        m.subm.forEach((subm) => {
          dataState = { ...dataState, ...subm.auth };
          dataSubm.push(subm.id);
        });
      }
    });

    dataState.title = null;
    dataState.dataM = dataM;
    dataState.dataSubm = dataSubm;
    return dataState;
  };

  // const onFormSubmit = async (values) => {
  //   try {
  //     const newUser = await addRole(auth, history, values);
  //     history.push('/setting/role/detail/' + newUser.id);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  return (
    <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
      <div className='p-1'>
        <div className='columns is-variable'>
          <div className='column is-fullwidth'>
            <div className='card'>
              <header className='card-header has-background-white has-text-info'>
                <div
                  className='custom-card-flex is-size-6'
                  style={{ width: '100%' }}
                >
                  <p>
                    <i className='mdi mdi-account-cog is-size-5 icon' /> Setting
                    <i className='mdi mdi-menu-right is-size-6 icon' />
                    Role
                  </p>
                </div>
              </header>
              <div className='card-content pl-4 pr-4'>
                <AddForm
                  initialValues={{}}
                  menus={Menus}
                  dataState={objFn}
                  loading={loading}
                  // onFormSubmit={onFormSubmit}
                  addRole={addRole}
                  auth={auth}
                  history={history}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(connect(mapState, actions)(Add));
