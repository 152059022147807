import React from 'react';
import { Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';

export default function IndexListItems(props) {
  const {
    item,
    currentPage,
    itemPerPage,
    index,
    loading,
    scope,
  } = props;
  if (loading)
    return (
      <tr>
        <td>Loading...</td>
      </tr>
    );

  const page = (currentPage - 1) * itemPerPage;

  const yesStatus = <span className='tag is-info'>Yes</span>;

  const noStatus = (
    <span className='tag has-background-grey has-text-white'>No</span>
  );

  const description = item?.description && JSON.parse(item?.description);

  return (
    <tr>
      <td className='has-text-centered'>{page + index + 1}</td>
      <td>{description?.nis}</td>
      <td>{description?.name}</td>
      <td className='has-text-centered'>{description?.activeClass}</td>
      <td className='has-text-centered'>{item?.deviceId ? yesStatus : noStatus}</td>
      <td className='has-text-centered is-capitalized'>{item?.deviceId ? item?.deviceId?.split('_')[0] : ''}</td>
      <td className='has-text-right'>
        {item?.createdAt &&
          format(parseISO(item.createdAt), 'd LLLL yyyy - HH:mm:ss')}
      </td>
      <td>
        <div className='content-wrap flex justify-center'>
          <div>
            {scope && scope.subm && scope.subm['portaluser_view'] === true && (
              <Link
                to={`/portal/portaluser/${item.id}`}
                className='button is-small is-rounded is-info is-outlined mr-2 mb-1'
              >
                <i className='is-size-6 mdi mdi-eye icon' />
              </Link>
            )}
          </div>
        </div>
      </td>
    </tr>
  );
}
