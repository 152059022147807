import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import { stringSimilarity } from 'string-similarity-js';

function ViewResultForm(props) {
  const { id, initialValues, keyAnswers } = props;
  const [scoreCount, setScoreCount] = useState(0);

  let questions = initialValues && initialValues.questions;
  let answers = initialValues && initialValues.answers;
  let tags = initialValues && initialValues.tags;

  useEffect(() => {
    function handleCountScore() {
      let count = 0;
      keyAnswers &&
        keyAnswers.forEach((ans, index) => {
          let kA = ans.answer + '';
          let keyAns = kA.trim();
          let sA = answers && answers[index] && answers[index].answer + '';
          let stAns = sA.trim();
          if (
            questions &&
            questions[0] &&
            questions[index]?.questionType === 'optionQuestion'
          ) {
            const string_chop = function (str, size) {
              if (str == null) return [];
              str = String(str);
              size = ~~size;
              return size > 0
                ? str.match(new RegExp('.{1,' + size + '}', 'g'))
                : [str];
            };
            let firstArray = stAns;
            if (stAns.length > keyAns.length) {
              firstArray = string_chop(stAns, keyAns.length)[0];
            }
            if (firstArray === keyAns) {
              count = count + 1;
            }
          }

          if (
            questions &&
            questions[0] &&
            questions[index]?.questionType === 'longQuestion'
          ) {
            let addScore = stringSimilarity(keyAns, stAns);
            count = count + addScore;
          }

          if (
            questions &&
            questions[0] &&
            questions[index]?.questionType === 'shortQuestion'
          ) {
            if (keyAns?.toLowerCase() === stAns?.toLowerCase()) {
              count = count + 1;
            }
          }
        });
      let keyAnswersLength = keyAnswers && keyAnswers.length;
      let score = (count / keyAnswersLength) * 100;
      setScoreCount(score);
    }
    handleCountScore();
  }, [keyAnswers, answers, questions]);

  const convertScore = (scoreCount) => {
    if (scoreCount === null) {
      return 'calculate';
    } else if (scoreCount === 0) {
      return 0;
    } else if (scoreCount > 0) {
      return scoreCount.toFixed(1);
    }
  };

  return (
    <>
      <div className='flex justify-between mb-5'>
        <div className='has-text-weight-bold custom-text-overflow'>
          {initialValues?.title}
        </div>
        <div className='flex justify-end'>
          <div className='buttons'>
            <Link
              to={'/pembelajaran/latihan/' + id}
              className='button custom-grey is-small is-rounded is-outlined'
            >
              <i className='is-size-6 mdi mdi-arrow-left icon' />
            </Link>
          </div>
        </div>
      </div>
      <div className='flex flex-column mb-4'>
        <article className='message is-info'>
          <div className='message-body is-size-7'>
            <p>Deskripsi:</p>
            <p className='is-capitalized' style={{ whiteSpace: 'pre-line' }}>
              {initialValues?.description}
            </p>
          </div>
        </article>
      </div>
      <br />
      <div className='columns'>
        <div className='column is-third-quarter'>
          <div className='content'>
            <div className='has-text-centered'>
              <p>Your Score</p>
              <p
                style={{
                  marginTop: -30,
                  marginBottom: 0,
                  fontSize: 80,
                  minHeight: 100,
                }}
              >
                {convertScore(scoreCount) === 'calculate' && (
                  <span className='is-size-5'>Calculating...</span>
                )}
                <span>{convertScore(scoreCount)}</span>
              </p>
              <p>Selesai dalam {initialValues.time}</p>
              <div className='flex justify-center'>
                <Link
                  to={{
                    pathname: '/pembelajaran/latihan/review/' + id,
                    state: initialValues,
                  }}
                  className='button is-small is-rounded is-info is-outlined ml-1'
                >
                  Review
                </Link>
                <Link
                  to={`/pembelajaran/latihan/` + id}
                  className='button is-small is-rounded is-info is-outlined ml-1'
                >
                  Kembali
                </Link>
              </div>
            </div>
            <hr />
            <label className='label'>Tags:</label>
            <div className='tags is-capitalized'>
              {tags &&
                tags.map((item, index) => (
                  <span key={index} className='tag is-success is-light'>
                    {item}
                  </span>
                ))}
              {!tags && <>-</>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default reduxForm({
  form: 'latihanResult',
  enableReinitialize: true,
})(ViewResultForm);
