import React from 'react';
import ListItems from './IndexListItems';

export default function IndexList(props) {
  const {
    loading,
    scope,
    items,
    jenjangs,
    tingkats,
    title,
    currentPage,
    itemPerPage,
    onDelete,
  } = props;
  return (
    <>
      {!loading && items &&
        items.length !== 0 &&
        items.map((item, index) => (
          <ListItems
            key={item.id}
            index={index}
            item={item}
            jenjangs={jenjangs}
            tingkats={tingkats}
            currentPage={currentPage}
            itemPerPage={itemPerPage}
            loading={loading}
            scope={scope}
            onDelete={onDelete}
          />
        ))}
      {!loading && items && items.length === 0 && (
        <tr>
          <td colSpan='7'>Tidak ada <span className='is-lowercase'>{title}</span></td>
        </tr>
      )}
    </>
  );
}
