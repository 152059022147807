import {
  TUGASS_GET,
  TUGAS_ADD,
  TUGAS_GET,
  STUDENT_ANSWER_GET,
  TUGAS_EDIT,
  TUGAS_UPLOADFILE,
  TUGAS_DELETE,
  TUGAS_RESET_SEARCH,
  TUGAS_DELETESTUDENTANSWER,
} from './reduxConstant';
import { createReducer } from '../../../../app/common/utilities/reducerUtils';

const initialState = {
  total: 0,
  search: {
    year: '',
    sem: '',
    jenjang: '',
    tingkat: '',
    kelas: '',
    pelajaran: '',
    published: 'all',
  },
  items: [],
  studentAnswer: [],
  uploadFiles: [],
};

const tugassGet = (state, payload) => {
  const tugass = payload.tugass;
  return {
    ...state,
    total: tugass.total,
    items: [...tugass.items],
    search: { ...tugass.search },
  };
};

const tugasAdd = (state, payload) => {
  return {
    ...state,
    total: parseInt(state.total) + 1,
    items: [...state.items, payload.tugas],
  };
};

const tugasGet = (state, payload) => {
  return {
    ...state,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.tugas.id + ''),
      payload.tugas,
    ],
  };
};

const studentAnswerGet = (state, payload) => {
  return {
    ...state,
    studentAnswer: [...payload.answers],
  };
};

const tugasEdit = (state, payload) => {
  return {
    ...state,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.tugas.id + ''),
      payload.tugas,
    ],
  };
};

const tugasUploadFile = (state, payload) => {
  let uploadFile = payload.uploadFile;
  let uploadFiles = (state.uploadFiles && state.uploadFiles) ? state.uploadFiles : [];
  let filteredUploadFiles = uploadFiles.filter(
    (i) => i.key !== uploadFile.key
  );
  let newUploadFiles = [...filteredUploadFiles, uploadFile];
  return {
    ...state,
    uploadFiles: newUploadFiles,
  };
};

const tugasDeleteStudentAnswer = (state, payload) => {
  return {
    ...state,
    studentAnswer: [],
  };
};

const tugasDelete = (state, payload) => {
  return {
    ...state,
    total: parseInt(state.total) - 1,
    items: [...state.items.filter((i) => i.id + '' !== payload.tugas.id + '')],
  };
};

const tugasResetSearch = (state, payload) => {
  return {
    ...state,
    search: {
      year: '',
      sem: '',
      jenjang: '',
      tingkat: '',
      kelas: '',
      pelajaran: '',
      published: 'all',
    },
  };
};

export default createReducer(initialState, {
  [TUGASS_GET]: tugassGet,
  [TUGAS_ADD]: tugasAdd,
  [TUGAS_GET]: tugasGet,
  [STUDENT_ANSWER_GET]: studentAnswerGet,
  [TUGAS_EDIT]: tugasEdit,
  [TUGAS_UPLOADFILE]: tugasUploadFile,
  [TUGAS_DELETESTUDENTANSWER]: tugasDeleteStudentAnswer,
  [TUGAS_DELETE]: tugasDelete,
  [TUGAS_RESET_SEARCH]: tugasResetSearch,
});
