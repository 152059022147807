import React, { useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';
import {
  composeValidators,
  combineValidators,
  isRequired,
  hasLengthGreaterThan,
} from 'revalidate';
import MultiSelect from '../../../app/common/form/MultiSelect';
import TextInput from '../../../app/common/form/TextInput';
import TextArea from '../../../app/common/form/TextArea';
import ReactTooltip from 'react-tooltip';
import { toastr } from 'react-redux-toastr';
import { nanoid } from 'nanoid';

const validate = combineValidators({
  recipients: composeValidators(isRequired({ message: 'Siswa harus diisi' }))(),
  messages: composeValidators(
    isRequired({ message: 'Pesan harus diisi' }),
    hasLengthGreaterThan(4)({
      message: 'Pesan harus memiliki paling sedikit 5 karakter',
    })
  )(),
});

let onChangeRecipients = [];
const handleOnFormChange = (newValues, dispatch, props, previousValues) => {
  const { recipients: newRecipients } = newValues;
  onChangeRecipients = newRecipients;
};

function Add(props) {
  const {
    messageId,
    auth,
    history,
    invalid,
    loading,
    pristine,
    handleSubmit,
    openModal,
    portalusersOption,
  } = props;

  const [stateVariable, setStateVariable] = useState({
    status: false,
    count: 0,
  });

  const onChangeVar = (e, val) => {
    e.preventDefault();
    setStateVariable({
      status: val,
      count: stateVariable.count++,
    });
  };

  const onBlurRecipient = () => {
    setStateVariable({
      status: stateVariable.status,
      count: stateVariable.count++,
    });
  };

  const onFormSubmit = async (values) => {
    let recipients = values?.recipients;
    let newRecipients = [];
    if (stateVariable && recipients[0]?.id) {
      for (const obj of recipients) {
        let arrVariable = values[`variable_${obj.userId}`]
          ? values[`variable_${obj.userId}`].split(';').filter((n) => n)
          : [];
        let newObj = {
          ...obj,
          messageId: messageId,
          studentMessageId: nanoid(),
          variable: arrVariable,
        };
        let newMessages = values.messages;
        if (stateVariable?.status) {
          let messages = values?.messages?.includes('[1]') ? true : false;
          if (!messages) {
            toastr.warning(
              'Terjadi Kesalahan',
              'Format pesan menggunakan variabel salah'
            );
            return;
          }
          if (messages && arrVariable[0]) {
            newMessages = values.messages;
            for (let i = 0; i < arrVariable.length; i++) {
              const item = arrVariable[i];
              if (!newMessages.includes(`[${i + 1}]`)) {
                toastr.warning(
                  'Terjadi Kesalahan',
                  'pada variabel ' + obj.label
                );
                return;
              }
              newMessages = newMessages.split(`[${i + 1}]`).join(item);
            }
          }
        }
        newRecipients.push({
          ...newObj,
          messages: newMessages,
        });
      }
    }
    let newVal = {
      ...values,
      messageId,
      recipients: newRecipients,
      withVar: stateVariable.status,
    };
    openModal('PesanPreview', {
      data: {
        auth,
        history,
        item: newVal,
      },
    });
  };

  return (
    <>
      <div className='level'>
        <div className='level-left'>
          <div className='level-item'>
            <nav className='breadcrumb is-size-6' aria-label='breadcrumbs'>
              <ul>
                <li className='is-active has-text-weight-bold'>
                  <Link to={'/portal/pesanpribadi/' + messageId}>Tambah</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className='level-right'>
          <div className='level-item'>
            <div className='buttons'>
              <button
                disabled={invalid || loading || pristine}
                onClick={handleSubmit(onFormSubmit)}
                className={
                  loading
                    ? 'button is-small is-info is-rounded is-outlined is-loading'
                    : 'button is-small is-info is-rounded is-outlined'
                }
              >
                <i className='is-size-6 mdi mdi-content-save icon' />
              </button>
              <button
                type='button'
                onClick={history.goBack}
                className='button custom-grey is-small is-rounded is-outlined'
              >
                <i className='is-size-6 mdi mdi-arrow-left icon' />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='columns'>
        <div className='column is-third-quarter'>
          <form onSubmit={handleSubmit(onFormSubmit)} autoComplete='off'>
            <div onBlur={() => onBlurRecipient()}>
              <Field
                name='recipients'
                type='text'
                component={MultiSelect}
                placeholder='Siswa'
                label='Siswa'
                options={portalusersOption}
                fullwidth={true}
              />
            </div>
            <Field
              name='title'
              type='text'
              component={TextInput}
              placeholder='Judul'
              label='Judul Pesan'
            />
            <Field
              name='messages'
              type='text'
              component={TextArea}
              placeholder='Isi Pesan'
              label='Isi Pesan'
            />
            <div className='flex justify-end'>
              <span
                data-for='main'
                data-tip={
                  stateVariable.status
                    ? 'Menghilangkan variable...'
                    : 'Menambahkan variable dengan ["Angka"], ex: [1], dst...'
                }
              >
                {stateVariable.status && (
                  <button
                    onClick={(e) => onChangeVar(e, false)}
                    className='button is-small is-rounded is-outlined'
                  >
                    <i className='is-size-6 mdi mdi-minus icon mr-1' />
                    Variabel
                  </button>
                )}
                {!stateVariable.status && (
                  <button
                    onClick={(e) => onChangeVar(e, true)}
                    className='button is-small is-rounded is-outlined'
                  >
                    <i className='is-size-6 mdi mdi-plus icon mr-1' />
                    Variabel
                  </button>
                )}
                <ReactTooltip
                  id='main'
                  place='bottom'
                  type='dark'
                  effect='solid'
                  multiline={true}
                />
              </span>
            </div>
            <div className='table-container mt-3'>
              <table className='table is-bordered is-striped is-narrow is-hoverable is-fullwidth'>
                <tbody>
                  {stateVariable?.status &&
                    onChangeRecipients?.map((item, key) => (
                      <tr key={key}>
                        <td>
                          <Field
                            name={'variable_' + item.userId}
                            type='text'
                            component={TextInput}
                            placeholder='Isian 1;Isian 2'
                            label={item.label}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div
              className='field is-grouped'
              style={{ marginTop: 20, marginBottom: 20 }}
            >
              <div className='control'>
                <button
                  type='submit'
                  disabled={invalid || loading || pristine}
                  className={
                    loading
                      ? 'button is-info is-small is-rounded is-outlined is-loading'
                      : 'button is-info is-small is-rounded is-outlined'
                  }
                >
                  <i className='is-size-6 mdi mdi-file-find icon icon' />
                </button>
              </div>
              <div className='control'>
                <button
                  onClick={() => history.goBack()}
                  className='button custom-grey is-small is-rounded is-outlined'
                >
                  <i className='is-size-6 mdi mdi-arrow-left icon' />
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default withRouter(
  reduxForm({
    form: 'addPesanPribadi',
    onChange: handleOnFormChange,
    validate,
  })(Add)
);
