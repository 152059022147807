import React, { Component, Fragment } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import isAuthenticated from '../common/utilities/authWrapper';
import Navbar from '../navbar/Navbar';
import Login from '../login/Login';
import NotFound from '../common/layout/NotFound';
import ModalManager from '../modals/ModalManager';
import Dashboard from '../../menus/dashboard/Index';
import Profile from '../../menus/profile/Index';
import UsersIndex from '../../menus/users/Index';
import UserAdd from '../../menus/users/Add';
import UserView from '../../menus/users/View';
import UserEdit from '../../menus/users/Edit';
import RolesIndex from '../../menus/roles/Index';
import RoleAdd from '../../menus/roles/Add';
import RoleEdit from '../../menus/roles/Edit';
import RoleView from '../../menus/roles/View';
// import Appsetting from '../../menus/appSetting/Index';
import RppIndex from '../../menus/_features/rpp/Index';
import RppAdd from '../../menus/_features/rpp/Add';
import RppView from '../../menus/_features/rpp/View';
import RppEdit from '../../menus/_features/rpp/Edit';
import RppPublish from '../../menus/_features/rpp/Publish';
import MateriIndex from '../../menus/_features/materi/Index';
import MateriAdd from '../../menus/_features/materi/Add';
import MateriView from '../../menus/_features/materi/View';
import MateriEdit from '../../menus/_features/materi/Edit';
import MateriPublish from '../../menus/_features/materi/Publish';
import TugasIndex from '../../menus/_features/tugas/Index';
import TugasAdd from '../../menus/_features/tugas/Add';
import TugasEdit from '../../menus/_features/tugas/Edit';
import TugasView from '../../menus/_features/tugas/View';
import TugasViewResult from '../../menus/_features/tugas/ViewResult';
import TugasViewReview from '../../menus/_features/tugas/ViewReview';
import TugasPublish from '../../menus/_features/tugas/Publish';
import TugasScore from '../../menus/_features/tugas/ViewScore';
import LatihanIndex from '../../menus/_features/latihan/Index';
import LatihanAdd from '../../menus/_features/latihan/Add';
import LatihanEdit from '../../menus/_features/latihan/Edit';
import LatihanView from '../../menus/_features/latihan/View';
import LatihanViewResult from '../../menus/_features/latihan/ViewResult';
import LatihanViewReview from '../../menus/_features/latihan/ViewReview';
import LatihanPublish from '../../menus/_features/latihan/Publish';
import LatihanImport from '../../menus/_features/latihan/Import';
import TesIndex from '../../menus/_features/tes/Index';
import TesAdd from '../../menus/_features/tes/Add';
import TesEdit from '../../menus/_features/tes/Edit';
import TesView from '../../menus/_features/tes/View';
import TesPublish from '../../menus/_features/tes/Publish';
import CalendarIndex from '../../menus/_features/calendar/Index';
import CalendarAdd from '../../menus/_features/calendar/Add';
import CalendarEdit from '../../menus/_features/calendar/Edit';
import CalendarImport from '../../menus/_features/calendar/Import';
import PortaluserIndex from '../../menus/_features/portaluser/Index';
import PortaluserSync from '../../menus/_features/portaluser/Sync';
import PortaluserView from '../../menus/_features/portaluser/View';
import PesanpribadiIndex from '../../menus/_features/pesanpribadi/Index';
import PesanpribadiAdd from '../../menus/_features/pesanpribadi/Add';
import PesanpribadiView from '../../menus/_features/pesanpribadi/View';
import PesanpribadiImport from '../../menus/_features/pesanpribadi/Import';
import SlideshowIndex from '../../menus/_features/slideshow/Index';
import SlideshowAdd from '../../menus/_features/slideshow/Add';
import SlideshowView from '../../menus/_features/slideshow/View';
import SlideshowEdit from '../../menus/_features/slideshow/Edit';
import AnnouncementIndex from '../../menus/_features/announcement/Index';
import AnnouncementAdd from '../../menus/_features/announcement/Add';
import AnnouncementView from '../../menus/_features/announcement/View';
import AnnouncementEdit from '../../menus/_features/announcement/Edit';
import WhatsappIndex from '../../menus/_features/whatsapp/Index';
import WhatsappImport from '../../menus/_features/whatsapp/Import';
import WhatsappView from '../../menus/_features/whatsapp/View';
import WhatsappSetting from '../../menus/_features/whatsapp/Setting';
import JenjangIndex from '../../menus/_features/jenjang/Index';
import JenjangAdd from '../../menus/_features/jenjang/Add';
import JenjangView from '../../menus/_features/jenjang/View';
import JenjangEdit from '../../menus/_features/jenjang/Edit';
import TingkatIndex from '../../menus/_features/tingkat/Index';
import TingkatAdd from '../../menus/_features/tingkat/Add';
import TingkatView from '../../menus/_features/tingkat/View';
import TingkatEdit from '../../menus/_features/tingkat/Edit';
import KelasIndex from '../../menus/_features/kelas/Index';
import KelasAdd from '../../menus/_features/kelas/Add';
import KelasView from '../../menus/_features/kelas/View';
import KelasEdit from '../../menus/_features/kelas/Edit';
import KelasSync from '../../menus/_features/kelas/Sync';
import PelajaranIndex from '../../menus/_features/pelajaran/Index';
import PelajaranAdd from '../../menus/_features/pelajaran/Add';
import PelajaranView from '../../menus/_features/pelajaran/View';
import PelajaranEdit from '../../menus/_features/pelajaran/Edit';
import PelajaranSync from '../../menus/_features/pelajaran/Sync';
import PelajaranImport from '../../menus/_features/pelajaran/Import';
import YearIndex from '../../menus/_features/year/Index';
import YearAdd from '../../menus/_features/year/Add';
import YearView from '../../menus/_features/year/View';
import YearEdit from '../../menus/_features/year/Edit';
import SemIndex from '../../menus/_features/sem/Index';
import SemAdd from '../../menus/_features/sem/Add';
import SemView from '../../menus/_features/sem/View';
import SemEdit from '../../menus/_features/sem/Edit';
import Test from '../../menus/tests/Index';
import PrintPage from '../../menus/tests/PrintPage';

class App extends Component {
  render() {
    return (
      <Fragment>
        <ModalManager />
        <Fragment>
          <Navbar />
          <Switch key={this.props.location.key}>
            <Route exact path='/' component={Login} />
            <Route exact path='/login' component={Login} />
            <Route
              exact
              path='/dashboard'
              component={isAuthenticated(Dashboard)}
            />
            <Route path='/profil' component={isAuthenticated(Profile)} />
            <Route
              exact
              path='/setting/user'
              component={isAuthenticated(UsersIndex)}
            />
            <Route
              exact
              path='/setting/user/tambah'
              component={isAuthenticated(UserAdd)}
            />
            <Route
              exact
              path='/setting/user/edit/:id'
              component={isAuthenticated(UserEdit)}
            />
            <Route
              exact
              path='/setting/user/:id'
              component={isAuthenticated(UserView)}
            />
            <Route
              exact
              path='/setting/role'
              component={isAuthenticated(RolesIndex)}
            />
            <Route
              exact
              path='/setting/role/tambah'
              component={isAuthenticated(RoleAdd)}
            />
            <Route
              exact
              path='/setting/role/edit/:id'
              component={isAuthenticated(RoleEdit)}
            />
            <Route
              exact
              path='/setting/role/:id'
              component={isAuthenticated(RoleView)}
            />
            {/* <Route
              exact
              path='/setting/app-setting'
              component={isAuthenticated(Appsetting)}
            /> */}
            <Route exact path='/rpp' component={isAuthenticated(RppIndex)} />
            <Route
              exact
              path='/rpp/tambah/:id'
              component={isAuthenticated(RppAdd)}
            />
            <Route
              exact
              path='/rpp/edit/:id'
              component={isAuthenticated(RppEdit)}
            />
            <Route
              exact
              path='/rpp/publish/:id'
              component={isAuthenticated(RppPublish)}
            />
            <Route exact path='/rpp/:id' component={isAuthenticated(RppView)} />
            <Route
              exact
              path='/pembelajaran/materi'
              component={isAuthenticated(MateriIndex)}
            />
            <Route
              exact
              path='/pembelajaran/materi/tambah/:id'
              component={isAuthenticated(MateriAdd)}
            />
            <Route
              exact
              path='/pembelajaran/materi/edit/:id'
              component={isAuthenticated(MateriEdit)}
            />
            <Route
              exact
              path='/pembelajaran/materi/publish/:id'
              component={isAuthenticated(MateriPublish)}
            />
            <Route
              exact
              path='/pembelajaran/materi/:id'
              component={isAuthenticated(MateriView)}
            />
            <Route
              exact
              path='/pembelajaran/tugas'
              component={isAuthenticated(TugasIndex)}
            />
            <Route
              exact
              path='/pembelajaran/tugas/tambah/:id'
              component={isAuthenticated(TugasAdd)}
            />
            <Route
              exact
              path='/pembelajaran/tugas/edit/:id'
              component={isAuthenticated(TugasEdit)}
            />
            <Route
              exact
              path='/pembelajaran/tugas/result/:id'
              component={isAuthenticated(TugasViewResult)}
            />
            <Route
              exact
              path='/pembelajaran/tugas/review/:id'
              component={isAuthenticated(TugasViewReview)}
            />
            <Route
              exact
              path='/pembelajaran/tugas/publish/:id'
              component={isAuthenticated(TugasPublish)}
            />
            <Route
              exact
              path='/pembelajaran/tugas/penilaian/:id'
              component={isAuthenticated(TugasScore)}
            />
            <Route
              exact
              path='/pembelajaran/tugas/:id'
              component={isAuthenticated(TugasView)}
            />
            <Route
              exact
              path='/pembelajaran/latihan'
              component={isAuthenticated(LatihanIndex)}
            />
            <Route
              exact
              path='/pembelajaran/latihan/tambah/:id'
              component={isAuthenticated(LatihanAdd)}
            />
            <Route
              exact
              path='/pembelajaran/latihan/edit/:id'
              component={isAuthenticated(LatihanEdit)}
            />
            <Route
              exact
              path='/pembelajaran/latihan/result/:id'
              component={isAuthenticated(LatihanViewResult)}
            />
            <Route
              exact
              path='/pembelajaran/latihan/review/:id'
              component={isAuthenticated(LatihanViewReview)}
            />
            <Route
              exact
              path='/pembelajaran/latihan/publish/:id'
              component={isAuthenticated(LatihanPublish)}
            />
            <Route
              exact
              path='/pembelajaran/latihan/import'
              component={isAuthenticated(LatihanImport)}
            />
            <Route
              exact
              path='/pembelajaran/latihan/:id'
              component={isAuthenticated(LatihanView)}
            />
            <Route
              exact
              path='/pembelajaran/tes'
              component={isAuthenticated(TesIndex)}
            />
            <Route
              exact
              path='/pembelajaran/tes/tambah/:id'
              component={isAuthenticated(TesAdd)}
            />
            <Route
              exact
              path='/pembelajaran/tes/edit/:id'
              component={isAuthenticated(TesEdit)}
            />
            <Route
              exact
              path='/pembelajaran/tes/:id'
              component={isAuthenticated(TesView)}
            />
            <Route
              exact
              path='/pembelajaran/tes/publish/:id'
              component={isAuthenticated(TesPublish)}
            />
            <Route
              exact
              path='/calendar'
              component={isAuthenticated(CalendarIndex)}
            />
            <Route
              exact
              path='/calendar/tambah/:id'
              component={isAuthenticated(CalendarAdd)}
            />
            <Route
              exact
              path='/calendar/edit/:id'
              component={isAuthenticated(CalendarEdit)}
            />
            <Route
              exact
              path='/calendar/import'
              component={isAuthenticated(CalendarImport)}
            />
            <Route
              exact
              path='/portal/portaluser'
              component={isAuthenticated(PortaluserIndex)}
            />
            <Route
              exact
              path='/portal/portaluser/sync'
              component={isAuthenticated(PortaluserSync)}
            />
            <Route
              path='/portal/portaluser/:id'
              component={isAuthenticated(PortaluserView)}
            />
            <Route
              exact
              path='/portal/pesanpribadi'
              component={isAuthenticated(PesanpribadiIndex)}
            />
            <Route
              exact
              path='/portal/pesanpribadi/tambah/:id'
              component={isAuthenticated(PesanpribadiAdd)}
            />
            <Route
              exact
              path='/portal/pesanpribadi/impor/:id'
              component={isAuthenticated(PesanpribadiImport)}
            />
            <Route
              exact
              path='/portal/pesanpribadi/:id'
              component={isAuthenticated(PesanpribadiView)}
            />
            <Route
              exact
              path='/portal/whatsapp'
              component={isAuthenticated(WhatsappIndex)}
            />
            <Route
              exact
              path='/portal/whatsapp/impor/:id'
              component={isAuthenticated(WhatsappImport)}
            />
            <Route
              exact
              path='/portal/whatsapp/setting'
              component={isAuthenticated(WhatsappSetting)}
            />
            <Route
              exact
              path='/portal/whatsapp/:id'
              component={isAuthenticated(WhatsappView)}
            />
            <Route
              exact
              path='/portal/slideshow'
              component={isAuthenticated(SlideshowIndex)}
            />
            <Route
              exact
              path='/portal/slideshow/tambah/:id'
              component={isAuthenticated(SlideshowAdd)}
            />
            <Route
              exact
              path='/portal/slideshow/edit/:id'
              component={isAuthenticated(SlideshowEdit)}
            />
            <Route
              exact
              path='/portal/slideshow/:id'
              component={isAuthenticated(SlideshowView)}
            />{' '}
            <Route
              exact
              path='/portal/pengumuman'
              component={isAuthenticated(AnnouncementIndex)}
            />
            <Route
              exact
              path='/portal/pengumuman/tambah/:id'
              component={isAuthenticated(AnnouncementAdd)}
            />
            <Route
              exact
              path='/portal/pengumuman/edit/:id'
              component={isAuthenticated(AnnouncementEdit)}
            />
            <Route
              exact
              path='/portal/pengumuman/:id'
              component={isAuthenticated(AnnouncementView)}
            />
            <Route
              exact
              path='/akademik/jenjang'
              component={isAuthenticated(JenjangIndex)}
            />
            <Route
              exact
              path='/akademik/jenjang/tambah'
              component={isAuthenticated(JenjangAdd)}
            />
            <Route
              exact
              path='/akademik/jenjang/edit/:id'
              component={isAuthenticated(JenjangEdit)}
            />
            <Route
              exact
              path='/akademik/jenjang/:id'
              component={isAuthenticated(JenjangView)}
            />
            <Route
              exact
              path='/akademik/tingkat'
              component={isAuthenticated(TingkatIndex)}
            />
            <Route
              exact
              path='/akademik/tingkat/tambah'
              component={isAuthenticated(TingkatAdd)}
            />
            <Route
              exact
              path='/akademik/tingkat/edit/:id'
              component={isAuthenticated(TingkatEdit)}
            />
            <Route
              exact
              path='/akademik/tingkat/:id'
              component={isAuthenticated(TingkatView)}
            />
            <Route
              exact
              path='/akademik/kelas'
              component={isAuthenticated(KelasIndex)}
            />
            <Route
              exact
              path='/akademik/kelas/tambah'
              component={isAuthenticated(KelasAdd)}
            />
            <Route
              exact
              path='/akademik/kelas/edit/:id'
              component={isAuthenticated(KelasEdit)}
            />
            <Route
              exact
              path='/akademik/kelas/sync'
              component={isAuthenticated(KelasSync)}
            />
            <Route
              exact
              path='/akademik/kelas/:id'
              component={isAuthenticated(KelasView)}
            />
            <Route
              exact
              path='/akademik/pelajaran'
              component={isAuthenticated(PelajaranIndex)}
            />
            <Route
              exact
              path='/akademik/pelajaran/tambah'
              component={isAuthenticated(PelajaranAdd)}
            />
            <Route
              exact
              path='/akademik/pelajaran/edit/:id'
              component={isAuthenticated(PelajaranEdit)}
            />
            <Route
              exact
              path='/akademik/pelajaran/sync'
              component={isAuthenticated(PelajaranSync)}
            />
            <Route
              exact
              path='/akademik/pelajaran/import'
              component={isAuthenticated(PelajaranImport)}
            />
            <Route
              exact
              path='/akademik/pelajaran/:id'
              component={isAuthenticated(PelajaranView)}
            />
            <Route
              exact
              path='/akademik/year'
              component={isAuthenticated(YearIndex)}
            />
            <Route
              exact
              path='/akademik/year/tambah'
              component={isAuthenticated(YearAdd)}
            />
            <Route
              exact
              path='/akademik/year/edit/:id'
              component={isAuthenticated(YearEdit)}
            />
            <Route
              exact
              path='/akademik/year/:id'
              component={isAuthenticated(YearView)}
            />
            <Route
              exact
              path='/akademik/sem'
              component={isAuthenticated(SemIndex)}
            />
            <Route
              exact
              path='/akademik/sem/tambah'
              component={isAuthenticated(SemAdd)}
            />
            <Route
              exact
              path='/akademik/sem/edit/:id'
              component={isAuthenticated(SemEdit)}
            />
            <Route
              exact
              path='/akademik/sem/:id'
              component={isAuthenticated(SemView)}
            />
            <Route path='/test' component={Test} />
            <Route path='/test/printpage' component={PrintPage} />
            <Route path='/*' component={NotFound} />
          </Switch>
        </Fragment>
      </Fragment>
    );
  }
}

export default withRouter(App);
