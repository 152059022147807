import React from 'react';
import { Link } from 'react-router-dom';
import TextInput from '../../../app/common/form/TextInput';
import { reduxForm, Field } from 'redux-form';
// import MultiSelect from '../../../app/common/form/MultiSelect';

function ViewFormInfo(props) {
  const {
    // auth,
    id,
    // history,
    // pathname,
    handleSubmit,
    initialValues,
    onFormSubmit,
    // openModal,
    // scope,
  } = props;

  let description =
    initialValues?.description && JSON.parse(initialValues?.description);

  return (
    <>
      <div style={{ position: 'absolute', right: 15, top: 48 }}>
        {/* {!editState && (
        <button
          id='profileEdit'
          onClick={() => handleClickEdit()}
          className='button is-info is-small is-rounded is-outlined'
        >
          <i className='is-size-6 mdi mdi-pencil icon' />
        </button>
      )} */}
        {/* {editState && ( */}
        <Link
          to='/portal/portaluser'
          // onClick={handleSubmit(onFormSubmit)}
          className='button custom-grey is-small is-rounded is-outlined'
        >
          <i className='is-size-6 mdi mdi-arrow-left icon' />
        </Link>
        {/* )} */}
      </div>
      <div className='level'>
        <div className='level-left'>
          <div className='level-item'>
            <nav className='breadcrumb is-size-6' aria-label='breadcrumbs'>
              <ul>
                <li className='is-active has-text-weight-bold'>
                  <Link to={'/portal/portaluser/' + id}>Detail</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className='level-right'>
          <div className='level-item'></div>
        </div>
      </div>
      <div className='columns'>
        <div className='column is-third-quarter'>
          <form onSubmit={handleSubmit(onFormSubmit)} autoComplete='off'>
            <div className='field is-horizontal'>
              <div className='field-body'>
                <Field
                  name='nis'
                  type='text'
                  component={TextInput}
                  defaultValue={description?.nis ?? ''}
                  placeholder='NIS'
                  label='NIS'
                  readOnly={true}
                />
                <Field
                  name='name'
                  type='text'
                  component={TextInput}
                  defaultValue={description?.name ?? ''}
                  placeholder='Nama Siswa'
                  label='Nama Siswa'
                  readOnly={true}
                />
              </div>
            </div>
            <div className='field is-horizontal'>
              <div className='field-body'>
                <Field
                  name='noAbsen'
                  type='text'
                  component={TextInput}
                  defaultValue={description?.noAbsen ?? ''}
                  placeholder='No Absen'
                  label='No Absen'
                  readOnly={true}
                />
                <Field
                  name='activeClass'
                  type='text'
                  component={TextInput}
                  defaultValue={description?.activeClass ?? ''}
                  placeholder='Kelas Aktif'
                  label='Kelas Aktif'
                  readOnly={true}
                />
              </div>
            </div>
            <div className='field is-horizontal'>
              <div className='field-body'>
                <Field
                  name='fatherName'
                  type='text'
                  component={TextInput}
                  defaultValue={description?.fatherName ?? ''}
                  placeholder='Nama Ayah'
                  label='Nama Ayah'
                  readOnly={true}
                />
                <Field
                  name='fatherPhoneNumber'
                  type='text'
                  component={TextInput}
                  defaultValue={description?.fatherPhoneNumber ?? ''}
                  placeholder='No HP Ayah'
                  label='No HP Ayah'
                  readOnly={true}
                />
              </div>
            </div>
            <div className='field is-horizontal'>
              <div className='field-body'>
                <Field
                  name='motherName'
                  type='text'
                  component={TextInput}
                  defaultValue={description?.motherName ?? ''}
                  placeholder='Nama Ibu'
                  label='Nama Ibu'
                  readOnly={true}
                />
                <Field
                  name='motherPhoneNumber'
                  type='text'
                  component={TextInput}
                  defaultValue={description?.motherPhoneNumber ?? ''}
                  placeholder='No HP Ibu'
                  label='No HP Ibu'
                  readOnly={true}
                />
              </div>
            </div>
            <div className='field is-horizontal'>
              <div className='field-body'>
                <Field
                  name='guardianName'
                  type='text'
                  component={TextInput}
                  defaultValue={description?.guardianName ?? ''}
                  placeholder='Nama Wali'
                  label='Nama Wali'
                  readOnly={true}
                />
                <Field
                  name='guardianPhoneNumber'
                  type='text'
                  component={TextInput}
                  defaultValue={description?.guardianPhoneNumber ?? ''}
                  placeholder='No HP Wali'
                  label='No HP Wali'
                  readOnly={true}
                />
              </div>
            </div>
          </form>
          <div className='field mt-3'>
            <label className='label'>Status Aktif</label>
          </div>
          <div className='tags has-addons'>
            <span
              className={
                initialValues?.status
                  ? 'tag'
                  : 'tag has-background-grey has-text-white'
              }
            >
              No
            </span>
            <span className={initialValues?.status ? 'tag is-info' : 'tag'}>
              Yes
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default reduxForm({
  form: 'portaluserViewInfo',
  enableReinitialize: true,
})(ViewFormInfo);
