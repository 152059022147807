import {
  ANNOUNCEMENTS_GET,
  ANNOUNCEMENT_ADD,
  ANNOUNCEMENT_EDIT,
  ANNOUNCEMENT_GET,
  ANNOUNCEMENT_UPLOADFILE,
  ANNOUNCEMENT_DELETE,
} from './reduxConstant';

export const announcementsGet = (announcements) => {
  return {
    type: ANNOUNCEMENTS_GET,
    payload: {
      announcements,
    },
  };
};

export const announcementAdd = (announcement) => {
  return {
    type: ANNOUNCEMENT_ADD,
    payload: {
      announcement,
    },
  };
};

export const announcementEdit = (announcement) => {
  return {
    type: ANNOUNCEMENT_EDIT,
    payload: {
      announcement,
    },
  };
};

export const announcementGet = (announcement) => {
  return {
    type: ANNOUNCEMENT_GET,
    payload: {
      announcement,
    },
  };
};

export const announcementUploadFile = (uploadFile) => {
  return {
    type: ANNOUNCEMENT_UPLOADFILE,
    payload: {
      uploadFile,
    },
  };
};

export const announcementDelete = (announcement) => {
  return {
    type: ANNOUNCEMENT_DELETE,
    payload: {
      announcement,
    },
  };
};
