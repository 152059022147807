import React, { useEffect, useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import TextInput from '../../app/common/form/TextInput';
import { reduxForm, Field } from 'redux-form';

function ViewForm(props) {
  const {
    auth,
    history,
    id,
    initialValues,
    handleSubmit,
    menus,
    dataState,
    scope,
    openModal
  } = props;
  const [state, setState] = useState({});

  const dataAuth =
    initialValues && initialValues.authorities
      ? JSON.parse(initialValues.authorities)
      : {};
  const authM = dataAuth && dataAuth.m ? dataAuth.m : {};
  const authSubm = dataAuth && dataAuth.subm ? dataAuth.subm : {};

  useEffect(() => {
    let data = dataState();
    if (dataAuth && dataAuth.subm) {
      data = { ...data, ...authM, ...authSubm };
    }
    setState({ ...state, ...data });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClikRoleDelete = () => {
    openModal('RoleDelete', {
      data: {
        auth,
        history,
        item: initialValues,
      },
    });
  };

  const handleInput = (event) => {};

  const handleClickSubmit = async (values) => {};

  return (
    <>
      <div className='level'>
        <div className='level-left'>
          <div className='level-item'>
            <nav className='breadcrumb is-size-6' aria-label='breadcrumbs'>
              <ul>
                <li className='is-active has-text-weight-bold'>
                  <Link to={'/setting/role/' + id}>Detail</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className='level-right'>
          <div className='level-item'>
            <div className='buttons'>
              {scope && scope.subm && scope.subm['role_edit'] && (
              <Link
                type='button'
                to={'/setting/role/edit/' + initialValues.id}
                className='button info is-small is-rounded is-outlined'
              >
                <i className='is-size-6 mdi mdi-pencil icon' />
              </Link>)}
              {scope && scope.subm && scope.subm['role_delete'] && (
                <button
                  className='button is-small is-rounded is-danger is-outlined'
                  onClick={() => handleClikRoleDelete()}
                >
                  <i className='is-size-6 mdi mdi-delete icon' />
                </button>
              )}
              <Link
                type='button'
                to={'/setting/role'}
                className='button custom-grey is-small is-rounded is-outlined'
              >
                <i className='is-size-6 mdi mdi-arrow-left icon' />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='columns'>
        <div className='column is-third-quarter'>
          <form onSubmit={handleSubmit(handleClickSubmit)} autoComplete='off'>
            <Field
              name='title'
              type='text'
              component={TextInput}
              defaultValue={initialValues.title ?? ''}
              placeholder='Nama Role'
              label='Nama Role'
              readOnly={true}
            />
          </form>
          <table
            className='table is-bordered is-striped is-narrow is-hoverable is-fullwidth'
            style={{ marginTop: 15 }}
          >
            <thead>
              <tr>
                <th className='has-text-centered' style={{ width: 40 }}>
                  No
                </th>
                <th>
                  <p style={{ marginLeft: 20 }}>Detail Akses</p>
                </th>
              </tr>
            </thead>
            <tbody>
              {menus &&
                menus.map((m, i) => (
                  <Fragment key={m.url}>
                    <tr key={m.id + '_view'}>
                      <td>
                        <p
                          className='has-text-centered'
                          style={{ marginTop: 15 }}
                        >
                          {1 + i++}
                        </p>
                      </td>
                      <td>
                        <div className='field' style={{ marginLeft: 20 }}>
                          <div className='control'>
                            <label className='custom-checkbox is-capitalized'>
                              View {m.id}
                              <input
                                name={m.id + '_view'}
                                type='checkbox'
                                checked={!!state[m.id + '_view']}
                                onChange={(event) => handleInput(event)}
                                readOnly={true}
                              />
                              <span className='checkmark' />
                            </label>
                          </div>
                        </div>
                      </td>
                    </tr>
                    {state[m.id + '_view'] === true &&
                      Object.keys(m.auth).length > 0 &&
                      Object.keys(m.auth).map((authM) => {
                        if (authM.split('_')[1] === 'view')
                          return <Fragment key={authM + '_1'}></Fragment>;
                        return (
                          <tr key={authM}>
                            <td></td>
                            <td>
                              <div className='field' style={{ marginLeft: 60 }}>
                                <div className='control'>
                                  <label className='custom-checkbox is-capitalized'>
                                    {`${authM.split('_')[1]} ${
                                      authM.split('_')[0]
                                    }`}
                                    <input
                                      name={authM}
                                      type='checkbox'
                                      checked={!!state[authM]}
                                      onChange={(event) => handleInput(event)}
                                      readOnly={true}
                                    />
                                    <span className='checkmark' />
                                  </label>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    {m.subm.length > 0 &&
                      state[m.id + '_view'] === true &&
                      m.subm.map((subm, j) => (
                        <Fragment key={subm.url + subm.id + '_view'}>
                          <tr key={subm.id + '_view'}>
                            <td></td>
                            <td>
                              <div className='field' style={{ marginLeft: 60 }}>
                                <div className='control'>
                                  <label className='custom-checkbox is-capitalized'>
                                    {'View ' + subm.id}
                                    <input
                                      name={subm.id + '_view'}
                                      type='checkbox'
                                      checked={!!state[subm.id + '_view']}
                                      onChange={(event) => handleInput(event)}
                                      readOnly={true}
                                    />
                                    <span className='checkmark' />
                                  </label>
                                </div>
                              </div>
                            </td>
                          </tr>
                          {state[subm.id + '_view'] === true &&
                            Object.keys(subm.auth).map((authSubm) => {
                              if (authSubm.split('_')[1] === 'view')
                                return (
                                  <Fragment key={authSubm + '_1'}></Fragment>
                                );
                              return (
                                <tr key={authSubm}>
                                  <td></td>
                                  <td>
                                    <div
                                      className='field'
                                      style={
                                        authSubm.split('_')[1] === 'view'
                                          ? { marginLeft: 60 }
                                          : { marginLeft: 90 }
                                      }
                                    >
                                      <div className='control'>
                                        <label className='custom-checkbox is-capitalized'>
                                          {`${authSubm.split('_')[1]} ${
                                            authSubm.split('_')[0]
                                          }`}
                                          <input
                                            name={authSubm}
                                            type='checkbox'
                                            checked={!!state[authSubm]}
                                            onChange={(event) =>
                                              handleInput(event)
                                            }
                                          />
                                          <span className='checkmark' />
                                        </label>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                        </Fragment>
                      ))}
                  </Fragment>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default reduxForm({
  form: 'roleView',
  enableReinitialize: true,
})(ViewForm);
