import React from 'react';
import { reduxForm } from 'redux-form';
import { combineValidators } from 'revalidate';

const handleOnFormChange = (newValues, dispatch, props, previousValues) => {};

const validate = combineValidators({});

function ViewFormPesan() {
  return (
    <form autoComplete='off'>
      <div className='table-container'>
        <table className='table is-bordered is-striped is-narrow is-hoverable is-fullwidth'>
          <thead>
            <tr>
              <th className='has-text-centered'>Isi Pesan</th>
              <th className='has-text-centered'>Dibaca</th>
              <th className='has-text-centered'>Dibuat</th>
              <th className='has-text-centered'>Kreator</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>Judul Pesan</p>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Maxime, eveniet! Magni non doloremque aut nihil soluta
                  exercitationem esse delectus est ad ratione. Sit, quis esse!
                  Rerum totam natus voluptas autem!
                </p>
              </td>
              <td className='has-text-centered'>Ya</td>
              <td className='has-text-centered'>12 Januari 2023</td>
              <td>Admin</td>
            </tr>
          </tbody>
        </table>
      </div>
    </form>
  );
}

export default reduxForm({
  form: 'portaluserViewPesan',
  onChange: handleOnFormChange,
  validate,
})(ViewFormPesan);
