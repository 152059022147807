import React from 'react';
import { Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';

export default function IndexListItems(props) {
  const {
    item,
    currentPage,
    itemPerPage,
    index,
    loading,
    scope,
    resetPassword,
    onDelete,
  } = props;
  if (loading)
    return (
      <tr>
        <td>Loading...</td>
      </tr>
    );

  const page = (currentPage - 1) * itemPerPage;

  const yesStatus = <span className='tag is-info'>Yes</span>;

  const noStatus = (
    <span className='tag has-background-grey has-text-white'>No</span>
  );

  return (
    <tr>
      <td className='has-text-centered'>{page + index + 1}</td>
      <td>{item.username}</td>
      <td className='has-text-centered'>{item.status ? yesStatus : noStatus}</td>
      <td className='has-text-centered'>
        {item.createdAt &&
          format(parseISO(item.createdAt), 'd LLLL yyyy - HH:mm:ss')}
      </td>
      <td>
        <div className='content-wrap flex justify-center'>
          <div>
            {scope.subm['user_view'] === true && (
              <Link
                to={`/setting/user/${item.id}`}
                className='button is-small is-rounded is-info is-outlined mr-2 mb-1'
              >
                <i className='is-size-6 mdi mdi-eye icon' />
              </Link>
            )}
            {scope.subm['user_edit'] === true && (
              <>
                {item.roles === 'SA' && (
                  <button
                    disabled
                    className='button is-small is-rounded is-info is-outlined mr-2 mb-1'
                  >
                    <i className='is-size-6 mdi mdi-pencil icon' />
                  </button>
                )}
                {item.roles !== 'SA' && (
                  <Link
                    to={`/setting/user/edit/${item.id}`}
                    className='button is-small is-rounded is-info is-outlined mr-2 mb-1'
                  >
                    <i className='is-size-6 mdi mdi-pencil icon' />
                  </Link>
                )}
                <button
                  disabled={item.roles === 'SA' ? true : false}
                  onClick={() => resetPassword(item)}
                  className='button is-small is-rounded is-info is-outlined mr-2 mb-1'
                >
                  <i className='is-size-6 mdi mdi-account-lock icon' />
                </button>
              </>
            )}
            {scope.subm['user_delete'] === true && (
              <button
                disabled={item.roles === 'SA' ? true : false}
                className='button is-small is-rounded is-danger is-outlined mb-1'
                onClick={() => onDelete(item)}
              >
                <i className='is-size-6 mdi mdi-delete icon' />
              </button>
            )}
          </div>
        </div>
      </td>
    </tr>
  );
}
