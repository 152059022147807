import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { addKelas } from './redux/reduxApi';
import AddForm from './AddForm';

const mapState = (state, ownProps) => {
  let jenjangs = [];
  if (state?.kelass?.jenjangs && state?.kelass?.jenjangs[0]) {
    jenjangs = state.kelass.jenjangs;
  }
  let tingkats = [];
  if (state?.kelass?.tingkats && state?.kelass?.tingkats[0]) {
    tingkats = state.kelass.tingkats;
  }
  return {
    loading: state.async.loading,
    auth: state.auth,
    jenjangs: jenjangs,
    tingkats: tingkats,
  };
};

const actions = {
  addKelas,
};

function Add(props) {
  const { auth, history, loading, jenjangs, tingkats, addKelas } = props;
  const [initialValues, setInitialValues] = useState({
    code: '',
    title: '',
    jenjangId: '',
    kelasId: '',
    description: '',
    jenjangSelect: [],
    tingkatSelect: [],
  });

  useEffect(() => {
    let jenjangSelect = [];
    jenjangs.forEach((j, i) => {
      jenjangSelect = [
        ...jenjangSelect,
        { key: i, value: j.id, text: j.title },
      ];
    });
    let tingkatSelect = [];
    tingkats.forEach((j, i) => {
      tingkatSelect = [
        ...tingkatSelect,
        { key: i, value: j.id, text: j.title },
      ];
    });
    setInitialValues({
      ...initialValues,
      jenjangSelect: jenjangSelect,
      tingkatSelect: tingkatSelect,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jenjangs, tingkats]);

  return (
    <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
      <div className='p-1'>
        <div className='columns is-variable'>
          <div className='column is-fullwidth'>
            <div className='card'>
              <header className='card-header has-background-white has-text-info'>
                <div
                  className='custom-card-flex is-size-6'
                  style={{ width: '100%' }}
                >
                  <p>
                    <i className='mdi mdi-school is-size-5 icon' /> Akademik
                    <i className='mdi mdi-menu-right is-size-6 icon' />
                    Kelas
                  </p>
                </div>
              </header>
              <div className='card-content pl-4 pr-4'>
                <AddForm
                  loading={loading}
                  initialValues={initialValues}
                  addKelas={addKelas}
                  auth={auth}
                  history={history}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(connect(mapState, actions)(Add));
