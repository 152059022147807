import {
  PESANPRIBADIS_GET,
  PESANPRIBADI_GET,
  PESANPRIBADIPORTALUSERS_GET,
  PESANPRIBADI_ADD,
  PESANPRIBADI_EDIT,
  PESANPRIBADI_SEND,
  PESANPRIBADI_REPORT,
  PESANPRIBADI_DELETE,
  PESANPRIBADI_IMPORT,
  PESANPRIBADI_UPLOADFILE,
} from './reduxConstant';

export const pesanpribadisGet = (pesanpribadis) => {
  return {
    type: PESANPRIBADIS_GET,
    payload: {
      pesanpribadis,
    },
  };
};

export const pesanpribadiPortalusersGet = (portalusers) => {
  return {
    type: PESANPRIBADIPORTALUSERS_GET,
    payload: {
      portalusers,
    },
  };
};

export const pesanpribadiAdd = (pesanpribadi) => {
  return {
    type: PESANPRIBADI_ADD,
    payload: {
      pesanpribadi,
    },
  };
};

export const pesanpribadiGet = (pesanpribadi) => {
  return {
    type: PESANPRIBADI_GET,
    payload: {
      pesanpribadi,
    },
  };
};

export const pesanpribadiEdit = (pesanpribadi) => {
  return {
    type: PESANPRIBADI_EDIT,
    payload: {
      pesanpribadi,
    },
  };
};

export const pesanpribadiSend = (pesanpribadi) => {
  return {
    type: PESANPRIBADI_SEND,
    payload: {
      pesanpribadi,
    },
  };
};

export const pesanpribadiReport = (reports) => {
  return {
    type: PESANPRIBADI_REPORT,
    payload: {
      reports,
    },
  };
};

export const pesanpribadiUploadFile = (uploadFile) => {
  return {
    type: PESANPRIBADI_UPLOADFILE,
    payload: {
      uploadFile,
    },
  };
};

export const pesanpribadiDelete = (pesanpribadi) => {
  return {
    type: PESANPRIBADI_DELETE,
    payload: {
      pesanpribadi,
    },
  };
};

export const pesanpribadiImport = (pesanpribadi) => {
  return {
    type: PESANPRIBADI_IMPORT,
    payload: {
      pesanpribadi,
    },
  };
};
